import { makeAutoObservable } from 'mobx'

import { IAdminVendorSiteSettings } from './types'
import { AdminVendorSiteDetailsStore } from './AdminVendorSiteDetailsStore'

export class AdminVendorSiteSettings implements IAdminVendorSiteSettings {
  store: AdminVendorSiteDetailsStore

  id: string
  type: 'adminVendorSiteReportSettingsRead'
  attributes: IAdminVendorSiteSettings['attributes']

  constructor(store: AdminVendorSiteDetailsStore, entity: IAdminVendorSiteSettings) {
    makeAutoObservable(this, {
      store: false
    })

    this.store = store

    this.id = entity.id
    this.type = entity.type
    this.attributes = { ...entity.attributes }
  }
}
