import { IHeaderItem, ITab, ITooltipMenuData } from '@doseme/cohesive-ui'

import { IAdminHospitalDrugListItem } from '../../../../../../store/Admin/AdminHospitalDrugList/types'

export const itemsPerPage = 10

export const hospitalDrugSettingsActionData = (
  drug: IAdminHospitalDrugListItem,
  isSuperAdmin: boolean,
  handleDrugDetailsButton: (drugId: string) => void,
  setShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectedDrugModel: (drug: IAdminHospitalDrugListItem) => void
): ITooltipMenuData[] => {
  const handleDelete = () => {
    setShowDeleteModal(true)
    setSelectedDrugModel(drug)
  }

  let drugSettingsActionData = [
    {
      id: 1,
      value: 'View model settings',
      onSelect: () => handleDrugDetailsButton(drug.id)
    }
  ]

  isSuperAdmin &&
    drugSettingsActionData.push({
      id: 2,
      value: 'Remove model from hospital',
      onSelect: () => handleDelete()
    })

  return drugSettingsActionData
}

export const hospitalDrugSettingsColumns = (
  handleSort: (colIndex: number, ascending: boolean) => void
): IHeaderItem[] => [
  {
    name: 'Name',
    handleSort,
    width: 10
  },
  {
    name: 'Default dosing method',
    width: 3
  },
  {
    name: '',
    width: 1
  }
]

export const drugSettingsTabs: ITab[] = [
  { id: 'viewByModel', displayName: 'View by model' },
  { id: 'viewBySettings', displayName: 'View by settings' }
]
