import { makeAutoObservable } from 'mobx'

import { IAdminHospitalListItem } from './types'
import { AdminHospitalListStore } from './AdminHospitalListStore'

export class AdminHospitalListItem implements IAdminHospitalListItem {
  store: AdminHospitalListStore

  id: string
  type: string
  attributes: IAdminHospitalListItem['attributes']

  constructor(store: AdminHospitalListStore, entity: IAdminHospitalListItem) {
    makeAutoObservable(this, {
      store: false
    })

    this.store = store

    this.id = entity.id
    this.type = entity.type
    this.attributes = { ...entity.attributes }
  }
}
