import { IAdminHospitalDrugSuperAdminInfoConstraints } from '../../../../../../../../store/Admin/AdminHospitalDrugDetails/types'

export const findPatientConstraint = (patientConstraints: IAdminHospitalDrugSuperAdminInfoConstraints[], id: string)
  : IAdminHospitalDrugSuperAdminInfoConstraints | undefined => {

  const foundConstraint = patientConstraints.filter((patientConstraint) => {
    return patientConstraint.id === id
  })

  return foundConstraint.length !== 1
    ? undefined
    : foundConstraint[0]
}
