import { makeAutoObservable } from 'mobx'

import axiosClient, { AxiosError, AxiosResponse } from '../../../utils/axiosClient'
import { RootStore } from '../../RootStore'
import { TLoadState } from '../../types'
import {
  adminAddVendorSiteGetUrl,
  adminVendorSiteCoreDetailsGetPutUrl,
  adminVendorSiteCoreSettingsGetPutUrl,
  adminVendorSiteDetailsGetUrl
} from '../../../constants/api'
import { AdminVendorSiteSettings } from './AdminVendorSiteSettings'
import { AdminVendorSiteCoreDetails } from './AdminVendorSiteCoreDetails'
import { AdminVendorSiteDetails } from './AdminVendorSiteDetails'
import { AdminAddVendorSiteDetails } from './AdminAddVendorSiteDetails'
import {
  IAdminAddVendorSiteDetails,
  IAdminVendorSiteCoreDetails,
  IAdminVendorSiteDetails,
  IAdminVendorSiteSettings,
  IPutAdminVendorSiteCoreDetails,
  IPutAdminVendorSiteSettings
} from './types'

export class AdminVendorSiteDetailsStore {
  rootStore: RootStore

  loadState: TLoadState = 'initial'
  error: string = ''

  settingsLoadStates: Record<string, TLoadState> = {
    coreDetails: 'initial',
    settings: 'initial',
    addVendorSiteDetails: 'initial',
    editVendorSiteDetails: 'initial'
  }
  settingsErrors: Record<string, string> = {
    coreDetails: '',
    settings: '',
    addVendorSiteDetails: '',
    editVendorSiteDetails: ''
  }

  adminVendorSiteDetails: AdminVendorSiteDetails | null = null
  adminVendorSiteCoreDetails: AdminVendorSiteCoreDetails | null = null
  adminVendorSiteSettings: AdminVendorSiteSettings | null = null
  adminAddVendorSiteDetails: AdminAddVendorSiteDetails | null = null

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false
    })

    this.rootStore = rootStore
  }

  setLoadState(loadState: TLoadState) {
    this.loadState = loadState
  }

  setError(errorState: 'loadError' | 'updateError', error: string) {
    this.error = error
    this.setLoadState(errorState)
  }

  setLoadStateSettings(loadState: TLoadState, setting: string) {
    this.settingsLoadStates[setting] = loadState
  }

  setErrorSettings(errorState: 'loadError' | 'updateError', error: string, setting: string) {
    this.settingsErrors[setting] = error
    this.setLoadStateSettings(errorState, setting)
  }

  setDetails(adminVendorSiteDetails: IAdminVendorSiteDetails) {
    this.resetDetails()
    this.adminVendorSiteDetails = new AdminVendorSiteDetails(this, adminVendorSiteDetails)
  }

  resetDetails() {
    this.adminVendorSiteDetails = null
  }

  async fetchAdminVendorSiteDetails(vendorId: string, vendorSiteId: string) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .get<AxiosResponse<IAdminVendorSiteDetails>>(adminVendorSiteDetailsGetUrl(vendorId, vendorSiteId), { headers })
      .then((response: AxiosResponse) => {
        this.setDetails(response.data.data)
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
      })
  }

  setCoreDetails(adminVendorSiteCoreDetails: IAdminVendorSiteCoreDetails) {
    this.resetCoreDetails()
    this.adminVendorSiteCoreDetails = new AdminVendorSiteCoreDetails(this, adminVendorSiteCoreDetails)
  }

  resetCoreDetails() {
    this.adminVendorSiteCoreDetails = null
  }

  async fetchAdminVendorSiteCoreDetails(vendorId: string, vendorSiteId: string) {
    this.setLoadStateSettings('loading', 'coreDetails')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .get<AxiosResponse<IAdminVendorSiteCoreDetails>>(adminVendorSiteCoreDetailsGetPutUrl(vendorId, vendorSiteId), {
        headers
      })
      .then((response: AxiosResponse) => {
        this.setCoreDetails(response.data.data)
        this.setLoadStateSettings('loaded', 'coreDetails')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setErrorSettings('loadError', loggableError, 'coreDetails')
      })
  }

  async putAdminVendorSiteCoreDetails(vendorId: string, vendorSiteId: string, details: IPutAdminVendorSiteCoreDetails) {
    this.setLoadStateSettings('loading', 'editVendorSiteDetails')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .put<AxiosResponse<IAdminVendorSiteDetails>>(
        adminVendorSiteCoreDetailsGetPutUrl(vendorId, vendorSiteId),
        {
          data: {
            id: vendorSiteId,
            type: 'adminVendorSiteDetailsWrite',
            attributes: details
          }
        },
        { headers }
      )
      .then((response: AxiosResponse) => {
        this.setDetails(response.data.data)

        this.setLoadState('loaded')
        this.setLoadStateSettings('loaded', 'editVendorSiteDetails')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('updateError', loggableError)
        this.setErrorSettings('loadError', loggableError, 'editVendorSiteDetails')
      })
  }

  setSettings(adminVendorSiteSettings: IAdminVendorSiteSettings) {
    this.resetSettings()
    this.adminVendorSiteSettings = new AdminVendorSiteSettings(this, adminVendorSiteSettings)
  }

  resetSettings() {
    this.adminVendorSiteSettings = null
  }

  async fetchAdminVendorSiteSettings(vendorId: string, vendorSiteId: string) {
    this.setLoadStateSettings('loading', 'settings')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .get<AxiosResponse<IAdminVendorSiteSettings>>(adminVendorSiteCoreSettingsGetPutUrl(vendorId, vendorSiteId), {
        headers
      })
      .then((response: AxiosResponse) => {
        this.setSettings(response.data.data)
        this.setLoadStateSettings('loaded', 'settings')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setErrorSettings('loadError', loggableError, 'settings')
      })
  }

  async putAdminVendorSiteSettings(vendorId: string, vendorSiteId: string, details: IPutAdminVendorSiteSettings) {
    this.setLoadStateSettings('loading', 'editVendorSiteDetails')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .put<AxiosResponse<IAdminVendorSiteDetails>>(
        adminVendorSiteCoreSettingsGetPutUrl(vendorId, vendorSiteId),
        {
          data: {
            id: vendorSiteId,
            type: 'adminVendorSiteDetailsWrite',
            attributes: details
          }
        },
        { headers }
      )
      .then((response: AxiosResponse) => {
        this.setDetails(response.data.data)
        this.setLoadStateSettings('loaded', 'editVendorSiteDetails')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('updateError', loggableError)
        this.setErrorSettings('loadError', loggableError, 'editVendorSiteDetails')
      })
  }

  setAddVendorSiteDetails(adminAddVendorSiteDetails: IAdminAddVendorSiteDetails) {
    this.resetSettings()
    this.adminAddVendorSiteDetails = new AdminAddVendorSiteDetails(this, adminAddVendorSiteDetails)
  }

  resetAddVendorSiteDetails() {
    this.adminAddVendorSiteDetails = null
  }

  async fetchAdminAddVendorSiteDetails(vendorId: string) {
    this.setLoadStateSettings('loading', 'addVendorSiteDetails')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .get<AxiosResponse<IAdminAddVendorSiteDetails>>(adminAddVendorSiteGetUrl(vendorId), { headers })
      .then((response: AxiosResponse) => {
        this.setAddVendorSiteDetails(response.data.data)
        this.setLoadStateSettings('loaded', 'addVendorSiteDetails')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setErrorSettings('loadError', loggableError, 'addVendorSiteDetails')
      })
  }
}
