import { useState } from 'react'
import { ActionButton } from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'
import { useIntercom } from 'react-use-intercom'

import { useAuthStore, usePatientStore, useHospitalStore } from '../../../../hooks/useStore'

import './index.scss'

interface IProps {
  patientId?: string
}

export const HelpRequestButtonIntercom: React.FC<IProps> = observer((props) => {
  const { boot, show } = useIntercom()

  const authStore = useAuthStore()
  const patientStore = usePatientStore()
  const hospitalStore = useHospitalStore()

  const [hasIntercomBooted, setHasIntercomBooted] = useState(false)

  return (
    <div className='request-help-button' key='nav-request-help' data-testid='request-help-intercom'>
      <ActionButton
        onClick={() => {
          if (!hasIntercomBooted) {
            boot({
              name: `${authStore.auth?.attributes.clinicianFirstName} ${authStore.auth?.attributes.clinicianLastName}`,
              customAttributes: {
                patient_id: props.patientId,
                instance: window.env.INSTALLATION,
                vendor: window.env.VENDOR_MODE,
                ehr_support_link: `https://${window.env.INSTALLATION.toLowerCase()}.doseme-rx.com/patients/${props.patientId}`,
                company: {
                  company_id: `${window.env.INSTALLATION}/${hospitalStore.hospital?.id}`,
                  name: hospitalStore.hospital?.attributes.name
                }
              }
            })

            setHasIntercomBooted(true)
          }

          show()

          return
        }}
        actionType='help'
        size='md'
        customLabel='Request Help'
        disabled={['loading', 'updating'].includes(patientStore.loadState)}
      />
    </div>
  )
})
