import { makeAutoObservable } from 'mobx'

import { PatientCoursesStore } from '../patientCourses/PatientCoursesStore'
import { PatientStore } from './PatientStore'
import { IPatient } from './types'

export class Patient implements IPatient {
  store: PatientStore
  patientCoursesStore: PatientCoursesStore

  id: string
  type: string
  attributes: IPatient['attributes']

  constructor(store: PatientStore, patientCoursesStore: PatientCoursesStore, entity: IPatient) {
    makeAutoObservable(this, {
      store: false,
      patientCoursesStore: false
    })

    this.store = store
    this.patientCoursesStore = patientCoursesStore

    this.id = entity.id
    this.type = entity.type
    this.attributes = { ...entity.attributes }
  }
}
