import { ObservableMap } from 'mobx'
import { IDropdownItem } from '@doseme/cohesive-ui'

import { ApplicableDrugModel } from '../../../../../../../../store/applicableDrugModels/ApplicableDrugModels'
import { IApplicableDrugModel } from '../../../../../../../../store/applicableDrugModels/types'
import { sortDropdownOptionsAlpha } from '../../../../../../../../utils/sorting'

export const filterApplicableDrugModels = (
  drugSwitchDropdownValue: string,
  drugModels: ObservableMap<string, ApplicableDrugModel>
) => {
  const applicableDrugModels: IApplicableDrugModel[] = [...drugModels].map(([key, drugModel]) => ({
    type: drugModel.type,
    id: drugModel.id,
    attributes: {
      name: drugModel.attributes.name,
      valid: drugModel.attributes.valid,
      isNew: drugModel.attributes.isNew,
      errors: drugModel.attributes.errors,
      warning: drugModel.attributes.warning
    }
  }))

  return applicableDrugModels.find((drugModel) => drugModel.id === drugSwitchDropdownValue)
}

export const formatApplicableDrugModelsData = (
  drugModels: ObservableMap<string, ApplicableDrugModel>
): IDropdownItem[] => {
  const drugModelDropdownItems = [...drugModels].map(([key, drugModel]) => ({
    value: drugModel.id,
    label:
      drugModel.attributes.errors && drugModel.attributes.errors.length > 0
        ? drugModel.attributes.name + ' - ' + drugModel.attributes.errors[0]
        : drugModel.attributes.name,
    disabled: drugModel.attributes.errors && drugModel.attributes.errors.length > 0
  }))

  const availableDrugModelItems = sortDropdownOptionsAlpha(drugModelDropdownItems.filter((x) => !x.disabled))
  const unavailableDrugModelItems = sortDropdownOptionsAlpha(drugModelDropdownItems.filter((x) => x.disabled))

  return availableDrugModelItems.concat(unavailableDrugModelItems)
}
