import {
  Button,
  InfoModal,
  Modal,
  Icons
} from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useDropzone } from 'react-dropzone'
import classnames from 'classnames'

import { usePatientStore } from '../../../../../hooks/useStore'
import { showErrorToast, showSuccessToast } from '../../../../../shared/toast'
import { IModalProps } from '../../../../PatientRouter/types'

import './index.scss'

const ImportPatientModal: React.FC<IModalProps> = observer((props) => {
  const history = useHistory()

  const [file, setFile] = useState<File>()
  const [errors, setErrors] = useState([])
  const [uploaded, setUploaded] = useState(false)

  const [fileDropHover, setFileDropHover] = useState(false)

  const patientStore = usePatientStore()

  useEffect(() => {
    window.addEventListener('mouseup', () => setFileDropHover(false))

    return () => window.removeEventListener('mouseup', () => setFileDropHover(false))
  })

  const parseAndUpload = async (rawString: string): Promise<void> => {
    let parsedObject = null

    try {
      parsedObject = (JSON.parse(rawString))
    } catch (e) {
      showErrorToast('Please upload a valid JSON file')
    }

    if (!parsedObject) {
      return
    }

    let result = await patientStore.postImportPatient(parsedObject)

    if (result === null || patientStore.loadState === 'updateError') {
      showErrorToast(patientStore.error || 'Failed to import patient')

      return
    }

    setUploaded(true)
    showSuccessToast('Patient course imported')
  }

  const validateUpload = (): void => {
    if (!file) {
      return
    }

    if (uploaded) {
      history.push('/patients')

      return
    }

    setErrors([])

    const reader = new FileReader()

    reader.onabort = () => showErrorToast('File reading was aborted. Please try again.')
    reader.onerror = () => showErrorToast('There was an error reading the file. Please try again.')

    reader.onload = () => parseAndUpload(reader.result as string)

    reader.readAsText(file)
  }

  const fileDropStatusIcon = (): JSX.Element | null => {
    if (uploaded) {
      return <Icons.Success/>
    }

    return errors.length
      ? <span role='img' aria-label='warning'><Icons.Alert/></span>
      : null
  }

  const Dropzone = () => {
    const { getRootProps, getInputProps } = useDropzone({
      onDrop: acceptedFiles => {
        setUploaded(false)
        setFile(acceptedFiles[0])
      },
      onDragEnter: () => setFileDropHover(false),
      onDragLeave: () => setFileDropHover(false),
      onDropAccepted: () => {
        setErrors([])
        setFileDropHover(false)
      },
      onDropRejected: () => {
        setErrors([])
        setFileDropHover(false)
      },
      accept: {
        'application/json': ['.json']
      }
    })

    return (
      <section className='w-100'>
        <span className='import-patient-subtitle'>Select file for upload (.json)</span>
        <div
          className={classnames(errors.length && 'drop-border-error', 'drop-border')}
          {...getRootProps()}
        >
          <input {...getInputProps()} />

          <div className={classnames('d-flex', 'align-items-center', file && 'upload-empty')}>
            <div className='filedrop-icon'>
              {fileDropHover ? <Icons.FileDropHover/> : <Icons.FileDrop/>}
            </div>
            <span className='file-name'>{file ? `${file.name}` : 'Drag and drop file here, or '}
              <span
                className='search-local-files'>{file ? '' : 'Search local files'}
              </span>
            </span>
            <div className='filedrop-status ml-auto'>
              {fileDropStatusIcon()}
            </div>
          </div>
          <span className='replace-uploaded-file'>{file ? 'Replace' : ''}</span>
        </div>
      </section>
    )
  }

  const dropzone: JSX.Element = (
    <div className='w-100'>
      <Dropzone/>
      {!!errors.length &&
        <div>
          <div className='errors-header'>Error Details</div>
          {errors.map(e =>
            <div key={`key-${e}`}  className='pt-2'>
              <span className='mr-2' role='img' aria-label='warning'><Icons.Error/></span>{e}
            </div>
          )}
        </div>
      }
    </div>
  )

  const stepThroughButton: JSX.Element = (
    <Button
      variant='info'
      className='info-button'
      onClick={validateUpload}
      disabled={!file}
    >
      {uploaded ? 'Close' : 'Save and Continue'}
    </Button>
  )

  return (
    <Modal show={props.show} onHide={() => props.setShow(false)}>
      <div className='import-patient-modal-container'>
        <InfoModal
          size='m'
          title='Import Patient from file'
          message={dropzone}
          onDismiss={() => props.setShow(false)}
          linkComponent={stepThroughButton}
        />
      </div>
    </Modal>
  )
})

export { ImportPatientModal }
