import { makeAutoObservable } from 'mobx'

import { AuthStore } from './AuthStore'
import { IAuth } from './types'

export class Auth implements IAuth {
  store: AuthStore

  type: string
  attributes: IAuth['attributes']

  constructor(store: AuthStore, entity: IAuth) {
    makeAutoObservable(this, {
      store: false
    })

    this.store = store

    this.type = entity.type

    this.attributes = { ...entity.attributes }
  }
}
