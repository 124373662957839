interface IProps {
  markerColour: string
  isLegendIcon?: boolean
  cx?: number
  cy?: number
}

export const DoseMarker: React.FC<IProps> = (props) => {
  if (!props.isLegendIcon) {
    if (!props.cx || isNaN(props.cx)) {
      return null
    }

    if (!props.cy || isNaN(props.cy)) {
      return null
    }
  }

  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
      x={props.cx ? props.cx - 16 / 2 + 4 : undefined}
      y={props.cy ? props.cy - 4 : props.cy}
    >
      <rect width='8' height='8' rx='2' fill={props.markerColour} />
    </svg>
  )
}
