import { observable } from 'mobx'
import { makeAutoObservable } from 'mobx'

import { AxiosError, isAxiosError } from '../../utils/axiosClient'
import { RootStore } from '../RootStore'
import { TLoadState } from '../types'
import { ApiUnknownError, ApiUnreachableError } from './Errors'

interface ErrorDetail {
  detail?: string
}

interface ErrorResponseData {
  errors?: ErrorDetail[]
}

export class ErrorsStore {
  rootStore: RootStore

  loadState: TLoadState = 'initial'
  errors = observable.array()

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false
    })

    this.rootStore = rootStore
  }

  addError(error: string | Error) {
    this.errors.push(error)
  }

  hasErrors() {
    return (!!this.errors.length)
  }

  lastError() {
    return this.errors.pop()
  }

  resetStore() {
    this.errors.clear()
    this.setLoadState('initial')
  }

  setLoadState(loadState: TLoadState) {
    this.loadState = loadState
  }

  parseLoggableError(error: Error | AxiosError): string {
    let errorMessage = 'An unexpected error has occurred.'

    const sanitiseError = (error: any): any => {
      const sanitisedError = { ...error }

      if (sanitisedError.config) {
        if (sanitisedError.config.data) {
          sanitisedError.config.data = '[Sanitised]'
        }

        if (sanitisedError.config.headers) {
          sanitisedError.config.headers = '[Sanitised]'
        }
      }

      return sanitisedError
    }

    if (isAxiosError(error)) {
      if (error.response) {
        const responseData = error.response.data

        if (typeof responseData === 'string') {
          // If the response data is a string, use it directly as the error message
          errorMessage = responseData
        } else if (responseData && (responseData as ErrorResponseData).errors) {
          // If the response data is an object and has errors, extract the first error detail
          const errorDetails = (responseData as ErrorResponseData).errors

          // Return the API error string to the component for the user to action accordingly
          if (errorDetails && errorDetails.length > 0) {
            return errorDetails[0].detail || errorMessage
          }
        }

        // Error response that doesn't conform to the API specification - it may be a low level server error
        const cause = responseData ? responseData : error.response

        // Raise a fatal FE error if we get 5xx responses from the back-end API
        if ([500, 501, 502, 503, 504].includes(error.response.status)) {
          this.addError(new ApiUnknownError(errorMessage, { cause: cause }))
        }

        return errorMessage
      }

      if (error.request) {
        // Handle network errors (e.g., ERR_INTERNET_DISCONNECTED)
        errorMessage = error.request.response || 'An error has occurred sending the request.'

        if (error.code) {
          switch (error.code) {
            case 'ERR_INTERNET_DISCONNECTED':
              errorMessage = 'Internet disconnected'
              break
            case 'ERR_NETWORK_CHANGED':
              errorMessage = 'Network changed'
              break
            case 'ECONNABORTED':
              errorMessage = 'Connection aborted'
              break
            default:
              errorMessage = `Network error: ${error.code}`
          }
        }

        this.addError(new ApiUnreachableError(errorMessage, { cause: sanitiseError(error) }))

        return errorMessage
      }
    }

    // Handle non-Axios errors dynamically
    errorMessage = error.message || errorMessage

    this.addError(new ApiUnknownError(errorMessage, { cause: sanitiseError(error) }))

    return errorMessage
  }
}
