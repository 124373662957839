import { IDropdownItem } from '@doseme/cohesive-ui'

import { ICourseLimits } from '../../../../../../../../../../store/course/types'
import {
  TModelType,
  IPerDoseOutcome,
  IPredictedOutcome
} from '../../../../../../../../../../store/dosingRecommendation/types'
import { TReportSimulationType, TValidDosingAttributes } from '../../../../../../../../../../types/doseReport'
import {
  buildCustomDoseAttributes,
  buildGuidelineDoseAttributes,
  buildDefaultNextDoseAttributes
} from '../../constants'
import { buildCustomizedTargetAttributes } from '../../utils'
import { ICustomDoseForms, ICustomTargetForms, IOutcome, TTargetTypes } from '../../types'

export const getSimulationType = (currentModel: TModelType): TReportSimulationType => {
  if (currentModel === 'indPop') {
    return 'DefaultNextDoseSimulation'
  }

  if (currentModel === 'guideline') {
    return 'GuidelineSimulation'
  }

  if (currentModel === 'customTarget') {
    return 'CustomizedTargetSimulation'
  }

  // must be custom dose
  return 'CustomDoseSimulation'
}

export const getReportTargetOutcomes = (
  currentModel: TModelType,
  selectedTargetDropdownItem: IDropdownItem,
  defaultLastPredictedDoseOutcomes: IPerDoseOutcome | null,
  customTargetForms: ICustomTargetForms,
  limits: ICourseLimits,
  defaultCustomTarget: any,
  predictedOutcome: IPredictedOutcome | null
): IOutcome[] => {
  if (currentModel === 'customTarget' && defaultLastPredictedDoseOutcomes) {
    if (selectedTargetDropdownItem.value === 'auc24') {
      return [
        {
          outcomeType: 'AUC24',
          amount: {
            value: customTargetForms['auc24'].values['auc24'],
            unit: limits.aucTarget.max.unit
          }
        }
      ]
    }

    if (selectedTargetDropdownItem.value === 'auc') {
      return [
        {
          outcomeType: 'AUC',
          amount: {
            value: customTargetForms['auc'].values['auc'],
            unit: limits.aucTarget.max.unit
          }
        }
      ]
    }

    if (selectedTargetDropdownItem.value === 'peakonly') {
      return [
        {
          outcomeType: 'Peak',
          amount: {
            value: customTargetForms['peakonly'].values['peak'],
            unit: limits.peakTarget.max.unit
          }
        }
      ]
    }

    if (selectedTargetDropdownItem.value === 'troughonly') {
      return [
        {
          outcomeType: 'Trough',
          amount: {
            value: customTargetForms['troughonly'].values['trough'],
            unit: limits.troughTarget.max.unit
          }
        }
      ]
    }

    if (
      selectedTargetDropdownItem.value === 'peak/trough' ||
      selectedTargetDropdownItem.value === 'extended_interval'
    ) {
      return [
        {
          outcomeType: 'Peak',
          amount: {
            value: customTargetForms['peakonly'].values['peak'],
            unit: limits.peakTarget.max.unit
          }
        },
        {
          outcomeType: 'Trough',
          amount: {
            value: customTargetForms['troughonly'].values['trough'],
            unit: limits.troughTarget.max.unit
          }
        }
      ]
    }

    if (selectedTargetDropdownItem.value === 'time_above_mic') {
      return [
        {
          outcomeType: 'T>MIC',
          amount: {
            value: customTargetForms['time_above_mic'].values['timeAboveMICPercentage'],
            unit: limits.timeAboveMICTarget.default.unit
          }
        }
      ]
    }

    if (selectedTargetDropdownItem.value === 'timetotrough') {
      return [
        {
          outcomeType: 'trough at next dose',
          amount: {
            value: customTargetForms['timetotrough'].values['trough'],
            unit: limits.troughTarget.default.unit
          }
        }
      ]
    }

    if (selectedTargetDropdownItem.value === 'range') {
      return [
        {
          outcomeType: 'INR range',
          amount: {
            value: `${customTargetForms['range'].values['trough']} — ${customTargetForms['range'].values['peak']}`
          }
        }
      ]
    }

    if (selectedTargetDropdownItem.value === 'cumulative_auc') {
      return [
        {
          outcomeType: 'Cumulative AUC',
          amount: {
            value: customTargetForms['cumulative_auc'].values['remainingAUC'],
            unit: limits.cumulativeAUCTarget.default.unit
          }
        }
      ]
    }

    return []
  }

  if (currentModel === 'indPop' || currentModel === 'guideline' || currentModel === 'customDose') {
    const amountNA = currentModel === 'guideline' || currentModel === 'customDose'

    if (defaultCustomTarget.name === 'Trough Only') {
      return [
        {
          outcomeType: 'Trough',
          amount: amountNA ? undefined : limits.troughTarget.default
        }
      ]
    }

    if (defaultCustomTarget.name === 'Peak Only') {
      return [
        {
          outcomeType: 'Peak',
          amount: amountNA ? undefined : limits.peakTarget.default
        }
      ]
    }

    if (defaultCustomTarget.name === 'AUC24' || defaultCustomTarget.name === 'AUC') {
      return [
        {
          outcomeType: defaultCustomTarget.name,
          amount: amountNA ? undefined : limits.aucTarget.default
        }
      ]
    }

    if (defaultCustomTarget.name === 'Peak & Trough' || defaultCustomTarget.name === 'Extended Interval') {
      return [
        {
          outcomeType: 'Peak',
          amount: amountNA ? undefined : limits.peakTarget.default
        },
        {
          outcomeType: 'Trough',
          amount: amountNA ? undefined : limits.troughTarget.default
        }
      ]
    }

    if (defaultCustomTarget.name === 'Time Above MIC') {
      return [
        {
          outcomeType: 'T>MIC',
          amount: amountNA ? undefined : limits.timeAboveMICTarget.default
        }
      ]
    }

    if (defaultCustomTarget.name === 'Time to Trough') {
      return [
        {
          outcomeType: 'trough at next dose',
          amount: amountNA ? undefined : limits.troughTarget.default
        }
      ]
    }

    if (selectedTargetDropdownItem.value === 'range') {
      return [
        {
          outcomeType: 'INR range',
          amount: {
            value: `${limits.troughTarget.default.value} — ${limits.peakTarget.default.value}`
          }
        }
      ]
    }

    if (selectedTargetDropdownItem.value === 'cumulative_auc') {
      return [
        {
          outcomeType: 'Cumulative AUC',
          amount: currentModel === 'guideline' || !predictedOutcome?.cumulativeAUCTarget ?
            undefined :
            predictedOutcome.cumulativeAUCTarget
        }
      ]
    }
  }

  return []
}

export const getReportPredictedOutcomes = (
  currentModel: TModelType,
  selectedTargetDropdownItem: IDropdownItem,
  defaultLastPredictedDoseOutcomes: IPerDoseOutcome | null,
  limits: ICourseLimits,
  predictedOutcome: IPredictedOutcome | null,
  defaultCustomTarget: any
): IOutcome[] => {
  if (currentModel === 'customTarget' && defaultLastPredictedDoseOutcomes) {
    if (selectedTargetDropdownItem.value === 'auc') {
      return [
        {
          outcomeType: 'AUC',
          amount: {
            value: defaultLastPredictedDoseOutcomes.auc,
            unit: limits.aucTarget.max.unit // predicted outcomes AUC does not return a unit
          }
        }
      ]
    }

    if (selectedTargetDropdownItem.value === 'auc24') {
      return [
        {
          outcomeType: 'AUC24',
          amount: {
            value: defaultLastPredictedDoseOutcomes.auc24,
            unit: limits.aucTarget.max.unit // predicted outcomes AUC does not return a unit
          }
        }
      ]
    }

    if (selectedTargetDropdownItem.value === 'peakonly') {
      return [
        {
          outcomeType: 'Peak',
          amount: {
            value: defaultLastPredictedDoseOutcomes.peak,
            unit: limits.peakTarget.max.unit // predicted outcomes peak does not return a unit
          }
        }
      ]
    }

    if (selectedTargetDropdownItem.value === 'troughonly') {
      return [
        {
          outcomeType: 'Trough',
          amount: {
            value: defaultLastPredictedDoseOutcomes.trough,
            unit: limits.troughTarget.max.unit // predicted outcomes trough does not return a unit
          }
        }
      ]
    }

    if (
      selectedTargetDropdownItem.value === 'peak/trough' ||
      selectedTargetDropdownItem.value === 'extended_interval'
    ) {
      return [
        {
          outcomeType: 'Peak',
          amount: {
            value: defaultLastPredictedDoseOutcomes.peak,
            unit: limits.peakTarget.max.unit // predicted outcomes peak does not return a unit
          }
        },
        {
          outcomeType: 'Trough',
          amount: {
            value: defaultLastPredictedDoseOutcomes.trough,
            unit: limits.troughTarget.max.unit // predicted outcomes trough does not return a unit
          }
        }
      ]
    }

    if (selectedTargetDropdownItem.value === 'time_above_mic') {
      return [
        {
          outcomeType: 'T>MIC',
          amount: predictedOutcome?.timeAboveMICPercentage
            ? {
              value: predictedOutcome.timeAboveMICPercentage,
              unit: limits.timeAboveMICTarget.default.unit
            }
            : undefined
        }
      ]
    }

    if (selectedTargetDropdownItem.value === 'timetotrough') {
      return [
        {
          outcomeType: 'trough at next dose',
          amount:
            predictedOutcome?.trough !== undefined && predictedOutcome.troughUnits !== undefined
              ? {
                value: predictedOutcome.trough,
                unit: {
                  name: predictedOutcome.troughUnits,
                  id: ''
                }
              }
              : undefined
        }
      ]
    }

    if (selectedTargetDropdownItem.value === 'range') {
      return [
        {
          outcomeType: 'INR range (over last dose)',
          amount: {
            value: `${defaultLastPredictedDoseOutcomes.trough} — ${defaultLastPredictedDoseOutcomes.peak}`
          }
        }
      ]
    }

    if (selectedTargetDropdownItem.value === 'cumulative_auc') {
      return [
        {
          outcomeType: 'Cumulative AUC',
          amount:
            predictedOutcome?.cumulativeAUC
              ? {
                value: predictedOutcome.cumulativeAUC.value.toString(),
                unit: limits.cumulativeAUCTarget.default.unit
              }
              : undefined
        }
      ]
    }
  }

  if (
    (currentModel === 'indPop' || currentModel === 'guideline' || currentModel === 'customDose') &&
    defaultLastPredictedDoseOutcomes
  ) {
    const defaultTarget = defaultCustomTarget

    if (defaultTarget.name === 'Trough Only') {
      return [
        {
          outcomeType: 'Trough',
          amount: {
            value: defaultLastPredictedDoseOutcomes.trough,
            unit: limits.troughTarget.default.unit
          }
        }
      ]
    }

    if (defaultTarget.name === 'Peak Only') {
      return [
        {
          outcomeType: 'Peak',
          amount: {
            value: defaultLastPredictedDoseOutcomes.peak,
            unit: limits.peakTarget.default.unit
          }
        }
      ]
    }

    if (defaultTarget.name === 'AUC24') {
      return [
        {
          outcomeType: defaultTarget.name,
          amount: {
            value: defaultLastPredictedDoseOutcomes.auc24,
            unit: limits.aucTarget.default.unit
          }
        }
      ]
    }

    if (defaultTarget.name === 'AUC') {
      return [
        {
          outcomeType: defaultTarget.name,
          amount: {
            value: defaultLastPredictedDoseOutcomes.auc,
            unit: limits.aucTarget.default.unit
          }
        }
      ]
    }

    if (defaultTarget.name === 'Peak & Trough' || defaultTarget.name === 'Extended Interval') {
      return [
        {
          outcomeType: 'Peak',
          amount: {
            value: defaultLastPredictedDoseOutcomes.peak,
            unit: limits.peakTarget.default.unit
          }
        },
        {
          outcomeType: 'Trough',
          amount: {
            value: defaultLastPredictedDoseOutcomes.trough,
            unit: limits.troughTarget.default.unit
          }
        }
      ]
    }

    if (defaultTarget.name === 'Time Above MIC') {
      return [
        {
          outcomeType: 'T>MIC',
          amount: predictedOutcome?.timeAboveMICPercentage
            ? {
              value: predictedOutcome.timeAboveMICPercentage,
              unit: limits.timeAboveMICTarget.default.unit
            }
            : undefined
        }
      ]
    }

    if (defaultTarget.name === 'Time to Trough') {
      return [
        {
          outcomeType: 'trough at next dose',
          amount:
            predictedOutcome?.trough !== undefined && predictedOutcome.troughUnits !== undefined
              ? {
                value: predictedOutcome.trough,
                unit: {
                  name: predictedOutcome.troughUnits,
                  id: ''
                }
              }
              : undefined
        }
      ]
    }

    if (defaultTarget.name === 'Range') {
      return [
        {
          outcomeType: 'INR range (over last dose)',
          amount: {
            value: `${defaultLastPredictedDoseOutcomes.trough} — ${defaultLastPredictedDoseOutcomes.peak}`
          }
        }
      ]
    }

    if (defaultTarget.name === 'Cumulative AUC') {
      return [
        {
          outcomeType: 'Cumulative AUC',
          amount:
            predictedOutcome?.cumulativeAUC
              ? {
                value: predictedOutcome.cumulativeAUC.value.toString(),
                unit: limits.cumulativeAUCTarget.default.unit
              }
              : undefined
        }
      ]
    }
  }

  return []
}

export const getCurrentSimulationAttributes = (
  currentModel: TModelType,
  selectedTargetDropdownItem: IDropdownItem,
  customDoseForms: ICustomDoseForms,
  customTargetForms: ICustomTargetForms,
  limits: ICourseLimits,
  nextDoseAt: Date,
  hospitalTimezone: string
): TValidDosingAttributes | null => {
  const dosingType = getSimulationType(currentModel)

  if (dosingType === 'CustomizedTargetSimulation') {
    return buildCustomizedTargetAttributes(
      customTargetForms,
      selectedTargetDropdownItem.value as TTargetTypes,
      limits,
      nextDoseAt,
      hospitalTimezone
    )
  }

  if (dosingType === 'CustomDoseSimulation') {
    return buildCustomDoseAttributes(customDoseForms, limits, nextDoseAt, hospitalTimezone)
  }

  if (dosingType === 'GuidelineSimulation') {
    return buildGuidelineDoseAttributes(nextDoseAt, hospitalTimezone)
  }

  // default next dose
  return buildDefaultNextDoseAttributes(nextDoseAt, hospitalTimezone)
}
