import { useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { decode } from 'he'
import {
  BackArrowButton,
  ControlButton,
  IColumnElement,
  IRowElement,
  Icons,
  ListButton,
  PaginationPanel,
  SearchInput,
  SmartList,
  TooltipMenu,
  ConfirmModal,
  Modal
} from '@doseme/cohesive-ui'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import {
  useAuthStore,
  useClinicianStore,
  useAdminHospitalDetailsStore,
  useAdminHospitalDrugListStore,
  useBreadcrumbsStore
} from '../../../../../../hooks/useStore'
import { getPageCount, paginate } from '../../../../../../utils/pagination'
import { sortAlpha } from '../../../../../../utils/smartListUtils'
import { IAdminHospitalDrugListItem } from '../../../../../../store/Admin/AdminHospitalDrugList/types'
import { showErrorToast, showSuccessToast } from '../../../../../../shared/toast'
import { handleBackButton } from '../../../../../../utils/navigation'
import { getAdminRoutePrefix } from '../../../../utils'
import { HospitalAddDrugsModal } from './HospitalAddDrugsModal'
import { searchAdminHospitalDrugs } from './utils'
import { IProps, TAdminHospitalDrugSettingsTabs } from './types'
import {
  hospitalDrugSettingsActionData,
  hospitalDrugSettingsColumns,
  itemsPerPage
} from './constants'

import './index.scss'

export const HospitalDrugSettingsList: React.FC<IProps> = observer((props) => {
  const [searchText, setSearchText] = useState('')
  const [sortColIndex, setSortColIndex] = useState(0)
  const [sortColAscending, setSortColAscending] = useState(true)
  const [sortedList, setSortedList] = useState<IRowElement[] | null>(null)
  const [totalPages, setTotalPages] = useState<number>(1)
  const [currentPage, updateCurrentPage] = useState(1)
  const activeDrugSettingsTab: TAdminHospitalDrugSettingsTabs = 'viewByModel'
  // FIXME - add back in when view by settings is added
  //const [activeDrugSettingsTab, setActiveDrugSettingsTab] = useState<TAdminHospitalDrugSettingsTabs>('viewByModel')
  const [showAddDrugsModal, setShowAddDrugsModal] = useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [selectedDrugModel, setSelectedDrugModel] = useState<IAdminHospitalDrugListItem | null>()

  const authStore = useAuthStore()
  const clinicianStore = useClinicianStore()
  const adminHospitalDetailsStore = useAdminHospitalDetailsStore()
  const adminHospitalDrugListStore = useAdminHospitalDrugListStore()
  const breadcrumbsStore = useBreadcrumbsStore()

  const history = useHistory()

  useEffect(() => {
    if (adminHospitalDetailsStore.loadState === 'loaded') {
      const breadcrumbs = [
        { label: 'Admin hub', onClick: () => returnToAdminHub() },
        {
          label: 'Drug settings',
          onClick:
            clinicianStore.onlyAdminHospital()
              ? undefined
              : () => returnToDrugSettings()
        },
        { label: `${decode(adminHospitalDetailsStore.adminHospitalDetails?.attributes.details.name || '')}` }
      ]
      breadcrumbsStore.setBreadcrumbs(breadcrumbs)
    }
  }, [adminHospitalDetailsStore.loadState])

  useEffect(() => {
    if (adminHospitalDrugListStore.loadState !== 'loaded') {
      adminHospitalDrugListStore.fetchAdminHospitalDrugModelList(props.hospitalId)
    }

    if (clinicianStore.loadState !== 'loaded' && authStore.auth) {
      clinicianStore.fetchClinician(authStore.auth.attributes.clinicianId)
    }

    if (adminHospitalDetailsStore.loadState !== 'loaded') {
      adminHospitalDetailsStore.fetchAdminHospitalDetails(props.hospitalId)
    }
  }, [])

  useEffect(() => {
    adminHospitalDrugListStore.fetchAdminHospitalDrugModelList(props.hospitalId)
    adminHospitalDetailsStore.fetchAdminHospitalDetails(props.hospitalId)
  }, [props.hospitalId])

  useEffect(() => {
    if (adminHospitalDrugListStore.loadState === 'loaded') {
      const searchedHospitalDrugList: IAdminHospitalDrugListItem[] = searchAdminHospitalDrugs(
        searchText,
        adminHospitalDrugListStore.adminHospitalDrugs
      )

      setTotalPages(getPageCount(searchedHospitalDrugList.length, itemsPerPage))
      const formattedClinicians = formatHospitalDrugs(searchedHospitalDrugList)
      const sortedSearchedFormattedClinicians = sortAlpha(formattedClinicians, sortColIndex, sortColAscending)
      setSortedList(sortedSearchedFormattedClinicians)
    }
  }, [adminHospitalDrugListStore.loadState, searchText, sortColIndex, sortColAscending, activeDrugSettingsTab])

  const returnToDrugSettings = () => {
    history.push(getAdminRoutePrefix(props.patientId) + '/drugsettings')
  }

  const returnToAdminHub = () => {
    history.push(getAdminRoutePrefix(props.patientId))
  }

  const handleDrugDetailsButton = (drugId: string) => {
    history.push(getAdminRoutePrefix(props.patientId) + `/hospitals/${props.hospitalId}/drugs/${drugId}`)
  }

  const handleSort = (colIndex: number, ascending: boolean) => {
    setSortColIndex(colIndex)
    setSortColAscending(ascending)
  }

  const handleSearch = (searchText: string) => {
    setSearchText(searchText)
    updateCurrentPage(1)
  }

  const handleDeleteDrugFromHospital = async () => {
    if (selectedDrugModel?.id) {
      await adminHospitalDrugListStore.deleteAdminHospitalDrugModel(props.hospitalId, selectedDrugModel.id)

      if (adminHospitalDrugListStore.loadState === 'loadError') {
        showErrorToast(adminHospitalDrugListStore.error || 'Failed to remove drug model from hospital')

        return
      }

      setShowDeleteModal(false)
      showSuccessToast('Removed drug model from hospital')
    }
  }

  const formatHospitalDrugs = (hospitalDrugs: IAdminHospitalDrugListItem[]): IRowElement[] => {
    return hospitalDrugs.map((drug, key) => {
      const drugName = `${drug.attributes.name} ${!drug.attributes.isValidated ? '[Research]' : ''} ${drug.attributes.isNew ? '[New]' : ''}`

      const recordColumns: IColumnElement[] = [
        {
          name: 'Name',
          link: () => handleDrugDetailsButton(drug.id),
          text: drugName
        },
        {
          name: 'Default dosing method',
          text: drug.attributes.defaultDosingMethod.label,
          onClick: () => handleDrugDetailsButton(drug.id)
        },
        {
          name: '',
          element: (
            <div className='hospital-drug-settings-list-action-button' data-testid='ellipsis-icon'>
              <TooltipMenu
                button={
                  <ListButton size='cm'>
                    <Icons.Ellipsis />
                  </ListButton>
                }
                data={hospitalDrugSettingsActionData(
                  drug,
                  clinicianStore.clinician?.attributes.isSuperAdmin || false,
                  handleDrugDetailsButton,
                  setShowDeleteModal,
                  setSelectedDrugModel
                )}
                alignRight={true}
                chevronOffset={10}
              />
            </div>
          )
        }
      ]

      return {
        id: drug.id,
        columns: recordColumns
      }
    })
  }

  const deleteDrugModalMessage: JSX.Element = (
    <>
      <div className='mt-2'>Access to the selected model will be removed.</div>
      <div className='mt-2'>You will be able to add the drug model back to the hospital at any time.</div>
    </>
  )

  const deleteDrugModalTitle: JSX.Element = (
    <div>
      <div>Remove drug Model</div>
      <div className='delete-drug-modal-subtitle'>
        {decode(adminHospitalDetailsStore.adminHospitalDetails?.attributes.details.name || '')}
      </div>
    </div>
  )

  return (
    <div data-testid='hospital-drug-settings-list' className='co-hospital-drug-settings-list-page'>
      <Modal
        show={showDeleteModal}
        onHide={() => {
          setShowDeleteModal(false)
        }}
      >
        <ConfirmModal
          confirmationType='delete'
          entityType='Dose'
          onCancel={() => {
            setShowDeleteModal(false)
          }}
          entityName={selectedDrugModel?.attributes.name}
          onConfirm={() => handleDeleteDrugFromHospital()}
          title={deleteDrugModalTitle}
          message={deleteDrugModalMessage}
          cancelButtonLabel='Cancel'
          confirmButtonLabel='Remove model'
        />
      </Modal>

      {clinicianStore.clinician?.attributes.isSuperAdmin && (
        <HospitalAddDrugsModal
          show={showAddDrugsModal}
          setShow={setShowAddDrugsModal}
          hospitalId={props.hospitalId}
          hospitalName={decode(adminHospitalDetailsStore.adminHospitalDetails?.attributes.details.name || '')}
        />
      )}
      <div className='hospital-drug-settings-list-heading'>
        <div className='hospital-drug-settings-list-back-btn'>
          <BackArrowButton
            data-testid='back-btn'
            onClick={() =>
              handleBackButton(getAdminRoutePrefix(props.patientId) + `/hospitals/${props.hospitalId}`, history)
            }
          />
        </div>
        <div className='hospital-drug-settings-list-title'>
          Drug settings: <b>{decode(adminHospitalDetailsStore.adminHospitalDetails?.attributes.details.name || '')}</b>
        </div>
        {clinicianStore.clinician?.attributes.isSuperAdmin && (
          <div className='create-new-hospital-drug-settings-btn'>
            <ControlButton icon={faPlus} onClick={() => setShowAddDrugsModal(true)}>
              Add Drug Model
            </ControlButton>
          </div>
        )}
      </div>
      <div className='hospital-drug-settings-list-content-panel'>
        {/* FIXME - add back in when view by settings is added */}
        {/* <div className='mb-3 '>
          <FloatingTabBar
            tabNames={drugSettingsTabs}
            activeTab={activeDrugSettingsTab}
            onSelectTab={(tab) => setActiveDrugSettingsTab(tab as TAdminHospitalDrugSettingsTabs)}
          />
        </div> */}
        <div className='hospital-drug-settings-list-search-bar-container'>
          <div className='hospital-drug-settings-list-search-bar'>
            <SearchInput
              value={searchText}
              onChange={handleSearch}
              width={360}
              borderRadius={12}
              placeholder='Search list...'
            />
          </div>
        </div>
        <div className='mt-2'>
          <SmartList
            cols={hospitalDrugSettingsColumns(handleSort)}
            data={sortedList ? paginate(sortedList, { currentPage, itemsPerPage }) : []}
            defaultSortColumn='Name'
            defaultSortDirection='asc'
            loading={adminHospitalDrugListStore.loadState === 'loading'}
            textIfEmpty='No data to display'
            minRowsToShow={itemsPerPage}
          />
        </div>
        <div className='hospital-drug-settings-pagination-panel'>
          <PaginationPanel currentPage={currentPage} totalPages={totalPages} onPageChange={updateCurrentPage} />
        </div>
      </div>
    </div>
  )
})
