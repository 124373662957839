import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { InfoModal, Modal, Button, Dropdown } from '@doseme/cohesive-ui'

import { useAdminClinicianDetailsStore, useAdminHospitalListStore } from '../../../../../../../hooks/useStore'
import { showErrorToast, showSuccessToast } from '../../../../../../../shared/toast'
import { useFormValidation } from '../../../../../../../hooks/useFormValidation'
import { IProps } from './types'
import { addHospitalAdminAccessFields } from './constants'
import { formatHospitals } from './utils'

import './index.scss'

export const ClinicianAddHospitalAdminAccessModal: React.FC<IProps> = observer((props) => {
  const adminHospitalListStore = useAdminHospitalListStore()
  const adminClinicianDetailsStore = useAdminClinicianDetailsStore()

  useEffect(() => {
    if (props.show && adminHospitalListStore.loadState !== 'loaded') {
      adminHospitalListStore.fetchAdminHospitals()
    }
  }, [props.show])

  const form = useFormValidation(addHospitalAdminAccessFields())

  const handleSubmit = async () => {
    await adminClinicianDetailsStore.addClinicianHospitalAccess(
      props.clinicianId,
      form.inputs['hospitals'].split('|')[1],
      form.inputs['hospitals'].split('|')[0]
    )

    if (['loadError', 'updateError'].includes(adminClinicianDetailsStore.settingsLoadStates.hospitalAccess)) {
      showErrorToast(adminClinicianDetailsStore.settingsErrors.hospitalAccess || 'Failed to add new Hospital Access')

      return
    }

    showSuccessToast('New Hospital Access added')
    props.setShow(false)
  }

  const handleClose = () => {
    props.setShow(false)
    form.reset()
  }

  const formContent = (): JSX.Element => {
    if (adminHospitalListStore.adminHospitals) {
      return (
        <div className='position-relative w-100'>
          <div className='clinician-roles-label mb-3 font-weight-bold'>Hospital access</div>
          <Dropdown
            fieldState={form.getValidState('hospitals')}
            searchBarWidth={500}
            showSearchThreshold={5}
            id='add-hospital-access'
            data-testid='model-dropdown'
            value={form.inputs['hospitals']}
            label='Select a hospital for this clinician to access:'
            placeholder='Search hospital...'
            data={formatHospitals(adminHospitalListStore.adminHospitals)}
            disabled={adminHospitalListStore.loadState !== 'loaded' || !adminHospitalListStore.adminHospitals}
            onSelect={(item) => {
              form.validateFields(
                [
                  {
                    field: 'hospitals',
                    input: item.value
                  }
                ],
                'updateFieldsDisplay'
              )
            }}
          />
        </div>
      )
    }

    return <></>
  }

  return (
    <Modal show={props.show} onHide={handleClose}>
      <InfoModal
        size='s'
        linkComponent={
          <>
            <Button
              className='mr-3'
              data-testid='cancel-btn'
              disabled={false}
              loading={['loading', 'updating'].includes(adminClinicianDetailsStore.settingsLoadStates.hospitalAccess)}
              onClick={handleClose}
              variant='primary-outline'
            >
              Cancel
            </Button>
            <Button
              data-testid='save-btn'
              disabled={!form.valid || ['loading', 'updating'].includes(adminClinicianDetailsStore.settingsLoadStates.hospitalAccess)}
              loading={['loading', 'updating'].includes(adminClinicianDetailsStore.settingsLoadStates.hospitalAccess)}
              onClick={handleSubmit}
              variant='primary'
            >
              Allow access
            </Button>
          </>
        }
        title={
          <div>
            <div>
              Add access to another hospital
              <div className='clinician-add-hospital-access-subtitle'>{props.clinicianName}</div>
            </div>
          </div>
        }
        message={formContent()}
      />
    </Modal>
  )
})
