import { makeAutoObservable } from 'mobx'

import { HistoricalSimulationStore } from './HistoricalSimulationStore'
import { IHistoricalSimulation } from './types'

export class HistoricalSimulation implements IHistoricalSimulation {
  store: HistoricalSimulationStore

  type: string
  attributes: IHistoricalSimulation['attributes']

  constructor(store: HistoricalSimulationStore, entity: IHistoricalSimulation) {
    makeAutoObservable(this, {
      store: false
    })

    this.store = store

    this.type = entity.type
    this.attributes = { ...entity.attributes }
  }
}
