import { IDropdownItem } from '@doseme/cohesive-ui'

import { IMolecule } from '../../../../../store/drugModels/types'

export const getMoleculeDropdownItems = (molecules: IMolecule[]): IDropdownItem[] => {
  const moleculeDropdownItems = molecules.map((molecules) => ({
    value: molecules.id,
    label: molecules.attributes.name
  }))

  return moleculeDropdownItems
}
