import { TFieldState } from '@doseme/cohesive-ui'

export class ValidationError extends Error {
  constructor(message: string) {
    super(message)
    this.name = ''
  }
}

export interface IRuleParams<I = any, C = undefined> {
  input: I
  constraints: C
}

export type TValidationRule = (params: IRuleParams<any, any>) => void

export type TFieldFormatter = (input: any, formatterConstraints?: any) => any

export interface IFormField {
  initialInput: any
  rules: TValidationRule[]
  formatter?: TFieldFormatter
  initialConstraints?: any
  formatterConstraints?: any
}

export interface IFormFieldValidityDisplay {
  state: TFieldState
  statusText: string
}

export interface IFormFieldInternalState extends IFormFieldValidityDisplay {
  value: any
  input: any
}

export interface IFieldValidationParams {
  field: string
  input: any
  constraints?: any
}

export interface IFormState {
  inputs: Record<string, any>
  values: Record<string, any>
  valid: boolean
  jsonState: string
  validateFields: (
    formFields: IFieldValidationParams[],
    updateFieldsDisplay?: 'updateFieldsDisplay' // when immediate update is required
  ) => void
  updateFieldsDisplay: (formFields: string[], returnErrorMap?: 'returnErrorMap') => Map<string, string>
  getValidState: (field: string) => TFieldState
  getValidationMsg: (field: string) => string
  getErrorMap: () => Map<string, string>
  reset: () => void
}
