import { CHARCOAL_GREY } from '@doseme/cohesive-ui'

import './index.scss'

interface ITickPayload {
  coordinate: number
  value: number
  offset: number
  tickCoord: number
  isShow: boolean
}

interface IProps {
  fill?: string
  height?: number
  index?: number
  name?: string
  orientation?: string
  payload?: ITickPayload
  stroke?: string
  textAnchor?: string
  tickFormatter?: (value: any, index: number) => string
  type?: string
  verticalAnchor?: string
  visibleTicksCount?: number
  width?: number
  x?: number
  y?: number
}

const getTickOffset = (tickIndex?: number, visibleTicksCount?: number): number => {
  const count = visibleTicksCount || 0

  if (tickIndex === 0) {
    // This value offsets the first tick label position far enough right to align with left viewport boundary.
    return 24
  }

  if (tickIndex === count - 1) {
    // This value offsets the last tick label position far enough left to align with right viewport boundary.
    return -30
  }

  return 0
}

// This custom tick ensures that leftmost and rightmost tick labels display within chart bounds
export const SmartXAxisTick: React.FC<IProps> = (props) => {
  return (
    <text
      name={props.name || 'name'}
      type={props.type || 'number'}
      stroke={CHARCOAL_GREY}
      orientation={props.orientation || 'bottom'}
      width={props.width || 0}
      height={props.height || 0}
      x={props.x || 0}
      y={props.y || 0}
      fill={CHARCOAL_GREY}
      className='recharts-text recharts-cartesian-axis-tick-value'
      textAnchor={props.textAnchor || 'middle'}
    >
      <tspan
        className='x-axis-title'
        x={props.payload ? props.payload.coordinate + getTickOffset(props.index, props.visibleTicksCount) : 0}
        dy='0.71em'
      >
        {props.tickFormatter?.(props.payload?.value || '', props.index || 0)}
      </tspan>
    </text>
  )
}
