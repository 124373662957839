import { ITimeState } from '@doseme/cohesive-ui'
import { format } from 'date-fns'
import moment from 'moment'

import { dateFnsRawDateOnly, rawTimeOnly } from '../../../../../../../../constants/timeFormat'
import { IAdministration } from '../../../../../../../../store/administrations/types'
import { ICourseLimits } from '../../../../../../../../store/course/types'
import {
  ICustomizedTargetAttribsAUC24,
  ICustomizedTargetAttribsAUC,
  ICustomizedTargetAttribsPeakOnly,
  ICustomizedTargetAttribsTroughOnly,
  ICustomizedTargetAttribsPeakTrough,
  ICustomizedTargetAttribsExtendedInterval,
  ICustomizedTargetAttribsTimeToTrough,
  IModelDoseRecommendation,
  TModelType,
  ICustomizedTargetAttribsTimeAboveMIC,
  ICustomizedTargetAttribsTimeInRange,
  ICustomizedTargetAttribsCumulativeAUC
} from '../../../../../../../../store/dosingRecommendation/types'
import { IObservation } from '../../../../../../../../store/observations/types'
import { ICustomTargetForms, TTargetTypes } from './types'

export const getModelTypeName = (selectedSimulationPanelTab: TModelType, isIndividualized: boolean) => {
  if (selectedSimulationPanelTab === 'indPop') {
    if (isIndividualized) {
      return 'Individualized'
    }

    return 'Population'
  }

  if (['customDose', 'customTarget'].includes(selectedSimulationPanelTab)) {
    return 'Custom'
  }

  // Guideline
  return 'Guideline'
}

export const getDoseDescription = (currentSimulation: IModelDoseRecommendation | null): string => {
  return currentSimulation?.doseDescription || '-'
}

export const isValidTimeInput = (time: ITimeState): boolean => {
  // validating hours
  const validHourCharsRegex = /^(2[0-3]|[01][0-9])$/
  const hhString = time.hh.toString().match(validHourCharsRegex)?.[1]
  if (!hhString) {
    return false
  }

  const isValidNumberRegex = /^(-?(\d+))$/
  if (!isValidNumberRegex.test(hhString.toString())) {
    return false
  }

  if (parseFloat(hhString!) < 0 || parseFloat(hhString!) > 23) {
    return false
  }

  // validating minutes
  const validMinuteCharsRegex = /^([0-5][0-9])$/
  const mmString = time.mm.toString().match(validMinuteCharsRegex)?.[1]

  if (!mmString) {
    return false
  }

  if (!isValidNumberRegex.test(mmString.toString())) {
    return false
  }

  if (parseFloat(mmString!) < 0 || parseFloat(mmString!) > 59) {
    return false
  }

  return true
}

export const getLastAdministration = (administrations: IAdministration[]): IAdministration | undefined => {
  if (administrations.length) {
    return administrations[administrations.length - 1]
  }
}

export const getFirstSeCrObservation = (observations: IObservation[]): IObservation | undefined => {
  if (observations.length) {
    return observations[0]
  }
}

export const getLastSeCrObservation = (observations: IObservation[]): IObservation | undefined => {
  if (observations.length) {
    return observations[observations.length - 1]
  }
}

export const getCalcTypeName = (currentModel: TModelType, isIndividualized: boolean, longVariant?: boolean): string => {
  if (currentModel === 'customTarget') {
    return longVariant ? 'Customized target' : 'Customized'
  }

  if (currentModel === 'customDose') {
    return longVariant ? 'Customized dose' : 'Customized'
  }

  if (currentModel === 'guideline') {
    return 'Guideline'
  }

  if (currentModel === 'indPop' && isIndividualized) {
    return 'Individualized'
  }

  return 'Population'
}

export const buildCustomizedTargetAttributes = (
  customTargetForms: ICustomTargetForms,
  targetType: TTargetTypes,
  limits: ICourseLimits,
  nextDoseAt: Date,
  hospitalTimezone: string
):
  | ICustomizedTargetAttribsAUC24
  | ICustomizedTargetAttribsAUC
  | ICustomizedTargetAttribsPeakOnly
  | ICustomizedTargetAttribsTroughOnly
  | ICustomizedTargetAttribsPeakTrough
  | ICustomizedTargetAttribsExtendedInterval
  | ICustomizedTargetAttribsTimeAboveMIC
  | ICustomizedTargetAttribsTimeToTrough
  | ICustomizedTargetAttribsTimeInRange
  | ICustomizedTargetAttribsCumulativeAUC
  | null => {
  const formattedDateString = moment
    .tz(format(nextDoseAt, dateFnsRawDateOnly) + 'T' + format(nextDoseAt, rawTimeOnly), hospitalTimezone)
    .utc()
    .toISOString()

  if (targetType === 'auc24') {
    return {
      targetType: 'auc24',
      nextDoseDate: formattedDateString,
      auc24: {
        value: customTargetForms['auc24'].values['auc24'],
        unit: limits.aucTarget.default.unit
      },
      infusionLength: parseFloat(customTargetForms['auc24'].values['infusionLength']), //FIXME remove once from validation is fixed
      dosingInterval: {
        value: customTargetForms['auc24'].values['dosingInterval'],
        unit: limits.dosingPeriod.default.unit
      },
      numberOfDoses: customTargetForms['auc24'].values['numberOfDoses']
    }
  }

  if (targetType === 'auc') {
    return {
      targetType: 'auc',
      nextDoseDate: formattedDateString,
      auc: {
        value: customTargetForms['auc'].values['auc'],
        unit: limits.aucTarget.default.unit
      },
      infusionLength: customTargetForms['auc'].values['infusionLength'],
      dosingInterval: {
        value: customTargetForms['auc'].values['dosingInterval'],
        unit: limits.dosingPeriod.default.unit
      },
      numberOfDoses: customTargetForms['auc'].values['numberOfDoses']
    }
  }

  if (targetType === 'peakonly') {
    return {
      targetType: 'peakonly',
      nextDoseDate: formattedDateString,
      peak: {
        value: customTargetForms['peakonly'].values['peak'],
        unit: limits.peakTarget.default.unit
      },
      infusionLength: parseFloat(customTargetForms['peakonly'].values['infusionLength']), //FIXME remove once from validation is fixed
      dosingInterval: {
        value: customTargetForms['peakonly'].values['dosingInterval'],
        unit: limits.dosingPeriod.default.unit
      },
      numberOfDoses: customTargetForms['peakonly'].values['numberOfDoses']
    }
  }

  if (targetType === 'troughonly') {
    return {
      targetType: 'troughonly',
      nextDoseDate: formattedDateString,
      trough: {
        value: customTargetForms['troughonly'].values['trough'],
        unit: limits.troughTarget.default.unit
      },
      infusionLength: parseFloat(customTargetForms['troughonly'].values['infusionLength']), //FIXME remove once from validation is fixed
      dosingInterval: {
        value: customTargetForms['troughonly'].values['dosingInterval'],
        unit: limits.dosingPeriod.default.unit
      },
      numberOfDoses: customTargetForms['troughonly'].values['numberOfDoses']
    }
  }

  if (targetType === 'peak/trough') {
    return {
      targetType: 'peak/trough',
      nextDoseDate: formattedDateString,
      peak: {
        value: customTargetForms['peak/trough'].values['peak'],
        unit: limits.peakTarget.default.unit
      },
      trough: {
        value: customTargetForms['peak/trough'].values['trough'],
        unit: limits.troughTarget.default.unit
      },
      infusionLength: parseFloat(customTargetForms['peak/trough'].values['infusionLength']), //FIXME remove once from validation is fixed
      dosingInterval: {
        value: customTargetForms['peak/trough'].values['dosingInterval'],
        unit: limits.dosingPeriod.default.unit
      },
      numberOfDoses: customTargetForms['peak/trough'].values['numberOfDoses']
    }
  }

  if (targetType === 'extended_interval') {
    return {
      targetType: 'extended_interval',
      peak: {
        value: customTargetForms['extended_interval'].values['peak'],
        unit: limits.peakTarget.default.unit
      },
      trough: {
        value: customTargetForms['extended_interval'].values['trough'],
        unit: limits.troughTarget.default.unit
      },
      infusionLength: parseFloat(customTargetForms['extended_interval'].values['infusionLength']) //FIXME remove once from validation is fixed
    }
  }

  if (targetType === 'time_above_mic') {
    return {
      targetType: 'time_above_mic',
      nextDoseDate: formattedDateString,
      timeAboveMICPercentage: customTargetForms['time_above_mic'].values['timeAboveMICPercentage'],
      infusionLength: parseFloat(customTargetForms['time_above_mic'].values['infusionLength']), //FIXME remove once from validation is fixed
      dosingInterval: {
        value: customTargetForms['time_above_mic'].values['dosingInterval'],
        unit: limits.dosingPeriod.default.unit
      },
      numberOfDoses: customTargetForms['time_above_mic'].values['numberOfDoses']
    }
  }

  if (targetType === 'timetotrough') {
    return {
      targetType: 'timetotrough',
      trough: {
        value: customTargetForms['timetotrough'].values['trough'],
        unit: limits.troughTarget.default.unit
      },
      infusionLength: parseFloat(customTargetForms['timetotrough'].values['infusionLength']), //FIXME remove once from validation is fixed,
      doseAmount: {
        value: parseFloat(customTargetForms['timetotrough'].values['doseAmount']),
        unit: limits.dose.min.unit
      },
      numberOfDoses: 1
    }
  }

  if (targetType === 'range') {
    return {
      targetType: 'range',
      nextDoseDate: formattedDateString,
      peak: {
        value: customTargetForms['range'].values['peak'],
        unit: limits.peakTarget.default.unit
      },
      trough: {
        value: customTargetForms['range'].values['trough'],
        unit: limits.troughTarget.default.unit
      },
      dosingInterval: {
        value: customTargetForms['range'].values['dosingInterval'],
        unit: limits.dosingPeriod.default.unit
      },
      infusionLength: parseFloat(customTargetForms['range'].values['infusionLength']),
      numberOfDoses: customTargetForms['range'].values['numberOfDoses']
    }
  }

  if (targetType === 'cumulative_auc') {
    return {
      targetType: 'cumulative_auc',
      nextDoseDate: formattedDateString,
      remainingAUC: {
        value: parseFloat(customTargetForms['cumulative_auc'].values['remainingAUC']),
        unit: limits.cumulativeAUCTarget.default.unit
      },
      dosingInterval: {
        value: customTargetForms['cumulative_auc'].values['dosingInterval'],
        unit: limits.dosingPeriod.default.unit
      },
      infusionLength: parseFloat(customTargetForms['cumulative_auc'].values['infusionLength']),
      numberOfDoses: customTargetForms['cumulative_auc'].values['numberOfDoses']
    }
  }

  return null
}
