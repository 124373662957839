import { makeAutoObservable } from 'mobx'

import { DosingMatrixStore } from './DosingMatrixStore'
import { IDosingMatrix } from './types'

export class DosingMatrix implements IDosingMatrix {
  store: DosingMatrixStore

  type: 'alternativeDosingResponse'
  attributes: IDosingMatrix['attributes']

  constructor(store: DosingMatrixStore, entity: IDosingMatrix) {
    makeAutoObservable(this, {
      store: false
    })

    this.store = store
    this.type = entity.type
    this.attributes = { ...entity.attributes }
  }
}
