import { makeAutoObservable } from 'mobx'

import { AdministrationsStore } from './AdministrationsStore'
import { IAdministration } from './types'

export class Administration implements IAdministration {
  store: AdministrationsStore

  id: string
  type: string
  attributes: IAdministration['attributes']

  constructor(store: AdministrationsStore, entity: IAdministration) {
    makeAutoObservable(this, {
      store: false
    })

    this.store = store

    this.id = entity.id
    this.type = entity.type
    this.attributes = { ...entity.attributes }
  }
}
