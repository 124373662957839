import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'
import { ActionButton, SidePanel } from '@doseme/cohesive-ui'

import { useFormValidation } from '../../../../../../../../hooks/useFormValidation'
import { IDrugDetailsPanelWithoutModalProps } from '../../types'
import { useAdminHospitalDrugDetailsStore } from '../../../../../../../../hooks/useStore'
import { buildInlineFormsFields, buildPutRequest, displayInlineEditPanels, displayNoModalPanels, arrangeKeysInOrder } from '../../utils'
import { IPutAdminHospitalDrugDoseCalculatedTargetLimits } from '../../../../../../../../store/Admin/AdminHospitalDrugDetails/types'
import { showErrorToast, showSuccessToast } from '../../../../../../../../shared/toast'

import '../../index.scss'

export const CalculatedDosesTargetSettings: React.FC<IDrugDetailsPanelWithoutModalProps> = observer((props) => {
  const [selectedPanelsHelpTextList, setSelectedPanelsHelptextList] = useState<string[]>([])
  const order = ['auc24', 'mic', 'timeAboveMic', 'cumulativeAuc', 'peak', 'trough']
  const dataKeys = props.data ? arrangeKeysInOrder(Object.keys(props.data), order) : []

  const adminHospitalDrugDetailsStore = useAdminHospitalDrugDetailsStore()

  const inlineFormFields = useMemo(() => {
    if (['loaded', 'updateError'].includes(adminHospitalDrugDetailsStore.settingsLoadStates.doseCalculatedTargetLimits)) {
      const builtInlineFormFields = buildInlineFormsFields(
        adminHospitalDrugDetailsStore.adminHospitalDrugDoseCalculatedTargetLimits?.attributes.settings.dosesCalculatedTargetLimits
      )

      return {
        mic: builtInlineFormFields['mic'] || {},
        timeAboveMic: builtInlineFormFields['timeAboveMic'] || {},
        auc24: builtInlineFormFields['auc24'] || {},
        peak: builtInlineFormFields['peak'] || {},
        trough: builtInlineFormFields['trough'] || {},
        cumulativeAuc: builtInlineFormFields['cumulativeAuc'] || {}
      }
    }

    return {
      mic: {},
      timeAboveMic: {},
      auc24: {},
      peak: {},
      trough: {},
      cumulativeAuc: {}
    }
  }, [adminHospitalDrugDetailsStore.settingsLoadStates.doseCalculatedTargetLimits])

  const forms = {
    mic: useFormValidation(inlineFormFields['mic']),
    timeAboveMic: useFormValidation(inlineFormFields['timeAboveMic']),
    auc24: useFormValidation(inlineFormFields['auc24']),
    peak: useFormValidation(inlineFormFields['peak']),
    trough: useFormValidation(inlineFormFields['trough']),
    cumulativeAuc: useFormValidation(inlineFormFields['cumulativeAuc'])
  }

  useEffect(() => {
    if (adminHospitalDrugDetailsStore.settingsLoadStates.doseCalculatedTargetLimits === 'loaded') {
      forms.mic.reset()
      forms.timeAboveMic.reset()
      forms.auc24.reset()
      forms.peak.reset()
      forms.trough.reset()
      forms.cumulativeAuc.reset()
    }
  }, [adminHospitalDrugDetailsStore.settingsLoadStates.doseCalculatedTargetLimits])

  useEffect(() => {
    if (props.isPanelEditable) {
      adminHospitalDrugDetailsStore.fetchAdminHospitalDrugDoseCalculatedTargetLimits(props.hospitalId, props.drugModelId)
    }
  }, [props.hospitalId, props.isPanelEditable])

  const handleSubmit = async () => {
    if (props.drugModelId && adminHospitalDrugDetailsStore.adminHospitalDrugDoseCalculatedTargetLimits) {
      const responseStructure = {
        name: adminHospitalDrugDetailsStore.adminHospitalDrugDoseCalculatedTargetLimits.attributes.name,
        settings: {
          dosesCalculatedTargetLimits:
            buildPutRequest(
              forms,
              adminHospitalDrugDetailsStore.adminHospitalDrugDoseCalculatedTargetLimits?.attributes.settings.dosesCalculatedTargetLimits
            )
        }
      }

      await adminHospitalDrugDetailsStore.putAdminHospitalDrugDoseCalculatedTargetLimits(
        props.hospitalId,
        props.drugModelId,
        responseStructure as IPutAdminHospitalDrugDoseCalculatedTargetLimits
      )

      if (['loadError', 'updateError'].includes(adminHospitalDrugDetailsStore.settingsLoadStates.doseCalculatedTargetLimits)) {
        showErrorToast(
          adminHospitalDrugDetailsStore.settingsErrors.doseCalculatedTargetLimits || 'Failed to update calculated doses: general settings'
        )

        return
      }

      showSuccessToast('Calculated doses: target settings updated')
      props.setIsPanelEditable(null)
    }
  }

  return (
    <div className='mt-3'>
      <SidePanel
        loading={
          ['loading', 'updating'].includes(adminHospitalDrugDetailsStore.settingsLoadStates.doseCalculatedTargetLimits)
          || ['loading', 'updating'].includes(adminHospitalDrugDetailsStore.loadState)
        }
        title='Calculated doses: target settings'
        actionButton={
          adminHospitalDrugDetailsStore.loadState === 'loaded' && props.data && !props.isPanelEditable
            ? (
              <ActionButton
                data-testid='hospital-drug-details-data-btn'
                actionType='edit'
                onClick={() => props.setIsPanelEditable('targetSettings')}
              />
            )
            : undefined
        }
      >
        {props.data &&
          (props.isPanelEditable &&
          adminHospitalDrugDetailsStore.adminHospitalDrugDoseCalculatedTargetLimits?.attributes.settings
            .dosesCalculatedTargetLimits
            ? displayInlineEditPanels(
              props.width,
              props.data,
              adminHospitalDrugDetailsStore.adminHospitalDrugDoseCalculatedTargetLimits?.attributes.settings
                .dosesCalculatedTargetLimits,
              dataKeys,
              forms,
              inlineFormFields,
              adminHospitalDrugDetailsStore.settingsLoadStates.doseCalculatedTargetLimits,
              handleSubmit,
              props.setIsPanelEditable
            )
            : displayNoModalPanels(
              props.data,
              dataKeys,
              selectedPanelsHelpTextList,
              props.width,
              setSelectedPanelsHelptextList
            ))}
      </SidePanel>
    </div>
  )
})
