import '../index.scss'

// Renders arrows pointing to latest SeCr observation when outside viewed range
export const SecrOutsideRange: React.FC<any> = (props) => {
  if (props.leftPointer) {
    return (
      <svg x={props.cx - 5} y={props.cy - 6} width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.99062 5.46885L11.2406 1.21885C11.5344 0.925098 12.0094 0.925098 12.3 1.21885L13.0062 1.9251C13.3 2.21885 13.3 2.69385 13.0062 2.98447L9.99687 6.0001L13.0094 9.0126C13.3031 9.30635 13.3031 9.78135 13.0094 10.072L12.3031 10.7813C12.0094 11.0751 11.5344 11.0751 11.2437 10.7813L6.99374 6.53135C6.69687 6.2376 6.69687 5.7626 6.99062 5.46885V5.46885ZM0.990614 6.53135L5.24061 10.7813C5.53436 11.0751 6.00937 11.0751 6.29999 10.7813L7.00624 10.0751C7.29999 9.78135 7.29999 9.30635 7.00624 9.01572L3.99686 6.0001L7.00937 2.9876C7.30312 2.69385 7.30312 2.21885 7.00937 1.92822L6.30312 1.21885C6.00937 0.925098 5.53437 0.925098 5.24374 1.21885L0.993739 5.46885C0.696864 5.7626 0.696864 6.2376 0.990614 6.53135V6.53135Z" fill="#4F30E9" />
      </svg>
    )
  }

  if (props.rightPointer) {
    return (
      <svg x={props.cx - 12} y={props.cy - 6} width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.00938 6.53135L2.75938 10.7813C2.46563 11.0751 1.99063 11.0751 1.7 10.7813L0.99375 10.0751C0.7 9.78135 0.7 9.30635 0.99375 9.01572L4.00625 6.00322L0.99375 2.99072C0.7 2.69697 0.7 2.22197 0.99375 1.93135L1.69687 1.21885C1.99062 0.925098 2.46562 0.925098 2.75625 1.21885L7.00625 5.46885C7.30312 5.7626 7.30312 6.2376 7.00938 6.53135ZM13.0094 5.46885L8.75937 1.21885C8.46562 0.925098 7.99062 0.925098 7.7 1.21885L6.99375 1.9251C6.7 2.21885 6.7 2.69385 6.99375 2.98447L10.0063 5.99697L6.99375 9.00947C6.7 9.30322 6.7 9.77822 6.99375 10.0688L7.7 10.7751C7.99375 11.0688 8.46875 11.0688 8.75937 10.7751L13.0094 6.5251C13.3031 6.2376 13.3031 5.7626 13.0094 5.46885Z" fill="#4F30E9" />
      </svg>
    )
  }

  return null
}
