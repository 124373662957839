import { Icons, InfoBubble, InfoModal, Modal } from '@doseme/cohesive-ui'

import { IModalProps } from '../../../../PatientRouter/types'

import './index.scss'

export const TwoFAModal: React.FC<IModalProps> = (props) => {
  const formContent = (): JSX.Element => {
    return (
      <div className='twofa-page-modal'>
        <div className='twofa-page-modal-subtitle'>Your site requires two-step authentcation with every login</div>
        <div className='twofa-page-modal-description'>
          Each time you sign in to your DoseMeRx account, you’ll need to enter your password as well as a secondary
          verification code.
        </div>
        <div>
          <InfoBubble bubbleTitle='About 2FA:' borderRadius={6}>
            <div className='twofa-page-modal-description-text mb-2'>
              2-factor authentication (2FA) is a security system that requires two separate, distinct forms of
              verification in order to access something.
            </div>
            <div className='twofa-page-modal-description-text'>
              The first factor is your password, and the second is a time-based one-time passcode (TOTP) accessed
              through an authenticator app via your smartphone.
            </div>
          </InfoBubble>
        </div>
        <div className='twofa-page-modal-warning'>
          <div className='mr-2'>
            <Icons.Alert />
          </div>
          <div>
            <b className='twofa-page-modal-description-text'>Note:</b> If you can’t access your authenticator codes,
            please contact your site admin to reset your authenticator app access, then set up 2FA for DoseMeRx again.
          </div>
        </div>
        <div></div>
      </div>
    )
  }

  const handleClose = () => {
    props.setShow(false)
  }

  return (
    <Modal show={props.show} onHide={handleClose}>
      <InfoModal size='s' title='Help with 2-factor authentication' message={formContent()} onDismiss={handleClose} />
    </Modal>
  )
}
