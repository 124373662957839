import * as ReactDOMServer from 'react-dom/server'

import { openExternalWindowEpic } from '../OpenWindowEpic'

export const downloadDocumentURL = (url: string) => {
  return window.env.AZURE_DOCUMENT_CONTAINER + url
}

export const formatExternalLinkDivs = (url: string, epicToken: string | undefined, externalLinkDiv: JSX.Element) => {
  if (window.env.VENDOR_MODE === 'epic') {
    return (
      <span onClick={() => openExternalWindowEpic(url, epicToken)}>
        {externalLinkDiv}
      </span>
    )
  }

  if (window.env.VENDOR_MODE === 'cerner') {
    const content = ReactDOMServer.renderToStaticMarkup(externalLinkDiv)
    const el = document.getElementById('applink') as HTMLAnchorElement
    const cloned = el.cloneNode(true) as HTMLAnchorElement
    cloned.id = ''
    cloned.href = el.href.replace('__PLACEHOLDER_URL__', url)
    cloned.innerHTML = content

    return <span dangerouslySetInnerHTML={{ __html: cloned.outerHTML }} />
  }

  return (
    <a href={url} target='_blank' >
      {externalLinkDiv}
    </a>
  )
}

export const getResourcesRoutePrefix = (patientId: string | undefined) => {
  return patientId ? `/patients/${patientId}/resources` : '/resources'
}
