import { makeAutoObservable } from 'mobx'

import { ActivityLogStore } from './ActivityLogStore'
import { IActivityLog } from './types'

export class ActivityLog implements IActivityLog {
  store: ActivityLogStore

  id: string
  type: string
  attributes: IActivityLog['attributes']

  constructor(store: ActivityLogStore, entity: IActivityLog) {
    makeAutoObservable(this, {
      store: false
    })

    this.store = store

    this.id = entity.id
    this.type = entity.type
    this.attributes = { ...entity.attributes }
  }
}
