import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import detector from 'i18next-browser-languagedetector'

import en from './locales/en.json'

const resources = {
  en: { translation: en }
}

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    debug: window.env.INSTANCE_TYPE === 'DEV',
    resources,
    fallbackLng: 'en',
    fallbackNS: 'common',
    interpolation: { escapeValue: false },
    detection: {
      // Options for URL detection (you can use 'querystring' or 'path')
      order: ['querystring', 'cookie', 'localStorage'],
      lookupQuerystring: 'lng'
    }
  })

export default i18n
