import React, { useEffect, useRef } from 'react'

import { tableauAPIScriptURL, vizElementHeight, vizElementWidth } from './constants'
import { loadScript } from './utils'

interface IProps {
  url: string
  token: string
  hospitalUUID: string
  hospitalDepartment?: string
}

const TableauEmbed: React.FC<IProps> = (props) => {
  const vizContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (vizContainerRef.current) {
      initViz()
    }

    // Cleanup any existing "tableau-viz" elements
    return () => {
      if (vizContainerRef.current) {
        const vizElement = vizContainerRef.current.querySelector('tableau-viz')

        if (vizElement) {
          vizElement.remove()
        }
      }
    }
  }, [props.url, props.token, props.hospitalUUID, props.hospitalDepartment])

  const initViz = async () => {
    try {
      await loadScript(tableauAPIScriptURL)

      // Ensure any existing viz is disposed of before creating a new one
      if (vizContainerRef.current) {
        const vizElement = vizContainerRef.current.querySelector('tableau-viz')

        if (vizElement) {
          vizElement.remove()
        }
      }

      const vizElement = document.createElement('tableau-viz')
      vizElement.setAttribute('src', props.url)
      vizElement.setAttribute('width', vizElementWidth)
      vizElement.setAttribute('height', vizElementHeight)
      vizElement.setAttribute('toolbar', 'hidden')
      vizElement.setAttribute('hide-tabs', '')
      vizElement.setAttribute('token', props.token)

      const hospitalUuidFilter = document.createElement('viz-filter')
      hospitalUuidFilter.setAttribute('field', 'Hospital Uuid')
      hospitalUuidFilter.setAttribute('value', props.hospitalUUID)
      vizElement.appendChild(hospitalUuidFilter)

      if (props.hospitalDepartment) {
        const siteAccessFilter = document.createElement('viz-filter')
        siteAccessFilter.setAttribute('field', 'Site Access')
        siteAccessFilter.setAttribute('value', props.hospitalDepartment)
        vizElement.appendChild(siteAccessFilter)
      }

      if (vizContainerRef.current) {
        vizContainerRef.current.appendChild(vizElement)
      }
    } catch (error) {
      throw new Error(`Error initialising viz: ${error}`)
    }
  }

  return (
    <div ref={vizContainerRef} style={{ width: vizElementWidth, height: vizElementHeight }}></div>
  )
}

export {
  TableauEmbed
}
