export const getDrugNameFromDrugModel = (modelName: string): string => {
  const split = modelName.split(' -')

  if (split.length > 1) {
    return split[0]
  }

  return ''
}

export const getModelNameFromDrugModel = (modelName: string): string => {
  const split = modelName.split('- ')

  if (split.length > 1) {
    return split[1]
  }

  return ''
}
