import { makeAutoObservable } from 'mobx'

import { VersionsStore } from './VersionsStore'
import { IVersions } from './types'

export class Versions implements IVersions {
  store: VersionsStore

  type: string
  attributes: IVersions['attributes']

  constructor(store: VersionsStore, entity: IVersions) {
    makeAutoObservable(this, {
      store: false
    })

    this.store = store
    this.type = entity.type
    this.attributes = { ...entity.attributes }
  }
}
