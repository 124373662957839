import { makeAutoObservable } from 'mobx'

import { IAdminHospitalDrugRecordedCourseData } from './types'
import { AdminHospitalDrugDetailsStore } from './AdminHospitalDrugDetailsStore'

export class AdminHospitalDrugRecordedCourseData implements IAdminHospitalDrugRecordedCourseData {
  store: AdminHospitalDrugDetailsStore

  id: string
  type: string
  attributes: IAdminHospitalDrugRecordedCourseData['attributes']

  constructor(store: AdminHospitalDrugDetailsStore, entity: IAdminHospitalDrugRecordedCourseData) {
    makeAutoObservable(this, {
      store: false
    })

    this.store = store

    this.id = entity.id
    this.type = entity.type
    this.attributes = { ...entity.attributes }
  }
}
