import { makeAutoObservable, observable, ObservableMap } from 'mobx'

import axiosClient, { AxiosError, AxiosResponse } from '../../utils/axiosClient'
import { drugModelsGetUrl, moleculesGetUrl } from '../../constants/api'
import { RootStore } from '../RootStore'
import { TLoadState } from '../types'
import { DrugModel } from './DrugModel'
import { IDrugModel, IMolecule } from './types'
import { Molecule } from './Molecule'

export class DrugModelsStore {
  rootStore: RootStore

  loadStateMolecules: TLoadState = 'initial'
  loadStateDrugmodels: TLoadState = 'initial'

  error: string = ''

  drugmodels: ObservableMap<string, DrugModel> = observable.map({}, { deep: false })
  molecules: ObservableMap<string, Molecule> = observable.map({}, { deep: false })

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false
    })

    this.rootStore = rootStore
  }

  // Drug Models Functions
  resetDrugModels(drugmodels: IDrugModel[]) {
    this.drugmodels = observable.map({}, { deep: false })
    drugmodels.forEach((drugmodel) => this.drugmodels.set(drugmodel.id, new DrugModel(this, drugmodel)))
  }

  setLoadStateDrugmodels(loadState: TLoadState) {
    this.loadStateDrugmodels = loadState
  }

  setErrorDrugModels(errorState: 'loadError' | 'updateError', error: string) {
    this.error = error
    this.setLoadStateDrugmodels(errorState)
  }

  // Molecules Functions
  resetMolecules(molecules: IMolecule[]) {
    this.molecules = observable.map({}, { deep: false })
    molecules.forEach((molecule) => this.molecules.set(molecule.id, new Molecule(this, molecule)))
  }

  setLoadStateMolecules(loadState: TLoadState) {
    this.loadStateMolecules = loadState
  }

  setErrorMolecules(errorState: 'loadError' | 'updateError', error: string) {
    this.error = error
    this.setLoadStateMolecules(errorState)
  }

  filterApplicableModels = () => {
    return this.drugModelItems().filter((drugModel) => {
      if (drugModel.attributes.valid) {
        return drugModel
      }
    })
  }

  getSuggestedModel = (): IDrugModel | null => {
    let suggestedDrugModel = null
    this.drugModelItems().filter((drugModel) => {
      if (drugModel.attributes.isSuggested) {
        suggestedDrugModel = drugModel
      }
    })

    return suggestedDrugModel
  }

  drugModelItems = (): DrugModel[] => {
    return [...this.drugmodels].map(([key, item]) => {
      return item
    })
  }

  moleculeItems = (): Molecule[] => {
    return [...this.molecules].map(([key, item]) => {
      return item
    })
  }

  async fetchDrugModels(patientId: string, drugId: string) {
    this.setLoadStateDrugmodels('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .get<AxiosResponse<IDrugModel[]>>(drugModelsGetUrl(patientId, drugId), { headers })
      .then((response: AxiosResponse) => {
        this.resetDrugModels(response.data.data)
        this.setLoadStateDrugmodels('loaded')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setErrorDrugModels('loadError', loggableError)
        this.resetDrugModels([])
      })
  }

  async fetchMolecules(patientId: string) {
    this.setLoadStateMolecules('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .get<AxiosResponse<IMolecule[]>>(moleculesGetUrl(patientId), { headers })
      .then((response: AxiosResponse) => {
        this.resetMolecules(response.data.data)
        this.setLoadStateMolecules('loaded')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setErrorMolecules('loadError', loggableError)
        this.resetMolecules([])
      })
  }
}
