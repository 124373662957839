import { FOOTER_LINK, IHeaderItem, ITab, ITooltipMenuData, LIGHTGREY, WARNING } from '@doseme/cohesive-ui'

import { IAdminClinicianListItem } from '../../../../store/Admin/AdminClinicianList/types'

export const itemsPerPage = 10

export const roleColours = {
  ADM: FOOTER_LINK,
  SPR: WARNING,
  EXU: LIGHTGREY,
  SUP: LIGHTGREY,
  HDE: LIGHTGREY,
  PLE: LIGHTGREY,
  RES: LIGHTGREY,
  AIP: LIGHTGREY,
  NCA: LIGHTGREY,
  COH: LIGHTGREY,
  ANA: LIGHTGREY,
  AVI: LIGHTGREY
}

export const enabledCliniciansActionData = (
  clinician: IAdminClinicianListItem,
  handleClinicianDetailsButton: (clinicianId: string) => void,
  handleHospitalAccessRedirect: (clinicianId: string) => void,
  handleDisableClinician: (clinicianIds: string[]) => void,
  currentClinicianId?: string
): ITooltipMenuData[] => {
  return [
    {
      id: 1,
      value: 'Edit clinician profile',
      onSelect: () => {
        handleClinicianDetailsButton(clinician.id)
      }
    },
    {
      id: 2,
      value: 'View site access',
      onSelect: () => handleHospitalAccessRedirect(clinician.id)
    },
    {
      id: 3,
      disabled: clinician.id === currentClinicianId,
      value: 'Disable clinician',
      onSelect: () => handleDisableClinician([clinician.id])
    }
  ]
}

export const pendingCliniciansActionData = (
  clinician: IAdminClinicianListItem,
  handleClinicianDetailsButton: (clinicianId: string) => void,
  handleResendClinicianInvite: (clinicianId: string) => void,
  handleCancelClinicianInvite: (clinicianId: string) => void
): ITooltipMenuData[] => {
  return [
    {
      id: 1,
      value: 'Edit clinician profile',
      onSelect: () => handleClinicianDetailsButton(clinician.id)
    },
    {
      id: 2,
      value: 'Re-send invite',
      onSelect: () => handleResendClinicianInvite(clinician.id)
    },
    {
      id: 3,
      value: 'Cancel invite',
      onSelect: () => handleCancelClinicianInvite(clinician.id)
    }
  ]
}

export const disabledCliniciansActionData = (
  clinician: IAdminClinicianListItem,
  handleClinicianDetailsButton: (clinicianId: string) => void,
  handleEnableClinician: (clinicianIds: string[]) => void
): ITooltipMenuData[] => {
  return [
    {
      id: 1,
      value: 'Edit clinician profile',
      onSelect: () => handleClinicianDetailsButton(clinician.id)
    },
    {
      id: 2,
      value: 'Enable clinician',
      onSelect: () => handleEnableClinician([clinician.id])
    }
  ]
}

export const enabledCliniciansColumns = (handleSort: (colIndex: number, ascending: boolean) => void, screenWidth: number): IHeaderItem[] => {
  if (screenWidth <= 1020) {
    return [
      {
        name: 'Name',
        handleSort,
        width: 7
      },
      {
        name: 'Last Activity',
        handleSort,
        width: 3
      },
      {
        name: '',
        width: 1
      }
    ]
  }

  return [
    {
      name: 'Name',
      handleSort,
      width: 7
    },
    {
      name: 'Email',
      handleSort,
      width: 6
    },
    {
      name: 'Last Activity',
      handleSort,
      width: 3
    },
    {
      name: '',
      width: 1
    }
  ]
}

export const pendingCliniciansColumns = (handleSort: (colIndex: number, ascending: boolean) => void, screenWidth: number): IHeaderItem[] => {
  if (screenWidth <= 1020) {
    return [
      {
        name: 'Email',
        handleSort,
        width: 6
      },
      {
        name: 'Invite expiry',
        handleSort,
        width: 3
      },
      {
        name: '',
        width: 1
      }
    ]
  }

  return [
    {
      name: 'Name',
      handleSort,
      width: 7
    },
    {
      name: 'Email',
      handleSort,
      width: 6
    },
    {
      name: 'Invite expiry',
      handleSort,
      width: 3
    },
    {
      name: '',
      width: 1
    }
  ]
}

export const disabledCliniciansColumns = (
  handleSort: (colIndex: number, ascending: boolean) => void,
  screenWidth: number
): IHeaderItem[] => {

  if (screenWidth <= 1020) {
    return [
      {
        name: 'Name',
        handleSort,
        width: 7
      },
      {
        name: 'Disabled at',
        handleSort,
        width: 3
      },
      {
        name: '',
        width: 1
      }
    ]
  }

  return [
    {
      name: 'Name',
      handleSort,
      width: 7
    },
    {
      name: 'Email',
      handleSort,
      width: 6
    },
    {
      name: 'Disabled at',
      handleSort,
      width: 3
    },
    {
      name: '',
      width: 1
    }
  ]}

export const adminCliniciansTabs: ITab[] = [
  { id: 'enabledClinicians', displayName: 'Enabled clinicians' },
  { id: 'pendingClinicians', displayName: 'Pending invites' },
  { id: 'disabledClinicians', displayName: 'Disabled clinicians' }
]
