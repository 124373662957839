import { Icons } from '@doseme/cohesive-ui'
import { Tooltip } from 'react-tooltip'

import './index.scss'

export const SimulationOutcomesInfoIcon: React.FC = () => {
  return (
    <div className='simulation-outcomes-info-icon'>
      <Tooltip id='simulation-outcomes-info-tip' place='top'>
        Based on available doses and observations. <br />
        Values represent final dose in this calculation.
      </Tooltip>

      <div data-tooltip-id='simulation-outcomes-info-tip' className='info-icon-simoutcomes'>
        <Icons.Info />
      </div>
    </div>
  )
}
