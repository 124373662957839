import { ITimeState } from '@doseme/cohesive-ui'
import moment from 'moment-timezone'

import { nonUtcIso, tableReadable } from '../constants/timeFormat'

const formatToDisplayDate = (rawDate: string, hospitalTimezone: string, dateFormat: string = tableReadable): string => {
  try {
    return moment.tz(rawDate, hospitalTimezone).format(dateFormat)
  } catch {
    return 'Invalid date'
  }
}

// date string and timestate are assumed to already be in the hospital timezone
// we use the hospitalTimezone here with moment.tz to detect DST discrepancies
const isValidDSTDateTime = (
  rawDate: string, // date-fns format this using dateFnsRawDateOnly
  timeState: ITimeState,
  hospitalTimezone: string
): boolean => {
  if (
    !(
      rawDate &&
      timeState &&
      timeState.hh.length === 2 &&
      timeState.mm.length === 2
    )
  ) {
    return false
  }

  const rawTime = timeState.hh + ':' + timeState.mm
  const dstTestDate = rawDate + 'T' + rawTime

  return dstTestDate === moment.tz(dstTestDate, hospitalTimezone).format(nonUtcIso)
}

export {
  formatToDisplayDate,
  isValidDSTDateTime
}
