import { makeAutoObservable } from 'mobx'

import { IAdminClinicianListItem } from './types'
import { AdminClinicianListStore } from './AdminClinicianListStore'

export class AdminClinicianListItem implements IAdminClinicianListItem {
  store: AdminClinicianListStore

  id: string
  type: string
  attributes: IAdminClinicianListItem['attributes']

  constructor(store: AdminClinicianListStore, entity: IAdminClinicianListItem) {
    makeAutoObservable(this, {
      store: false
    })

    this.store = store

    this.id = entity.id
    this.type = entity.type
    this.attributes = { ...entity.attributes }
  }
}
