import { ActionButton } from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'

import { usePatientStore } from '../../../../hooks/useStore'

import './index.scss'

interface IProps {
  setShowHelpRequestModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const HelpRequestButton: React.FC<IProps> = observer((props) => {
  const patientStore = usePatientStore()

  return (
    <div className='request-help-button' key='nav-request-help' data-testid='request-help'>
      <ActionButton
        onClick={() => {
          props.setShowHelpRequestModal(true)
        }}
        actionType='help'
        size='md'
        customLabel='Request Help'
        disabled={['loading', 'updating'].includes(patientStore.loadState)}
      />
    </div>
  )
})
