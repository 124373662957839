import { makeAutoObservable } from 'mobx'

import { IAdminClinicianDetailsHospitalAccessList } from './types'
import { AdminClinicianDetailsStore } from './AdminClinicianDetailsStore'

export class AdminClinicianDetailsHospitalAccessList implements IAdminClinicianDetailsHospitalAccessList {
  store: AdminClinicianDetailsStore

  id: string
  type: string
  attributes: IAdminClinicianDetailsHospitalAccessList['attributes']

  constructor(store: AdminClinicianDetailsStore, entity: IAdminClinicianDetailsHospitalAccessList) {
    makeAutoObservable(this, {
      store: false
    })

    this.store = store

    this.id = entity.id
    this.type = entity.type
    this.attributes = { ...entity.attributes }
  }
}
