import { IHeaderItem, ITab, ITooltipMenuData } from '@doseme/cohesive-ui'

export const itemsPerPage = 10

export const adminClinicianDetailsTabs = (isSuperAdmin: boolean): ITab[] => {
  let tabs = [
    { id: 'profile', displayName: 'Profile' },
    { id: 'hospitalaccess', displayName: 'Hospital access' }
  ]

  if (isSuperAdmin) {
    tabs.push({ id: 'security', displayName: 'Security' })
  }

  return tabs
}

export const hospitalAccessColumns = (): IHeaderItem[] => [
  {
    name: 'Hospital name',
    width: 7
  },
  {
    name: 'Admin at hospital',
    width: 4
  },
  {
    name: '',
    width: 1
  }
]

export const disabledCliniciansActionData = (
  clinicianId: string,
  hospitalId: string,
  handleClinicianRemoveHospitalAccessButton: (clinicianId: string, hospitalId: string) => void
): ITooltipMenuData[] => {
  return [
    {
      id: 1,
      value: 'Remove from this hospital',
      onSelect: () => handleClinicianRemoveHospitalAccessButton(clinicianId, hospitalId)
    }
  ]
}
