import { BasicList, Icons, IHeaderItem } from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import { useHospitalStore, usePatientCoursesStore } from '../../../../../../../../hooks/useStore'
import { PatientCourse } from '../../../../../../../../store/patientCourses/PatientCourse'
import { formatToDisplayDate } from '../../../../../../../../utils/dates'
import { IRecentAdministrations, IRecentObservations } from '../../../../../../../../store/types'

import '../../../../index.scss'

const doseCols: IHeaderItem[] = [
  { name: 'Dose Time ↓', width: 5 },
  { name: 'Amount', width: 3 },
  { name: 'Inf. Length', width: 4 }
]

const observationCols: IHeaderItem[] = [
  { name: 'Measurement Time ↓', width: 5 },
  { name: 'Type', width: 3 },
  { name: 'Amount', width: 4 }
]

interface IProps {
  selectedCourseId: string
  patientId: string
}

export const PreviewPanelBottomSection: React.FC<IProps> = observer((props) => {
  const hospitalStore = useHospitalStore()
  const patientCoursesStore = usePatientCoursesStore()

  const formatRecentAdmins = (course: PatientCourse) => {
    const recentAdmins = course.attributes.recentAdministrations.filter(
      (admin) => admin.amount.value > 0 && parseInt(admin.amount.unit.id) > 0
    )

    return [...recentAdmins].map((admin, index) => {
      return {
        id: `${course.id}-administration-${index}`, //FIXME - would be better to use admin Id but requires change to endpoint
        disabled: admin.excludeFromCalculations,
        columns: [
          {
            name: 'Dose Time',
            text: formatToDisplayDate(admin.administeredAt, hospitalStore.hospital!.attributes.timezone)
          },
          {
            name: 'Amount',
            element: formatAdminAmount(admin)
          },
          {
            name: 'Infusion Length',
            text: `${admin.infusionLength.toString()} hour${admin.infusionLength === 1 ? '' : 's'}`
          }
        ]
      }
    })
  }

  const formatAdminAmount = (admin: IRecentAdministrations) => {
    return (
      <span className='d-inline-block'>
        {`${admin.amount.value.toString()} ${admin.amount.unit.name}`}
        {admin.excludeFromCalculations ?
          <div className='d-inline-block pl-1'>
            <Icons.Excluded />
          </div> : null
        }
      </span>
    )
  }

  const formatRecentObservations = (course: PatientCourse) => {
    const recentObservations = course.attributes.recentObservations.filter(
      (obs) => obs.amount.value > 0 && parseInt(obs.amount.unit.id) > 0
    )

    return [...recentObservations].map((observation, index) => {
      return {
        id: `${course.id}-observation-${index}`, //FIXME - would be better to use admin Id but requires change to endpoint
        disabled: observation.excludeFromCalculations,
        columns: [
          {
            name: 'observationTime',
            text: formatToDisplayDate(observation.observedAt, hospitalStore.hospital!.attributes.timezone)
          },
          {
            name: 'type',
            text: observation.observationType.shortName
          },
          {
            name: 'level',
            element: formatObservationLevel(observation)
          }
        ]
      }
    })
  }

  const formatObservationLevel = (observation: IRecentObservations) => {
    return (
      <span className='d-inline-block'>
        {`${observation.amount.value.toString()} ${observation.amount.unit.name || ''}`}
        {observation.excludeFromCalculations ?
          <div className='d-inline-block pl-1'>
            <Icons.Excluded />
          </div> : null
        }
      </span>
    )
  }

  const selectedCourse = patientCoursesStore.courses.get(props.selectedCourseId)

  const recentAdminsContent = selectedCourse ? formatRecentAdmins(selectedCourse) : []
  const recentObservationsContent = selectedCourse ? formatRecentObservations(selectedCourse) : []

  const doseFooter = () => {
    const count = selectedCourse?.attributes.administrationCount
    const disabled = !count

    return (
      <Link className={classnames('bottom-panel-footer-link', { 'bottom-panel-footer-link-disabled': disabled })}
        to={disabled ? '#' : `/patients/${props.patientId}/courses/${props.selectedCourseId}#administrations`}>
        <div className='bottom-panel-footer-text'>
          <span>{disabled ? '' : `${count} doses recorded`}</span>
          <span className={classnames('bottom-panel-footer-view-all', { 'bottom-panel-footer-view-all-disabled': disabled })}>View all &rarr;</span>
        </div>
      </Link>
    )
  }

  const observationFooter = () => {
    const count = selectedCourse?.attributes.observationCount
    const disabled = !count

    return (
      <Link className={classnames('bottom-panel-footer-link', { 'bottom-panel-footer-link-disabled': disabled })}
        to={disabled ? '#' : `/patients/${props.patientId}/courses/${props.selectedCourseId}#observations`}>
        <div className='bottom-panel-footer-text'>
          <span>{disabled ? '' : `${count} observations recorded`}</span>
          <span className={classnames('bottom-panel-footer-view-all', { 'bottom-panel-footer-view-all-disabled': disabled })}>View all &rarr;</span>
        </div>
      </Link>
    )
  }

  return (
    <div className='bottom-panel-wrapper'>
      <p className='text-header'>Last 3 Doses:</p>
      <div data-testid='dose-details' className='mb-5'>
        <BasicList
          cols={doseCols}
          minRowsToShow={3}
          data={recentAdminsContent}
          header={true}
          textIfEmpty='No doses to display.'
          cellClassName='ife-basic-list-cell-height'
          footer={doseFooter()}
        />
      </div>
      <p className='text-header'>Last 3 Observations:</p>
      <div data-testid='observation-details' className='mb-5'>
        <BasicList
          cols={observationCols}
          minRowsToShow={3}
          data={recentObservationsContent}
          header={true}
          textIfEmpty='No observations to display.'
          cellClassName='ife-basic-list-cell-height'
          footer={observationFooter()}
        />
      </div>
    </div>
  )
})
