import { makeAutoObservable } from 'mobx'

import { PatientCoursesStore } from './PatientCoursesStore'
import { IPatientCourse } from './types'

export class PatientCourse {
  store: PatientCoursesStore

  id: string
  type: string
  attributes: IPatientCourse['attributes']

  constructor(store: PatientCoursesStore, entity: IPatientCourse) {
    makeAutoObservable(this, {
      store: false
    })

    this.store = store

    this.id = entity.id
    this.type = entity.type
    this.attributes = { ...entity.attributes }
  }
}
