import { useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import useScreenSize from 'use-screen-size'
import {
  BackArrowButton,
  ControlButton,
  IColumnElement,
  IRowElement,
  Icons,
  ListButton,
  PaginationPanel,
  SearchInput,
  SmartList,
  TooltipMenu,
  Modal,
  ConfirmModal,
  Tag,
  MENU_GREY,
  IBreadcrumb,
  IHeaderItem
} from '@doseme/cohesive-ui'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import { getPageCount, paginate } from '../../../../utils/pagination'
import { useAdminVendorListStore, useBreadcrumbsStore, useClinicianStore } from '../../../../hooks/useStore'
import { sortAlpha } from '../../../../utils/smartListUtils'
import { IAdminVendorListItem } from '../../../../store/Admin/AdminVendorList/types'
import { searchVendors } from './utils'
import { vendorListColumns, itemsPerPage, vendorActionData } from './constants'
import { EditVendorModal } from './components/EditVendorModal/EditVendorModal'
import { showErrorToast, showSuccessToast } from '../../../../shared/toast'
import { handleBackButton } from '../../../../utils/navigation'
import { getAdminRoutePrefix } from '../../utils'

import './index.scss'

interface IProps {
  patientId?: string
}

export const VendorList: React.FC<IProps> = observer((props) => {
  const [searchText, setSearchText] = useState('')
  const [sortColIndex, setSortColIndex] = useState(0)
  const [sortColAscending, setSortColAscending] = useState(true)
  const [sortedList, setSortedList] = useState<IRowElement[] | null>(null)
  const [totalPages, setTotalPages] = useState<number>(1)
  const [currentPage, updateCurrentPage] = useState(1)
  const [showEditModal, setShowEditModal] = useState(false)
  const [editVendor, setEditVendor] = useState<string | undefined>(undefined)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteVendor, setDeleteVendor] = useState<IAdminVendorListItem | undefined>(undefined)
  const [vendorColumns, setVendorColumns] = useState<IHeaderItem[]>([])

  const adminVendorListStore = useAdminVendorListStore()
  const clinicianStore = useClinicianStore()
  const breadcrumbsStore = useBreadcrumbsStore()
  const size = useScreenSize()
  const history = useHistory()

  useEffect(() => {
    if (adminVendorListStore.loadState === 'loaded') {
      const breadcrumbs: IBreadcrumb[] = [
        {
          label: 'Admin hub',
          onClick: () =>
            handleBackButton(getAdminRoutePrefix(props.patientId), history)
        },
        { label: 'Vendors' }
      ]
      breadcrumbsStore.setBreadcrumbs(breadcrumbs)
    }

    adminVendorListStore.fetchVendors()
  }, [])

  useEffect(() => {
    if (adminVendorListStore.loadState === 'loaded' && adminVendorListStore.adminVendors) {
      const searchedVendors: IAdminVendorListItem[] = searchVendors(searchText, adminVendorListStore.adminVendors)
      setTotalPages(getPageCount(searchedVendors.length, itemsPerPage))
      const formattedVendors = formatVendors(searchedVendors, size.width)
      const sortedSearchedFormattedVendors = sortAlpha(formattedVendors, sortColIndex, sortColAscending)
      setSortedList(sortedSearchedFormattedVendors)
    }
  }, [adminVendorListStore.loadState, searchText, sortColIndex, sortColAscending, size.width])

  useEffect(() => {
    setVendorColumns(vendorListColumns(handleSort, size.width))
  }, [size.width])

  const handleCreateNewVendor = () => {
    history.push(getAdminRoutePrefix(props.patientId) + '/vendors/new')
  }

  const handleVendorDetailLink = (vendorId: string) => {
    history.push(getAdminRoutePrefix(props.patientId) + `/vendors/${vendorId}/sites`)
  }

  const handleSort = (colIndex: number, ascending: boolean) => {
    setSortColIndex(colIndex)
    setSortColAscending(ascending)
  }

  const handleSearch = (searchText: string) => {
    setSearchText(searchText)
    updateCurrentPage(1)
  }

  const handleEditVendor = (vendorId: string) => {
    setShowEditModal(true)
    setEditVendor(vendorId)
  }

  const handleDeleteVendor = (vendor: IAdminVendorListItem) => {
    setShowDeleteModal(true)
    setDeleteVendor(vendor)
  }

  const formatVendors = (vendors: IAdminVendorListItem[], screenWidth: number): IRowElement[] => {
    return vendors.map((vendor, key) => {
      const recordColumns: IColumnElement[] = [
        {
          name: 'Vendor name',
          link: () => handleVendorDetailLink(vendor.id),
          text: vendor.attributes.name
        }
      ]

      if (screenWidth > 1020) {
        recordColumns.push({
          name: 'Key',
          text: vendor.attributes.key,
          onClick: () => handleVendorDetailLink(vendor.id)
        })
      }

      recordColumns.push(
        {
          name: 'No. of sites',
          text: vendor.attributes.numberOfSites.toString(),
          onClick: () => handleVendorDetailLink(vendor.id)
        },
        {
          name: '',
          element: (
            <div className='vendor-list-action-button' data-testid='ellipsis-icon'>
              <TooltipMenu
                button={
                  <ListButton size='cm'>
                    <Icons.Ellipsis />
                  </ListButton>
                }
                data={vendorActionData(vendor, handleEditVendor, handleDeleteVendor) }
                alignRight={true}
                chevronOffset={10}
              />
            </div>
          )
        }
      )

      return {
        id: vendor.id,
        columns: recordColumns
      }
    })
  }

  const deleteModalMessage = () => {
    return (
      <div className='preview-panel-delete-modal'>
        <Tag size='large' text={deleteVendor?.attributes.name || ''} color={MENU_GREY} bold />
        <br />
        <div>The selected vendor will be permanently deleted.</div>
        <br />
        <div className='font-weight-bold'>This cannot be reversed.</div>
      </div>
    )
  }

  const deleteModal = () => {
    if (deleteVendor) {
      return (
        <Modal
          show={showDeleteModal}
          onHide={() => {
            setShowDeleteModal(false)
          }}
        >
          <ConfirmModal
            entityType='vendor'
            confirmationType='delete'
            onCancel={() => setShowDeleteModal(false)}
            onConfirm={() => handleDelete(deleteVendor)}
            title='Delete Vendor'
            message={deleteModalMessage()}
            cancelButtonLabel='Cancel'
            confirmButtonLabel='Delete'
          />
        </Modal>
      )
    }
  }

  const handleDelete = async (vendor: IAdminVendorListItem) => {
    if (vendor) {
      await adminVendorListStore.deleteVendor(vendor.id)
    }

    if (adminVendorListStore.loadState === 'updateError') {
      showErrorToast(adminVendorListStore.error || 'Failed to delete vendor')

      return
    }

    setDeleteVendor(undefined)
    showSuccessToast('Vendor deleted')
    setShowDeleteModal(false)
  }

  return (
    <div data-testid='vendor-list' className='co-vendor-list-page'>
      <EditVendorModal show={showEditModal} setShow={setShowEditModal} vendorId={editVendor} />
      {deleteModal()}
      <div className='vendor-list-heading'>
        <div className='vendor-list-back-btn'>
          <BackArrowButton
            data-testid='back-btn'
            onClick={() =>
              handleBackButton(getAdminRoutePrefix(props.patientId), history)
            }
          />
        </div>
        <div className='vendor-list-title'>Vendors</div>
        {clinicianStore.clinician?.attributes.isSuperAdmin && (
          <div className='create-new-vendor-btn'>
            <ControlButton icon={faPlus} onClick={handleCreateNewVendor}>
              Create new vendor
            </ControlButton>
          </div>
        )}
      </div>
      <div className='vendor-list-content-panel'>
        <div className='vendor-list-search-bar-container'>
          <div className='vendor-list-search-bar'>
            <SearchInput
              value={searchText}
              onChange={handleSearch}
              width={360}
              borderRadius={12}
              placeholder='Search list...'
            />
          </div>
        </div>
        <div className='vendor-list-list'>
          <SmartList
            cols={vendorColumns}
            data={sortedList ? paginate(sortedList, { currentPage, itemsPerPage }) : []}
            defaultSortColumn='Vendor name'
            defaultSortDirection='asc'
            loading={adminVendorListStore.loadState === 'loading'}
            textIfEmpty='No data to display'
          />
        </div>
        {sortedList && sortedList.length > 10 && (
          <div className='patient-profile-pagination-panel'>
            <PaginationPanel currentPage={currentPage} totalPages={totalPages} onPageChange={updateCurrentPage} />
          </div>
        )}
      </div>
    </div>
  )
})
