import classnames from 'classnames'
import { Trans } from 'react-i18next'

import { IProps } from './types'

import './index.scss'

export const TOSFooter: React.FC<IProps> = (props) => {
  return (
    <div className={classnames('login-page-panel smaller-width tos', props.className)}>
      <Trans i18nKey="auth.tos">
        By using DoseMeRx, you understand and agree to follow the
        <a className='highlight' href='https://doseme-rx.com/tos' target='_blank'> terms of service</a> {' '}
        and our <a className='highlight' href='https://doseme-rx.com/privacy-policy' target='_blank'> privacy policy</a>.
      </Trans>
    </div>
  )
}
