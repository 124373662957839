import { makeAutoObservable } from 'mobx'

import { IEditPatient } from './types'
import { PatientStore } from './PatientStore'

export class EditPatient implements IEditPatient {
  store: PatientStore

  type: 'hospitalPatientEditRead'
  attributes: IEditPatient['attributes']

  constructor(store: PatientStore, entity: IEditPatient) {
    makeAutoObservable(this, {
      store: false
    })

    this.store = store

    this.type = entity.type
    this.attributes = { ...entity.attributes }
  }
}
