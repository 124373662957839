/*

FIXME - IFE-700

I'd really like to be using the ES2022/ES6 Error constructor here
https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error

As that gives a second {cause: {foo}} optional param on the error constructor

To get that we either need to aggressively update core-js to a much much newer version or
change the tsconfig.json values for compilerOptions to:

"compilerOptions": {
  "target": "es2022",
    "lib": [
    "es2022",
    "dom",
    "dom.iterable",
    "esnext"
  ],

Unfortunately that then breaks _other_ deps - including momement-js/moment-timezone.

Long-term FIXME (possible in the next iternation)
  - verison bump all the deps to latest major versions
  - scrap moment-js for date-fns as _we no longer need to support IE8!_
  - then update this definition to use the two param constructor

*/

export class ApiServerError extends Error {
  __proto__ = Error

  constructor(message: string, options?: object) {
    super((options ? message + '\n' + JSON.stringify(options) : message))
    Object.setPrototypeOf(this, ApiServerError.prototype)
  }
}

export class ApiUnreachableError extends Error {
  __proto__ = Error

  constructor(message: string, options?: object) {
    super((options ? message + '\n' + JSON.stringify(options) : message))
    Object.setPrototypeOf(this, ApiUnreachableError.prototype)
  }
}

export class ApiUnknownError extends Error {
  __proto__ = Error

  constructor(message: string, options?: object) {
    super(message + '\n' + JSON.stringify(options))
    Object.setPrototypeOf(this, ApiUnknownError.prototype)
  }
}
