import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { decode } from 'he'
import { BackArrowButton, Button, Dropdown, InfoBubble, TextInput, WHITE } from '@doseme/cohesive-ui'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useFormValidation } from '../../../../../../hooks/useFormValidation'
import {
  useAdminHospitalDetailsStore,
  useAdminHospitalListStore,
  useBreadcrumbsStore,
  useConstantsTimezoneStore
} from '../../../../../../hooks/useStore'
import { showErrorToast, showSuccessToast } from '../../../../../../shared/toast'
import { duplicateHospitalFields } from './constants'
import { getAdminRoutePrefix } from '../../../../utils'
import { formatTimezones } from '../../../../../../utils/timezones'
import { handleBackButton } from '../../../../../../utils/navigation'

import './index.scss'

interface IProps {
  hospitalId: string
  patientId?: string
}

export const DuplicateHospital: React.FC<IProps> = observer((props) => {
  const timezoneStore = useConstantsTimezoneStore()
  const adminHospitalListStore = useAdminHospitalListStore()
  const breadcrumbsStore = useBreadcrumbsStore()

  const adminHospitalDetailsStore = useAdminHospitalDetailsStore()

  const history = useHistory()

  useEffect(() => {
    const breadcrumbs = [
      { label: 'Admin hub', onClick: () => returnToAdminHub() },
      {
        label: 'Hospitals',
        onClick: () =>
          handleBackButton(getAdminRoutePrefix(props.patientId) + `/hospitals/${props.hospitalId}`, history)
      },
      { label: 'Duplicate hospital' }
    ]
    breadcrumbsStore.setBreadcrumbs(breadcrumbs)
  }, [])

  const form = useFormValidation(duplicateHospitalFields())

  useEffect(() => {
    adminHospitalDetailsStore.fetchAdminHospitalDetails(props.hospitalId)
  }, [props.hospitalId])

  useEffect(() => {
    if (timezoneStore.loadState === 'initial' && !timezoneStore.timezones) {
      timezoneStore.fetchTimezones()
    }
  }, [])

  const returnToAdminHub = () => {
    history.push(getAdminRoutePrefix(props.patientId))
  }

  const submitHospital = async () => {
    const newHospitalId = await adminHospitalListStore.duplicateHospital(props.hospitalId, form.inputs['name'], form.inputs['timezone'])

    if (!newHospitalId || adminHospitalListStore.loadState === 'updateError') {
      showErrorToast(adminHospitalListStore.error || 'Failed to duplicate hospital')

      return
    }

    if (newHospitalId) {
      showSuccessToast('Hospital duplicated')
      history.push(getAdminRoutePrefix(props.patientId) + `/hospitals/${newHospitalId}`, { from: '/admin/hospitals' })
    }
  }

  return (
    <div data-testid='duplicate-hospital' className='duplicate-hospital-page'>
      <div className='duplicate-hospital-heading'>
        <div className='duplicate-hospital-back-btn'>
          <BackArrowButton
            data-testid='back-btn'
            onClick={() =>
              handleBackButton(getAdminRoutePrefix(props.patientId) + `/hospitals/${props.hospitalId}`, history)
            }
          />
        </div>
        <div>
          <div className='duplicate-hospital-title'>
            Hospitals: <b>Duplicate hospital</b>
          </div>
          <div className='duplicate-hospital-sub-title'>
            {`Duplicating from ${decode(adminHospitalDetailsStore.adminHospitalDetails?.attributes.details.name || '')}`}
          </div>
        </div>
      </div>
      <div className='duplicate-hospital-content-panel'>
        <div className='duplicate-hospital-content-title'>New hospital details</div>
        <div className='duplicate-hospital-inputs-wrapper'>
          <div className='duplicate-hospital-name-input'>
            <TextInput
              label='New hospital name:'
              fieldState={form.getValidState('name')}
              validationText={form.getValidationMsg('name')}
              value={form.inputs['name']}
              name='name-input'
              onChange={(value) =>
                form.validateFields([
                  {
                    field: 'name',
                    input: value
                  }
                ])
              }
              onBlur={() => form.updateFieldsDisplay(['name'])}
            />
          </div>
          <div>
            <Dropdown
              fieldState='valid'
              searchBarWidth={500}
              showSearchThreshold={5}
              id='duplicate-hospital-timezone'
              data-testid='model-dropdown'
              value={form.inputs['timezone']}
              label='Timezone:'
              placeholder='Select a timezone...'
              data={formatTimezones(timezoneStore.timezones)}
              disabled={timezoneStore.loadState !== 'loaded' || !timezoneStore.timezones}
              onSelect={(item) => {
                form.validateFields(
                  [
                    {
                      field: 'timezone',
                      input: item.value
                    }
                  ],
                  'updateFieldsDisplay'
                )
              }}
            />
          </div>
          <InfoBubble
            type='info'
            bubbleTitle='This new hospital will copy some settings over from the existing hospital setup:'
          >
            <ul className='duplicate-hospital-info-list'>
              <li>hospital settings;</li>
              <li>drug models available;</li>
              <li>drug settings and limits;</li>
              <li>patient limits settings;</li>
              <li>default dosing settings; and</li>
              <li>hospital-wide roles.</li>
            </ul>
          </InfoBubble>
          <Button
            disabled={!form.valid || ['loading', 'updating'].includes(adminHospitalListStore.loadState)}
            loading={['loading', 'updating'].includes(adminHospitalListStore.loadState)}
            className='duplicate-hospital-submit-btn'
            onClick={submitHospital}
            variant='primary'
          >
            Save & create hospital&nbsp;&nbsp;
            <FontAwesomeIcon color={WHITE} icon={faArrowRight} />
          </Button>
        </div>
      </div>
    </div>
  )
})
