import { InfoModal, Modal, Button, Dropdown, IconButton, Icons, IDropdownItem } from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'
import pluralize from 'pluralize'

import { useAdminHospitalDrugListStore } from '../../../../../../hooks/useStore'
import { showErrorToast, showSuccessToast } from '../../../../../../shared/toast'
import { IModalProps } from '../../../../../PatientRouter/types'
import {
  formatMultipleSelectionReturnedValues,
  formatMultipleSelectionValues
} from '../../../../../../shared/buildForms'
import { useFormValidation } from '../../../../../../hooks/useFormValidation'
import { isRequired } from '../../../../../../utils/validation/rules'
import { IFormField } from '../../../../../../types/validation'

interface IProps extends IModalProps {
  hospitalId: string
  hospitalName?: string
}

const HospitalAddDrugsModal: React.FC<IProps> = observer((props) => {
  const [subscribedOnly, setSubscribedOnly] = useState(true)
  const [selectedItemsDisplayed, setSelectedItemsDisplayed] = useState<IDropdownItem[] | null>(null)

  const adminHospitalDrugListStore = useAdminHospitalDrugListStore()

  useEffect(() => {
    if (props.show) {
      adminHospitalDrugListStore.fetchAdminHospitalAddDrugs(props.hospitalId)
    } else {
      form.reset()
      setSelectedItemsDisplayed(null)
    }
  }, [props.hospitalId, props.show])

  useEffect(() => {
    if (adminHospitalDrugListStore.settingsLoadStates.addDrugs === 'loaded') {
      form.reset()
    }
  }, [adminHospitalDrugListStore.settingsLoadStates.addDrugs])

  const formFields = useMemo((): Record<string, IFormField> => {
    if (adminHospitalDrugListStore.settingsLoadStates.addDrugs === 'loaded') {
      return {
        ['addDrugs']: {
          initialInput: [],
          rules: [isRequired]
        }
      }
    }

    return {}
  }, [adminHospitalDrugListStore.settingsLoadStates.addDrugs])

  const form = useFormValidation(formFields)

  const formContent = (): JSX.Element => {
    if (adminHospitalDrugListStore.adminHospitalAddDrugs) {
      return (
        <div className='position-relative w-100'>
          <div className='clinician-roles-label mb-3 font-weight-bold'>
            Selected models will be made available to use at this hospital, based on subscription limits set in DoseMe
            Access.
          </div>
          <div className='hospital-add-drugs-filter-checkbox'>
            <span
              className='hospital-add-drugs-filter-checkbox-input'
              onClick={() => setSubscribedOnly(!subscribedOnly)}
            >
              <input type='checkbox' id='filter-drugs' checked={subscribedOnly} readOnly />
              <label className='pl-1' htmlFor='confirm-warnings'>
                Show subscribed models only
              </label>
            </span>
          </div>
          {buildCustomMultipleSelectionInput()}
          {buildSelectedModels()}
        </div>
      )
    }

    return <></>
  }

  const buildSelectedModels = () => {
    if (selectedItemsDisplayed?.length) {
      const selectedModels = selectedItemsDisplayed?.map((model: IDropdownItem, index: number) => {
        return (
          <div className='hospital-add-drugs-selected-model' key={model?.id}>
            {model?.label}
            <IconButton
              size='32px'
              onClick={() => removeModel(index)}
              background='none'
              className='hospital-add-drugs-selected-model-icon-button'
            >
              <Icons.CloseActive />
            </IconButton>
          </div>
        )
      }, [])

      return (
        <>
          <div className='hospital-add-drugs-selected-model-subtitle'>Selected models:</div>
          <div className='hospital-add-drugs-selected-items'>{selectedModels}</div>
        </>
      )
    }

    return
  }

  const removeModel = (index: number) => {
    if (form.inputs['addDrugs']?.length) {
      const newInputs = form.inputs['addDrugs']
      newInputs.splice(index, 1)
      form.validateFields(
        [
          {
            field: 'addDrugs',
            input: formatMultipleSelectionReturnedValues(newInputs)
          }
        ],
        'updateFieldsDisplay'
      )
      setSelectedItemsDisplayed(newInputs)
    }
  }

  const buildCustomMultipleSelectionInput = () => {
    const selectedCount = form.inputs['addDrugs']?.length
    const selectedLabel = selectedCount ? `${selectedCount} ${pluralize('model', selectedCount)} selected` : undefined

    return (
      <Dropdown
        multiSelect
        label={'Models:'}
        fieldState={form.getValidState('addDrugs')}
        validationText={form.getValidationMsg('addDrugs')}
        data={formatAllowedValues()}
        values={formatMultipleSelectionValues(form.inputs['addDrugs'])}
        selectedLabel={selectedLabel}
        selectAll
        showSearchThreshold={5}
        onSelectMultiple={(items) =>
          form.validateFields(
            [
              {
                field: 'addDrugs',
                input: formatMultipleSelectionReturnedValues(items)
              }
            ],
            'updateFieldsDisplay'
          )
        }
        onBlurMultiple={(items) => setSelectedItemsDisplayed(items)}
        placeholder='Select an item'
      />
    )
  }

  const formatAllowedValues = (): IDropdownItem[] => {
    return [...adminHospitalDrugListStore.adminHospitalAddDrugs].reduce((acc: IDropdownItem[], [key, curr]) => {
      if (subscribedOnly && !curr.attributes?.isEntitled) {
        return acc
      }

      const drugName = `${curr.attributes.name} ${!curr.attributes.isValidated ? ' [Research]' : ''} ${curr.attributes.isNew ? ' [New]' : ''}`

      return acc.concat({
        id: key,
        label: drugName,
        value: curr.id,
        default: false,
        icon:
          curr.attributes?.isEntitled === undefined ? undefined : curr.attributes.isEntitled ? (
            <Icons.DoubleTick />
          ) : (
            <Icons.Cross width='12' height='12' />
          )
      })
    }, [])
  }

  const handleSubmit = async () => {
    const modelIds = form.values['addDrugs'].map((model: IDropdownItem) => {
      return parseInt(model.value)
    })
    await adminHospitalDrugListStore.postAdminHospitalAddDrugs(props.hospitalId, modelIds)

    if (['loadError', 'updateError'].includes(adminHospitalDrugListStore.settingsLoadStates.addDrugs)) {
      showErrorToast(adminHospitalDrugListStore.settingsErrors.addDrugs || 'Failed to addd Drugs to Hospital')

      return
    }

    showSuccessToast('Drugs added to Hospital')
    props.setShow(false)
  }

  const handleClose = () => {
    setSubscribedOnly(true)
    props.setShow(false)
    form.reset()
  }

  return (
    <Modal show={props.show} onHide={handleClose}>
      <InfoModal
        size='s'
        loading={['loading', 'updating'].includes(adminHospitalDrugListStore.settingsLoadStates.addDrugs)}
        linkComponent={
          <>
            <Button
              className='mr-3'
              data-testid='cancel-btn'
              disabled={false}
              loading={['loading', 'updating'].includes(adminHospitalDrugListStore.settingsLoadStates.addDrugs)}
              onClick={handleClose}
              variant='primary-outline'
            >
              Cancel
            </Button>
            <Button
              data-testid='save-btn'
              disabled={
                !form.valid ||
                !form.inputs['addDrugs']?.length ||
                ['loading', 'updating'].includes(adminHospitalDrugListStore.settingsLoadStates.addDrugs)
              }
              loading={['loading', 'updating'].includes(adminHospitalDrugListStore.settingsLoadStates.addDrugs)}
              onClick={handleSubmit}
              variant='primary'
            >
              Allow access
            </Button>
          </>
        }
        title='Add drug model'
        subtitle={props.hospitalName}
        message={formContent()}
      />
    </Modal>
  )
})

export { HospitalAddDrugsModal }
