import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Skeleton } from '@doseme/cohesive-ui'

import { useAuthStore } from '../../hooks/useStore'

import './index.scss'

export const minSupportedWidth = 820

export const UnsupportedDeviceWidth: React.FC = observer(() => {
  const authStore = useAuthStore()

  useEffect(() => {
    // FIXME - IFE-1105 it would be safer to have a high level function that reset _all_ stores contents
    // This may require a proper refactor of all existing stores to achieve.

    // Trigger a logout to reset the authStore.
    // If resized back to a 'supported' width, this will trigger the login route.
    const logout = async () => {
      await authStore.authLogout()
    }

    logout()

    return () => {}
  }, [])

  return (
    <div className='unsupported-device'>
      <Skeleton.UnsupportedWidth />
    </div>
  )
})
