import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { ObservableMap, reaction } from 'mobx'
import { decode } from 'he'
import { SidePanel, CommentForm, Icons, LOADING_GREY, FloatingTabBar, ITab } from '@doseme/cohesive-ui'

import {
  useActivityLogStore,
  useAdministrationsStore,
  useCourseFeaturesStore,
  useCourseStore,
  useGenerateReportStore,
  useHospitalStore,
  useObservationsStore,
  usePatientStore
} from '../../../../../../../../hooks/useStore'
import { showErrorToast, showSuccessToast } from '../../../../../../../../shared/toast'
import { ActivityLog } from '../../../../../../../../store/activityLog/ActivityLog'
import { formatToDisplayDate } from '../../../../../../../../utils/dates'
import { ICourse } from '../../../../../../../../store/course/types'
import { TActivityLogFilter } from './types'
import { ReportActivityLog } from './components/ReportActivityLog'

import './index.scss'

interface IProps {
  patientId: string
  course: ICourse
}

const activityLogTabs: ITab[] = [
  { id: 'all', displayName: 'All' },
  { id: 'activity', displayName: 'Activity' },
  { id: 'comments', displayName: 'Comments' },
  { id: 'reports', displayName: 'Reports' }
]

export const ActivityLogs: React.FC<IProps> = observer((props) => {
  const [comment, setComment] = useState<string>('')
  const [activeTab, setActiveTab] = useState<TActivityLogFilter>('all')

  const activityLogRef = useRef<HTMLDivElement>(null)

  const hospitalStore = useHospitalStore()
  const patientStore = usePatientStore()
  const courseStore = useCourseStore()
  const courseFeaturesStore = useCourseFeaturesStore()
  const activityLogStore = useActivityLogStore()
  const administrationsStore = useAdministrationsStore()
  const observationsStore = useObservationsStore()
  const generateReportStore = useGenerateReportStore()

  useEffect(() => {
    const updateActivityLogReaction = reaction(
      () => [
        administrationsStore.loadState,
        observationsStore.loadState,
        patientStore.loadState,
        courseStore.loadState,
        courseFeaturesStore.loadState,
        generateReportStore.loadState
      ],
      ([
        newAdminLoadState,
        newObservationsLoadState,
        newPatientLoadState,
        newCourseLoadState,
        newCourseFeaturesLoadState
      ]) => {
        if (
          newAdminLoadState === 'loaded' &&
          newObservationsLoadState === 'loaded' &&
          newPatientLoadState === 'loaded' &&
          newCourseLoadState === 'loaded' &&
          newCourseFeaturesLoadState === 'loaded'
        ) {
          activityLogStore.fetchActivityLogs(props.patientId, props.course.id)
        }
      }
    )

    // Clean up the reaction when the component unmounts
    return () => {
      updateActivityLogReaction()
    }
  }, [])

  useLayoutEffect(() => {
    if (activityLogStore.loadState === 'loaded') {
      const logsDiv = document.getElementsByClassName('activity-log-content')[0]
      logsDiv.scrollTop = logsDiv.scrollHeight
    }
  }, [activityLogStore.loadState, activeTab])

  // if there are more than 2 newlines then replace them with 2
  const handleSubmit = async () => {
    const regex = /(\n)\1{2,}/g
    let formattedComment = comment.replace(regex, '\n\n')

    const result = await activityLogStore.createActivityLog(props.patientId, props.course.id, formattedComment)

    if (!result || activityLogStore.loadState === 'updateError') {
      showErrorToast(activityLogStore.error || 'Failed to record comment')

      return
    }

    activityLogStore.fetchActivityLogs(props.patientId, props.course.id)
    showSuccessToast('Comment recorded')

    setTimeout(() => {
      activityLogRef?.current ? (activityLogRef.current.scrollTop = activityLogRef.current.scrollHeight) : null
    }, 100)
    setComment('')
  }

  const formatSystemActivityLogs = (log: ActivityLog): JSX.Element => {
    return (
      <div className='activity-log-entry-outer'>
        <div className='activity-log-entry-icon d-flex'>
          <Icons.ActionIcon height={24} width={24} />
          <div className='activity-log-entry-date'>
            {formatToDisplayDate(log.attributes.created, hospitalStore.hospital!.attributes.timezone)}
          </div>
        </div>

        <div className='activity-log-entry'>
          <div className='activity-log-entry-border'>
            <div className='activity-log-entry-comment'>
              <span className='font-weight-bold'>{decode(log.attributes.clinician?.name || '')}</span>
              <span> {log.attributes.message}</span>
              {log.attributes.note ? <div className='note-bubble'>{log.attributes.note}</div> : null}
              {log.attributes.details?.map((item, key) => (
                <div className='pb-1 pt-1' key={`log-entry-${key}-${item}`}>
                  <span className='activity-log-details-wrapper'>{item}</span>
                </div>
              ))}
              {log.attributes.changes ? (
                <ul>
                  {log.attributes.changes.map((item, key) => (
                    <li className='activity-log-system-message-list-entry' key={`log-entry-${key}-${item}`}>
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const formatManualActivityLogs = (log: ActivityLog) => {

    return (
      <div className='activity-log-entry-outer'>
        <div className='activity-log-entry-icon d-flex'>
          <Icons.UserIcon height={24} width={24} />
          <div className='activity-log-entry-date'>
            {formatToDisplayDate(log.attributes.created, hospitalStore.hospital!.attributes.timezone)}
          </div>
        </div>

        <div className='activity-log-entry'>
          <div className='activity-log-entry-border'>
            <div className='activity-log-entry-comment'>
              <span className='font-weight-bold'>{decode(log.attributes.clinician?.name || '')}</span>
              <span> {log.attributes.message}</span> <div className='note-bubble'>{log.attributes.note}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const filterActivityLogs = (log: ActivityLog) => {
    if (log.attributes.type === 'report' && ['all', 'reports'].includes(activeTab)) {
      return <ReportActivityLog log={log} hospital={hospitalStore.hospital} patientId={props.patientId} courseId={props.course.id}/>
    }

    if (log.attributes.type === 'comment' && ['all', 'comments'].includes(activeTab)) {
      return formatManualActivityLogs(log)
    }

    if (log.attributes.type === 'activity' && ['all', 'activity'].includes(activeTab)) {
      return formatSystemActivityLogs(log)
    }

    return null
  }

  const formatActivityLogs = (logs: ObservableMap<string, ActivityLog>): JSX.Element => (
    <>
      <div className='activity-log-course-created-title'>
        <div className='activity-log-course-created-title-header'>Course created</div>
        {formatToDisplayDate(props.course.attributes.courseStart, hospitalStore.hospital!.attributes.timezone)}
      </div>
      {[...logs].map(([key, log]) => (
        <React.Fragment key={`log-entry-${log.id}-${key}`}>
          {filterActivityLogs(log)}
        </React.Fragment>
      ))}
    </>
  )

  const panelContent: JSX.Element = (
    <div className='notes-and-activity-log'>
      {activityLogStore.loadState !== 'loaded' ? (
        <div className='activity-log-store-grey-spinner'>
          <Icons.ThinSpinner strokeWidth={12} r={30} stroke={LOADING_GREY} width='60px' />
        </div>
      ) : (
        <>
          <div ref={activityLogRef} className='activity-log-content'>
            <div>{formatActivityLogs(activityLogStore.activityLogs)}</div>
          </div>
          <CommentForm
            placeholder='Add Comment...'
            onSubmit={handleSubmit}
            className='activity-log-text-input'
            setValue={setComment}
            value={comment}
            disabled={!!courseStore.course?.attributes.courseArchived || !!courseStore.course?.attributes.isReadOnly}
            disabledSubmit={!comment}
          />
        </>
      )}
    </div>
  )

  return (
    <SidePanel title='Notes & Activity Log'>
      <div className='mt-4'>
        <FloatingTabBar
          tabNames={activityLogTabs}
          activeTab={activeTab}
          onSelectTab={(tab) => setActiveTab(tab as TActivityLogFilter)}
        />
      </div>
      <div className='notes-and-activity-log mt-2'>{panelContent}</div>
    </SidePanel>
  )
})
