import { ITooltipMenuData } from '@doseme/cohesive-ui'

import { IAdminHospitalListItem } from '../../../../store/Admin/AdminHospitalList/types'

export const itemsPerPage = 10

export const hospitalActionData = (
  hospital: IAdminHospitalListItem,
  handleDuplicateHospital: (hospitalId: string) => void): ITooltipMenuData[] => {

  return [
    {
      id: 1,
      value: 'Duplicate and create new hospital',
      onSelect: () => handleDuplicateHospital(hospital.id)
    }
    // {
    //   id: 2,
    //   value: 'Disable this hospital',
    //   onSelect: () => {} //FIXME replace with action when disable/edit endpoint is created
    // }
  ]
}
