import { makeAutoObservable } from 'mobx'

import { DrugModelsStore } from './DrugModelsStore'
import { IMolecule } from './types'

export class Molecule implements IMolecule {
  store: DrugModelsStore

  id: string
  type: string
  attributes: IMolecule['attributes']

  constructor(store: DrugModelsStore, entity: IMolecule) {
    makeAutoObservable(this, {
      store: false
    })

    this.store = store

    this.id = entity.id
    this.type = entity.type
    this.attributes = { ...entity.attributes }
  }
}
