import { makeAutoObservable } from 'mobx'

import { IChangeLogItem } from './types'
import { AdminChangeLogListStore } from './AdminChangeLogListStore'

export class AdminChangeLogListItem implements IChangeLogItem {
  store: AdminChangeLogListStore

  id: string
  type: 'adminChangeLog'
  attributes: IChangeLogItem['attributes']

  constructor(store: AdminChangeLogListStore, entity: IChangeLogItem) {
    makeAutoObservable(this, {
      store: false
    })

    this.store = store

    this.id = entity.id
    this.type = entity.type
    this.attributes = { ...entity.attributes }
  }
}
