import { ITab } from '@doseme/cohesive-ui'

import { IFormField } from '../../types/validation'

export const adminClinicianDetailsTabs: ITab[] = [
  { id: 'profile', displayName: 'Profile settings' },
  { id: 'password', displayName: 'Reset Password' }
]

export const passwordFormFields: Record<string, IFormField> = {
  currentPassword: {
    initialInput: '',
    rules: []
  },
  newPassword: {
    initialInput: '',
    rules: []
  }
}
