import { IFormField } from '../../../../../../../types/validation'
import { isRequired } from '../../../../../../../utils/validation/rules'

export const addHospitalAdminAccessFields = (): Record<string, IFormField> => {
  const initialHospitalValue = '' // form input is of type string

  return {
    hospitals: {
      // Input - string
      // Value - number
      initialInput: initialHospitalValue,
      rules: [isRequired]
    }
  }
}

export const resetPasswordFormFields: Record<string, IFormField> = {
  newPassword: {
    initialInput: '',
    rules: []
  },
  confirmNewPassword: {
    initialInput: '',
    rules: []
  }
}
