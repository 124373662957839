import { InfoModal, Modal, Button } from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'

import { useAdminVendorDetailsStore } from '../../../../../../hooks/useStore'
import { showErrorToast, showSuccessToast } from '../../../../../../shared/toast'
import { IModalProps } from '../../../../../PatientRouter/types'
import { buildFormFields, buildInputs } from '../../../../../../shared/buildForms'
import { useFormValidation } from '../../../../../../hooks/useFormValidation'
import { IPutAdminVendorDetails } from '../../../../../../store/Admin/AdminVendorDetails/types'

interface IProps extends IModalProps {
  vendorId?: string
}

const EditVendorModal: React.FC<IProps> = observer((props) => {
  const adminVendorDetailsStore = useAdminVendorDetailsStore()

  useEffect(() => {
    if (props.show && props.vendorId) {
      adminVendorDetailsStore.fetchAdminVendorDetails(props.vendorId)
    }
  }, [props.vendorId, props.show])

  const formFields = useMemo(() => {
    if (['loaded', 'loadError', 'updateError'].includes(adminVendorDetailsStore.loadState) && adminVendorDetailsStore.adminVendorDetails) {
      return buildFormFields(adminVendorDetailsStore.adminVendorDetails)
    }

    return {}
  }, [adminVendorDetailsStore.loadState])

  const form = useFormValidation(formFields)

  const formContent = (): JSX.Element => {
    const displayOrder = ['name', 'key']

    if (adminVendorDetailsStore.adminVendorDetails) {
      return (
        <div className='position-relative w-100'>
          {buildInputs(adminVendorDetailsStore.adminVendorDetails, form, displayOrder, formFields)}
        </div>
      )
    }

    return <></>
  }

  // As this is an edit form, show all validation statuses for fields on initial render
  useEffect(() => {
    if (adminVendorDetailsStore.loadState === 'loaded') {
      form.reset()
    }
  }, [adminVendorDetailsStore.loadState])

  const handleSubmit = async () => {
    await adminVendorDetailsStore.putAdminVendorDetails(props.vendorId!, form.values as IPutAdminVendorDetails)

    if (['loadError', 'updateError'].includes(adminVendorDetailsStore.loadState)) {
      showErrorToast(adminVendorDetailsStore.error || 'Failed to update Vendor Details')

      return
    }

    showSuccessToast('Vendor Details updated')
    props.setShow(false)
  }

  const handleClose = () => {
    props.setShow(false)
    form.reset()
  }

  return (
    <Modal
      show={props.show}
      onHide={handleClose}
    >
      <InfoModal
        size='s'
        loading={['loading', 'updating'].includes(adminVendorDetailsStore.loadState)}
        linkComponent={
          <>
            <Button
              className='mr-3'
              data-testid='cancel-btn'
              disabled={false}
              loading={['loading', 'updating'].includes(adminVendorDetailsStore.loadState)}
              onClick={handleClose}
              variant='primary-outline'
            >
              Cancel
            </Button>
            <Button
              data-testid='save-btn'
              disabled={!form.valid || ['loading', 'updating'].includes(adminVendorDetailsStore.loadState)}
              loading={['loading', 'updating'].includes(adminVendorDetailsStore.loadState)}
              onClick={handleSubmit}
              variant='primary'
            >
              Save
            </Button>
          </>
        }
        title='Edit vendor details'
        message={formContent()}
      />
    </Modal>
  )
})

export { EditVendorModal }
