import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'
import { ActionButton, SidePanel } from '@doseme/cohesive-ui'

import { useFormValidation } from '../../../../../../../../hooks/useFormValidation'
import { IDrugDetailsPanelWithoutModalProps } from '../../types'
import { useAdminHospitalDrugDetailsStore } from '../../../../../../../../hooks/useStore'
import { showErrorToast, showSuccessToast } from '../../../../../../../../shared/toast'
import { IPutAdminHospitalDrugDoseCalculatedWarningLimits } from '../../../../../../../../store/Admin/AdminHospitalDrugDetails/types'
import { buildInlineFormsFields, buildPutRequest, displayInlineEditPanels, displayNoModalPanels } from '../../utils'

import '../../index.scss'

export const CalculatedDosesLimitsAndWarnings: React.FC<IDrugDetailsPanelWithoutModalProps> = observer((props) => {
  const [selectedPanelsHelpTextList, setSelectedPanelsHelptextList] = useState<string[]>([])
  const dataKeys = ['absolute', 'clinical', 'amountThreshold', 'perWeightThreshold', 'infusionRate']
  const formKeys = ['clinical', 'amountThreshold', 'perWeightThreshold', 'infusionRate']

  const adminHospitalDrugDetailsStore = useAdminHospitalDrugDetailsStore()

  const inlineFormFields = useMemo(() => {
    if (['loaded', 'updateError'].includes(adminHospitalDrugDetailsStore.settingsLoadStates.doseCalculatedWarningLimits)) {
      return buildInlineFormsFields(
        adminHospitalDrugDetailsStore.adminHospitalDrugDoseCalculatedWarningLimits?.attributes.settings.dosesCalculatedWarningLimits
      )
    }

    return {
      clinical: {},
      amountThreshold: {},
      perWeightThreshold: {},
      infusionRate: {}
    }
  }, [adminHospitalDrugDetailsStore.settingsLoadStates.doseCalculatedWarningLimits])

  const forms = {
    clinical: useFormValidation(inlineFormFields['clinical']),
    amountThreshold: useFormValidation(inlineFormFields['amountThreshold']),
    perWeightThreshold: useFormValidation(inlineFormFields['perWeightThreshold']),
    infusionRate: useFormValidation(inlineFormFields['infusionRate'])
  }

  useEffect(() => {
    if (adminHospitalDrugDetailsStore.settingsLoadStates.doseCalculatedWarningLimits === 'loaded') {
      forms.clinical.reset()
      forms.amountThreshold.reset()
      forms.perWeightThreshold.reset()
      forms.infusionRate.reset()
    }
  }, [adminHospitalDrugDetailsStore.settingsLoadStates.doseCalculatedWarningLimits])

  useEffect(() => {
    if (props.isPanelEditable) {
      adminHospitalDrugDetailsStore.fetchAdminHospitalDrugDoseCalculatedWarningLimits(props.hospitalId, props.drugModelId)
    }
  }, [props.hospitalId, props.isPanelEditable])

  const handleSubmit = async () => {
    if (props.drugModelId && adminHospitalDrugDetailsStore.adminHospitalDrugDoseCalculatedWarningLimits) {
      const responseStructure = {
        name: adminHospitalDrugDetailsStore.adminHospitalDrugDoseCalculatedWarningLimits.attributes.name,
        settings: {
          dosesCalculatedWarningLimits:
            buildPutRequest(
              forms,
              adminHospitalDrugDetailsStore.adminHospitalDrugDoseCalculatedWarningLimits?.attributes.settings.dosesCalculatedWarningLimits
            )
        }
      }

      await adminHospitalDrugDetailsStore.putAdminHospitalDrugDoseCalculatedWarningLimits(
        props.hospitalId,
        props.drugModelId,
        responseStructure as IPutAdminHospitalDrugDoseCalculatedWarningLimits
      )

      if (['loadError', 'updateError'].includes(adminHospitalDrugDetailsStore.settingsLoadStates.doseCalculatedWarningLimits)) {
        showErrorToast(
          adminHospitalDrugDetailsStore.settingsErrors.doseCalculatedWarningLimits || 'Failed to update calculated doses: general settings'
        )

        return
      }

      showSuccessToast('Calculated doses: limits & warnings updated')
      props.setIsPanelEditable(null)
    }
  }

  return (
    <div className='mt-3'>
      <SidePanel
        title='Calculated doses: limits & warnings'
        loading={
          ['loading', 'updating'].includes(adminHospitalDrugDetailsStore.settingsLoadStates.doseCalculatedWarningLimits)
          || ['loading', 'updating'].includes(adminHospitalDrugDetailsStore.loadState)
        }
        actionButton={
          adminHospitalDrugDetailsStore.loadState === 'loaded' && props.data && !props.isPanelEditable
            ? (
              <ActionButton
                data-testid='hospital-drug-details-data-btn'
                actionType='edit'
                onClick={() => props.setIsPanelEditable('limitsWarnings')}
              />
            )
            : undefined
        }
      >
        {props.data &&
          (props.isPanelEditable &&
          adminHospitalDrugDetailsStore.adminHospitalDrugDoseCalculatedWarningLimits?.attributes.settings
            .dosesCalculatedWarningLimits
            ? displayInlineEditPanels(
              props.width,
              props.data,
              adminHospitalDrugDetailsStore.adminHospitalDrugDoseCalculatedWarningLimits?.attributes.settings
                .dosesCalculatedWarningLimits,
              dataKeys,
              forms,
              inlineFormFields,
              adminHospitalDrugDetailsStore.settingsLoadStates.doseCalculatedWarningLimits,
              handleSubmit,
              props.setIsPanelEditable,
              formKeys
            )
            : displayNoModalPanels(
              props.data,
              dataKeys,
              selectedPanelsHelpTextList,
              props.width,
              setSelectedPanelsHelptextList
            ))}
      </SidePanel>
    </div>
  )
})
