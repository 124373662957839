import { IRowElement } from '@doseme/cohesive-ui'

export const paginate = (
  data: IRowElement[],
  pageNav: {
    currentPage: number
    itemsPerPage: number
  }
): IRowElement[] => {
  const { currentPage, itemsPerPage } = pageNav

  return data.slice(
    (currentPage - 1) * itemsPerPage,
    itemsPerPage * currentPage
  )
}

export const getPageCount = (count: number, itemsPerPage: number) => {
  // this should never be the case but safeguard anyway
  if (itemsPerPage < 1) {
    return 1
  }

  return count > 0 ? Math.ceil(count / itemsPerPage) : 1
}
