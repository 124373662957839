interface IProps {
  modelType: string
  drugModelName: string
  citation: string
}

const textToUrl = (citationText?: string) => {
  return (
    {
      __html: citationText ? citationText.replace(
        new RegExp('\\b(https?|ftp|file)://[-A-Z0-9+&@#/%?=~_|!:,.;]*[A-Z0-9+&@#/%=~_|]', 'gim'),
        '<a target="_blank" href="$&">$& <i class="fa fa-external-link" aria-hidden="true"></i></a>'
      ) : ''
    }
  )
}

export const GuidelineInfo: React.FC<IProps> = (props) => {
  return (
    <div>
      <h4>{props.modelType} Citation</h4>
      <p dangerouslySetInnerHTML={textToUrl(props.citation)} />
      <h4>About {props.modelType} Doses</h4>
      <p>
        Guideline doses typically calculate a dose based on criteria such as
        indication, weight, age, and renal function. DoseMeRx does not have a
        guideline enabled for {props.drugModelName} at your hospital. If you wish to
        have a particular guideline added for {props.drugModelName} at your hospital,
        please contact DoseMe support via the "Request Help" button.
      </p>
    </div>
  )
}
