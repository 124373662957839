import { useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { decode } from 'he'
import {
  BackArrowButton,
  IColumnElement,
  IRowElement,
  PaginationPanel,
  SearchInput,
  SmartList
} from '@doseme/cohesive-ui'

import { getPageCount, paginate } from '../../../../../utils/pagination'
import { useAdminHospitalListStore, useBreadcrumbsStore } from '../../../../../hooks/useStore'
import { IAdminHospitalListItem } from '../../../../../store/Admin/AdminHospitalList/types'
import { sortAlpha } from '../../../../../utils/smartListUtils'
import { searchHospitals } from '../../HospitalSettings/utils'
import { drugSettingsSelectHospitalColumns } from './constants'
import { getAdminRoutePrefix } from '../../../utils'
import { handleBackButton } from '../../../../../utils/navigation'

import './index.scss'

interface IProps {
  patientId?: string
}

export const DrugSettingsSelectHospitalList: React.FC<IProps> = observer((props) => {
  const [searchText, setSearchText] = useState('')
  const [sortColAscending, setSortColAscending] = useState(true)
  const [sortColIndex, setSortColIndex] = useState(0)
  const [sortedList, setSortedList] = useState<IRowElement[] | null>(null)
  const [totalPages, setTotalPages] = useState<number>(1)
  const [currentPage, updateCurrentPage] = useState(1)

  const adminHospitalListStore = useAdminHospitalListStore()
  const breadcrumbsStore = useBreadcrumbsStore()

  const history = useHistory()

  useEffect(() => {
    const breadcrumbs = [
      { label: 'Admin hub', onClick: () => returnToAdminHub() },
      { label: 'Drug settings' }
    ]
    breadcrumbsStore.setBreadcrumbs(breadcrumbs)
  }, [])

  const itemsPerPage = 10

  useEffect(() => {
    adminHospitalListStore.fetchAdminHospitals()
  }, [])

  useEffect(() => {
    if (adminHospitalListStore.loadState === 'loaded' && adminHospitalListStore.adminHospitals) {
      const searchedHospitals: IAdminHospitalListItem[] = searchHospitals(
        searchText,
        adminHospitalListStore.adminHospitals
      )
      setTotalPages(getPageCount(searchedHospitals.length, itemsPerPage))
      const formattedHospitals = formatHospitals(searchedHospitals)
      const sortedSearchedFormattedHospitals = sortAlpha(formattedHospitals, sortColIndex, sortColAscending)
      setSortedList(sortedSearchedFormattedHospitals)
    }
  }, [adminHospitalListStore.loadState, searchText, sortColAscending])

  const returnToAdminHub = () => {
    history.push(getAdminRoutePrefix(props.patientId))
  }

  const handleHospitalDrugsLink = (hospitalId: string) => {
    history.push(getAdminRoutePrefix(props.patientId) + `/hospitals/${hospitalId}/drugs`, { from: '/admin/drugsettings' })
  }

  const handleSort = (colIndex: number, ascending: boolean) => {
    setSortColAscending(ascending)
    setSortColIndex(colIndex)
  }

  const handleSearch = (searchText: string) => {
    setSearchText(searchText)
    updateCurrentPage(1)
  }

  const formatHospitals = (hospitals: IAdminHospitalListItem[]): IRowElement[] => {
    return hospitals.map((hospital, key) => {
      const recordColumns: IColumnElement[] = [
        {
          name: 'Name',
          link: () => handleHospitalDrugsLink(hospital.id),
          text: decode(hospital.attributes.name)
        }
      ]

      return {
        id: hospital.id,
        columns: recordColumns
      }
    })
  }

  return (
    <div className='drug-settings-hospital-list-page'>
      <div className='drug-settings-hospital-list-heading'>
        <div className='drug-settings-hospital-list-back-btn'>
          <BackArrowButton
            data-testid='back-btn'
            onClick={() =>
              handleBackButton(getAdminRoutePrefix(props.patientId), history)
            }
          />
        </div>
        <div className='drug-settings-hospital-list-title'>Drug Settings</div>
      </div>
      <div className='drug-settings-hospital-list-content-panel'>
        <div className='drug-settings-hospital-list-subtitle'>Select a hospital</div>
        <div className='drug-settings-hospital-list-search-bar-container'>
          <SearchInput
            value={searchText}
            onChange={handleSearch}
            width={488}
            borderRadius={12}
            placeholder='Search list...'
          />
        </div>
        <div className='drug-settings-hospital-list-list'>
          <SmartList
            cols={drugSettingsSelectHospitalColumns(handleSort)}
            data={sortedList ? paginate(sortedList, { currentPage, itemsPerPage }) : []}
            defaultSortColumn='Name'
            defaultSortDirection='asc'
            loading={adminHospitalListStore.loadState === 'loading'}
            textIfEmpty='No data to display'
          />
        </div>
        {sortedList && sortedList.length > 10 && (
          <div className='patient-profile-pagination-panel'>
            <PaginationPanel currentPage={currentPage} totalPages={totalPages} onPageChange={updateCurrentPage} />
          </div>
        )}
      </div>
    </div>
  )
})
