import { IStatistic } from '@doseme/cohesive-ui'

import { IPerDoseOutcome, IPredictedOutcome } from '../../../../../../../../../../store/dosingRecommendation/types'
import { IDefaultLimit } from '../../../../../../../../../../store/types'
import { roundDecimal } from '../../../../../../../../../../utils/numbers'

export const predictedOutcomesStats = (
  loaded: boolean,
  outcomes: IPerDoseOutcome | null,
  predictedOutcome: IPredictedOutcome | null,
  aucTargetLimit: IDefaultLimit,
  peakTargetLimit: IDefaultLimit,
  troughTargetLimit: IDefaultLimit,
  timeAboveMICTargetLimit: IDefaultLimit
): IStatistic[] => {
  const statistics = [
    {
      id: 'auc24',
      title: 'AUC24',
      subtitle: (loaded && aucTargetLimit.default.unit?.name) || '—',
      value: outcomes ? roundDecimal(outcomes.auc24).toString() : '—'
    },
    {
      id: 'peak',
      title: 'Peak',
      subtitle: (loaded && peakTargetLimit.default.unit?.name) || '—',
      value: outcomes ? roundDecimal(outcomes.peak).toString() : '—'
    },
    {
      id: 'trough',
      title: 'Trough',
      subtitle: (loaded && troughTargetLimit.default.unit?.name) || '—',
      value: outcomes ? roundDecimal(outcomes.trough).toString() : '—'
    }
  ]

  if (
    predictedOutcome &&
    predictedOutcome.timeAboveMICPercentage !== null &&
    predictedOutcome.timeAboveMICPercentage !== undefined
  ) {
    statistics[0] = {
      id: 'timeAboveMICPercentage',
      title: 'T>MIC',
      subtitle: (loaded && timeAboveMICTargetLimit.default.unit?.name) || '—',
      value: predictedOutcome.timeAboveMICPercentage?.toString() || '—'
    }
  }

  if (predictedOutcome && predictedOutcome.trough !== null && predictedOutcome.trough !== undefined) {
    statistics[0] = {
      id: 'timetotrough',
      title: 'Trough at next dose',
      subtitle: (loaded && predictedOutcome.troughUnits) || '—',
      value: predictedOutcome.trough.toString() || '—'
    }
  }

  return statistics
}

export const predictedOutcomeRangeStats = (outcomes: IPerDoseOutcome | null): IStatistic[] => {
  return [
    {
      id: 'trough',
      title: 'Last dose',
      subtitle: 'min INR',
      value: outcomes ? roundDecimal(outcomes.trough).toString() : '—'
    },
    {
      id: 'peak',
      title: 'Last dose',
      subtitle: 'max INR',
      value: outcomes ? roundDecimal(outcomes.peak).toString() : '—'
    }
  ]
}

export const predictedOutcomeCmlAUCStats = (
  loaded: boolean,
  predictedOutcome?: IPredictedOutcome | null
): IStatistic[] => {
  return [
    {
      id: 'cumulative_auc',
      title: predictedOutcome ? `Cumulative AUC${predictedOutcome.cumulativeAUCTau?.value.toString() || ''}` : 'Cumulative AUC',
      subtitle: (loaded && predictedOutcome?.cumulativeAUC?.unit?.name) || '',
      value: predictedOutcome?.cumulativeAUC ? roundDecimal(predictedOutcome.cumulativeAUC.value).toString() : '—'
    }
  ]
}
