import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'
import { ActionButton, SidePanel } from '@doseme/cohesive-ui'

import { useFormValidation } from '../../../../../../../../hooks/useFormValidation'
import { IDrugDetailsPanelWithoutModalProps } from '../../types'
import { useAdminHospitalDrugDetailsStore } from '../../../../../../../../hooks/useStore'
import { buildInlineFormsFields, buildPutRequest, displayInlineEditPanels, displayNoModalPanels } from '../../utils'
import { IPutAdminHospitalDrugRecordedCourseData } from '../../../../../../../../store/Admin/AdminHospitalDrugDetails/types'
import { showErrorToast, showSuccessToast } from '../../../../../../../../shared/toast'

import '../../index.scss'

export const RecordedCourseData: React.FC<IDrugDetailsPanelWithoutModalProps> = observer((props) => {
  const adminHospitalDrugDetailsStore = useAdminHospitalDrugDetailsStore()

  const [selectedPanelsHelpTextList, setSelectedPanelsHelptextList] = useState<string[]>([])
  const dataKeys = ['administration', 'observationLevel']

  const inlineFormFields = useMemo(() => {
    if (['loaded', 'updateError'].includes(adminHospitalDrugDetailsStore.settingsLoadStates.recordedCourseData)) {
      return buildInlineFormsFields(
        adminHospitalDrugDetailsStore.adminHospitalDrugRecordedCourseData?.attributes.settings.recordedCourseDataLimits
      )
    }

    return {
      administration: {},
      observationLevel: {}
    }
  }, [adminHospitalDrugDetailsStore.settingsLoadStates.recordedCourseData])

  const forms = {
    administration: useFormValidation(inlineFormFields['administration']),
    observationLevel: useFormValidation(inlineFormFields['observationLevel'])
  }

  useEffect(() => {
    if (adminHospitalDrugDetailsStore.settingsLoadStates.recordedCourseData === 'loaded') {
      forms.administration.reset()
      forms.observationLevel.reset()
    }
  }, [adminHospitalDrugDetailsStore.settingsLoadStates.recordedCourseData])

  useEffect(() => {
    if (props.isPanelEditable) {
      adminHospitalDrugDetailsStore.fetchAdminHospitalDrugRecordedCourseData(props.hospitalId, props.drugModelId)
    }
  }, [props.hospitalId, props.isPanelEditable])

  const handleSubmit = async () => {
    if (props.drugModelId && adminHospitalDrugDetailsStore.adminHospitalDrugRecordedCourseData) {
      const responseStructure = {
        name: adminHospitalDrugDetailsStore.adminHospitalDrugRecordedCourseData.attributes.name,
        settings: {
          recordedCourseDataLimits:
            buildPutRequest(
              forms,
              adminHospitalDrugDetailsStore.adminHospitalDrugRecordedCourseData?.attributes.settings
                .recordedCourseDataLimits
            )
        }
      }

      await adminHospitalDrugDetailsStore.putAdminHospitalDrugRecordedCourseData(
        props.hospitalId,
        props.drugModelId,
        responseStructure as IPutAdminHospitalDrugRecordedCourseData
      )

      if (['loadError', 'updateError'].includes(adminHospitalDrugDetailsStore.settingsLoadStates.recordedCourseData)) {
        showErrorToast(
          adminHospitalDrugDetailsStore.settingsErrors.recordedCourseData || 'Failed to update recorded course data'
        )

        return
      }

      showSuccessToast('Recorded course data updated')
      props.setIsPanelEditable(null)
    }
  }

  return (
    <div className='mt-3'>
      <SidePanel
        title='Recorded course data'
        loading={
          ['loading', 'updating'].includes(adminHospitalDrugDetailsStore.loadState)
          || ['loading', 'updating'].includes(adminHospitalDrugDetailsStore.settingsLoadStates.recordedCourseData)
        }
        actionButton={adminHospitalDrugDetailsStore.loadState === 'loaded' && props.data && !props.isPanelEditable
          ? (
            <ActionButton
              data-testid='hospital-drug-details-data-btn'
              actionType='edit'
              onClick={() => props.setIsPanelEditable('recordedCourseData')}
            />
          )
          : undefined
        }
      >
        {props.data &&
          (props.isPanelEditable &&
          adminHospitalDrugDetailsStore.adminHospitalDrugRecordedCourseData?.attributes.settings
            .recordedCourseDataLimits
            ? displayInlineEditPanels(
              props.width,
              props.data,
              adminHospitalDrugDetailsStore.adminHospitalDrugRecordedCourseData?.attributes.settings
                .recordedCourseDataLimits,
              dataKeys,
              forms,
              inlineFormFields,
              adminHospitalDrugDetailsStore.settingsLoadStates.recordedCourseData,
              handleSubmit,
              props.setIsPanelEditable
            )
            : displayNoModalPanels(
              props.data,
              dataKeys,
              selectedPanelsHelpTextList,
              props.width,
              setSelectedPanelsHelptextList
            ))}
      </SidePanel>
    </div>
  )
})
