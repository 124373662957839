import moment from 'moment'
import pluralize from 'pluralize'

import { PatientCourse } from '../../../../../../../../store/patientCourses/PatientCourse'
import { IRecentAdministrations, IRecentObservations } from '../../../../../../../../store/types'
import { formatToDisplayDate } from '../../../../../../../../utils/dates'

export const findMostRecentCourse = (courses: PatientCourse[]) => {
  return courses.reduce((acc: PatientCourse | null, curr) => {
    if (!acc || !acc.attributes.lastAdministrationTime) {
      return curr
    }

    if (acc.attributes.lastAdministrationTime && curr.attributes.lastAdministrationTime
      && moment(acc.attributes.lastAdministrationTime) < moment(curr.attributes.lastAdministrationTime)) {
      return curr
    }

    return acc
  }, null)
}

export const formatRecentAdmins = (course: PatientCourse, timezone: string, formatAdminAmount: (administration: IRecentAdministrations) => JSX.Element) => {
  const recentAdmins = course.attributes.recentAdministrations.filter(
    (admin) => admin.amount.value > 0 && parseInt(admin.amount.unit.id) > 0
  )

  return recentAdmins.map((admin, index) => {
    return {
      id: `${course.id}-administration-${index}`, //FIXME - would be better to use admin Id but requires change to endpoint
      disabled: admin.excludeFromCalculations,
      columns: [
        {
          name: 'Dose Time ↓',
          text: formatToDisplayDate(admin.administeredAt, timezone)
        },
        {
          name: 'Amount',
          element: formatAdminAmount(admin)
        },
        {
          name: 'Infusion Length',
          text: `${admin.infusionLength.toString()} ${pluralize('hour', admin.infusionLength)}`
        }
      ]
    }
  })
}

export const formatRecentObservations = (course: PatientCourse, timezone: string, formatObservationLevel: (observation: IRecentObservations) => JSX.Element) => {
  const recentObservations = course.attributes.recentObservations.filter(
    (obs) => obs.amount.value > 0 && parseInt(obs.amount.unit.id) > 0
  )

  return recentObservations.map((observation, index) => {
    if (observation.amount.unit && !observation.amount.unit.name) {
      observation.amount.unit.name = ''
    }

    if (observation.observationType.unit && !observation.observationType.unit?.name) {
      observation.observationType.unit.name = ''
    }

    return {
      id: `${course.id}-observation-${index}`, //FIXME - would be better to use admin Id but requires change to endpoint
      disabled: observation.excludeFromCalculations,
      columns: [
        {
          name: 'observationTime',
          text: formatToDisplayDate(observation.observedAt, timezone)
        },
        {
          name: 'type',
          text: observation.observationType.shortName
        },
        {
          name: 'level',
          element: formatObservationLevel(observation)
        }
      ]
    }
  })
}
