import { makeAutoObservable } from 'mobx'

import { IAdminHospitalDetails } from './types'
import { AdminHospitalDetailsStore } from './AdminHospitalDetailsStore'

export class AdminHospitalDetails implements IAdminHospitalDetails {
  store: AdminHospitalDetailsStore

  id: string
  type: string
  attributes: IAdminHospitalDetails['attributes']

  constructor(store: AdminHospitalDetailsStore, entity: IAdminHospitalDetails) {
    makeAutoObservable(this, {
      store: false
    })

    this.store = store

    this.id = entity.id
    this.type = entity.type
    this.attributes = { ...entity.attributes }
  }
}
