import { makeAutoObservable } from 'mobx'

import axiosClient, { AxiosError, AxiosResponse } from '../../utils/axiosClient'
import { clinicianGetUrl, clinicianDetailGetPutUrl, clinicianPasswordPutUrl } from '../../constants/api'
import { RootStore } from '../RootStore'
import { TLoadState } from '../types'
import { IClinician, IClinicianHospitalListItem, IEditClinicianDetails, IPutEditClinicianDetails, IPutEditClinicianPassword } from './types'
import { Clinician } from './Clinician'
import { EditClinicianDetails } from './EditClinicianDetails'

export class ClinicianStore {
  rootStore: RootStore

  loadState: TLoadState = 'initial'
  error: string = ''

  editClinicianLoadState: TLoadState = 'initial'
  editClinicianError: string = ''

  updatePasswordLoadState: TLoadState = 'initial'
  updatePasswordError: string = ''

  clinician: Clinician | null = null
  editClinicianDetails: EditClinicianDetails | null = null

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false
    })

    this.rootStore = rootStore
  }

  setClinician(clinicianAttrs: IClinician) {
    this.clinician = new Clinician(this, clinicianAttrs)
  }

  setLoadState(loadState: TLoadState) {
    this.loadState = loadState
  }

  setError(errorState: 'loadError' | 'updateError', error: string) {
    this.error = error
    this.setLoadState(errorState)
  }

  setEditClinicianDetails(editClinicianDetails: IEditClinicianDetails) {
    this.editClinicianDetails = new EditClinicianDetails(this, editClinicianDetails)
  }

  setEditClinicianLoadState(loadState: TLoadState) {
    this.editClinicianLoadState = loadState
  }

  setEditClinicianError(errorState: 'loadError' | 'updateError', error: string) {
    this.editClinicianError = error
    this.setEditClinicianLoadState(errorState)
  }

  resetStore() {
    this.clinician = null
    this.editClinicianDetails = null
    this.loadState = 'initial'
  }

  onlyAdminHospital(): string | null {
    const adminHospitals = this.clinician?.attributes.hospitals.filter((hospital: IClinicianHospitalListItem) => {
      return hospital.isAdmin
    })

    if (adminHospitals?.length === 1) {
      return adminHospitals[0].id
    }

    return null
  }

  canViewAdminHub(): boolean {
    return this.clinician?.attributes.isSuperAdmin ||
      !!this.clinician?.attributes.hospitals.find(hospital => hospital.isAdmin)
  }

  canViewAnalyticsHub(): boolean {
    // Only users with the "analytics" role (ID: 11) can view the analytics pages
    return !!this.clinician?.attributes.roles.find(roles => roles.id === '11')
  }

  //FIXME - use this function for canViewAnalyticsHub
  //FIXME - also add a new function canViewResearchOptions for the research role ('7')
  hasRole(roleId: string): boolean {
    return !!this.clinician?.attributes.roles.find(roles => roles.id === roleId)
  }

  async fetchClinician(clinicianId: string) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .get<AxiosResponse<IClinician>>(clinicianGetUrl(clinicianId), { headers })
      .then((response: AxiosResponse) => {
        this.setClinician(response.data.data)
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
      })
  }

  async fetchEditClinicianDetail(clinicianId: string) {
    this.setEditClinicianLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .get<AxiosResponse<IEditClinicianDetails>>(clinicianDetailGetPutUrl(clinicianId), { headers })
      .then((response: AxiosResponse) => {
        this.setEditClinicianDetails(response.data.data)
        this.setEditClinicianLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setEditClinicianError('loadError', loggableError)
      })
  }

  async putEditClinicianDetail(clinicianId: string, details: IPutEditClinicianDetails) {
    this.setEditClinicianLoadState('updating')
    this.setLoadState('updating')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .put<AxiosResponse<IClinician>>(
        clinicianDetailGetPutUrl(clinicianId),
        {
          data: {
            id: clinicianId,
            type: 'authClinicianProfile',
            attributes: details
          }
        },
        { headers }
      )
      .then((response: AxiosResponse) => {
        this.setClinician(response.data.data)
        this.setEditClinicianLoadState('loaded')
        this.setLoadState('loaded')
        this.fetchEditClinicianDetail(clinicianId)
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setEditClinicianError('updateError', loggableError)
        this.setError('updateError', loggableError)
      })
  }

  setUpdatePasswordLoadState(loadState: TLoadState) {
    this.updatePasswordLoadState = loadState
  }

  setUpdatePasswordError(errorState: 'loadError' | 'updateError', error: string) {
    this.updatePasswordError = error
    this.setUpdatePasswordLoadState(errorState)
  }

  async putEditClinicianPassword(clinicianId: string, passwords: IPutEditClinicianPassword) {
    this.setUpdatePasswordLoadState('updating')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .put<AxiosResponse<IClinician>>(
        clinicianPasswordPutUrl(clinicianId),
        {
          data: {
            id: clinicianId,
            type: 'authClinicianProfilePassword',
            attributes: passwords
          }
        },
        { headers }
      )
      .then(() => {
        this.setUpdatePasswordLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setUpdatePasswordError('updateError', loggableError)
      })
  }
}
