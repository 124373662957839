import { useEffect, useState } from 'react'
import { SmartList, IHeaderItem, PaginationPanel, IRowElement, Icons, LOADING_GREY } from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { Tooltip } from 'react-tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook } from '@fortawesome/free-solid-svg-icons'

import { useHospitalStore, usePatientCoursesStore } from '../../../../../../hooks/useStore'
import { getPageCount, paginate } from '../../../../../../utils/pagination'
import { sortAlpha } from '../../../../../../utils/smartListUtils'
import { formatToDisplayDate } from '../../../../../../utils/dates'
import { PatientCourse } from '../../../../../../store/patientCourses/PatientCourse'

import '../../index.scss'

interface IProps {
  patientId: string
  selectedCourseId: string | undefined
  setSelectedCourseId: (id: string) => void
  setShowCoursePreviewModal?: (showModal: boolean) => void
  isTabletSize: boolean
}

export const CourseList: React.FC<IProps> = observer(({ patientId, ...props }) => {
  const hospitalStore = useHospitalStore()
  const patientCoursesStore = usePatientCoursesStore()

  const [sortColIndex, setSortColIndex] = useState(2)
  const [sortColAscending, setSortColAscending] = useState(false)
  const [sortedList, setSortedList] = useState<IRowElement[] | null>(null)
  const [totalPages, setTotalPages] = useState<number>(1)

  const [currentPage, updateCurrentPage] = useState(1)
  const itemsPerPage = 10

  const handleSort = (colIndex: number, isAscending: boolean): void => {
    setSortColIndex(colIndex)
    setSortColAscending(isAscending)
  }

  const courseListColumns: IHeaderItem[] = [
    {
      name: 'drug',
      displayName: 'Drug',
      width: 4
    },
    {
      name: 'firstDoseTime',
      displayName: 'First Dose',
      handleSort,
      width: 4
    },
    {
      name: 'lastDoseTime',
      displayName: 'Last Dose',
      handleSort,
      width: 4
    }
  ]

  const tabletViewCourseListColumns: IHeaderItem[] = [
    {
      name: 'drug',
      displayName: 'Drug',
      width: 6
    },
    {
      name: 'firstDoseTime',
      displayName: 'First Dose',
      handleSort,
      width: 3
    },
    {
      name: 'lastDoseTime',
      displayName: 'Last Dose',
      handleSort,
      width: 3
    }
  ]

  const iconElement = (
    moleculeName: string,
    courseId: string,
    isArchived: boolean,
    isReadOnly: boolean,
    archivedReason?: string | null
  ): JSX.Element => {
    const archivedTooltipId = `'course-list-archived-tooltip-'${courseId}`
    const readOnlyTooltipId = `'course-list-read-only-tooltip-'${courseId}`

    return (
      <>
        {archivedReason ? (
          <Tooltip className='center-tooltip-text' id={archivedTooltipId} place='top'>
            {archivedReason}
          </Tooltip>
        ) : null}

        {isReadOnly ? (
          <Tooltip className='center-tooltip-text' id={readOnlyTooltipId} place='top'>
            This course is read-only as the drug model is no longer available.
          </Tooltip>
        ) : null}

        <div className='course-list-molecule-name'>
          <div className='course-list-molecule-name-ellipsis-overflow'>{moleculeName}</div>
          {isArchived ? (
            <div data-tooltip-id={archivedTooltipId} className='ml-2'>
              <Icons.Archive />
            </div>
          ) : null}

          {isReadOnly && !isArchived ? (
            <div data-tooltip-id={readOnlyTooltipId} className='ml-2'>
              <FontAwesomeIcon color={LOADING_GREY} icon={faBook} />
            </div>
          ) : null}
        </div>
      </>
    )
  }

  const getContent = (courses: PatientCourse[]): IRowElement[] => {
    return courses.map((course) => {
      const isArchived = !!course.attributes.courseArchived

      return {
        id: course.id,
        archived: isArchived,
        columns: [
          {
            name: 'drug',
            element: iconElement(
              course.attributes.drugModel.molecule.name,
              course.id,
              isArchived,
              !!course.attributes.isReadOnly,
              course.attributes.archivedReason
            )
          },
          {
            name: 'firstDoseTime',
            element: (
              <span>
                {course.attributes.firstAdministrationTime
                  ? formatToDisplayDate(
                    course.attributes.firstAdministrationTime,
                      hospitalStore.hospital!.attributes.timezone
                  )
                  : '-'}
              </span>
            ),
            text: course.attributes.firstAdministrationTime ? course.attributes.firstAdministrationTime : ''
          },
          {
            name: 'lastDoseTime',
            element: (
              <span>
                {course.attributes.lastAdministrationTime
                  ? formatToDisplayDate(
                    course.attributes.lastAdministrationTime,
                      hospitalStore.hospital!.attributes.timezone
                  )
                  : '-'}
              </span>
            ),
            text: course.attributes.lastAdministrationTime ? course.attributes.lastAdministrationTime : ''
          }
        ]
      }
    })
  }

  useEffect(() => {
    if (patientCoursesStore.loadState === 'loaded') {
      setTotalPages(getPageCount([...patientCoursesStore.courses].length, itemsPerPage))
      const courses = [...patientCoursesStore.courses.values()]
      const contentSorted = sortAlpha(getContent(courses), sortColIndex, sortColAscending)
      setSortedList(contentSorted)

      if (!props.isTabletSize) {
        const initialActiveRowId = contentSorted[0]?.id.toString() || ''
        props.setSelectedCourseId(initialActiveRowId)
      }
    }
  }, [patientCoursesStore.loadState, props.isTabletSize, sortColIndex, sortColAscending])

  return (
    <>
      <div
        className={classnames({
          'courselist-tablet-view': props.isTabletSize
        })}
      >
        <SmartList
          cols={!props.isTabletSize ? courseListColumns : tabletViewCourseListColumns}
          data={sortedList ? paginate(sortedList, { currentPage, itemsPerPage }) : []}
          defaultSortColumn='lastDoseTime'
          defaultSortDirection='desc'
          onActivate={(selectedRowId) => {
            props.setSelectedCourseId(selectedRowId)
            {
              props.setShowCoursePreviewModal ? props.setShowCoursePreviewModal(true) : null
            }
          }}
          activeRow={props.selectedCourseId}
          loading={['loading', 'updating'].includes(patientCoursesStore.loadState)}
          textIfEmpty='No courses found for this patient.'
          minRowsToShow={10}
        />
      </div>

      <div className='patient-profile-pagination-panel'>
        <PaginationPanel currentPage={currentPage} totalPages={totalPages} onPageChange={updateCurrentPage} />
      </div>
    </>
  )
})
