import classnames from 'classnames'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useIntercom } from 'react-use-intercom'
import { Icons, InfoBubble, LOADING_GREY, VerificationCodeInputs } from '@doseme/cohesive-ui'

import { useAuthStore } from '../../../../hooks/useStore'
import { TwoFAModal } from './components'
import { verificationCodeInitialValue } from './constants'

import './index.scss'

export const TwoFAPage: React.FC = () => {
  const [verificationCode, setVerificationCode] = useState<string[]>(verificationCodeInitialValue)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)

  const history = useHistory()
  const { shutdown } = useIntercom()

  const authStore = useAuthStore()

  useEffect(() => {
    if (verificationCode && !verificationCode.includes('')) {
      sendVerificationCode(verificationCode.join(''))
    }
  }, [verificationCode])

  useEffect(() => {
    const element = document.getElementsByClassName('verification-code-input') as HTMLCollectionOf<HTMLInputElement>
    element[0].focus()
  }, [])

  const sendVerificationCode = async (code: string) => {
    await authStore.authPost2FA(code)

    if (authStore.loadState === 'loaded') {
      setSuccess(true)

      await authStore.authSession()
      history.push('/patients')

      return
    }

    setVerificationCode(verificationCodeInitialValue)
    const element = document.getElementsByClassName('verification-code-input') as HTMLCollectionOf<HTMLInputElement>
    element[0].focus()
  }

  const logOut = async () => {
    await authStore.authLogout()

    // Shuts down the Intercom instance on logout
    shutdown()

    history.push('/login')
    location.reload()
  }

  return (
    <div>
      <TwoFAModal show={showModal} setShow={setShowModal} />
      <div className='twofa-page-panel'>
        <div className='twofa-page-title'>2-factor authentication</div>
        <div className='twofa-page-email-subtitle'>{authStore.auth?.attributes.clinicianEmail}</div>
        {authStore.loadState === 'loading' && (
          <div className='twofa-page-loading-spinner'>
            <Icons.ThinSpinner strokeWidth={12} r={32} stroke={LOADING_GREY} width='64px' />
          </div>
        )}
        {success && (
          <div className='twofa-page-loading-spinner success'>
            <Icons.Success width={56} height={56} />
          </div>
        )}
        <div
          className={classnames({
            'loading-opacity-outer': ['loading', 'updating'].includes(authStore.loadState)
          })}
        >
          <div className='twofa-page-input-title'>Enter the 6-digit code found in your authenticator app</div>
          <div className='twofa-page-inputs-outer'>
            <VerificationCodeInputs code={verificationCode} setCode={setVerificationCode} />
          </div>
          {['loadError', 'updateError'].includes(authStore.loadState) && (
            <div className='twofa-page-error-message'>
              <InfoBubble type='error' bubbleTitle='Invalid verification code, please try again.' borderRadius={6} />
            </div>
          )}
          <div className='twofa-page-hyperlink-text' onClick={logOut}>Not you? Sign in as someone else</div>
          <div className='twofa-page-hyperlink-text more-info' onClick={() => setShowModal(true)}>
            More info about 2FA
          </div>
        </div>
      </div>
    </div>
  )
}
