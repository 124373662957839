import { IHeaderItem } from '@doseme/cohesive-ui/dist/types'

export const doseCols: IHeaderItem[] = [
  { name: 'Dose Time ↓', width: 5 },
  { name: 'Amount', width: 3 },
  { name: 'Inf. Length', width: 4 }
]

export const observationCols: IHeaderItem[] = [
  { name: 'Measurement Time ↓', width: 5 },
  { name: 'Type', width: 3 },
  { name: 'Level', width: 4 }
]
