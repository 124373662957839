import { InfoModal, Modal, Button } from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'

import { useAdminHospitalDetailsStore } from '../../../../../../../hooks/useStore'
import { showErrorToast, showSuccessToast } from '../../../../../../../shared/toast'
import { IPutAdminHospitalReportSettings } from '../../../../../../../store/Admin/AdminHospitalDetails/types'
import { IModalProps } from '../../../../../../PatientRouter/types'
import { buildFormFields, buildInputs } from '../../../../../../../shared/buildForms'
import { useFormValidation } from '../../../../../../../hooks/useFormValidation'

interface IProps extends IModalProps {
  hospitalId: string
  hospitalName?: string
}

const HospitalReportSettingsModal: React.FC<IProps> = observer((props) => {
  const adminHospitalDetailsStore = useAdminHospitalDetailsStore()

  useEffect(() => {
    if (props.show) {
      adminHospitalDetailsStore.fetchAdminHospitalReportSettings(props.hospitalId)
    }
  }, [props.hospitalId, props.show])

  const formFields = useMemo(() => {
    if (['loaded', 'updateError'].includes(adminHospitalDetailsStore.settingsLoadStates.reportSettings)) {
      return buildFormFields(adminHospitalDetailsStore.adminHospitalReportSettings)
    }

    return {}
  }, [adminHospitalDetailsStore.settingsLoadStates.reportSettings])

  const form = useFormValidation(formFields)

  useEffect(() => {
    if (adminHospitalDetailsStore.settingsLoadStates.reportSettings === 'loaded') {
      form.reset()
    }
  }, [adminHospitalDetailsStore.settingsLoadStates.reportSettings, formFields])

  const formContent = (): JSX.Element => {
    const displayOrder = ['overrideReportLogoFile', 'showInfoWarningsOnReports']

    if (adminHospitalDetailsStore.adminHospitalReportSettings) {
      return (
        <div className='position-relative w-100'>
          {buildInputs(adminHospitalDetailsStore.adminHospitalReportSettings, form, displayOrder, formFields)}
        </div>
      )
    }

    return <></>
  }

  const handleSubmit = async () => {
    const putValues: IPutAdminHospitalReportSettings = form.values as IPutAdminHospitalReportSettings
    // '--- Use System Logo' is a placeholder value for this being unset
    if (putValues['overrideReportLogoFile'] === '--- Use System Logo') {
      putValues['overrideReportLogoFile'] = null
    }
    await adminHospitalDetailsStore.putAdminHospitalReportSettings(props.hospitalId, putValues)

    if (['loadError', 'updateError'].includes(adminHospitalDetailsStore.settingsLoadStates.reportSettings)) {
      showErrorToast(adminHospitalDetailsStore.settingsErrors.reportSettings || 'Failed to update Hospital Report Settings')

      return
    }

    showSuccessToast('Hospital Report Settings updated')
    props.setShow(false)
  }

  const handleClose = () => {
    props.setShow(false)
    form.reset()
  }

  return (
    <Modal
      show={props.show}
      onHide={handleClose}
    >
      <InfoModal
        size='s'
        loading={['loading', 'updating'].includes(adminHospitalDetailsStore.settingsLoadStates.reportSettings)}
        linkComponent={
          <>
            <Button
              className='mr-3'
              data-testid='cancel-btn'
              disabled={false}
              loading={['loading', 'updating'].includes(adminHospitalDetailsStore.settingsLoadStates.reportSettings)}
              onClick={handleClose}
              variant='primary-outline'
            >
              Cancel
            </Button>
            <Button
              data-testid='save-btn'
              disabled={!form.valid || ['loading', 'updating'].includes(adminHospitalDetailsStore.settingsLoadStates.reportSettings)}
              loading={['loading', 'updating'].includes(adminHospitalDetailsStore.settingsLoadStates.reportSettings)}
              onClick={handleSubmit}
              variant='primary'
            >
              Save
            </Button>
          </>
        }
        title='Dosing report general settings'
        subtitle={props.hospitalName}
        message={formContent()}
      />
    </Modal>
  )
})

export { HospitalReportSettingsModal }
