import { ICourseLimits } from '../../../../../../../../../store/course/types'
import { IObservationType } from '../../../../../../../../../store/observations/types'
import { IFormField } from '../../../../../../../../../types/validation'
import {
  stringObsTypeIdToObsType,
  dateToISODateString,
  timeStateToTimeString,
  stringToFloat
} from '../../../../../../../../../utils/validation/formatters'
import {
  isRequired,
  isValidTimeState,
  isTimeStateDSTValid,
  isStringValidNumber,
  isStringWithinNumericLimits,
  isLessThanFourDecimalPlaces
} from '../../../../../../../../../utils/validation/rules'
import { isObservationTypeLevel } from '../../DosingProfilePanel/utils'

export const observationFormFields = (
  hospitalTimezone: string,
  courseLimits: ICourseLimits,
  observationTypesMap: Map<string, IObservationType>
): Record<string, IFormField> => {
  // FIXME: Apply default/min/max date rules
  const initialDateObserved = new Date()
  const initialTimeObserved = { hh: '', mm: '' }
  const initialObservationType = [...observationTypesMap][0][1]
  const initialAmountValue = ''

  // FIXME: Need a more comprehensive check once other obs types like INR are supported
  const levelOrSeCrLimits = isObservationTypeLevel(initialObservationType)
    ? courseLimits.concentration
    : courseLimits.secr

  return {
    observationType: {
      // Input - string (observation type id)
      // Value - IObservationType
      initialInput: initialObservationType.id,
      rules: [isRequired],
      formatter: stringObsTypeIdToObsType,
      formatterConstraints: observationTypesMap
    },
    dateObserved: {
      // Input - Date object
      // Value - ISO Date string yyyy-MM-dd
      initialInput: initialDateObserved,
      rules: [
        isRequired // remaining validation implicitly handled by DateTimePicker logic for now
      ],
      formatter: dateToISODateString
    },
    timeObserved: {
      // Input - ITimeState
      // Value - Time string HH:mm
      initialInput: initialTimeObserved,
      initialConstraints: {
        date: initialDateObserved,
        tz: hospitalTimezone
      },
      rules: [isRequired, isValidTimeState, isTimeStateDSTValid],
      formatter: timeStateToTimeString
    },
    amount: {
      // Input - string
      // Value - number
      initialInput: initialAmountValue,
      initialConstraints: levelOrSeCrLimits,
      rules: [isRequired, isStringValidNumber, isStringWithinNumericLimits, isLessThanFourDecimalPlaces],
      formatter: stringToFloat
    }
  }
}
