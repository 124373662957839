import { IHeaderItem } from '@doseme/cohesive-ui'

export const itemsPerPage = 5

export const patientExternalDataColumns = (handleSort: (colIndex: number, ascending: boolean) => void): IHeaderItem[] => [
  {
    name: 'Date & Time',
    handleSort,
    width: 4
  },
  {
    name: 'Clinician',
    handleSort,
    width: 5
  },
  {
    name: 'Type',
    width: 3
  }
]
