import { makeAutoObservable } from 'mobx'

import { AuthStore } from './AuthStore'
import { IInvitationDetails } from './types'

export class InvitationDetails implements IInvitationDetails {
  store: AuthStore

  type: 'authClinicianInvitePasswordFormDetails'
  attributes: IInvitationDetails['attributes']

  constructor(store: AuthStore, entity: IInvitationDetails) {
    makeAutoObservable(this, {
      store: false
    })

    this.store = store

    this.type = entity.type

    this.attributes = { ...entity.attributes }
  }
}
