import { makeAutoObservable } from 'mobx'

import { IAdminHospitalDrugDoseCalculatedTargetLimits } from './types'
import { AdminHospitalDrugDetailsStore } from './AdminHospitalDrugDetailsStore'

export class AdminHospitalDrugDoseCalculatedTargetLimits implements IAdminHospitalDrugDoseCalculatedTargetLimits {
  store: AdminHospitalDrugDetailsStore

  id: string
  type: string
  attributes: IAdminHospitalDrugDoseCalculatedTargetLimits['attributes']

  constructor(store: AdminHospitalDrugDetailsStore, entity: IAdminHospitalDrugDoseCalculatedTargetLimits) {
    makeAutoObservable(this, {
      store: false
    })

    this.store = store

    this.id = entity.id
    this.type = entity.type
    this.attributes = { ...entity.attributes }
  }
}
