import { roundDecimal } from '../../utils/numbers'
import { TLoadState } from '../types'
import { IModelResults, IModelResultsTypes, TModelType } from './types'

export const getModelResults = (
  modelResultsTypes: IModelResultsTypes,
  selectedSimulationPanelTab: TModelType,
  loadState: TLoadState
): IModelResults | null => {
  if (['loading', 'updating'].includes(loadState)) {
    return null
  }

  if (selectedSimulationPanelTab === 'customTarget') {
    return modelResultsTypes.customTarget
  }

  if (selectedSimulationPanelTab === 'customDose') {
    return modelResultsTypes.customDose
  }

  if (selectedSimulationPanelTab === 'guideline') {
    return modelResultsTypes.guideline
  }

  return modelResultsTypes.indPop
}

// FIXME: Move this calculation to the backend and return auc24 as its own attribute
export const normalizeAuc = (auc: number, dosingPeriod: number): number | null => {
  if (dosingPeriod === 24) {
    return roundDecimal(auc)
  }

  if (dosingPeriod === 0) {
    return null
  }

  const ratio = 24 / dosingPeriod

  return roundDecimal(auc * ratio)
}
