import { ILoginPageEmail, ILoginPagePassword } from './types'

export const loginPageEmailFields: ILoginPageEmail = {
  type: 'test',
  id: '1',
  attributes: {
    email: {
      id: '1',
      type: 'text',
      attributes: {
        name: 'email',
        label: 'email',
        format: 'text',
        currentValue: '',
        defaultValue: '',
        isRequired: true
      }
    }
  }
}

export const loginPagePasswordFields: ILoginPagePassword = {
  type: 'test2',
  id: '2',
  attributes: {
    password: {
      id: '2',
      type: 'text',
      attributes: {
        name: 'password',
        label: 'password',
        format: 'text',
        currentValue: '',
        defaultValue: '',
        isRequired: true
      }
    }
  }
}
