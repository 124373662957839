import { makeAutoObservable } from 'mobx'

import { IAdminHospitalDrugSuperAdminInfo } from './types'
import { AdminHospitalDrugDetailsStore } from './AdminHospitalDrugDetailsStore'

export class AdminHospitalDrugSuperAdminInfo implements IAdminHospitalDrugSuperAdminInfo {
  store: AdminHospitalDrugDetailsStore

  id: string
  type: string
  attributes: IAdminHospitalDrugSuperAdminInfo['attributes']

  constructor(store: AdminHospitalDrugDetailsStore, entity: IAdminHospitalDrugSuperAdminInfo) {
    makeAutoObservable(this, {
      store: false
    })

    this.store = store

    this.id = entity.id
    this.type = entity.type
    this.attributes = { ...entity.attributes }
  }
}
