import { InfoModal, Modal, Button, Icons, LOADING_GREY, TextInput, InfoBubble } from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'
import { useState, useEffect } from 'react'

import { useFormValidation } from '../../../../../../../hooks/useFormValidation'
import { useAdminClinicianSecurityStore, usePasswordRulesStore } from '../../../../../../../hooks/useStore'
import { checkPasswordRules, checkPasswordValid } from '../../../../../../../shared/checkPassword'
import { IPasswordRulesCheck } from '../../../../../../../shared/checkPassword/types'
import { showErrorToast, showSuccessToast } from '../../../../../../../shared/toast'
import { IModalProps } from '../../../../../../PatientRouter/types'
import { resetPasswordFormFields } from './constants'

interface IProps extends IModalProps {
  clinicianId: string
  clinicianName: string
}

const ResetPasswordModal: React.FC<IProps> = observer((props) => {
  const [passwordCheck, setPasswordCheck] = useState<IPasswordRulesCheck>({
    upperCaseLetter: false,
    lowerCaseLetter: false,
    number: false,
    characterLength: false,
    passwordsMatch: false
  })
  const [isShowNewPasswordChecked, setIsShowNewPasswordChecked] = useState<boolean>(false)
  const [isShowConfirmPasswordChecked, setIsShowConfirmPasswordChecked] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | undefined>()

  const passwordRulesStore = usePasswordRulesStore()
  const adminClinicianSecurityStore = useAdminClinicianSecurityStore()

  const form = useFormValidation(resetPasswordFormFields)

  useEffect(() => {
    if (passwordRulesStore.loadState === 'initial') {
      passwordRulesStore.getPasswordRules()
    }
  }, [])

  const handleNewPasswordChange = (newPassword: string) => {
    if (passwordRulesStore.passwordRules?.attributes) {
      const newPasswordCheck = checkPasswordRules(
        newPassword,
        form.inputs['confirmNewPassword'],
        passwordRulesStore.passwordRules.attributes
      )

      setPasswordCheck(newPasswordCheck)
    }
  }

  const handleConfirmPasswordChange = (confirmPassword: string) => {
    if (passwordRulesStore.passwordRules?.attributes) {
      const newPasswordCheck = checkPasswordRules(
        form.inputs['newPassword'],
        confirmPassword,
        passwordRulesStore.passwordRules.attributes
      )

      setPasswordCheck(newPasswordCheck)
    }
  }

  const savePassword = async () => {
    await adminClinicianSecurityStore.putAdminClinicianPassword(props.clinicianId, form.inputs['newPassword'])

    if (['loadError', 'updateError'].includes(adminClinicianSecurityStore.loadState)) {
      showErrorToast(adminClinicianSecurityStore.error || 'Failed to update Clinician Password')
      setErrorMessage(adminClinicianSecurityStore.error || 'Failed to update Clinician Password')

      return
    }

    showSuccessToast('Clinician Password updated')
    handleClose()
  }

  const handleClose = () => {
    props.setShow(false)
    form.reset()
    setPasswordCheck({
      upperCaseLetter: false,
      lowerCaseLetter: false,
      number: false,
      characterLength: false,
      passwordsMatch: false
    })
    setErrorMessage(undefined)
    setIsShowNewPasswordChecked(false)
    setIsShowConfirmPasswordChecked(false)
  }

  //FIXME - Refactor Password Panel so it can be used here too
  const passwordContent = (): JSX.Element => {
    return (
      <div className='reset-password-modal-content'>
        {newPasswordInputContent()}
        {
          errorMessage && (
            <div className='reset-password-error'>
              <InfoBubble type='error' bubbleTitle={errorMessage} borderRadius={6} />
            </div>
          )
        }
        {passwordValidityContent()}
      </div>
    )
  }

  const newPasswordInputContent = (): JSX.Element => {
    return (
      <>
        <div className='reset-password-label'>
          <div className='reset-password-input-label'>New password:</div>
          <div className='reset-password-show-password' onClick={() => setIsShowNewPasswordChecked(!isShowNewPasswordChecked)}>
            <input
              type='checkbox'
              checked={isShowNewPasswordChecked}
              readOnly
            />
            <div className='reset-password-show-password-label'>Show password</div>
          </div>
        </div>
        <TextInput
          key='newPassword'
          showCapsLock={true}
          type={isShowNewPasswordChecked ? 'text' : 'password'}
          fieldState={form.getValidState('newPassword')}
          value={form.inputs['newPassword']}
          name={'newPassword'}
          onChange={(value) => {
            form.validateFields([
              {
                field: 'newPassword',
                input: value
              }
            ])
            handleNewPasswordChange(value)
          }}
          onBlur={() => form.updateFieldsDisplay(['newPassword'])}
        />
        <div className='reset-password-label reset-password-label-confirm'>
          <div className='reset-password-input-label'>Confirm password:</div>
          <div className='reset-password-show-password' onClick={() => setIsShowConfirmPasswordChecked(!isShowConfirmPasswordChecked)}>
            <input
              type='checkbox'
              checked={isShowConfirmPasswordChecked}
              readOnly
            />
            <div className='reset-password-show-password-label'>Show password</div>
          </div>
        </div>
        <TextInput
          key='confirmNewPassword'
          showCapsLock={true}
          type={isShowConfirmPasswordChecked ? 'text' : 'password'}
          fieldState={form.getValidState('confirmNewPassword')}
          value={form.inputs['confirmNewPassword']}
          name={'confirmNewPassword'}
          onChange={(value) => {
            form.validateFields([
              {
                field: 'confirmNewPassword',
                input: value
              }
            ])
            handleConfirmPasswordChange(value)
          }}
          onBlur={() => form.updateFieldsDisplay(['confirmNewPassword'])}
        />
      </>
    )
  }

  const passwordValidityContent = (): JSX.Element => {
    if (passwordRulesStore.loadState === 'loaded' && passwordRulesStore.passwordRules?.attributes) {
      const passwordRules = passwordRulesStore.passwordRules.attributes

      return (
        <div className='reset-password-validation'>
          <div className='reset-password-validation-title'>Passwords must contain:</div>
          {passwordRules.requiresUpperCase &&
            <div className='reset-password-validation-row'>
              {passwordCheck.upperCaseLetter ? <Icons.SuccessCircle /> : <Icons.UnavailableCircle />}
              <div className='reset-password-validation-text'>One upper-case letter</div>
            </div>
          }
          {passwordRules.requiresLowerCase &&
            <div className='reset-password-validation-row'>
              {passwordCheck.lowerCaseLetter ? <Icons.SuccessCircle /> : <Icons.UnavailableCircle />}
              <div className='reset-password-validation-text'>One lower-case letter</div>
            </div>
          }
          {passwordRules.requiresNumOrSymbol &&
            <div className='reset-password-validation-row'>
              {passwordCheck.number ? <Icons.SuccessCircle /> : <Icons.UnavailableCircle />}
              <div className='reset-password-validation-text'>One number or symbol</div>
            </div>
          }
          <div className='reset-password-validation-row'>
            {passwordCheck.characterLength ? <Icons.SuccessCircle /> : <Icons.UnavailableCircle />}
            <div className='reset-password-validation-text'>
              {`Between ${passwordRules.minLength} and ${passwordRules.maxLength} characters`}
            </div>
          </div>
          <div className='reset-password-validation-row'>
            {passwordCheck.passwordsMatch ? <Icons.SuccessCircle /> : <Icons.UnavailableCircle />}
            <div className='reset-password-validation-text'>Passwords match</div>
          </div>
        </div>
      )
    }

    return (
      <div className='reset-password-page-spinner'>
        <Icons.ThinSpinner strokeWidth={12} r={32} stroke={LOADING_GREY} width='64px' />
      </div>
    )
  }

  const isPasswordValid = () => {
    if (passwordRulesStore.passwordRules?.attributes) {
      return checkPasswordValid(passwordCheck, passwordRulesStore.passwordRules.attributes)
    }

    return false
  }

  return (
    <Modal
      show={props.show}
      onHide={handleClose}
    >
      <InfoModal
        size='s'
        loading={['loading', 'updating'].includes(adminClinicianSecurityStore.loadState)}
        linkComponent={
          <>
            <Button
              className='mr-3'
              data-testid='cancel-btn'
              disabled={['loading', 'updating'].includes(adminClinicianSecurityStore.loadState) }
              onClick={handleClose}
              variant='primary-outline'
            >
              Cancel
            </Button>
            <Button
              data-testid='save-btn'
              disabled={!isPasswordValid() || ['loading', 'updating'].includes(adminClinicianSecurityStore.loadState)}
              loading={['loading', 'updating'].includes(adminClinicianSecurityStore.loadState)}
              onClick={savePassword}
              variant='primary'
            >
              Update Password
            </Button>
          </>
        }
        title='Manual password reset'
        subtitle={props.clinicianName}
        message={passwordContent()}
      />
    </Modal>
  )
})

export { ResetPasswordModal }
