import { InfoModal, Modal, Button } from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'

import { useAdminVendorSiteDetailsStore } from '../../../../../../../hooks/useStore'
import { showErrorToast, showSuccessToast } from '../../../../../../../shared/toast'
import { IModalProps } from '../../../../../../PatientRouter/types'
import { buildFormFields, buildInputs } from '../../../../../../../shared/buildForms'
import { useFormValidation } from '../../../../../../../hooks/useFormValidation'
import { IPutAdminVendorSiteSettings } from '../../../../../../../store/Admin/AdminVendorSiteDetails/types'

interface IProps extends IModalProps {
  vendorSiteId: string
  vendorSiteName?: string
  vendorId: string
}

const VendorSiteSettingsModal: React.FC<IProps> = observer((props) => {
  const adminVendorSiteDetailsStore = useAdminVendorSiteDetailsStore()

  useEffect(() => {
    if (props.show && adminVendorSiteDetailsStore.settingsLoadStates) {
      adminVendorSiteDetailsStore.fetchAdminVendorSiteSettings(props.vendorId, props.vendorSiteId)
    }
  }, [props.vendorSiteId, props.show])

  const formFields = useMemo(() => {
    if (['loaded', 'updateError'].includes(adminVendorSiteDetailsStore.settingsLoadStates.settings)) {
      return buildFormFields(adminVendorSiteDetailsStore.adminVendorSiteSettings)
    }

    return {}
  }, [adminVendorSiteDetailsStore.settingsLoadStates.settings])

  const form = useFormValidation(formFields)

  const formContent = (): JSX.Element => {
    const displayOrder = [
      'reportSystem',
      'reportCode',
      'patientHeightObservationCodes',
      'patientWeightObservationCodes'
    ]

    if (adminVendorSiteDetailsStore.adminVendorSiteSettings) {
      return (
        <div className='position-relative w-100'>
          {buildInputs(adminVendorSiteDetailsStore.adminVendorSiteSettings, form, displayOrder, formFields)}
        </div>
      )
    }

    return <></>
  }

  useEffect(() => {
    if (adminVendorSiteDetailsStore.settingsLoadStates.settings === 'loaded') {
      form.reset()
    }
  }, [adminVendorSiteDetailsStore.settingsLoadStates.settings])

  const handleSubmit = async () => {
    await adminVendorSiteDetailsStore.putAdminVendorSiteSettings(
      props.vendorId,
      props.vendorSiteId,
      form.values as IPutAdminVendorSiteSettings
    )

    if (['loadError', 'updateError'].includes(adminVendorSiteDetailsStore.settingsLoadStates.settings)) {
      showErrorToast(adminVendorSiteDetailsStore.settingsErrors.settings || 'Failed to update Vendor Site Settings')

      return
    }

    showSuccessToast('Vendor Site Settings updated')
    props.setShow(false)
  }

  const handleClose = () => {
    props.setShow(false)
    form.reset()
  }

  return (
    <Modal show={props.show} onHide={handleClose}>
      <InfoModal
        size='s'
        loading={['loading', 'updating'].includes(adminVendorSiteDetailsStore.settingsLoadStates.settings)}
        linkComponent={
          <>
            <Button
              className='mr-3'
              data-testid='cancel-btn'
              disabled={false}
              loading={['loading', 'updating'].includes(adminVendorSiteDetailsStore.settingsLoadStates.settings)}
              onClick={handleClose}
              variant='primary-outline'
            >
              Cancel
            </Button>
            <Button
              data-testid='save-btn'
              disabled={!form.valid || ['loading', 'updating'].includes(adminVendorSiteDetailsStore.settingsLoadStates.settings)}
              loading={['loading', 'updating'].includes(adminVendorSiteDetailsStore.settingsLoadStates.settings)}
              onClick={handleSubmit}
              variant='primary'
            >
              Save
            </Button>
          </>
        }
        title='Edit vendor site settings'
        subtitle={props.vendorSiteName}
        message={formContent()}
      />
    </Modal>
  )
})

export { VendorSiteSettingsModal }
