import { makeAutoObservable } from 'mobx'

import axiosClient, { AxiosError, AxiosResponse } from '../../../utils/axiosClient'
import {
  IAdminHospitalCoreDetails,
  IAdminHospitalDetails,
  IPutAdminHospitalCoreDetails,
  IAdminHospitalDefaultDoseSettings,
  IPutAdminHospitalDefaultDoseSettings,
  IAdminHospitalReportSettings,
  IPutAdminHospitalReportSettings,
  IAdminHospitalClinicianRoles,
  IPutAdminHospitalClinicianRoles,
  IAdminHospitalWards,
  IPutAdminHospitalWards
} from './types'
import { RootStore } from '../../RootStore'
import { TLoadState } from '../../types'
import {
  adminHospitalDetailsGetUrl,
  adminHospitalCoreDetailsGetUrl,
  adminHospitalDefaultDoseSettingsGetUrl,
  adminHospitalReportSettingsGetUrl,
  adminHospitalClinicianRolesGetUrl,
  adminHospitalWardsGetUrl
} from '../../../constants/api'
import { AdminHospitalDetails } from './AdminHospitalDetails'
import { AdminHospitalCoreDetails } from './AdminHospitalCoreDetails'
import { AdminHospitalDefaultDoseSettings } from './AdminHospitalDefaultDoseSettings'
import { AdminHospitalReportSettings } from './AdminHospitalReportSettings'
import { AdminHospitalClinicianRoles } from './AdminHospitalClinicianRoles'
import { AdminHospitalWards } from './AdminHospitalWards'

export class AdminHospitalDetailsStore {
  rootStore: RootStore

  loadState: TLoadState = 'initial'
  error: string = ''

  adminHospitalDetails: IAdminHospitalDetails | null = null

  settingsLoadStates: Record<string, TLoadState> = {
    coreDetails: 'initial',
    defaultDoseSettings: 'initial',
    reportSettings: 'initial',
    clinicianRoles: 'initial',
    wards: 'initial'
  }
  settingsErrors: Record<string, string> = {
    coreDetails: '',
    defaultDoseSettings: '',
    reportSettings: '',
    clinicianRoles: '',
    wards: ''
  }

  adminHospitalCoreDetails: AdminHospitalCoreDetails | null = null
  adminHospitalDefaultDoseSettings: AdminHospitalDefaultDoseSettings | null = null
  adminHospitalReportSettings: AdminHospitalReportSettings | null = null
  adminHospitalClinicianRoles: AdminHospitalClinicianRoles | null = null
  adminHospitalWards: AdminHospitalWards | null = null

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false
    })

    this.rootStore = rootStore
  }

  setLoadState(loadState: TLoadState) {
    this.loadState = loadState
  }

  setError(errorState: 'loadError' | 'updateError', error: string) {
    this.error = error
    this.setLoadState(errorState)
  }

  setLoadStateSettings(loadState: TLoadState, setting: string) {
    this.settingsLoadStates[setting] = loadState
  }

  setErrorSettings(errorState: 'loadError' | 'updateError', error: string, setting: string) {
    this.settingsErrors[setting] = error
    this.setLoadStateSettings(errorState, setting)
  }

  setStore(adminHospitalDetails: IAdminHospitalDetails) {
    this.resetStore()
    this.adminHospitalDetails = new AdminHospitalDetails(this, adminHospitalDetails)
  }

  resetStore() {
    this.adminHospitalDetails = null
  }

  async fetchAdminHospitalDetails(hospitalId: string) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .get<AxiosResponse<IAdminHospitalDetails>>(adminHospitalDetailsGetUrl(hospitalId), { headers })
      .then((response: AxiosResponse) => {
        this.setStore(response.data.data)
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
      })
  }

  setStoreCoreDetails(adminHospitalCoreDetails: IAdminHospitalCoreDetails) {
    this.resetStoreCoreDetails()
    this.adminHospitalCoreDetails = new AdminHospitalCoreDetails(this, adminHospitalCoreDetails)
  }

  resetStoreCoreDetails() {
    this.adminHospitalCoreDetails = null
  }

  async fetchAdminHospitalCoreDetails(hospitalId: string) {
    this.setLoadStateSettings('loading', 'coreDetails')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .get<AxiosResponse<IAdminHospitalCoreDetails>>(adminHospitalCoreDetailsGetUrl(hospitalId), { headers })
      .then((response: AxiosResponse) => {
        this.setStoreCoreDetails(response.data.data)
        this.setLoadStateSettings('loaded', 'coreDetails')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setErrorSettings('loadError', loggableError, 'coreDetails')
      })
  }

  async putAdminHospitalCoreDetails(hospitalId: string, details: IPutAdminHospitalCoreDetails) {
    this.setLoadState('loading')
    this.setLoadStateSettings('loading', 'coreDetails')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .put<AxiosResponse<IAdminHospitalDetails>>(
        adminHospitalCoreDetailsGetUrl(hospitalId),
        {
          data: {
            id: hospitalId,
            type: 'adminHospitalCoreDetails',
            attributes: details
          }
        },
        { headers }
      )
      .then((response: AxiosResponse) => {
        this.setStore(response.data.data)

        this.setLoadState('loaded')
        this.setLoadStateSettings('loaded', 'coreDetails')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('updateError', loggableError)
        this.setErrorSettings('updateError', loggableError, 'coreDetails')
      })
  }

  setStoreDefaultDoseSettings(adminHospitalDefaultDoseSettings: IAdminHospitalDefaultDoseSettings) {
    this.resetStoreDefaultDoseSettings()
    this.adminHospitalDefaultDoseSettings = new AdminHospitalDefaultDoseSettings(this, adminHospitalDefaultDoseSettings)
  }

  resetStoreDefaultDoseSettings() {
    this.adminHospitalDefaultDoseSettings = null
  }

  async fetchAdminHospitalDefaultDoseSettings(hospitalId: string) {
    this.setLoadStateSettings('loading', 'defaultDoseSettings')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .get<AxiosResponse<IAdminHospitalDefaultDoseSettings>>(adminHospitalDefaultDoseSettingsGetUrl(hospitalId), {
        headers
      })
      .then((response: AxiosResponse) => {
        this.setStoreDefaultDoseSettings(response.data.data)
        this.setLoadStateSettings('loaded', 'defaultDoseSettings')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setErrorSettings('loadError', loggableError, 'defaultDoseSettings')
      })
  }

  async putAdminHospitalDefaultDoseSettings(hospitalId: string, details: IPutAdminHospitalDefaultDoseSettings) {
    this.setLoadState('loading')
    this.setLoadStateSettings('loading', 'defaultDoseSettings')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .put<AxiosResponse<IAdminHospitalDetails>>(
        adminHospitalDefaultDoseSettingsGetUrl(hospitalId),
        {
          data: {
            id: hospitalId,
            type: 'adminHospitalDefaultDoseSettings',
            attributes: details
          }
        },
        { headers }
      )
      .then((response: AxiosResponse) => {
        this.setStore(response.data.data)

        this.setLoadState('loaded')
        this.setLoadStateSettings('loaded', 'defaultDoseSettings')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('updateError', loggableError)
        this.setErrorSettings('updateError', loggableError, 'defaultDoseSettings')
      })
  }

  setStoreReportSettings(adminHospitalReportSettings: IAdminHospitalReportSettings) {
    this.resetStoreReportSettings()
    this.adminHospitalReportSettings = new AdminHospitalReportSettings(this, adminHospitalReportSettings)
  }

  resetStoreReportSettings() {
    this.adminHospitalReportSettings = null
  }

  async fetchAdminHospitalReportSettings(hospitalId: string) {
    this.setLoadStateSettings('loading', 'reportSettings')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .get<AxiosResponse<IAdminHospitalReportSettings>>(adminHospitalReportSettingsGetUrl(hospitalId), { headers })
      .then((response: AxiosResponse) => {
        this.setStoreReportSettings(response.data.data)
        this.setLoadStateSettings('loaded', 'reportSettings')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setErrorSettings('loadError', loggableError, 'reportSettings')
      })
  }

  async putAdminHospitalReportSettings(hospitalId: string, details: IPutAdminHospitalReportSettings) {
    this.setLoadState('loading')
    this.setLoadStateSettings('loading', 'reportSettings')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .put<AxiosResponse<IAdminHospitalDetails>>(
        adminHospitalReportSettingsGetUrl(hospitalId),
        {
          data: {
            id: hospitalId,
            type: 'adminHospitalReportSettings',
            attributes: details
          }
        },
        { headers }
      )
      .then((response: AxiosResponse) => {
        this.setStore(response.data.data)

        this.setLoadState('loaded')
        this.setLoadStateSettings('loaded', 'reportSettings')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('updateError', loggableError)
        this.setErrorSettings('updateError', loggableError, 'reportSettings')
      })
  }

  setStoreClinicianRoles(adminHospitalClinicianRoles: IAdminHospitalClinicianRoles) {
    this.adminHospitalClinicianRoles = new AdminHospitalClinicianRoles(this, adminHospitalClinicianRoles)
  }

  resetStoreClinicianRoles() {
    this.adminHospitalClinicianRoles = null
  }

  async fetchAdminHospitalClinicianRoles(hospitalId: string) {
    this.setLoadStateSettings('loading', 'clinicianRoles')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .get<AxiosResponse<IAdminHospitalClinicianRoles>>(adminHospitalClinicianRolesGetUrl(hospitalId), { headers })
      .then((response: AxiosResponse) => {
        this.setStoreClinicianRoles(response.data.data)
        this.setLoadStateSettings('loaded', 'clinicianRoles')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setErrorSettings('loadError', loggableError, 'clinicianRoles')
      })
  }

  async putAdminHospitalClinicianRoles(hospitalId: string, details: IPutAdminHospitalClinicianRoles) {
    this.setLoadState('loading')
    this.setLoadStateSettings('loading', 'clinicianRoles')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .put<AxiosResponse<IAdminHospitalDetails>>(
        adminHospitalClinicianRolesGetUrl(hospitalId),
        {
          data: {
            id: hospitalId,
            type: 'adminHospitalClinicianRoles',
            attributes: details
          }
        },
        { headers }
      )
      .then((response: AxiosResponse) => {
        this.setStore(response.data.data)

        this.setLoadState('loaded')
        this.setLoadStateSettings('loaded', 'clinicianRoles')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('updateError', loggableError)
        this.setErrorSettings('updateError', loggableError, 'clinicianRoles')
      })
  }

  setStoreWards(adminHospitalWards: IAdminHospitalWards) {
    this.adminHospitalWards = new AdminHospitalWards(this, adminHospitalWards)
  }

  resetStoreWards() {
    this.adminHospitalWards = null
  }

  async fetchAdminHospitalWards(hospitalId: string) {
    this.setLoadStateSettings('loading', 'wards')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .get<AxiosResponse<IAdminHospitalWards>>(adminHospitalWardsGetUrl(hospitalId), { headers })
      .then((response: AxiosResponse) => {
        this.setStoreWards(response.data.data)
        this.setLoadStateSettings('loaded', 'wards')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setErrorSettings('loadError', loggableError, 'wards')
      })
  }

  async putAdminHospitalWards(hospitalId: string, details: IPutAdminHospitalWards) {
    this.setLoadState('loading')
    this.setLoadStateSettings('loading', 'wards')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .put<AxiosResponse<IAdminHospitalDetails>>(adminHospitalWardsGetUrl(hospitalId),
        {
          data: {
            id: hospitalId,
            type: 'adminHospitalWards',
            attributes: details
          }
        },
        { headers }
      )
      .then((response: AxiosResponse) => {
        this.setStore(response.data.data)

        this.setLoadState('loaded')
        this.setLoadStateSettings('loaded', 'wards')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('updateError', loggableError)
        this.setErrorSettings('updateError', loggableError, 'wards')
      })
  }
}
