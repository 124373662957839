import { ObservableMap } from 'mobx'
import { decode } from 'he'
import { ISelectedRows } from '@doseme/cohesive-ui'

import { IPatientListItem } from '../../../../../../store/PatientList/types'

export const searchPatients = (
  searchText: string,
  patients: ObservableMap<string, IPatientListItem>
): IPatientListItem[] => {
  return [...patients].reduce((acc: IPatientListItem[], [key, curr]) => {
    if (curr.attributes.lastName?.toLowerCase().includes(searchText.toLowerCase())
      || curr.attributes.firstName?.toLowerCase().includes(searchText.toLowerCase())
      || curr.attributes.longid?.toLowerCase().includes(searchText.toLowerCase())) {
      return acc.concat(curr)
    }

    return acc
  }, [])
}

export const filterArchivedUnarchivedPatients = (
  archived: boolean,
  patients: IPatientListItem[]
): IPatientListItem[] => {
  return [...patients].reduce((acc: IPatientListItem[], curr: IPatientListItem) => {
    if (curr.attributes.isArchived === archived) {
      return acc.concat(curr)
    }

    return acc
  }, [])
}

export const getSelectedRowCount = (selected: ISelectedRows): number => {
  return Object.keys(selected).reduce((acc, curr) => {
    if (selected[curr]) {
      return acc + 1
    }

    return acc
  }, 0)
}

export const getPatientDisplayName = (patientLastName: string | null, patientFirstName: string | null): string => {
  if (patientLastName && patientFirstName) {
    return decode(`${patientLastName}, ${patientFirstName}`)
  }

  return (patientLastName && decode(patientLastName)) || (patientFirstName && decode(patientFirstName)) || '-'
}
