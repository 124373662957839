interface IProps {
  modelType: string
  citation: string
}

export const IndPopInfo: React.FC<IProps> = (props) => {
  return (
    <div>

      The <em>{props.modelType} Dose Outcomes</em> section list dose parameters (time of administration, length of infusion,
      and amount) for recommended doses, as well as the predicted outcome for each dose.

      <h2 id='model_citation'>Model Citation</h2>
      <p>
        {props.citation}
      </p>

      <h3 id='predicted-outcome'>Predicted Outcome</h3>
      <p>This is the drug plasma concentration likely to be achieved if the recommended dose is given.</p>
      <p>
        To calculate this concentration, the drug model is fitted to individual patient using Bayesian fitting as
        described by Duffull, Kirkpatrick, and Begg (1997). DoseMeRx takes into account individual blood plasma
        concentration as represented in lab results, and selects the drug model parameters, such as volume of
        distribution and clearance, so that the blood plasma concentration computed by the individualised model
        is as close as possible to that recorded in lab results.
      </p>

      <p>
        While DoseMeRx uses a complex optimisation method that takes into account the likelihood of recorded lab
        results with respect to population pharmacokinetic drug model, in order to verify the model fit to
        individual patient one could find a set of individualised values for the population model parameters
        (see <em>Drug Info</em> tab for reference to specific drug model used) by using the 'Goal Seek' function
        available in spreadsheets, together with formulas for computing drug plasma concentration described by
        Bazzoli, Retout, and Mentr&eacute; (2010).
      </p>

      <p>
        <img
          src='/drug-plasma-concentration-graph.svg'
          alt='Drug Plasma Concentration Graph' style={{ width: '550px' }} />
      </p>

      <h3 id='predicted-peak'>Predicted Peak</h3>
      <p>
        This is the highest blood plasma concentration after administration of the recommended dose and before the
        end of dosing period as computed by individualised drug model.
      </p>

      <p>
        In order to verify this value, plot a graph of blood plasma concentration using the drug model (see
        <em>Drug Info</em> tab for reference to specific drug model used), the individualised model parameters
        given by DoseMeRx in the printed dose recommendation report, or alternatively using the individualised
        model parameters computed using method suggested in <em>Predicted Outcome</em> section of this document,
        together with formulas for computing drug plasma concentration described by Bazzoli, Retout, and
        Mentr&eacute; (2010). Select the highest point on the graph.
      </p>

      <h3 id='predicted-trough'>Predicted Trough</h3>
      <p>
        This is the lowest blood plasma concentration after administration of the recommended dose at the end of
        dosing period as computed by the individualised drug model.
      </p>

      <p>
        In order to verify this value, compute the blood plasma concentration at end of dosing period using the drug
        model (see <em>Drug Info</em> tab for reference to specific drug model used), the individualised model
        parameters given by DoseMeRx in the printed dose recommendation report, or alternatively using the
        individualised model parameters computed using method suggested in <em>Predicted Outcome</em> section of
        this document, together with formulas for computing drug plasma concentration described by Bazzoli, Retout,
        and Mentr&eacute; (2010).
      </p>

      <h3 id='predicted-auc'>Predicted AUC</h3>
      <p>
        This is the area under the blood plasma concentration curve as computed by the individualised drug model
        between the time of administration of recommended dose and the end of dosing period.
      </p>
      <p>
        In order to verify this value, plot a graph of blood plasma concentration using the drug model (see
        <em>Drug Info</em> tab for reference to specific drug model used), the individualised model parameters given
        by DoseMeRx in the printed dose recommendation report, or alternatively using the individualised model
        parameters computed using method suggested in <em>Predicted Outcome</em> section of this document, together
        with formulas for computing drug plasma concentration described by Bazzoli, Retout, and Mentr&eacute; (2010).
        Then integrate the curve using the trapezoidal rule or other methods as described in Chapter 4 of Jambhekar
        and Breen (2009).
      </p>

      <h2 id='references'>References</h2>
      <p>
        Bazzoli, C., Retout, S., &amp; Mentr&eacute;, F. (2010). Design evaluation and optimisation in multiple
        response nonlinear mixed effect models: PFIM 3.0. <em>Computer Methods and Programs in Biomedicine</em>,
        <em>98</em>(1), 55&ndash;65.
        <a href='http://doi.org/10.1016/j.cmpb.2009.09.012' target='_blank'>
        http://doi.org/10.1016/j.cmpb.2009.09.012 <i className='fa fa-external-link' aria-hidden='true' />
        </a>
      </p>
      <p>
        Duffull, S. B., Kirkpatrick, C. M. J., &amp; Begg, E. J. (1997). Comparison of two Bayesian approaches to
        dose-individualization for once-daily aminoglycoside regimens.
        <em>British Journal of Clinical
        Pharmacology
        </em>, <em>43</em>(2), 125&ndash;135.
        <a href='http://doi.org/10.1046/j.1365-2125.1997.05341.x' target='_blank'>
        http://doi.org/10.1046/j.1365-2125.1997.05341.x <i className='fa fa-external-link' aria-hidden='true' />
        </a>
      </p>
      <p>
        Jambhekar, S. S., &amp; Breen, P. J. (2009). <em>Basic pharmacokinetics</em>. Cornwall: Pharmaceutical Press.
      </p>
    </div>
  )
}
