import { makeAutoObservable, observable, ObservableMap } from 'mobx'

import axiosClient, { AxiosError, AxiosResponse } from '../../../utils/axiosClient'
import { constantsTimezoneGetUrl } from '../../../constants/api'
import { RootStore } from '../../RootStore'
import { TLoadState } from '../../types'
import { TimezoneItem } from './TimezoneItem'
import { ITimezone } from './types'

export class TimezoneStore {
  rootStore: RootStore

  loadState: TLoadState = 'initial'
  error: string = ''

  timezones: ObservableMap<string, TimezoneItem> = observable.map({}, { deep: false })

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false
    })

    this.rootStore = rootStore
  }

  setLoadState(loadState: TLoadState) {
    this.loadState = loadState
  }

  setError(errorState: 'loadError' | 'updateError', error: string) {
    this.error = error
    this.setLoadState(errorState)
  }

  resetTimezones(timezones: ITimezone[]) {
    this.timezones = observable.map({}, { deep: false })
    timezones.forEach((timezone) => this.timezones.set(timezone.attributes.name, new TimezoneItem(this, timezone)))
  }

  async fetchTimezones() {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .get<AxiosResponse<ITimezone[]>>(constantsTimezoneGetUrl(), { headers })
      .then((response: AxiosResponse) => {
        this.resetTimezones(response.data.data)
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
      })
  }
}
