import { Icons, LOADING_GREY, Panel } from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'

import { usePatientCoursesStore } from '../../../../../../hooks/useStore'
import { PreviewPanelBottomSection } from './components/PreviewPanelBottomSection'
import { PreviewPanelTopSection } from './components/PreviewPanelTopSection'

import '../../index.scss'

interface IProps {
  patientId: string
  selectedCourseId: string
}

export const CoursePreviewPanel: React.FC<IProps> = observer(({ patientId, selectedCourseId }) => {
  const patientCoursesStore = usePatientCoursesStore()

  return (
    <Panel
      title='Course Preview'
      sections={
        patientCoursesStore.loadState === 'loading'
          ? [
            {
              id: 3,
              element: (
                <div className='course-preview-loading-state' data-testid='course-preview-panel-spinner' >
                  <Icons.ThinSpinner strokeWidth={12} r={30} stroke={LOADING_GREY} width='60px' />
                </div>
              )
            }
          ]
          : [
            {
              id: 1,
              element: <PreviewPanelTopSection patientId={patientId} selectedCourseId={selectedCourseId} />
            },
            {
              id: 2,
              element: <PreviewPanelBottomSection selectedCourseId={selectedCourseId} patientId={patientId} />
            }
          ]
      }
    />
  )
})
