import { makeAutoObservable } from 'mobx'

import { IPatientExternalDataListItem } from './types'
import { PatientExternalDataStore } from './PatientExternalDataStore'

export class PatientExternalDataListItem implements IPatientExternalDataListItem {
  store: PatientExternalDataStore

  id: string
  type: 'patientExternalDataList'
  attributes: IPatientExternalDataListItem['attributes']

  constructor(store: PatientExternalDataStore, entity: IPatientExternalDataListItem) {
    makeAutoObservable(this, {
      store: false
    })

    this.store = store

    this.id = entity.id
    this.type = entity.type
    this.attributes = { ...entity.attributes }
  }
}
