import { LocationState } from '../components/PatientRouter/types'

export const handleBackButton = (defaultPath: string, history: any) => {
  const location = history.location as { state: LocationState }
  if (location.state?.from) {
    history.push(location.state?.from)
  } else {
    history.push(defaultPath)
  }
}
