import { makeAutoObservable } from 'mobx'

import { IAdminHospitalDefaultDoseSettings } from './types'
import { AdminHospitalDetailsStore } from './AdminHospitalDetailsStore'

export class AdminHospitalDefaultDoseSettings implements IAdminHospitalDefaultDoseSettings {
  store: AdminHospitalDetailsStore

  id: string
  type: string
  attributes: IAdminHospitalDefaultDoseSettings['attributes']

  constructor(store: AdminHospitalDetailsStore, entity: IAdminHospitalDefaultDoseSettings) {
    makeAutoObservable(this, {
      store: false
    })

    this.store = store

    this.id = entity.id
    this.type = entity.type
    this.attributes = { ...entity.attributes }
  }
}
