import { makeAutoObservable } from 'mobx'

import axiosClient, { AxiosError, AxiosResponse } from '../../utils/axiosClient'
import { versionsGetUrl } from '../../constants/api'
import { RootStore } from '../RootStore'
import { TLoadState } from '../types'
import { Versions } from './Versions'
import { IVersions } from './types'

export class VersionsStore {
  rootStore: RootStore

  loadState: TLoadState = 'initial'
  error: string = ''

  versions: Versions | null = null

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false
    })

    this.rootStore = rootStore
  }

  setVersions(versionsAttrs: IVersions) {
    const versions = new Versions(this, versionsAttrs)
    this.versions = versions

    return versions
  }

  setLoadState(loadState: TLoadState) {
    this.loadState = loadState
  }

  setError(errorState: 'loadError', error: string) {
    this.error = error
    this.setLoadState(errorState)
  }

  async fetchVersions() {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .get<AxiosResponse<IVersions>>(versionsGetUrl(), { headers })
      .then((response: AxiosResponse) => {
        this.setVersions(response.data.data)
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
      })
  }
}
