import { makeAutoObservable } from 'mobx'

import { IAdminHospitalDrugListItem } from './types'
import { AdminHospitalDrugListStore } from './AdminHospitalDrugListStore'

export class AdminHospitalDrugListItem implements IAdminHospitalDrugListItem {
  store: AdminHospitalDrugListStore

  id: string
  type: 'adminHospitalDrugModelSummary'
  attributes: IAdminHospitalDrugListItem['attributes']

  constructor(store: AdminHospitalDrugListStore, entity: IAdminHospitalDrugListItem) {
    makeAutoObservable(this, {
      store: false
    })

    this.store = store

    this.id = entity.id
    this.type = entity.type
    this.attributes = { ...entity.attributes }
  }
}
