import { useEffect } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { LoginPage } from './components/LoginPage'
import { TwoFAPage } from './components/TwoFAPage'
import { TwoFASetupPage } from './components/TwoFASetupPage'
import { ResetPasswordPage } from './components/ResetPasswordPage'
import { ForgotPasswordPage } from './components/ForgotPasswordPage'
import { useAuthStore, useBreadcrumbsStore } from '../../hooks/useStore'
import { SetPasswordPage } from './components/SetPasswordPage'

import './index.scss'

export const AuthRouter: React.FC = observer((props) => {
  const breadcrumbsStore = useBreadcrumbsStore()
  const authStore = useAuthStore()

  const history = useHistory()

  useEffect(() => {
    // Only trigger session refresh once on page load
    if (authStore.loadState === 'initial') {
      refreshDoseMeSession()
    }

    breadcrumbsStore.setBreadcrumbs([])
  }, [])

  const refreshDoseMeSession = async () => {
    await authStore.authSession()

    if (authStore.isAuthenticated()) {
      history.push('/patients')
    }
  }

  return (
    <div id='login-wrapper' className='login-wrapper'>
      <Switch>
        {/* Login Page */}
        <Route exact path='/login' render={() => <LoginPage />} />

        {/* Forgot Password Page */}
        <Route exact path='/forgot_password' render={() => <ForgotPasswordPage />} />

        {/* Set Password Page */}
        <Route exact path='/set_password' render={() => <SetPasswordPage />} />

        {/* Reset Password Page */}
        <Route exact path='/reset_password' render={() => <ResetPasswordPage />} />

        {/* TwoFASetup Page */}
        <Route exact path='/twofasetup' render={() => <TwoFASetupPage />} />

        {/* TwoFA Page */}
        <Route exact path='/twofa' render={() => <TwoFAPage />} />

        {/* Redirect */}
        <Route
          exact
          path='*'
          render={() =>
            <Redirect to={{ pathname: '/login' }} />
          }
        />
      </Switch>
    </div>
  )
})
