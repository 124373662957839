import { IFormField } from '../../../../../../../../../../types/validation'
import { stringToFloat, timeStateToTimeString } from '../../../../../../../../../../utils/validation/formatters'
import {
  isStringWithinMaxNumericLimit,
  isStringWithinMinNumericLimit,
  isRequired,
  isStringValidNumber,
  isTimeStateDSTValid,
  isValidTimeState
} from '../../../../../../../../../../utils/validation/rules'

export const modalHDSessionDates = (nextDoseAt: Date, quantity: number): Date[] => {
  const dayInMilliseconds = 86400000

  return Array.from({ length: quantity }, (_, i) => {
    return new Date(nextDoseAt.getTime() + i * dayInMilliseconds)
  })
}

export const simulateHDScheduleFormFields = (date: Date): Record<string, IFormField> => {
  return {
    timeAdministered: {
      initialInput: { hh: '', mm: '' },
      initialConstraints: {
        date: date
      },
      rules: [isRequired, isValidTimeState, isTimeStateDSTValid],
      formatter: timeStateToTimeString
    },
    duration: {
      initialInput: null,
      initialConstraints: {
        max: {
          value: 24
        },
        min: {
          value: 0.02
        }
      },
      rules: [isRequired, isStringValidNumber, isStringWithinMaxNumericLimit, isStringWithinMinNumericLimit],
      formatter: stringToFloat
    }
  }
}
