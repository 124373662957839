import { IHeaderItem, ITooltipMenuData } from '@doseme/cohesive-ui'

import { IAdminVendorListItem } from '../../../../store/Admin/AdminVendorList/types'

export const itemsPerPage = 10

export const vendorListColumns = (handleSort: (colIndex: number, ascending: boolean) => void, screenWidth: number): IHeaderItem[] => {
  if (screenWidth <= 1020) {
    return [
      {
        name: 'Vendor name',
        handleSort,
        width: 5
      },
      {
        name: 'No. of sites',
        width: 2
      },
      {
        name: '',
        width: 1
      }
    ]
  }

  return  [
    {
      name: 'Vendor name',
      handleSort,
      width: 5
    },
    {
      name: 'Key',
      width: 5
    },
    {
      name: 'No. of sites',
      width: 2
    },
    {
      name: '',
      width: 1
    }
  ]}

export const vendorActionData = (vendor: IAdminVendorListItem, handleEditVendor: (vendorId: string) => void, handleDeleteVendor: (vendor: IAdminVendorListItem) => void): ITooltipMenuData[] => {
  const actionData = [
    {
      id: 1,
      value: 'Edit Vendor details',
      onSelect: () => handleEditVendor(vendor.id)
    }
  ]

  if (vendor.attributes.numberOfSites === 0) {
    return actionData.concat({
      id: 2,
      value: 'Delete vendor',
      onSelect: () => handleDeleteVendor(vendor)
    })
  }

  return actionData
}
