import { makeAutoObservable } from 'mobx'

import axiosClient, { AxiosError, AxiosResponse, CancelTokenSource, isAxiosError, isCancel, CancelToken } from '../../utils/axiosClient'
import {
  patientAddGetUrl,
  patientEditGetPutUrl,
  patientGetPutUrl,
  patientImportPostUrl,
  patientSearchHospitalUrl,
  patientsListGetUrl
} from '../../constants/api'
import { RootStore } from '../RootStore'
import { TLoadState } from '../types'
import { AddPatient } from './AddPatient'
import { EditPatient } from './EditPatient'
import { Patient } from './Patient'
import {
  IAddPatient,
  IEditPatient,
  IPatient,
  IAddEditPatientResponse,
  IPostAddPatient,
  IPutEditPatient,
  IImportPatientResponse
} from './types'

export class PatientStore {
  rootStore: RootStore

  cancelToken?: CancelTokenSource = undefined
  loadState: TLoadState = 'initial'
  error: string = ''
  longIdLoadState: TLoadState = 'initial'

  patient: Patient | null = null
  addPatient: AddPatient | null = null
  editPatient: EditPatient | null = null

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false
    })

    this.rootStore = rootStore
  }

  setPatient(patientAttrs: IPatient) {
    const patient = new Patient(this, this.rootStore.patientCoursesStore, patientAttrs)
    this.patient = patient

    return patient
  }

  resetPatient() {
    this.patient = null
    this.loadState = 'initial'
  }

  setCancelToken(cancelToken?: CancelTokenSource) {
    this.cancelToken = cancelToken
  }

  setLoadState(loadState: TLoadState) {
    this.loadState = loadState
  }

  setError(errorState: 'loadError' | 'updateError', error: string) {
    this.error = error
    this.setLoadState(errorState)
  }

  async fetchPatient(patientId: string) {
    this.setLoadState('loading')
    if (this.cancelToken) {
      this.cancelToken.cancel()
    }
    const cancelToken = CancelToken.source()
    this.setCancelToken(cancelToken)

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .get<AxiosResponse<IPatient>>(patientGetPutUrl(patientId), { headers, cancelToken: cancelToken?.token })
      .then((response: AxiosResponse) => {
        this.setCancelToken()
        this.setPatient(response.data.data)
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        if (isCancel(error)) {
          return
        }
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
      })
  }

  setLongIdLoadState(loadState: TLoadState) {
    this.longIdLoadState = loadState
  }

  async searchHospitalPatient(hospitalId: string, patientLongId: string) {
    this.setLongIdLoadState('loading')
    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    return await axiosClient
      .get<AxiosResponse<IPatient>>(patientSearchHospitalUrl(hospitalId, patientLongId), { headers })
      .then((response: AxiosResponse) => {
        this.setPatient(response.data.data)
        this.setLongIdLoadState('loaded')

        return response.data
      })
      .catch((error: Error | AxiosError) => {
        // 404 is expected for any patient long id that doesn't exist
        if (isAxiosError(error) && error.response?.status === 404) {
          this.setLongIdLoadState('loaded')

          return null
        }

        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
      })
  }

  setAddPatient(addPatient: IAddPatient) {
    this.resetAddPatient()
    this.addPatient = new AddPatient(this, addPatient)
  }

  resetAddPatient() {
    this.addPatient = null
  }

  async fetchAddPatient(hospitalId: string) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .get<AxiosResponse<IAddPatient>>(patientAddGetUrl(hospitalId), { headers })
      .then((response: AxiosResponse) => {
        this.setAddPatient(response.data.data)
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
      })
  }

  async postAddPatient(hospitalId: string, addPatientRequest: IPostAddPatient): Promise<IPatient | null> {
    this.setLoadState('updating')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    return await axiosClient
      .post(patientsListGetUrl(hospitalId), { data: addPatientRequest }, { headers })
      .then((response: AxiosResponse<IAddEditPatientResponse>) => {
        this.setPatient(response.data.data)
        this.setLoadState('loaded')

        return response.data.data
      })
      .catch((error: Error | AxiosError) => {
        this.setLoadState('updateError')

        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('updateError', loggableError)

        return null
      })
  }

  async postImportPatient(parsedObject: string) {
    this.setLoadState('updating')

    const headers = {
      Accept: 'application/json'
    }

    return await axiosClient
      .post(patientImportPostUrl(), parsedObject, { headers })
      .then((response: AxiosResponse<IImportPatientResponse>) => {
        this.setLoadState('loaded')

        return response.data.data
      })
      .catch((error) => {
        this.setLoadState('updateError')

        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('updateError', loggableError)

        return null
      })
  }

  setEditPatient(editPatient: IEditPatient) {
    this.resetEditPatient()
    this.editPatient = new EditPatient(this, editPatient)
  }

  resetEditPatient() {
    this.editPatient = null
  }

  async fetchEditPatient(patientId: string) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .get<AxiosResponse<IEditPatient>>(patientEditGetPutUrl(patientId), { headers })
      .then((response: AxiosResponse) => {
        this.setEditPatient(response.data.data)
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
      })
  }

  async putEditPatient(patientId: string, editPatientRequest: IPutEditPatient): Promise<IPatient | null> {
    this.setLoadState('updating')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    return await axiosClient
      .put(patientGetPutUrl(patientId), { data: editPatientRequest }, { headers })
      .then((response: AxiosResponse<IAddEditPatientResponse>) => {
        this.setPatient(response.data.data)
        this.fetchEditPatient(patientId)

        return response.data.data
      })
      .catch((error: Error | AxiosError) => {
        this.setLoadState('updateError')

        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('updateError', loggableError)

        return null
      })
  }
}
