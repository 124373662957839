import { IClinicianHospitalListItem } from '../../../../store/ClinicianStore/types'

export const searchClinicianHospitals = (
  searchText: string,
  clinicianHospitals: IClinicianHospitalListItem[],
  currentHospitalId?: string
): IClinicianHospitalListItem[] => {
  return clinicianHospitals.reduce((acc: IClinicianHospitalListItem[], curr) => {
    if (curr.name.toLowerCase().includes(searchText.toLowerCase()) && curr.id !== currentHospitalId) {
      return acc.concat({
        name: curr.name,
        id: curr.id,
        isAdmin: curr.isAdmin
      })
    }

    return acc
  }, [])
}
