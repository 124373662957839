import { ObservableMap } from 'mobx'

import { IAdminVendorSiteListItem } from '../../../../../store/Admin/AdminVendorSiteList/types'

export const searchVendorSites = (
  searchText: string,
  vendorSites: ObservableMap<string, IAdminVendorSiteListItem>
): IAdminVendorSiteListItem[] => {
  return [...vendorSites].reduce((acc: IAdminVendorSiteListItem[], [key, curr]) => {
    if (curr.attributes.key.toLowerCase().includes(searchText.toLowerCase())
      || curr.attributes.hospital.name.toLowerCase().includes(searchText.toLowerCase())) {
      return acc.concat(curr)
    }

    return acc
  }, [])
}
