import { decode } from 'he'

// Capitalize the error message, and make sure it ends with a period.
export const formatErrorMessage = (rawError: string): string => {
  const formatterError = () : string => {

    if (rawError.length < 1) {
      return rawError
    }

    const capitalized = rawError.charAt(0).toUpperCase() + rawError.slice(1)

    if (capitalized.charAt(capitalized.length - 1) === '.') {
      return capitalized
    }

    return capitalized.concat('.')
  }

  return decode(formatterError())
}
