import { makeAutoObservable } from 'mobx'

import { AuthStore } from './AuthStore'
import { IAuth2FASetUp } from './types'

export class TwoFASetUp implements IAuth2FASetUp {
  store: AuthStore

  type: 'authLogin2faSetup'
  attributes: IAuth2FASetUp['attributes']

  constructor(store: AuthStore, entity: IAuth2FASetUp) {
    makeAutoObservable(this, {
      store: false
    })

    this.store = store

    this.type = entity.type

    this.attributes = { ...entity.attributes }
  }
}
