import { ObservableMap } from 'mobx'

import { IAdminHospitalDrugListItem } from '../../../../../../store/Admin/AdminHospitalDrugList/types'

export const searchAdminHospitalDrugs = (
  searchText: string,
  hospitalDrugs: ObservableMap<string, IAdminHospitalDrugListItem>
): IAdminHospitalDrugListItem[] => {
  return [...hospitalDrugs].reduce((acc: IAdminHospitalDrugListItem[], [key, curr]) => {
    if (curr.attributes.name.toLowerCase().includes(searchText.toLowerCase())) {
      return acc.concat({
        id: curr.id,
        type: curr.type,
        attributes: {
          name: curr.attributes.name,
          defaultDosingMethod: {
            label: curr.attributes.defaultDosingMethod.label,
            value: curr.attributes.defaultDosingMethod.value
          },
          molecule: {
            name: curr.attributes.molecule.name,
            id: curr.attributes.molecule.id
          },
          isValidated: curr.attributes.isValidated,
          isNew: curr.attributes.isNew
        }
      })
    }

    return acc
  }, [])
}
