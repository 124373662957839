import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  DOSEME_BLUE,
  Icons,
  InfoBubble,
  LOADING_GREY,
  TextInput,
  WHITE
} from '@doseme/cohesive-ui'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { observer } from 'mobx-react-lite'

import { useFormValidation } from '../../../../hooks/useFormValidation'
import { IFormField, IFormState } from '../../../../types/validation'
import { IPasswordRulesCheck } from '../../../../shared/checkPassword/types'
import { checkPasswordRules, checkPasswordValid } from '../../../../shared/checkPassword'
import { usePasswordRulesStore } from '../../../../hooks/useStore'

import '../../index.scss'

interface IProps {
  savePassword: (form: IFormState) => {}
  passwordUpdateError?: string
  isOnboardingPage?: boolean
}

export const PasswordPanel: React.FC<IProps> = observer((props) => {
  const [passwordCheck, setPasswordCheck] = useState<IPasswordRulesCheck>({
    upperCaseLetter: false,
    lowerCaseLetter: false,
    number: false,
    characterLength: false,
    passwordsMatch: false
  })
  const [isShowNewPasswordChecked, setIsShowNewPasswordChecked] = useState<boolean>(false)
  const [isShowConfirmPasswordChecked, setIsShowConfirmPasswordChecked] = useState<boolean>(false)

  const resetPasswordFormFields: Record<string, IFormField> = {
    newPassword: {
      initialInput: '',
      rules: []
    },
    confirmNewPassword: {
      initialInput: '',
      rules: []
    }
  }

  const form = useFormValidation(resetPasswordFormFields)
  const passwordRulesStore = usePasswordRulesStore()

  useEffect(() => {
    if (passwordRulesStore.loadState === 'initial') {
      passwordRulesStore.getPasswordRules()
    }
  }, [])

  const handleNewPasswordChange = (newPassword: string) => {
    if (passwordRulesStore.passwordRules?.attributes) {
      const newPasswordCheck = checkPasswordRules(
        newPassword,
        form.inputs['confirmNewPassword'],
        passwordRulesStore.passwordRules.attributes
      )

      setPasswordCheck(newPasswordCheck)
    }
  }

  const handleConfirmPasswordChange = (confirmPassword: string) => {
    if (passwordRulesStore.passwordRules?.attributes) {
      const newPasswordCheck = checkPasswordRules(
        form.inputs['newPassword'],
        confirmPassword,
        passwordRulesStore.passwordRules.attributes
      )

      setPasswordCheck(newPasswordCheck)
    }
  }

  const handleEnter = (event: React.FormEvent) => {
    event.preventDefault()
    if (isPasswordValid()) {
      props.savePassword(form)
    }
  }

  const newPasswordInputContent = (): JSX.Element => {
    return (
      <>
        <div className='login-page-password-label'>
          <div className='login-page-email-input-label'>{props.isOnboardingPage ? 'Password:' : 'New password:'}</div>
          <div className='login-page-show-password' onClick={() => setIsShowNewPasswordChecked(!isShowNewPasswordChecked)}>
            <input
              className='login-page-show-password'
              type='checkbox'
              checked={isShowNewPasswordChecked}
              readOnly
            />
            <div className='login-page-show-password-label'>Show password</div>
          </div>
        </div>
        <form onSubmit={handleEnter}>
          <TextInput
            key='newPassword'
            showCapsLock={true}
            type={isShowNewPasswordChecked ? 'text' : 'password'}
            fieldState={form.getValidState('newPassword')}
            value={form.inputs['newPassword']}
            name={'newPassword'}
            onChange={(value) => {
              form.validateFields([
                {
                  field: 'newPassword',
                  input: value
                }
              ])
              handleNewPasswordChange(value)
            }}
            onBlur={() => form.updateFieldsDisplay(['newPassword'])}
          />
        </form>
        <div className='login-page-password-label login-page-password-label-confirm'>
          <div className='login-page-email-input-label'>Confirm password:</div>
          <div className='login-page-show-password' onClick={() => setIsShowConfirmPasswordChecked(!isShowConfirmPasswordChecked)}>
            <input
              type='checkbox'
              checked={isShowConfirmPasswordChecked}
              readOnly
            />
            <div className='login-page-show-password-label'>Show password</div>
          </div>
        </div>
        <form onSubmit={handleEnter}>
          <TextInput
            key='confirmNewPassword'
            showCapsLock={true}
            type={isShowConfirmPasswordChecked ? 'text' : 'password'}
            fieldState={form.getValidState('confirmNewPassword')}
            value={form.inputs['confirmNewPassword']}
            name={'confirmNewPassword'}
            onChange={(value) => {
              form.validateFields([
                {
                  field: 'confirmNewPassword',
                  input: value
                }
              ])
              handleConfirmPasswordChange(value)
            }}
            onBlur={() => form.updateFieldsDisplay(['confirmNewPassword'])}
          />
        </form>
      </>
    )
  }

  const passwordValidityContent = (): JSX.Element => {
    if (passwordRulesStore.loadState === 'loaded' && passwordRulesStore.passwordRules?.attributes) {
      const passwordRules = passwordRulesStore.passwordRules.attributes

      return (
        <div className='reset-password-validation'>
          <div className='reset-password-validation-title'>Passwords must contain:</div>
          {passwordRules.requiresUpperCase &&
            <div className='reset-password-validation-row'>
              {passwordCheck.upperCaseLetter ? <Icons.SuccessCircle /> : <Icons.UnavailableCircle />}
              <div className='reset-password-validation-text'>One upper-case letter</div>
            </div>
          }
          {passwordRules.requiresLowerCase &&
            <div className='reset-password-validation-row'>
              {passwordCheck.lowerCaseLetter ? <Icons.SuccessCircle /> : <Icons.UnavailableCircle />}
              <div className='reset-password-validation-text'>One lower-case letter</div>
            </div>
          }
          {passwordRules.requiresNumOrSymbol &&
            <div className='reset-password-validation-row'>
              {passwordCheck.number ? <Icons.SuccessCircle /> : <Icons.UnavailableCircle />}
              <div className='reset-password-validation-text'>One number or symbol</div>
            </div>
          }
          <div className='reset-password-validation-row'>
            {passwordCheck.characterLength ? <Icons.SuccessCircle /> : <Icons.UnavailableCircle />}
            <div className='reset-password-validation-text'>
              {`Between ${passwordRules.minLength} and ${passwordRules.maxLength} characters`}
            </div>
          </div>
          <div className='reset-password-validation-row'>
            {passwordCheck.passwordsMatch ? <Icons.SuccessCircle /> : <Icons.UnavailableCircle />}
            <div className='reset-password-validation-text'>Passwords match</div>
          </div>
        </div>
      )
    }

    return (
      <div className='reset-password-page-spinner'>
        <Icons.ThinSpinner strokeWidth={12} r={32} stroke={LOADING_GREY} width='64px' />
      </div>
    )
  }

  const isPasswordValid = () => {
    if (passwordRulesStore.passwordRules?.attributes) {
      return checkPasswordValid(passwordCheck, passwordRulesStore.passwordRules.attributes)
    }

    return false
  }

  return (
    <div className='reset-password-page'>
      {newPasswordInputContent()}
      {props.passwordUpdateError && (
        <div className='reset-password-error'>
          <InfoBubble type='error' bubbleTitle={props.passwordUpdateError} borderRadius={6} />
        </div>
      )}
      {passwordValidityContent()}
      <Button onClick={() => props.savePassword(form)} variant='primary-alt' disabled={!isPasswordValid()}>
        {props.isOnboardingPage ? 'Save & log in' : 'Save password'}&nbsp;
        <FontAwesomeIcon color={isPasswordValid() ? DOSEME_BLUE : WHITE} icon={faArrowRight} />
      </Button>
    </div>
  )
})
