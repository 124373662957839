import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'

import './index.scss'

interface IProps {
  onDismiss: () => void
  index: number
  arraySize: number
}

export const AddAdminObsPanel: React.FC<IProps> = (props) => {
  return (
    <div
      className={classnames('add-admin-obs-panel-outer', {
        'bottom-border': props.arraySize > 1 && props.index !== props.arraySize - 1
      })}
    >
      <div className='add-admin-obs-panel-number'>
        <span className='text'>{props.index + 1}</span>
      </div>
      <div className='add-admin-obs-panel-inner'>{props.children}</div>
      <div className='add-admin-obs-close-panel-button'>
        {props.arraySize > 1 && (
          <button
            className='dismiss-button float-right'
            data-testid={`modal-dismiss-btn-${props.index}`}
            onClick={props.onDismiss}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        )}
      </div>
    </div>
  )
}
