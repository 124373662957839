import { makeAutoObservable } from 'mobx'

import { IAdminHospitalCoreDetails } from './types'
import { AdminHospitalDetailsStore } from './AdminHospitalDetailsStore'

export class AdminHospitalCoreDetails implements IAdminHospitalCoreDetails {
  store: AdminHospitalDetailsStore

  id: string
  type: string
  attributes: IAdminHospitalCoreDetails['attributes']

  constructor(store: AdminHospitalDetailsStore, entity: IAdminHospitalCoreDetails) {
    makeAutoObservable(this, {
      store: false
    })

    this.store = store

    this.id = entity.id
    this.type = entity.type
    this.attributes = { ...entity.attributes }
  }
}
