import { IBreadcrumb } from '@doseme/cohesive-ui'
import { makeAutoObservable } from 'mobx'

import { RootStore } from '../RootStore'

export class BreadcrumbsStore {
  rootStore: RootStore

  breadcrumbs: IBreadcrumb[] = []

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false
    })

    this.rootStore = rootStore
  }

  setBreadcrumbs(breadcrumbs: IBreadcrumb[]) {
    this.breadcrumbs = breadcrumbs
  }

  resetBreadcrumbs() {
    this.breadcrumbs = []
  }
}
