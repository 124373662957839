import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { SidePanel, Icons, ActionButton } from '@doseme/cohesive-ui'
import { Tooltip } from 'react-tooltip'

import { useCourseFeaturesStore, useCourseStore } from '../../../../../../../../hooks/useStore'
import { UpdateCourseFeaturesModal } from '../UpdateCourseFeaturesModal'

import './index.scss'

interface IProps {
  patientId: string
  courseId: string
  drugModelId: string
}

export const CourseFeaturesPanel: React.FC<IProps> = observer((props) => {
  const [showCourseFeaturesModal, setShowCourseFeaturesModal] = useState(false)

  const courseFeaturesStore = useCourseFeaturesStore()
  const courseStore = useCourseStore()

  useEffect(() => {
    if (courseStore.loadState === 'loaded') {
      if (courseFeaturesStore.loadState === 'initial') {
        courseFeaturesStore.fetchCourseFeatures(props.patientId, props.courseId)
      }
    }
  }, [courseStore.loadState])

  const panelActionButton: JSX.Element = (
    <div>
      <ActionButton
        data-testid='complete-course-data-btn'
        actionType='edit'
        disabled={courseFeaturesStore.courseFeatures.size === 0 || !!courseStore.course?.attributes.courseArchived || !!courseStore.course?.attributes.isReadOnly}
        onClick={() => setShowCourseFeaturesModal(true)}
      />
    </div>
  )

  const getPanelContent = (): JSX.Element[] | JSX.Element => {
    const content = [...courseFeaturesStore.courseFeatures].map(([key, courseFeature]) => {
      const info = courseFeature.attributes.description ? (
        <div className='course-variable-info-tooltip'>
          <Tooltip
            id={`course-variable-info-tip-${key}`}
            place='top'
            className='course-variables-tooltip-width'
          >
            {courseFeature.attributes.description}
          </Tooltip>

          <div data-tooltip-id={`course-variable-info-tip-${key}`}>
            <Icons.Info />
          </div>
        </div>
      ) : null

      if (!courseFeature.attributes.currentValue) {
        const warningIcon = courseFeature.attributes.requiresReview ? (
          <div className='side-panel-warning-icon'>
            <Icons.Alert />
          </div>
        ) : null

        return (
          <div key={`course-feature-${courseFeature.id}`} className='mt-2 d-flex flex-row'>
            <div className='font-bold dark-label-text mr-1'>
              {courseFeature.attributes.label === 'Indication'
                ? courseFeature.attributes.label + ' (Guideline Only)'
                : courseFeature.attributes.label}
              :
            </div>
            {info}
            <div
              className={courseFeature.attributes.requiresReview ? 'side-panel-required-warning-text' : 'side-panel-text'}
            >
              Unknown
            </div>
            {warningIcon}
          </div>
        )
      }

      return (
        <div key={`course-feature-${courseFeature.id}`} className='mt-2 d-flex flex-row'>
          <span className='font-bold dark-label-text mr-1'>{courseFeature.attributes.label}: </span>
          {info}
          {courseFeature.attributes.currentValue}
          {courseFeature.type === 'text' && courseFeature.attributes.units ? (
            <span className='ml-1 d-inline-block'>{courseFeature.attributes.units}</span>
          ) : null}
        </div>
      )
    })

    if (content.length) {
      return content
    }

    return <div>N/A</div>
  }

  return (
    <div id='course-features-panel'>
      <UpdateCourseFeaturesModal
        show={showCourseFeaturesModal}
        patientId={props.patientId}
        courseId={props.courseId}
        drugModelId={props.drugModelId}
        setShow={setShowCourseFeaturesModal}
      />
      <SidePanel
        title='Course Variables'
        loading={['loading', 'initial', 'updating'].includes(courseFeaturesStore.loadState)}
        errorMessage={courseFeaturesStore.loadState === 'loadError' ? 'Failed to load' : ''}
        actionButton={panelActionButton}
      >
        <div>{getPanelContent()}</div>
      </SidePanel>
    </div>
  )
})
