import { Accordion, BackArrowButton } from '@doseme/cohesive-ui'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useBreadcrumbsStore } from '../../../../hooks/useStore'
import { handleBackButton } from '../../../../utils/navigation'
import { getResourcesRoutePrefix } from '../../utils'

import './index.scss'

interface IProps {
  patientId?: string
}

export const HelpVideos: React.FC<IProps> = (props) => {
  const history = useHistory()
  const breadcrumbsStore = useBreadcrumbsStore()

  useEffect(() => {
    const breadcrumbs = [
      { label: 'Resources', onClick: () => handleBackButton(`/patients/${props.patientId}/resources`, history) },
      { label: 'Help videos' }
    ]
    breadcrumbsStore.setBreadcrumbs(breadcrumbs)
  }, [])

  const accordChildData = [
    {
      title: 'Introduction to DoseMeRx – Navigation and Layout',
      data: (
        <>
          <div className='help-videos-section-title'>
            Explore how to easily navigate DoseMeRx including the patient profile page, course list, and dosing profile.
          </div>
          <div className='help-videos-video' style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
            <iframe
              src='https://player.vimeo.com/video/679106706?h=bda1c71134&'
              frameBorder='0'
              allow='autoplay; fullscreen; picture-in-picture'
              allowFullScreen
              style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
              title='01 Introduction to DoseMeRx- Navigation and Layout'
            >
            </iframe>
          </div>
        </>
      )
    },
    {
      title: 'How to Add, Edit, and Review Data',
      data: (
        <>
          <div className='help-videos-section-title'>
            Learn how DoseMeRx handles your EHR data, including how you can manually add and edit patient demographics,
            doses and observations.
          </div>
          <div className='help-videos-video' style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
            <iframe
              src='https://player.vimeo.com/video/679289035?h=c2a0935413&'
              frameBorder='0'
              allow='autoplay; fullscreen; picture-in-picture'
              allowFullScreen
              style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
              title='02 Add Edit and Review Data'
            >
            </iframe>
          </div>
        </>
      )
    },
    {
      title: 'How to Interpret the Recorded Course Data Panel',
      data: (
        <>
          <div className='help-videos-section-title'>
            See how to review your patient's doses, observations and predicted outcomes.
          </div>
          <div className='help-videos-video' style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
            <iframe
              src='https://player.vimeo.com/video/679547739?h=293607056d&'
              frameBorder='0'
              allow='autoplay; fullscreen; picture-in-picture'
              allowFullScreen
              style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
              title='03 How to interpret the recorded course data panel'
            >
            </iframe>
          </div>
        </>
      )
    },
    {
      title: 'How to Calculate a First Dose and Loading Dose',
      data: (
        <>
          <div className='help-videos-section-title'>
            See how to easily start a course for your patient, including how to generate a dosing report to download or
            save back to your system.
          </div>
          <div className='help-videos-video' style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
            <iframe
              src='https://player.vimeo.com/video/679807068?h=59a57e3ae5&'
              frameBorder='0'
              allow='autoplay; fullscreen; picture-in-picture'
              allowFullScreen
              style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
              title='04 How to calculate a first dose - loading dose'
            >
            </iframe>
          </div>
        </>
      )
    },
    {
      title: 'How to Calculate a Maintenance Dose and use the Next Dose Panel',
      data: (
        <>
          <div className='help-videos-section-title'>
            Explore the different features of DoseMeRx to calculate a dosing recommendation, including an individualized
            or custom simulation.
          </div>
          <div className='help-videos-video' style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
            <iframe
              src='https://player.vimeo.com/video/680230363?h=8085f4c263&'
              frameBorder='0'
              allow='autoplay; fullscreen; picture-in-picture'
              allowFullScreen
              style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
              title='05 How to calculate a maintenance dose - next dose panel'
            >
            </iframe>
          </div>
        </>
      )
    }
  ]

  return (
    <div data-testid='help-videos' className='co-resource-page'>
      <div className='d-flex'>
        <div className='resource-back-btn'>
          <BackArrowButton data-testid='back-btn' onClick={() => handleBackButton(getResourcesRoutePrefix(props.patientId), history)} />
        </div>
        <div className='resource-title help-videos'>Help Videos</div>
      </div>

      <div className='resource-panel'>
        <div className='resource-panel-title'>
          Watch our short how-to videos to assist in getting to know your way around DoseMeRx.
        </div>
        <div>
          {window.env.VENDOR_MODE === 'standalone' ? (
            <div>
              <div className='help-videos-background'>
                {/* this needs to be replaced with correct video */}
                <>
                  <div className='help-videos-video' style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                    <iframe
                      src='https://player.vimeo.com/video/950752030?h=f3dda1e418&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
                      frameBorder='0'
                      allow='autoplay; fullscreen; picture-in-picture'
                      allowFullScreen
                      style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
                      title='DoseMeRx Web New and Improved Interface Navigation and Use'
                    />
                  </div>
                </>
              </div>
            </div>
          ) : (
            accordChildData.map((item, i) => {
              return (
                <div key={`${item}-${i}`} className='mt-2'>
                  <Accordion type='parent' title={accordChildData[i].title} childData={accordChildData[i].data} />
                </div>
              )
            })
          )}
        </div>
      </div>
    </div>
  )
}
