import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'

// Responsible for creating the appInsights object (and help objects) for sending logs/events to Azure
// See: https://learn.microsoft.com/en-us/azure/azure-monitor/app/api-custom-events-metrics
const reactPlugin = new ReactPlugin()
const appInsights = new ApplicationInsights({
  config: {
    connectionString: window.env.AZURE_APP_INSIGHTS_ID,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: {}
    },
    disableTelemetry: false, // Enable AppInsights telemetry
    disableExceptionTracking: false, // Enable exception tracking
    disableAjaxTracking: true, // Disable XHR/ App Insights 'Dependency' tracking
    disableFetchTracking: true, // Disable fetch calls for App Insights 'Dependency' tracking
    disableCorrelationHeaders: true, // Disable adding headers to XHR/fetch requests for server side tracking
    autoTrackPageVisitTime: false,  // Disable page load time tracking
    enableCorsCorrelation: false, // Disable tracking on any CORS preflight/OPTIONS requests
    enableAutoRouteTracking: false,  // Disable tracking route changes inside SPA
    enableRequestHeaderTracking: false, // Disable tracking request headers
    enableResponseHeaderTracking: false // Disable tracking response headers
  }
})

// Filter out exceptions/EventError caused by ResizeObserver
const telemetryFilterResizeObserver = (envelope: ITelemetryItem) => {
  if (envelope.name && envelope.name.includes('Exception')) {
    if (envelope.baseData && envelope.baseData.exceptions) {
      // exceptions is an array of [1..n] exception events - remove any that are about ResizeObserver
      const filteredExceptions = envelope.baseData.exceptions.filter(
        (e: any) =>  e.message && !e.message.includes('ResizeObserver')
      )
      // Return false if after filtering out all ResizeObserver errors, there are none left to log
      if (filteredExceptions.length === 0) {

        return false
      }

      // Update the envelope with the remaining non ResizeObserver exceptions
      envelope.baseData.exceptions = filteredExceptions
    }
  }

  return true
}

appInsights.loadAppInsights()
// Multiple TelemetryInitializers can be added, they will be executed over each telemetry item in the order added below
appInsights.addTelemetryInitializer(telemetryFilterResizeObserver)
appInsights.addTelemetryInitializer((env:ITelemetryItem) => {
  env.tags = []
  env.tags['ai.cloud.role'] = window.env.INSTALLATION
})

export { reactPlugin, appInsights }
