import { Modal, InfoModal, Button, InfoBubble } from '@doseme/cohesive-ui'

import { IModalProps } from '../../../../../../../../types'

import './index.scss'

interface IProps extends IModalProps {
  continue: () => void
}

export const TDIInfoModal: React.FC<IProps> = (props) => {
  const buttonBar = (
    <div className='d-flex'>
      <Button
        variant='secondary'
        className='mr-2'
        onClick={() => props.setShow(false)}
      >
        Go back
      </Button>
      <Button
        variant='primary-hover'
        onClick={() => {
          props.setShow(false)
          props.continue()
        }}
      >
        I understand, continue
      </Button>
    </div>
  )

  const infoMessage = 'The Dosing Institute provides access to the DoseMeRx platform for teaching and learning purposes only. If you would like to discuss how DoseMeRx can be used in a clinical situation, please contact our support team.'

  return (
    <Modal show={props.show} onHide={() => props.setShow(false)}>
      <InfoModal
        customClassName='TDI-info-modal'
        size='s'
        linkComponent={buttonBar}
        title='Important note'
        message={
          <InfoBubble
            type='info'
            bubbleTitle={'This dose recommendation is for teaching and/or learning purposes only.'}
            borderRadius={6}>
            <div className='TDI-info-message'>
              {infoMessage}
            </div>
          </InfoBubble>
        }
        onDismiss={() => props.setShow(false)}
      />
    </Modal>
  )
}
