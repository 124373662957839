import { InfoModal, Modal, Button } from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'

import { useFormValidation } from '../../../../../../../hooks/useFormValidation'
import { useAdminHospitalDetailsStore } from '../../../../../../../hooks/useStore'
import { showErrorToast, showSuccessToast } from '../../../../../../../shared/toast'
import { IPutAdminHospitalClinicianRoles } from '../../../../../../../store/Admin/AdminHospitalDetails/types'
import { IModalProps } from '../../../../../../PatientRouter/types'
import { buildFormFields, buildInputs } from '../../../../../../../shared/buildForms'

import './index.scss'

interface IProps extends IModalProps {
  hospitalId: string
  hospitalName?: string
}

const HospitalClinicianRolesModal: React.FC<IProps> = observer((props) => {
  const adminHospitalDetailsStore = useAdminHospitalDetailsStore()

  useEffect(() => {
    if (props.show) {
      adminHospitalDetailsStore.fetchAdminHospitalClinicianRoles(props.hospitalId)
    }
  }, [props.hospitalId, props.show])

  const formFields = useMemo(() => {
    if (['loaded', 'updateError'].includes(adminHospitalDetailsStore.settingsLoadStates.clinicianRoles)) {
      return buildFormFields(adminHospitalDetailsStore.adminHospitalClinicianRoles)
    }

    return {}
  }, [adminHospitalDetailsStore.settingsLoadStates.clinicianRoles])

  const form = useFormValidation(formFields)

  useEffect(() => {
    if (adminHospitalDetailsStore.settingsLoadStates.clinicianRoles === 'loaded') {
      form.reset()
    }
  }, [adminHospitalDetailsStore.settingsLoadStates.clinicianRoles])

  const formContent = (): JSX.Element => {
    const displayOrder = ['hospitalWideRoles']

    if (adminHospitalDetailsStore.adminHospitalClinicianRoles) {
      return (
        <div className='position-relative w-100'>
          <div className='clinician-roles-label mb-3 font-weight-bold'>Apply roles to all clinicians at this hospital</div>
          {buildInputs(adminHospitalDetailsStore.adminHospitalClinicianRoles, form, displayOrder, formFields)}
        </div>
      )
    }

    return <></>
  }

  const handleSubmit = async () => {
    const formValues = {
      hospitalWideRoles: form.values['hospitalWideRoles'] || []
    }
    await adminHospitalDetailsStore.putAdminHospitalClinicianRoles(props.hospitalId, formValues as IPutAdminHospitalClinicianRoles)

    if (['loadError', 'updateError'].includes(adminHospitalDetailsStore.settingsLoadStates.clinicianRoles)) {
      showErrorToast(adminHospitalDetailsStore.settingsErrors.clinicianRoles || 'Failed to update Hospital Clinician Roles')

      return
    }

    showSuccessToast('Hospital Clinician Roles updated')
    props.setShow(false)
  }

  const handleClose = () => {
    props.setShow(false)
    form.reset()
  }

  return (
    <Modal
      show={props.show}
      onHide={handleClose}
    >
      <InfoModal
        size='s'
        loading={['loading', 'updating'].includes(adminHospitalDetailsStore.settingsLoadStates.clinicianRoles)}
        linkComponent={
          <>
            <Button
              className='mr-3'
              data-testid='cancel-btn'
              disabled={false}
              loading={['loading', 'updating'].includes(adminHospitalDetailsStore.settingsLoadStates.clinicianRoles)}
              onClick={handleClose}
              variant='primary-outline'
            >
              Cancel
            </Button>
            <Button
              data-testid='save-btn'
              disabled={!form.valid || ['loading', 'updating'].includes(adminHospitalDetailsStore.settingsLoadStates.clinicianRoles)}
              loading={['loading', 'updating'].includes(adminHospitalDetailsStore.settingsLoadStates.clinicianRoles)}
              onClick={handleSubmit}
              variant='primary'
            >
              Save
            </Button>
          </>
        }
        title='Hospital-wide clinician roles'
        subtitle={props.hospitalName}
        message={formContent()}
      />
    </Modal>
  )
})

export { HospitalClinicianRolesModal }
