import { makeAutoObservable } from 'mobx'

import { IClinician } from './types'
import { ClinicianStore } from './ClinicianStore'

export class Clinician implements IClinician {
  store: ClinicianStore

  id: string
  type: string
  attributes: IClinician['attributes']

  constructor(store: ClinicianStore, entity: IClinician) {
    makeAutoObservable(this, {
      store: false
    })

    this.store = store

    this.id = entity.id
    this.type = entity.type
    this.attributes = { ...entity.attributes }
  }
}
