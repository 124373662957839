// Vendor type
export type TVendor = 'standalone' | 'cerner' | 'epic' | 'dss'

export const apiBaseUrl = window.env.API_BASE_URL

// Patient Endpoint
export const patientExternalDataListGetUrl = (patientId: string) => `${apiBaseUrl}/patients/${patientId}/externalData`
export const patientExternalDataDownloadGetUrl = (patientId: string, externalDataId: string) =>
  `${apiBaseUrl}/patients/${patientId}/externalData/${externalDataId}`
export const patientsListGetUrl = (hospitalId: string) => `/api/hospitals/${hospitalId}/patients`
export const patientsListFiltersGetUrl = (hospitalId: string) => `/api/hospitals/${hospitalId}/patients/filters`
export const patientAddGetUrl = (hospitalId: string) => `${apiBaseUrl}/hospitals/${hospitalId}/patients/new`
export const patientEditGetPutUrl = (patientId: string) => `${apiBaseUrl}/patients/${patientId}/edit`
export const patientBulkArchivePutUrl = (hospitalId: string) => `${apiBaseUrl}/hospitals/${hospitalId}/patients/archive`
export const patientBulkUnarchivePutUrl = (hospitalId: string) =>
  `${apiBaseUrl}/hospitals/${hospitalId}/patients/unarchive`
export const patientBulkDeletePutUrl = (hospitalId: string) =>
  `${apiBaseUrl}/hospitals/${hospitalId}/patients/archive/delete`
export const patientSearchHospitalUrl = (hospitalId: string, longId: string) =>
  `${apiBaseUrl}/hospitals/${hospitalId}/patients/search?longid=${longId}`
export const patientGetPutUrl = (patientId: string) => `${apiBaseUrl}/patients/${patientId}`

// Patient Courses Endpoints
export const patientImportPostUrl = () => `${apiBaseUrl}/patients/import`
export const patientCoursesGetUrl = (patientId: string) => `${apiBaseUrl}/patients/${patientId}/courses`
export const patientCoursesPostUrl = (patientId: string) => `${apiBaseUrl}/patients/${patientId}/courses`

// Activity Logs Endpoints
export const activityLogsGetUrl = (patientId: string, courseId: string) =>
  `${apiBaseUrl}/patients/${patientId}/courses/${courseId}/activitylogs`
export const activityLogsPostUrl = (patientId: string, courseId: string) =>
  `${apiBaseUrl}/patients/${patientId}/courses/${courseId}/activitylogs`

// Hospital Endpoint
export const hospitalGetUrl = (hospitalId: string) => `${apiBaseUrl}/hospitals/${hospitalId}`
export const hospitalSwitchGetUrl = (clinicianId: string, hospitalId: string) =>
  `${apiBaseUrl}/clinicians/${clinicianId}/hospitals/${hospitalId}/switch`

// Course Features Endpoints
export const patientCourseFeaturesGetUrl = (patientId: string, courseId: string) =>
  `${apiBaseUrl}/patients/${patientId}/courses/${courseId}/specificfeatures`
export const patientCourseFeaturesPatchUrl = (patientId: string, courseId: string) =>
  `${apiBaseUrl}/patients/${patientId}/courses/${courseId}/specificfeatures`

// Single Patient Course Endpoints
export const courseGetUrl = (patientId: string, courseId: string) =>
  `${apiBaseUrl}/patients/${patientId}/courses/${courseId}`
export const coursePutUrl = (patientId: string, courseId: string) =>
  `${apiBaseUrl}/patients/${patientId}/courses/${courseId}`
export const courseDeleteUrl = (patientId: string, courseId: string) =>
  `${apiBaseUrl}/patients/${patientId}/courses/${courseId}`

// Drug Models Endpoint
export const drugModelsGetUrl = (patientId: string, drugId: string) =>
  `${apiBaseUrl}/patients/${patientId}/drugmodels/molecules/${drugId}`
export const moleculesGetUrl = (patientId: string) => `${apiBaseUrl}/patients/${patientId}/drugmodels/molecules`
export const applicableDrugModelsGetUrl = (patientId: string, courseId: string) =>
  `${apiBaseUrl}/patients/${patientId}/courses/${courseId}/applicabledrugmodels`

// Drug Models Information Endpoint
export const drugModelsInformationGetUrl = (hospitalId: string, patientId?: string) => {
  return patientId
    ? `/api/hospitals/${hospitalId}/drugmodels/information?patient=${patientId}`
    : `/api/hospitals/${hospitalId}/drugmodels/information`
}

// Administration Endpoints
export const adminsGetUrl = (patientId: string, courseId: string) =>
  `${apiBaseUrl}/patients/${patientId}/courses/${courseId}/administrations`
export const adminPostUrl = (patientId: string, courseId: string) =>
  `${apiBaseUrl}/patients/${patientId}/courses/${courseId}/administrations`
export const adminPutUrl = (patientId: string, courseId: string, adminId: string) =>
  `${apiBaseUrl}/patients/${patientId}/courses/${courseId}/administrations/${adminId}`
export const adminDeleteUrl = (patientId: string, courseId: string, adminId: string) =>
  `${apiBaseUrl}/patients/${patientId}/courses/${courseId}/administrations/${adminId}`
export const adminBulkIncludeUrl = (patientId: string, courseId: string) =>
  `${apiBaseUrl}/patients/${patientId}/courses/${courseId}/administrations/bulk/include`
export const adminBulkExcludeUrl = (patientId: string, courseId: string) =>
  `${apiBaseUrl}/patients/${patientId}/courses/${courseId}/administrations/bulk/exclude`

// Observation Endpoints
export const observationGetUrl = (patientId: string, courseId: string) =>
  `/api/patients/${patientId}/courses/${courseId}/observations`
export const observationPostUrl = (patientId: string, courseId: string) =>
  `/api/patients/${patientId}/courses/${courseId}/observations`
export const observationPutUrl = (patientId: string, courseId: string, obsId: string) =>
  `/api/patients/${patientId}/courses/${courseId}/observations/${obsId}`
export const observationDeleteUrl = (patientId: string, courseId: string, obsId: string) =>
  `/api/patients/${patientId}/courses/${courseId}/observations/${obsId}`
export const observationBulkIncludeUrl = (patientId: string, courseId: string) =>
  `${apiBaseUrl}/patients/${patientId}/courses/${courseId}/observations/bulk/include`
export const observationBulkExcludeUrl = (patientId: string, courseId: string) =>
  `${apiBaseUrl}/patients/${patientId}/courses/${courseId}/observations/bulk/exclude`

// Dose Report Endpoint
export const doseReportPostUrl = (patientId: string, courseId: string, drugModelId: string) =>
  `${apiBaseUrl}/patients/${patientId}/courses/${courseId}/drugmodels/${drugModelId}/dosereport`

// Drug Model Dosing recommendation modelling Endpoints
export const dosingRecommendationGetUrl = (patientId: string, courseId: string, drugModelId: string) =>
  `${apiBaseUrl}/patients/${patientId}/courses/${courseId}/drugmodels/${drugModelId}/historical_simulation`
export const dosingRecommendationRXBEGetUrl = (patientId: string, courseId: string, drugModelId: string) =>
  `/api/patients/${patientId}/courses/${courseId}/drugmodels/${drugModelId}/historical_simulation`
export const dosingRecommendationPostUrl = (patientId: string, courseId: string, drugModelId: string) =>
  `${apiBaseUrl}/patients/${patientId}/courses/${courseId}/drugmodels/${drugModelId}/predicted_simulation`
export const dosingRecommendationRXBEPostUrl = (patientId: string, courseId: string, drugModelId: string) =>
  `/api/patients/${patientId}/courses/${courseId}/drugmodels/${drugModelId}/predicted_simulation`

// Dosing Matrix Endpoint
export const dosingMatrixGetUrl = (patientId: string, courseId: string, drugModelId: string) =>
  `${apiBaseUrl}/patients/${patientId}/courses/${courseId}/drugmodels/${drugModelId}/alternative_dosing`

// Constants Endpoints
export const constantsTimezoneGetUrl = () => `${apiBaseUrl}/constants/timezones`

// Auth Endpoint
export const AUTH_POST_URL = `${apiBaseUrl}/auth`
export const AUTH_CHECK_URL = `${apiBaseUrl}/auth_check`
export const AUTH_SESSION_URL = '/auth/session'
export const AUTH_LOGIN_URL = '/auth/login'
export const AUTH_LOGOUT_URL = '/auth/logout'
export const AUTH_RESET_PASSWORD_URL = '/auth/clinicians/reset_password'
export const AUTH_VERIFY_TOKEN = '/auth/clinicians/invite'
export const AUTH_GET_PASSWORD_RULES = '/auth/clinicians/password/rules'
export const AUTH_2FA_URL = '/auth/login/2fa'
export const AUTH_CHECK_SSO = '/auth/check/sso'

// Analytics Endpoint
export const analyticsGetUrl = (hospitalId: string) => `${apiBaseUrl}/hospitals/${hospitalId}/analytics`

// Versions Endpoint
export const versionsGetUrl = () => `${apiBaseUrl}/version`

//Admin ChangeLog Endpoint
export const adminChangeLogGetUrl = () => `${apiBaseUrl}/admin/changelog`

// Admin Hospital Endpoint
export const hospitalPostUrl = () => '/api/admin/hospitals'

// Admin Hospitals Endpoint
export const adminHospitalsGetUrl = () => '/api/admin/hospitals'

// Admin Hospital Endpoint
export const hospitalDuplicatePostUrl = (hospitalId: string) => `${apiBaseUrl}/admin/hospitals/${hospitalId}/duplicate`

// Admin Hospital Details Endpoint
export const adminHospitalDetailsGetUrl = (hospitalId: string) => `${apiBaseUrl}/admin/hospitals/${hospitalId}`

// Admin Hospital Details Settings Modals Endpoints
export const adminHospitalCoreDetailsGetUrl = (hospitalId: string) =>
  `${apiBaseUrl}/admin/hospitals/${hospitalId}/details`
export const adminHospitalDefaultDoseSettingsGetUrl = (hospitalId: string) =>
  `${apiBaseUrl}/admin/hospitals/${hospitalId}/defaultdosesettings`
export const adminHospitalReportSettingsGetUrl = (hospitalId: string) =>
  `${apiBaseUrl}/admin/hospitals/${hospitalId}/reportsettings`
export const adminHospitalClinicianRolesGetUrl = (hospitalId: string) =>
  `${apiBaseUrl}/admin/hospitals/${hospitalId}/clinicianroles`
export const adminHospitalWardsGetUrl = (hospitalId: string) => `${apiBaseUrl}/admin/hospitals/${hospitalId}/wards`

// Admin Hospital Drug Settings
export const adminHospitalDrugModelListGetUrl = (hospitalId: string) =>
  `${apiBaseUrl}/admin/hospitals/${hospitalId}/drugmodels/summary`
export const adminHospitalAddDrugsGetUrl = (hospitalId: string) =>
  `${apiBaseUrl}/admin/hospitals/${hospitalId}/drugmodels/addable`
export const adminHospitalAddDrugsPostUrl = (hospitalId: string) =>
  `${apiBaseUrl}/admin/hospitals/${hospitalId}/drugmodels`

// Admin Hospital Drug Details
export const adminHospitalAddDrugDetailsGetUrl = (hospitalId: string, drugModelId: string) =>
  `${apiBaseUrl}/admin/hospitals/${hospitalId}/drugmodels/${drugModelId}`
export const adminHospitalDrugDetailsDeleteUrl = (hospitalId: string, drugModelId: string) =>
  `${apiBaseUrl}/admin/hospitals/${hospitalId}/drugmodels/${drugModelId}`
export const adminHospitalDrugGeneralModelSettingsGetUrl = (hospitalId: string, drugModelId: string) =>
  `${apiBaseUrl}/admin/hospitals/${hospitalId}/drugmodels/${drugModelId}/settings/general`
export const adminHospitalDrugGeneralModelSettingsPutUrl = (hospitalId: string, drugModelId: string) =>
  `${apiBaseUrl}/admin/hospitals/${hospitalId}/drugmodels/${drugModelId}/settings/general`
export const adminHospitalDrugRecordedCourseDataGetUrl = (hospitalId: string, drugModelId: string) =>
  `${apiBaseUrl}/admin/hospitals/${hospitalId}/drugmodels/${drugModelId}/settings/recordedcoursedatalimits`
export const adminHospitalDrugRecordedCourseDataPutUrl = (hospitalId: string, drugModelId: string) =>
  `${apiBaseUrl}/admin/hospitals/${hospitalId}/drugmodels/${drugModelId}/settings/recordedcoursedatalimits`
export const adminHospitalDrugDoseCalculatedGeneralGetUrl = (hospitalId: string, drugModelId: string) =>
  `${apiBaseUrl}/admin/hospitals/${hospitalId}/drugmodels/${drugModelId}/settings/dosecalculatedgenerallimits`
export const adminHospitalDrugDoseCalculatedGeneralPutUrl = (hospitalId: string, drugModelId: string) =>
  `${apiBaseUrl}/admin/hospitals/${hospitalId}/drugmodels/${drugModelId}/settings/dosecalculatedgenerallimits`
export const adminHospitalDrugDoseCalculatedTargetGetUrl = (hospitalId: string, drugModelId: string) =>
  `${apiBaseUrl}/admin/hospitals/${hospitalId}/drugmodels/${drugModelId}/settings/dosecalculatedtargetlimits`
export const adminHospitalDrugDoseCalculatedTargetPutUrl = (hospitalId: string, drugModelId: string) =>
  `${apiBaseUrl}/admin/hospitals/${hospitalId}/drugmodels/${drugModelId}/settings/dosecalculatedtargetlimits`
export const adminHospitalDrugDoseCalculatedWarningGetUrl = (hospitalId: string, drugModelId: string) =>
  `${apiBaseUrl}/admin/hospitals/${hospitalId}/drugmodels/${drugModelId}/settings/dosecalculatedwarninglimits`
export const adminHospitalDrugDoseCalculatedWarningPutUrl = (hospitalId: string, drugModelId: string) =>
  `${apiBaseUrl}/admin/hospitals/${hospitalId}/drugmodels/${drugModelId}/settings/dosecalculatedwarninglimits`
export const adminHospitalDrugReportTemplatesGetUrl = (hospitalId: string, drugModelId: string) =>
  `${apiBaseUrl}/admin/hospitals/${hospitalId}/drugmodels/${drugModelId}/settings/reporttemplates`
export const adminHospitalDrugReportTemplatesPutUrl = (hospitalId: string, drugModelId: string) =>
  `${apiBaseUrl}/admin/hospitals/${hospitalId}/drugmodels/${drugModelId}/settings/reporttemplates`
export const adminHospitalDrugSuperAdminInfoGetUrl = (hospitalId: string, drugModelId: string) =>
  `${apiBaseUrl}/admin/hospitals/${hospitalId}/drugmodels/${drugModelId}/settings/superadmininfo`
export const adminHospitalDrugSuperAdminInfoPutUrl = (hospitalId: string, drugModelId: string) =>
  `${apiBaseUrl}/admin/hospitals/${hospitalId}/drugmodels/${drugModelId}/settings/superadmininfo`

// Admin Vendor Endpoint
export const adminVendorPostUrl = () => `${apiBaseUrl}/admin/vendors`
export const adminVendorAddGetUrl = () => `${apiBaseUrl}/admin/vendors/new`
export const adminVendorDetailsGetPutUrl = (vendorId: string) => `${apiBaseUrl}/admin/vendors/${vendorId}/details`

// Admin Vendors Endpoint
export const adminVendorsGetUrl = () => `${apiBaseUrl}/admin/vendors`
export const adminVendorsDeleteUrl = (vendorId: string) => `${apiBaseUrl}/admin/vendors/${vendorId}`

// Admin Vendor Site Endpoint
export const adminVendorSitePostUrl = (vendorId: string) => `${apiBaseUrl}/admin/vendors/${vendorId}/sites`
export const adminAddVendorSiteGetUrl = (vendorId: string) => `${apiBaseUrl}/admin/vendors/${vendorId}/sites/new`
export const adminVendorSiteDetailsGetUrl = (vendorId: string, vendorSiteId: string) =>
  `${apiBaseUrl}/admin/vendors/${vendorId}/sites/${vendorSiteId}`
export const adminVendorSiteCoreDetailsGetPutUrl = (vendorId: string, vendorSiteId: string) =>
  `${apiBaseUrl}/admin/vendors/${vendorId}/sites/${vendorSiteId}/details`
export const adminVendorSiteCoreSettingsGetPutUrl = (vendorId: string, vendorSiteId: string) =>
  `${apiBaseUrl}/admin/vendors/${vendorId}/sites/${vendorSiteId}/settings`

// Admin Vendor Sites Endpoint
export const adminVendorSitesGetUrl = (vendorId: string) => `${apiBaseUrl}/admin/vendors/${vendorId}/sites`
export const adminVendorSitesDeleteUrl = (vendorId: string, vendorSiteId: string) =>
  `${apiBaseUrl}/admin/vendors/${vendorId}/sites/${vendorSiteId}`

// Clinician Endpoint
export const clinicianGetUrl = (clinicianId: string) => `${apiBaseUrl}/clinicians/${clinicianId}`
export const clinicianDetailGetPutUrl = (clinicianId: string) => `${apiBaseUrl}/clinicians/${clinicianId}/profile`
export const clinicianPasswordPutUrl = (clinicianId: string) =>
  `${apiBaseUrl}/clinicians/${clinicianId}/profile/password`

// Admin Clinicians Endpoints
export const adminClinicianListGetUrl = () => '/api/admin/clinicians'
export const adminClinicianInviteGetUrl = () => `${apiBaseUrl}/admin/clinicians/invite`
export const adminClinicianInvitePostUrl = () => `${apiBaseUrl}/admin/clinicians/invite`
export const adminClinicianBulkEnablePutUrl = () => `${apiBaseUrl}/admin/clinicians/bulk/enable`
export const adminClinicianBulkDisablePutUrl = () => `${apiBaseUrl}/admin/clinicians/bulk/disable`
export const adminClinicianListFiltersGetUrl = () => '/api/admin/clinicians/filters'

// Admin Clinician Details Endpoints
export const adminClinicianDetailsGetUrl = (clinicianId: string) => `${apiBaseUrl}/admin/clinicians/${clinicianId}`
export const adminClinicianDetailsPutUrl = (clinicianId: string) => `${apiBaseUrl}/admin/clinicians/${clinicianId}`

export const adminClinicianInviteDeleteUrl = (clinicianId: string) =>
  `${apiBaseUrl}/admin/clinicians/${clinicianId}/invite`
export const adminClinicianResendInviteGetUrl = (clinicianId: string) =>
  `${apiBaseUrl}/admin/clinicians/${clinicianId}/invite/resend`

// Admin Clinician Security Endpoints
export const adminClinicianSecurityGetUrl = (clinicianId: string) =>
  `${apiBaseUrl}/admin/clinicians/${clinicianId}/security`
export const adminClinicianSecurityResetDeleteUrl = (clinicianId: string) =>
  `${apiBaseUrl}/admin/clinicians/${clinicianId}/security/2fa`
export const adminClinicianSecurityPasswordPutUrl = (clinicianId: string) =>
  `${apiBaseUrl}/admin/clinicians/${clinicianId}/security/password`

// Admin Clinician Hospitals Endpoints
export const adminClinicianHospitalAccessGetUrl = (clinicianId: string) =>
  `${apiBaseUrl}/admin/clinicians/${clinicianId}/hospitals`
export const adminClinicianAddHospitalAccessPostUrl = (clinicianId: string) =>
  `${apiBaseUrl}/admin/clinicians/${clinicianId}/hospitals`
export const adminClinicianRemoveHospitalAccessDeleteUrl = (clinicianId: string, hospitalId: string) =>
  `${apiBaseUrl}/admin/clinicians/${clinicianId}/hospitals/${hospitalId}`
export const adminClinicianHospitalAdminTogglePutUrl = (clinicianId: string, hospitalId: string) =>
  `${apiBaseUrl}/admin/clinicians/${clinicianId}/hospitals/${hospitalId}`
