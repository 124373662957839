import { IToggleOption } from '@doseme/cohesive-ui'

export const activeArchivedOptions: IToggleOption[] = [
  {
    id: 'active',
    value: 'active',
    label: 'Active'
  },
  {
    id: 'archived',
    value: 'archived',
    label: 'Archived'
  }
]
