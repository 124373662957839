import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'
import { decode } from 'he'
import { InfoModal, Modal, Button } from '@doseme/cohesive-ui'

import { useAdminHospitalDetailsStore, useAdminHospitalDrugDetailsStore } from '../../../../../../../../hooks/useStore'
import { IDrugDetailsModalProps } from '../../types'
import { useFormValidation } from '../../../../../../../../hooks/useFormValidation'
import { buildFormFields, buildInputs } from '../../../../../../../../shared/buildForms'
import { IPutAdminHospitalDrugReportTemplates } from '../../../../../../../../store/Admin/AdminHospitalDrugDetails/types'
import { showErrorToast, showSuccessToast } from '../../../../../../../../shared/toast'
import { dataKeys } from './constants'

import '../../index.scss'

export const DosingReportTemplatesModal: React.FC<IDrugDetailsModalProps> = observer((props) => {
  const adminHospitalDrugDetailsStore = useAdminHospitalDrugDetailsStore()
  const adminHospitalDetailsStore = useAdminHospitalDetailsStore()

  useEffect(() => {
    if (props.show && props.drugId) {
      adminHospitalDrugDetailsStore.fetchAdminHospitalDrugReportTemplates(props.hospitalId, props.drugId)
    }
  }, [props.hospitalId, props.show])

  const formFields = useMemo(() => {
    if (adminHospitalDrugDetailsStore.settingsLoadStates.reportTemplates === 'loaded') {
      return buildFormFields(
        adminHospitalDrugDetailsStore.repackageResponseForBuilding(
          adminHospitalDrugDetailsStore.adminHospitalDrugReportTemplates?.attributes.settings.reportTemplates
        )
      )
    }

    return {}
  }, [adminHospitalDrugDetailsStore.settingsLoadStates.reportTemplates])

  const form = useFormValidation(formFields)

  const formContent = (): JSX.Element => {
    if (adminHospitalDrugDetailsStore.adminHospitalDrugReportTemplates) {
      return (
        <div className='position-relative w-100'>
          {buildInputs(
            adminHospitalDrugDetailsStore.repackageResponseForBuilding(
              adminHospitalDrugDetailsStore.adminHospitalDrugReportTemplates.attributes.settings.reportTemplates
            ),
            form,
            dataKeys,
            formFields
          )}
        </div>
      )
    }

    return <></>
  }

  // As this is an edit form, show all validation statuses for fields on initial render
  useEffect(() => {
    if (adminHospitalDrugDetailsStore.settingsLoadStates.reportTemplates === 'loaded') {
      form.reset()
    }
  }, [adminHospitalDrugDetailsStore.settingsLoadStates.reportTemplates])

  const handleSubmit = async () => {
    if (props.drugId && adminHospitalDrugDetailsStore.adminHospitalDrugReportTemplates) {
      const responseStructure = {
        name: adminHospitalDrugDetailsStore.adminHospitalDrugReportTemplates.attributes.name,
        settings: {
          reportTemplates: {
            firstDose: form.values.firstDose?.toString() || null,
            default: form.values.default?.toString() || null
          }
        }
      }

      await adminHospitalDrugDetailsStore.putAdminHospitalDrugReportTemplates(
        props.hospitalId,
        props.drugId,
        responseStructure as IPutAdminHospitalDrugReportTemplates
      )
      if (
        ['loadError', 'updateError'].includes(adminHospitalDrugDetailsStore.settingsLoadStates.generalModelSettings)
      ) {
        showErrorToast(
          adminHospitalDrugDetailsStore.settingsErrors.generalModelSettings || 'Failed to update report templates'
        )

        return
      }
      showSuccessToast('Report templates updated')
      props.setShow(false)
    }
  }

  const handleClose = () => {
    props.setShow(false)
    form.reset()
  }

  return (
    <Modal show={props.show} onHide={handleClose}>
      <InfoModal
        size='s'
        loading={['loading', 'updating'].includes(adminHospitalDrugDetailsStore.settingsLoadStates.reportTemplates)}
        linkComponent={
          <>
            <Button
              className='mr-3'
              data-testid='cancel-btn'
              disabled={false}
              loading={['loading', 'updating'].includes(
                adminHospitalDrugDetailsStore.settingsLoadStates.reportTemplates
              )}
              onClick={handleClose}
              variant='primary-outline'
            >
              Cancel
            </Button>
            <Button
              data-testid='save-btn'
              disabled={!form.valid}
              loading={['loading', 'updating'].includes(
                adminHospitalDrugDetailsStore.settingsLoadStates.reportTemplates
              )}
              onClick={handleSubmit}
              variant='primary'
            >
              Save
            </Button>
          </>
        }
        title='Dosing report templates'
        subtitle={
          <div className='hospital-drug-model-settings-modal-subtitle'>
            <b>{adminHospitalDrugDetailsStore.adminHospitalDrugDetails?.attributes.name}</b>
            <div className='mt-2'>{decode(adminHospitalDetailsStore.adminHospitalDetails?.attributes.details.name || '')}</div>
          </div>
        }
        message={formContent()}
      />
    </Modal>
  )
})
