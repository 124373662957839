import { Icons } from '@doseme/cohesive-ui'
import classnames from 'classnames'

import './index.scss'

interface IProps {
  display: 'simulation' | 'report'
  warnings: string[]
}

export const WarningsDisplay: React.FC<IProps> = (props) => {
  const formattedWarnings: JSX.Element[] = props.warnings.map((warning, idx) => (
    <div
      className={classnames('d-flex', 'align-items-start', 'justify-content-start', {
        'mt-2': props.display === 'simulation',
        'mt-3': props.display === 'report'
      })}
      key={`${props.display}-warning-${idx}`}
    >
      <div className='warning-number'>{idx + 1}</div>
      <div>{warning}</div>
    </div>
  ))

  return (
    <div className={classnames('', { 'my-4 pl-1': props.display === 'simulation' })} >
      <div className='d-flex align-items-center'>
        {props.display === 'report' && (
          <div className='font-bold mr-1'>
            <span >Review</span>{' '}
            <span className='warning-orange'>{`(${props.warnings.length}) `}</span>
          </div>
        )}
        <div className='font-bold mr-2'>Warnings:</div>
        <Icons.Alert />
        {props.display === 'simulation' && (
          <div className='warning-sim-subtitle ml-2'>Please contact DoseMeRx support for further assistance.</div>
        )}
      </div>
      <div className='warning-list'>{formattedWarnings}</div>
    </div>
  )
}
