import { Icons } from '@doseme/cohesive-ui'

import { IProps } from './types'

export const ModelFitIndicatorIcons: React.FC<IProps> = (props) => {
  const unavailableModelFit = !props.modelFitStatus || props.modelFitStatus?.message

  if (unavailableModelFit) {
    return <Icons.UnavailableCircle />
  }

  if (props.modelFitStatus?.hasGoodModelFit) {
    return <Icons.SuccessCircle />
  }

  return (
    <Icons.AlertCircle />
  )
}
