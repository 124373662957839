import { InfoModal, Modal, Button, DatePickerInput, ITimeState, TimeInput, TextInput, ValidationMessage } from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import moment from 'moment-timezone'

import { rawDateOnly, rawTimeOnly } from '../../../../../../../../constants/timeFormat'
import { useFormValidation } from '../../../../../../../../hooks/useFormValidation'
import { useObservationsStore } from '../../../../../../../../hooks/useStore'
import { showErrorToast, showSuccessToast } from '../../../../../../../../shared/toast'
import { Course } from '../../../../../../../../store/course/Course'
import { Observation } from '../../../../../../../../store/observations/Observation'
import { IFormField } from '../../../../../../../../types/validation'
import {
  dateToISODateString,
  stringToFloat,
  timeStateToTimeString
} from '../../../../../../../../utils/validation/formatters'
import {
  isLessThanFourDecimalPlaces,
  isRequired,
  isStringValidNumber,
  isStringWithinNumericLimits,
  isTimeStateDSTValid,
  isValidTimeState
} from '../../../../../../../../utils/validation/rules'
import { IModalProps } from '../../../../../../types'
import { isObservationTypeLevel, isObservationTypeSeCr, isObservationTypeDialysis } from '../DosingProfilePanel/utils'

interface IProps extends IModalProps {
  course: Course
  observation: Observation
  patientId: string
  setObservation: (observation: Observation | null) => void
  hospitalTimezone: string
}

const EditObservationModal: React.FC<IProps> = observer((props) => {
  const observationsStore = useObservationsStore()

  const initialAmountValue = props.observation.attributes.amount.value

  const initialDateTimeObserved = moment.tz(props.observation.attributes.observedAt.value, props.hospitalTimezone)
  const initialDateObserved = new Date(initialDateTimeObserved.format(rawDateOnly) + 'T00:00:00')

  const initialTimeObserved = initialDateTimeObserved.format(rawTimeOnly)
  const initialTimeStateObserved: ITimeState = {
    hh: initialTimeObserved.split(':')[0],
    mm: initialTimeObserved.split(':')[1]
  }

  const observationContraints = () => {
    if (isObservationTypeLevel(props.observation.attributes.observationType)) {
      return props.course.attributes.limits.concentration
    }
    if (isObservationTypeSeCr(props.observation.attributes.observationType)) {
      return props.course.attributes.limits.secr
    }
    if (isObservationTypeDialysis(props.observation.attributes.observationType)) {
      return props.course.attributes.limits.dialysisSessionDuration
    }

    return
  }

  const formFields: Record<string, IFormField> = {
    dateObserved: {
      // Input - Date object
      // Value - ISO Date string yyyy-MM-dd
      initialInput: initialDateObserved,
      rules: [
        isRequired // remaining validation implicitly handled by DateTimePicker logic for now
      ],
      formatter: dateToISODateString
    },
    timeObserved: {
      // Input - ITimeState
      // Value - Time string HH:mm
      initialInput: initialTimeStateObserved,
      initialConstraints: {
        date: initialDateObserved,
        tz: props.hospitalTimezone
      },
      rules: [
        isRequired,
        isValidTimeState,
        isTimeStateDSTValid
      ],
      formatter: timeStateToTimeString
    },
    amount: {
      // Input - string
      // Value - number
      initialInput: initialAmountValue,
      initialConstraints: observationContraints(),
      rules: [isRequired, isStringValidNumber, isStringWithinNumericLimits, isLessThanFourDecimalPlaces],
      formatter: stringToFloat
    }
  }

  const form = useFormValidation(formFields)

  // As this is an edit form, show all validation statuses for fields on initial render
  useEffect(() => { form.updateFieldsDisplay(Object.keys(formFields)) }, [])

  const handleSubmit = async () => {

    //must remove the "units" from payload if INR
    let amount = {
      value: form.values['amount'],
      unit: props.observation.attributes.observationType.unit
    }

    let observationType = props.observation.attributes.observationType

    if (amount.unit && observationType.unit && props.observation.attributes.observationType.name === 'INR') {
      amount.unit.name = 'N/A'
      observationType.unit.name = 'N/A'
    }

    // We expect the entire store to be refetched after this operation succeeds,
    const result = await observationsStore.editObservation(
      props.observation.id,
      props.patientId,
      props.course.id,
      observationType,
      amount,
      moment.tz(
        form.values['dateObserved'] + 'T' + form.values['timeObserved'],
        props.hospitalTimezone
      ).utc().toISOString(),
      props.observation.attributes.excludeFromCalculations
    )

    if (!result || observationsStore.loadState === 'updateError') {
      if (observationsStore.error) {
        showErrorToast(observationsStore.error || 'Failed to update observation')

        return
      }
    }

    // ensures that duplicates all update simultaneously.
    observationsStore.fetchObservationsForPatientCourse(props.patientId, props.course.id)

    showSuccessToast('Observation updated')
    props.setShow(false)
    props.setObservation(null)
  }

  const handleClose = () => {
    props.setShow(false)
    props.setObservation(null)
    form.reset()
  }

  const formContent: JSX.Element = (
    <div className='position-relative w-100'>
      <TextInput
        fieldState='valid'
        disabled
        label='Observation type:'
        value={props.observation.attributes.observationType.name}
        placeholder=''
      />
      <div className='d-flex w-100 mt-4'>
        <div>
          <DatePickerInput
            label='Date & time (00:00-23:59):'
            fieldState={form.getValidState('dateObserved')}
            hasValidation={false}
            validationText={form.getValidationMsg('dateObserved')}
            value={form.inputs['dateObserved']}
            onChange={(value) =>
              form.validateFields(
                [
                  {
                    field: 'dateObserved',
                    input: value
                  },
                  {
                    field: 'timeObserved',
                    input: form.inputs['timeObserved'],
                    constraints: {
                      date: value,
                      tz: props.hospitalTimezone
                    }
                  }
                ],
                'updateFieldsDisplay'
              )
            }
            testId='date-input'
          />
        </div>
        <div className='time-modal-input ml-2'>
          <TimeInput
            label={<span>&#8205;</span>}
            fieldState={form.getValidState('timeObserved')}
            hasValidation={false}
            validationText={form.getValidationMsg('timeObserved')}
            testIdHh='time-input-hh'
            testIdMm='time-input-mm'
            value={form.inputs['timeObserved']}
            onChange={(value: ITimeState) => {
              form.validateFields([
                {
                  field: 'timeObserved',
                  input: value,
                  constraints: {
                    date: form.inputs['dateObserved'],
                    tz: props.hospitalTimezone
                  }
                }
              ])
            }}
            onBlur={() => form.updateFieldsDisplay(['timeObserved'])}
          />
        </div>
      </div>
      <ValidationMessage
        labelState='error'
        message={form.getValidationMsg('dateObserved') || form.getValidationMsg('timeObserved')}
      />

      <div className='mt-4'>
        <TextInput
          label='Amount:'
          fieldState={form.getValidState('amount')}
          validationText={form.getValidationMsg('amount')}
          value={form.inputs['amount']}
          name='amount-input-edit-obs'
          onChange={(value) =>
            form.validateFields([
              {
                field: 'amount',
                input: value,
                constraints: observationContraints()
              }
            ])
          }
          onBlur={() => form.updateFieldsDisplay(['amount'])}
          units={props.observation.attributes.observationType.unit!.name}
        />
      </div>
    </div>
  )

  return (
    <Modal
      show={props.show}
      onHide={handleClose}
    >
      <InfoModal
        size='s'
        linkComponent={
          <Button
            disabled={!form.valid || observationsStore.loadState === 'updating'}
            loading={observationsStore.loadState === 'updating'}
            onClick={handleSubmit}
            variant='primary'
          >
            Save
          </Button>
        }
        title='Edit observation'
        message={formContent}
        onDismiss={handleClose}
      />
    </Modal>
  )
})

export { EditObservationModal }
