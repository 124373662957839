import { useState } from 'react'
import { useIntercom } from 'react-use-intercom'
import { Route, BrowserRouter, Switch, RouteComponentProps, Redirect } from 'react-router-dom'
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { ToastContainer } from 'react-toastify'
import useScreenSize from 'use-screen-size'

import { StoreProvider } from './store/StoreProvider'
import { reactPlugin } from './components/AzureAppInsights'
import { AppInsightsErrorBoundary } from './components/AppInsightsErrorBoundary'
import { UnsupportedDeviceWidth, minSupportedWidth } from './components/UnsupportedDeviceWidth'
import { TopMenuBar } from './components/TopMenuBar'
import { IntegratedFooter } from './components/IntegratedFooter'
import { ResourcesRouter } from './components/ResourcesRouter'
import { AdminRouter } from './components/AdminRouter'
import { PatientsRouter } from './components/PatientsRouter'
import { AuthRouter } from './components/AuthRouter'
import { ClinicianSettings } from './components/ClinicianSettings'
import { AnalyticsPage } from './components/AnalyticsPage'
import { StandaloneSessionMiddleware } from './components/SessionMiddleware'
import { ScrollToTop } from './shared/ScrollToTop'

import './index.scss'

interface IPatientProfileMatchParams {
  patientId: string
}

interface IPatientRouterMatchParams {
  patientId?: string
}

const StandaloneApp: React.FC = () => {
  const [hasIntercomBooted, setHasIntercomBooted] = useState(false)

  const size = useScreenSize()

  if (!hasIntercomBooted && window.env.INTERCOM_APP_ID) {
    const { boot, shutdown } = useIntercom()

    // Delete any existing Intercom session before booting the new instance
    shutdown()

    boot({
      customAttributes: {
        instance: window.env.INSTALLATION,
        vendor: window.env.VENDOR_MODE
      }
    })

    setHasIntercomBooted(true)
  }

  return (
    <>
      <ToastContainer newestOnTop={true} closeOnClick={true} />
      <StoreProvider>
        <BrowserRouter>
          <ScrollToTop />
          <div className='app-wrapper'>
            <AppInsightsContext.Provider value={reactPlugin}>
              <TopMenuBar />
              {size.width < minSupportedWidth ? (
                <UnsupportedDeviceWidth />
              ) : (
                <AppInsightsErrorBoundary>
                  <Switch>
                    {/* Auth Router */}
                    <Route
                      path={[
                        '/login',
                        '/reset_password',
                        '/set_password',
                        '/sso',
                        '/twofa',
                        '/twofasetup',
                        '/forgot_password'
                      ]}
                      render={() => <AuthRouter />}
                    />

                    {/* Admin Router */}
                    <Route
                      path='/admin'
                      render={({ match }: RouteComponentProps) => (
                        <StandaloneSessionMiddleware>
                          <AdminRouter />
                        </StandaloneSessionMiddleware>
                      )}
                    />

                    {/* Resources Router */}
                    <Route
                      path='/resources'
                      render={({ match }: RouteComponentProps) => (
                        <StandaloneSessionMiddleware>
                          <ResourcesRouter />
                        </StandaloneSessionMiddleware>
                      )}
                    />

                    {/* Resources Router */}
                    <Route
                      path='/patients/:patientId/resources'
                      render={({ match }: RouteComponentProps<IPatientProfileMatchParams>) => (
                        <StandaloneSessionMiddleware patientId={match.params.patientId}>
                          <ResourcesRouter patientId={match.params.patientId} />
                        </StandaloneSessionMiddleware>
                      )}
                    />

                    {/* Analytics Page */}
                    <Route
                      path='/analytics'
                      render={({ match }: RouteComponentProps) => (
                        <StandaloneSessionMiddleware>
                          <AnalyticsPage />
                        </StandaloneSessionMiddleware>
                      )}
                    />

                    {/* Analytics Page */}
                    <Route
                      path='/patients/:patientId/analytics'
                      render={({ match }: RouteComponentProps<IPatientProfileMatchParams>) => (
                        <StandaloneSessionMiddleware patientId={match.params.patientId}>
                          <AnalyticsPage patientId={match.params.patientId} />
                        </StandaloneSessionMiddleware>
                      )}
                    />

                    {/* Clinician Settings (Your Profile) */}
                    <Route
                      path='/cliniciansettings'
                      render={({ match }: RouteComponentProps) => (
                        <StandaloneSessionMiddleware>
                          <ClinicianSettings />
                        </StandaloneSessionMiddleware>
                      )}
                    />

                    {/* Patients Router */}
                    <Route
                      path='/patients/:patientId?'
                      render={({ match }: RouteComponentProps<IPatientRouterMatchParams>) => (
                        <StandaloneSessionMiddleware patientId={match.params.patientId}>
                          <PatientsRouter />
                        </StandaloneSessionMiddleware>
                      )}
                    />

                    {/* Redirect */}
                    <Route exact path='*' render={() => <Redirect to={{ pathname: '/login' }} />} />
                  </Switch>
                </AppInsightsErrorBoundary>
              )}
              <IntegratedFooter width={size.width} />
            </AppInsightsContext.Provider>
          </div>
        </BrowserRouter>
      </StoreProvider>
    </>
  )
}

export { StandaloneApp }
