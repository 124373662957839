import { ObservableMap } from 'mobx'
import { decode } from 'he'
import { IDropdownItem } from '@doseme/cohesive-ui'

import { IAdminHospitalListItem } from '../../../../../../../store/Admin/AdminHospitalList/types'

export const formatHospitals = (admins: ObservableMap<string, IAdminHospitalListItem>): IDropdownItem[] => {
  const hospitalsArr = [...admins.values()]
  const hospitalDropdownItems = hospitalsArr.map((hospitals) => ({
    value: hospitals.attributes.name + '|' + hospitals.id,
    label: decode(hospitals.attributes.name || '')
  }))

  return hospitalDropdownItems
}
