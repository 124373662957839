import { makeAutoObservable } from 'mobx'

import { IAdminAddVendorDetails } from './types'
import { AdminVendorDetailsStore } from './AdminVendorDetailsStore'

export class AdminAddVendorDetails implements IAdminAddVendorDetails {
  store: AdminVendorDetailsStore

  type: string
  attributes: IAdminAddVendorDetails['attributes']

  constructor(store: AdminVendorDetailsStore, entity: IAdminAddVendorDetails) {
    makeAutoObservable(this, {
      store: false
    })

    this.store = store

    this.type = entity.type
    this.attributes = { ...entity.attributes }
  }
}
