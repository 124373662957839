import { SeverityLevel } from '@microsoft/applicationinsights-web'
import { v4 as uuidv4 } from 'uuid'

import { appInsights } from '../AzureAppInsights'

type TOnSuccessCallback = (token: string) => void
type TOnMessageCallback = (event: IReceiveTokenPayload, success: TOnSuccessCallback) => string | void

interface IReceiveTokenPayload {
  data: {
    [key: string]: string | object
  }
}

export const requestEpicHandshake = (receiveMessage: TOnMessageCallback, successCallback: TOnSuccessCallback) => {
  window.addEventListener(
    'message',
    (event: IReceiveTokenPayload) => {
      receiveMessage(event, successCallback)
    },
    false
  )

  window.parent.postMessage(
    {
      action: 'Epic.Clinical.Informatics.Web.InitiateHandshake'
    },
    '*'
  )
}

export const receiveEpicToken = (event: IReceiveTokenPayload, successCallback: TOnSuccessCallback): string | void => {
  for (const type in event.data) {
    if (type === 'token') {
      successCallback(event.data[type] as string)

      return
    }
  }
}

export const openExternalWindowEpic = (url: string, token?: string): void => {
  if (!token) {
    const errorId = uuidv4()
    const error = new Error(`No Epic window token received to open URL ${url}`)
    appInsights.trackException({
      error: error,
      severityLevel: SeverityLevel.Warning,
      properties: {
        errorId: errorId,
        errorMessage: error?.message,
        installation: window.env.INSTALLATION
      }
    })

    window.open(url, '_blank')

    return
  }

  window.parent.postMessage(
    {
      token: token,
      action: 'Epic.Clinical.Informatics.Web.OpenExternalWindow',
      args: url
    },
    '*'
  )
}
