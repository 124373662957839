import { IDosingMatrixData, IDosingMatrixAUCAmounts } from '@doseme/cohesive-ui'
import { keys, toJS } from 'mobx'

import { IDosingMatrix } from '../../../../../../../../../../store/dosingMatrix/types'

export const dosingMatrixValueRange = (min: number, max: number, value?: number): string => {
  if (!value) {
    return ''
  }

  if (value > max) {
    return 'supratherapeutic'
  }

  if (value < min) {
    return 'subtherapeutic'
  }

  return 'in-range'
}

export const getMatrixKeys = (dosingMatrix: IDosingMatrix | null): string[] | undefined => {
  if (dosingMatrix) {
    const matrixKeys = keys(dosingMatrix.attributes.alternativeRegimensByDose)
    const matrixKeysMapped = matrixKeys.map((x) => x.toString())

    return [...matrixKeysMapped].sort((a, b) => parseFloat(a) - parseFloat(b))
  }
}

export const sortDosingMatrixData = (dosingMatrix: IDosingMatrix | null): IDosingMatrixData | null => {
  const matrixKeys = getMatrixKeys(dosingMatrix)

  if (dosingMatrix && matrixKeys) {
    const dosingAttrAlt = dosingMatrix?.attributes.alternativeRegimensByDose
    const dosingAttrClinTargets = dosingMatrix?.attributes.clinicalTargets
    const matrixattrDataAlt = toJS(dosingAttrAlt)
    const matrixattrDataClin = toJS(dosingAttrClinTargets)

    const matrixData = matrixKeys.map((key) => {
      return {
        values: {
          q6h: matrixattrDataAlt[key].q6h ? matrixattrDataAlt[key].q6h.predictedOutcome.auc24.value : null,
          q8h: matrixattrDataAlt[key].q8h ? matrixattrDataAlt[key].q8h.predictedOutcome.auc24.value : null,
          q12h: matrixattrDataAlt[key].q12h ? matrixattrDataAlt[key].q12h.predictedOutcome.auc24.value : null,
          q24h: matrixattrDataAlt[key].q24h ? matrixattrDataAlt[key].q24h.predictedOutcome.auc24.value : null
        },
        range: [
          dosingMatrixValueRange(
            matrixattrDataClin.minimum.auc24.value,
            matrixattrDataClin.maximum.auc24.value,
            matrixattrDataAlt[key].q6h ? matrixattrDataAlt[key].q6h.predictedOutcome.auc24.value : undefined
          ),
          dosingMatrixValueRange(
            matrixattrDataClin.minimum.auc24.value,
            matrixattrDataClin.maximum.auc24.value,
            matrixattrDataAlt[key].q8h ? matrixattrDataAlt[key].q8h.predictedOutcome.auc24.value : undefined
          ),
          dosingMatrixValueRange(
            matrixattrDataClin.minimum.auc24.value,
            matrixattrDataClin.maximum.auc24.value,
            matrixattrDataAlt[key].q12h ? matrixattrDataAlt[key].q12h.predictedOutcome.auc24.value : undefined
          ),
          dosingMatrixValueRange(
            matrixattrDataClin.minimum.auc24.value,
            matrixattrDataClin.maximum.auc24.value,
            matrixattrDataAlt[key].q24h ? matrixattrDataAlt[key].q24h.predictedOutcome.auc24.value : undefined
          )
        ],
        infusion: matrixattrDataAlt[key].infusionLength.value,
        dose: parseFloat(key)
      }
    })

    //array for the number of doses for each time period
    const numberofDosesMatrix: IDosingMatrixAUCAmounts = {
      q6h: matrixattrDataAlt[matrixKeys[0].toString()].q6h
        ? matrixattrDataAlt[matrixKeys[0].toString()].q6h.numberOfDoses
        : null,
      q8h: matrixattrDataAlt[matrixKeys[0].toString()].q8h
        ? matrixattrDataAlt[matrixKeys[0].toString()].q8h.numberOfDoses
        : null,
      q12h: matrixattrDataAlt[matrixKeys[0].toString()].q12h
        ? matrixattrDataAlt[matrixKeys[0].toString()].q12h.numberOfDoses
        : null,
      q24h: matrixattrDataAlt[matrixKeys[0].toString()].q24h
        ? matrixattrDataAlt[matrixKeys[0].toString()].q24h.numberOfDoses
        : null
    }

    const defaultIndividualized = [
      dosingMatrix.attributes.defaultDose.value.toString(),
      dosingMatrix.attributes.defaultRegimen
    ]

    return {
      matrixData,
      numberOfDoses: numberofDosesMatrix,
      defaultIndividualized,
      doseUnits: dosingMatrix?.attributes.defaultDose.unit!.name.toString()
    }
  }

  return null
}
