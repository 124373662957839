import { createContext, ReactNode } from 'react'

import { RootStore } from './RootStore'

interface IProviderProps {
  children: ReactNode
  store?: RootStore
}

const RootAppStore: RootStore = new RootStore()

const RootStoreContext = createContext(RootAppStore)
const AuthStoreContext = createContext(RootAppStore.authStore)
const PasswordRulesStoreContext = createContext(RootAppStore.passwordRulesStore)
const ErrorsStoreContext = createContext(RootAppStore.errorsStore)
const HospitalStoreContext = createContext(RootAppStore.hospitalStore)
const PatientStoreContext = createContext(RootAppStore.patientStore)
const PatientExternalDataStoreContext = createContext(RootAppStore.patientExternalDataStore)
const CourseFeaturesStoreContext = createContext(RootAppStore.courseFeaturesStore)
const PatientCoursesStoreContext = createContext(RootAppStore.patientCoursesStore)
const CourseStoreContext = createContext(RootAppStore.courseStore)
const AdministrationsStoreContext = createContext(RootAppStore.administrationsStore)
const ObservationsStoreContext = createContext(RootAppStore.observationsStore)
const DrugModelsStoreContext = createContext(RootAppStore.drugModelsStore)
const HistoricalSimulationStoreContext = createContext(RootAppStore.historicalSimulationStore)
const DosingRecommendationStoreContext = createContext(RootAppStore.dosingRecommendationStore)
const ActivityLogStoreContext = createContext(RootAppStore.activityLogStore)
const GenerateReportStoreContext = createContext(RootAppStore.generateReportStore)
const DrugModelsInformationStoreContext = createContext(RootAppStore.drugModelsInformationStore)
const DosingMatrixStoreContext = createContext(RootAppStore.dosingMatrixStore)
const VersionsStoreContext = createContext(RootAppStore.versionsStore)
const AdminHospitalListStoreContext = createContext(RootAppStore.adminHospitalListStore)
const AdminVendorListStoreContext = createContext(RootAppStore.adminVendorListStore)
const AdminVendorDetailStoreContext = createContext(RootAppStore.adminVendorDetailsStore)
const AdminVendorSiteDetailStoreContext = createContext(RootAppStore.adminVendorSiteDetailsStore)
const AdminVendorSiteListStoreContext = createContext(RootAppStore.adminVendorSiteListStore)
const AdminHospitalDrugListStoreContext = createContext(RootAppStore.adminHospitalDrugListStore)
const ConstantsTimezoneStoreContext = createContext(RootAppStore.constantsTimezoneStore)
const ApplicableDrugModelsStoreContext = createContext(RootAppStore.applicableDrugModelsStore)
const AdminHospitalDetailsStoreContext = createContext(RootAppStore.adminHospitalDetailsStore)
const ClinicianStoreContext = createContext(RootAppStore.clinicianStore)
const AdminClinicianListStoreContext = createContext(RootAppStore.adminClinicianListStore)
const AdminClinicianDetailsStoreContext = createContext(RootAppStore.adminClinicianDetailsStore)
const AdminClinicianSecurityStoreContext = createContext(RootAppStore.adminClinicianSecurityStore)
const AdminHospitalDrugDetailsStoreContext = createContext(RootAppStore.adminHospitalDrugDetailsStore)
const AdminChangeLogListStoreContext = createContext(RootAppStore.adminChangeLogListStore)
const PatientListStoreContext = createContext(RootAppStore.patientListStore)
const BreadcrumbsStoreContext = createContext(RootAppStore.breadcrumbsStore)
const AnalyticsStoreContext = createContext(RootAppStore.analyticsStore)

const StoreProvider = ({ children, store }: IProviderProps) => {
  const applicationStore = store || RootAppStore

  return (
    <RootStoreContext.Provider value={applicationStore}>
      <AuthStoreContext.Provider value={applicationStore.authStore}>
        <PasswordRulesStoreContext.Provider value={applicationStore.passwordRulesStore}>
          <ErrorsStoreContext.Provider value={applicationStore.errorsStore}>
            <HospitalStoreContext.Provider value={applicationStore.hospitalStore}>
              <PatientListStoreContext.Provider value={applicationStore.patientListStore}>
                <PatientStoreContext.Provider value={applicationStore.patientStore}>
                  <PatientExternalDataStoreContext.Provider value={applicationStore.patientExternalDataStore}>
                    <PatientCoursesStoreContext.Provider value={applicationStore.patientCoursesStore}>
                      <CourseStoreContext.Provider value={applicationStore.courseStore}>
                        <AdministrationsStoreContext.Provider value={applicationStore.administrationsStore}>
                          <ObservationsStoreContext.Provider value={applicationStore.observationsStore}>
                            <DrugModelsStoreContext.Provider value={applicationStore.drugModelsStore}>
                              <CourseFeaturesStoreContext.Provider value={applicationStore.courseFeaturesStore}>
                                <HistoricalSimulationStoreContext.Provider value={applicationStore.historicalSimulationStore}>
                                  <DosingRecommendationStoreContext.Provider value={applicationStore.dosingRecommendationStore}>
                                    <ActivityLogStoreContext.Provider value={applicationStore.activityLogStore}>
                                      <GenerateReportStoreContext.Provider value={applicationStore.generateReportStore}>
                                        <DrugModelsInformationStoreContext.Provider value={applicationStore.drugModelsInformationStore}>
                                          <DosingMatrixStoreContext.Provider value={applicationStore.dosingMatrixStore}>
                                            <VersionsStoreContext.Provider value={applicationStore.versionsStore}>
                                              <AdminHospitalListStoreContext.Provider value={applicationStore.adminHospitalListStore}>
                                                <AdminVendorListStoreContext.Provider value={applicationStore.adminVendorListStore}>
                                                  <AdminVendorDetailStoreContext.Provider value={applicationStore.adminVendorDetailsStore}>
                                                    <AdminVendorSiteDetailStoreContext.Provider value={applicationStore.adminVendorSiteDetailsStore}>
                                                      <AdminVendorSiteListStoreContext.Provider value={applicationStore.adminVendorSiteListStore}>
                                                        <ApplicableDrugModelsStoreContext.Provider value={applicationStore.applicableDrugModelsStore}>
                                                          <AdminHospitalDetailsStoreContext.Provider value={applicationStore.adminHospitalDetailsStore}>
                                                            <ConstantsTimezoneStoreContext.Provider value={applicationStore.constantsTimezoneStore}>
                                                              <ClinicianStoreContext.Provider value={applicationStore.clinicianStore}>
                                                                <AdminClinicianListStoreContext.Provider value={applicationStore.adminClinicianListStore}>
                                                                  <AdminClinicianDetailsStoreContext.Provider value={applicationStore.adminClinicianDetailsStore}>
                                                                    <AdminHospitalDrugListStoreContext.Provider value={applicationStore.adminHospitalDrugListStore}>
                                                                      <AdminClinicianSecurityStoreContext.Provider value={applicationStore.adminClinicianSecurityStore}>
                                                                        <AdminHospitalDrugDetailsStoreContext.Provider value={applicationStore.adminHospitalDrugDetailsStore}>
                                                                          <BreadcrumbsStoreContext.Provider value={applicationStore.breadcrumbsStore}>
                                                                            <AnalyticsStoreContext.Provider value={applicationStore.analyticsStore}>
                                                                              <AdminChangeLogListStoreContext.Provider value={applicationStore.adminChangeLogListStore} >
                                                                                {children}
                                                                              </AdminChangeLogListStoreContext.Provider>
                                                                            </AnalyticsStoreContext.Provider>
                                                                          </BreadcrumbsStoreContext.Provider>
                                                                        </AdminHospitalDrugDetailsStoreContext.Provider>
                                                                      </AdminClinicianSecurityStoreContext.Provider>
                                                                    </AdminHospitalDrugListStoreContext.Provider>
                                                                  </AdminClinicianDetailsStoreContext.Provider>
                                                                </AdminClinicianListStoreContext.Provider>
                                                              </ClinicianStoreContext.Provider>
                                                            </ConstantsTimezoneStoreContext.Provider>
                                                          </AdminHospitalDetailsStoreContext.Provider>
                                                        </ApplicableDrugModelsStoreContext.Provider>
                                                      </AdminVendorSiteListStoreContext.Provider>
                                                    </AdminVendorSiteDetailStoreContext.Provider>
                                                  </AdminVendorDetailStoreContext.Provider>
                                                </AdminVendorListStoreContext.Provider>
                                              </AdminHospitalListStoreContext.Provider>
                                            </VersionsStoreContext.Provider>
                                          </DosingMatrixStoreContext.Provider>
                                        </DrugModelsInformationStoreContext.Provider>
                                      </GenerateReportStoreContext.Provider>
                                    </ActivityLogStoreContext.Provider>
                                  </DosingRecommendationStoreContext.Provider>
                                </HistoricalSimulationStoreContext.Provider>
                              </CourseFeaturesStoreContext.Provider>
                            </DrugModelsStoreContext.Provider>
                          </ObservationsStoreContext.Provider>
                        </AdministrationsStoreContext.Provider>
                      </CourseStoreContext.Provider>
                    </PatientCoursesStoreContext.Provider>
                  </PatientExternalDataStoreContext.Provider>
                </PatientStoreContext.Provider>
              </PatientListStoreContext.Provider>
            </HospitalStoreContext.Provider>
          </ErrorsStoreContext.Provider>
        </PasswordRulesStoreContext.Provider>
      </AuthStoreContext.Provider>
    </RootStoreContext.Provider>
  )
}

export {
  StoreProvider,
  RootStoreContext,
  AuthStoreContext,
  PasswordRulesStoreContext,
  ErrorsStoreContext,
  HospitalStoreContext,
  PatientStoreContext,
  PatientExternalDataStoreContext,
  CourseFeaturesStoreContext,
  PatientCoursesStoreContext,
  CourseStoreContext,
  AdministrationsStoreContext,
  ObservationsStoreContext,
  DrugModelsStoreContext,
  HistoricalSimulationStoreContext,
  DosingRecommendationStoreContext,
  ActivityLogStoreContext,
  GenerateReportStoreContext,
  DrugModelsInformationStoreContext,
  DosingMatrixStoreContext,
  VersionsStoreContext,
  AdminHospitalListStoreContext,
  AdminVendorListStoreContext,
  AdminVendorDetailStoreContext,
  AdminVendorSiteDetailStoreContext,
  AdminVendorSiteListStoreContext,
  AdminHospitalDrugListStoreContext,
  ConstantsTimezoneStoreContext,
  ApplicableDrugModelsStoreContext,
  AdminHospitalDetailsStoreContext,
  ClinicianStoreContext,
  AdminClinicianListStoreContext,
  AdminClinicianDetailsStoreContext,
  AdminClinicianSecurityStoreContext,
  AdminHospitalDrugDetailsStoreContext,
  AdminChangeLogListStoreContext,
  PatientListStoreContext,
  BreadcrumbsStoreContext,
  AnalyticsStoreContext
}
