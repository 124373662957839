import { IDropdownItem } from '@doseme/cohesive-ui'

import { IAdministration } from '../store/administrations/types'
import { IObservation } from '../store/observations/types'

export const sortDropdownOptionsAlpha = (content: IDropdownItem[]): IDropdownItem[] => {
  return content.concat().sort((a, b) => {
    const one = a.label?.toLowerCase() || ''
    const two = b.label?.toLowerCase() || ''

    return one > two ? 1 : -1
  })
}

export const sortAdministrationsByDateAsc = (administrations: IAdministration[]): IAdministration[] => {
  return administrations.sort((a, b) =>
    new Date(a.attributes.administeredAt.value) > new Date(b.attributes.administeredAt.value) ? 1 : -1
  )
}

export const sortObservationsByDateAsc = (observations: IObservation[]): IObservation[] => {
  return observations.sort((a, b) =>
    new Date(a.attributes.observedAt.value) > new Date(b.attributes.observedAt.value) ? 1 : -1
  )
}
