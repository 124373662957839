// The goal for time formats in this app are as follows:
// 1. Give them meaningful names
// 2. Reuse previously defined time formats wherever possible

// NB: These time formats are **not** ISO 8601 nor RFC 3339, they are designed
// to comply with the format() method expected input format in moment.

export const tableReadable = 'MMM DD YYYY, HH:mm'
export const tableReadableDate = 'MMM DD YYYY'
export const dateFnsTableReadable = 'MMM dd yyyy, HH:mm'

export const nonUtcIso = 'YYYY-MM-DDTHH:mm'

export const rawDateOnly = 'YYYY-MM-DD'
export const dateFnsRawDateOnly = 'yyyy-MM-dd'

export const rawTimeOnly = 'HH:mm'

export const chartFormat = 'MMM DD, HH:mm'
