import { makeAutoObservable } from 'mobx'

import { TimezoneStore } from './TimezoneStore'
import { ITimezone } from './types'

export class TimezoneItem {
  store: TimezoneStore

  type: string
  attributes: ITimezone['attributes']

  constructor(store: TimezoneStore, entity: ITimezone) {
    makeAutoObservable(this, {
      store: false
    })

    this.store = store

    this.type = entity.type
    this.attributes = { ...entity.attributes }
  }
}
