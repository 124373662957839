import { InfoModal, Modal, Button } from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'

import { useAdminHospitalDetailsStore } from '../../../../../../../hooks/useStore'
import { showErrorToast, showSuccessToast } from '../../../../../../../shared/toast'
import { IPutAdminHospitalDefaultDoseSettings } from '../../../../../../../store/Admin/AdminHospitalDetails/types'
import { IModalProps } from '../../../../../../PatientRouter/types'
import { buildBooleanInput, buildFormFields, buildSelectionInput, buildTextInput } from '../../../../../../../shared/buildForms'
import { useFormValidation } from '../../../../../../../hooks/useFormValidation'

import './index.scss'

interface IProps extends IModalProps {
  hospitalId: string
  hospitalName?: string
}

const HospitalDefaultDoseSettingsModal: React.FC<IProps> = observer((props) => {
  const adminHospitalDetailsStore = useAdminHospitalDetailsStore()

  useEffect(() => {
    if (props.show) {
      adminHospitalDetailsStore.fetchAdminHospitalDefaultDoseSettings(props.hospitalId)
    }
  }, [props.hospitalId, props.show])

  const formFields = useMemo(() => {
    if (['loaded', 'updateError'].includes(adminHospitalDetailsStore.settingsLoadStates.defaultDoseSettings)) {
      return buildFormFields(adminHospitalDetailsStore.adminHospitalDefaultDoseSettings)
    }

    return {}
  }, [adminHospitalDetailsStore.settingsLoadStates.defaultDoseSettings])

  const form = useFormValidation(formFields)

  useEffect(() => {
    if (adminHospitalDetailsStore.settingsLoadStates.defaultDoseSettings === 'loaded') {
      form.reset()
    }
  }, [adminHospitalDetailsStore.settingsLoadStates.defaultDoseSettings, formFields])

  const formContent = (): JSX.Element => {
    if (adminHospitalDetailsStore.adminHospitalDefaultDoseSettings) {
      return (
        <div className='position-relative w-100'>
          {buildSelectionInput(
            adminHospitalDetailsStore.adminHospitalDefaultDoseSettings.attributes['hospitalCreatinineUnits'],
            form,
            'hospitalCreatinineUnits'
          )}
          <div className='hospital-patient-limits'>
            <div className='hospital-patient-limits-label'>
              SeCr limits in selected hospital creatinine units
            </div>
            <div className='d-flex'>
              <div className='mr-4'>
                {buildTextInput(
                  adminHospitalDetailsStore.adminHospitalDefaultDoseSettings.attributes['minCreatinine'],
                  form,
                  formFields['minCreatinine'],
                  'minCreatinine'
                )}
              </div>
              {buildTextInput(
                adminHospitalDetailsStore.adminHospitalDefaultDoseSettings.attributes['maxCreatinine'],
                form,
                formFields['maxCreatinine'],
                'maxCreatinine'
              )}
            </div>
          </div>
          {buildSelectionInput(
            adminHospitalDetailsStore.adminHospitalDefaultDoseSettings.attributes['hospitalWeightUnits'],
            form,
            'hospitalWeightUnits'
          )}
          <div className='hospital-patient-limits'>
            <div className='hospital-patient-limits-label'>
              Patient weight limits in default weight units
            </div>
            <div className='d-flex'>
              <div className='mr-4'>
                {buildTextInput(
                  adminHospitalDetailsStore.adminHospitalDefaultDoseSettings.attributes['minWeight'],
                  form,
                  formFields['minWeight'],
                  'minWeight'
                )}
              </div>
              {buildTextInput(
                adminHospitalDetailsStore.adminHospitalDefaultDoseSettings.attributes['maxWeight'],
                form,
                formFields['maxWeight'],
                'maxWeight'
              )}
            </div>
          </div>
          {buildSelectionInput(
            adminHospitalDetailsStore.adminHospitalDefaultDoseSettings.attributes['hospitalHeightUnits'],
            form,
            'hospitalHeightUnits'
          )}
          <div className='hospital-patient-limits'>
            <div className='hospital-patient-limits-label'>
              Patient height limits in default height units
            </div>
            <div className='d-flex'>
              <div className='mr-4'>
                {buildTextInput(
                  adminHospitalDetailsStore.adminHospitalDefaultDoseSettings.attributes['minHeight'],
                  form,
                  formFields['minHeight'],
                  'minHeight'
                )}
              </div>
              {buildTextInput(
                adminHospitalDetailsStore.adminHospitalDefaultDoseSettings.attributes['maxHeight'],
                form,
                formFields['maxHeight'],
                'maxHeight'
              )}
            </div>
          </div>
          {buildBooleanInput(
            adminHospitalDetailsStore.adminHospitalDefaultDoseSettings.attributes['extendDefaultDosePeriod'],
            form,
            'extendDefaultDosePeriod'
          )}
          {buildTextInput(
            adminHospitalDetailsStore.adminHospitalDefaultDoseSettings.attributes['recordedDosesDaysPermittedInFuture'],
            form,
            formFields['recordedDosesDaysPermittedInFuture'],
            'recordedDosesDaysPermittedInFuture'
          )}
        </div>
      )
    }

    return <></>
  }

  const handleSubmit = async () => {
    const putValues = form.values
    putValues.minHeight = {
      value: form.values.minHeight || null,
      unit: adminHospitalDetailsStore.adminHospitalDefaultDoseSettings?.attributes.minHeight.attributes.units
    }
    putValues.maxHeight = {
      value: form.values.maxHeight || null,
      unit: adminHospitalDetailsStore.adminHospitalDefaultDoseSettings?.attributes.maxHeight.attributes.units
    }
    putValues.minWeight = {
      value: form.values.minWeight || null,
      unit: adminHospitalDetailsStore.adminHospitalDefaultDoseSettings?.attributes.minWeight.attributes.units
    }
    putValues.maxWeight = {
      value: form.values.maxWeight || null,
      unit: adminHospitalDetailsStore.adminHospitalDefaultDoseSettings?.attributes.maxWeight.attributes.units
    }

    await adminHospitalDetailsStore.putAdminHospitalDefaultDoseSettings(props.hospitalId, putValues as IPutAdminHospitalDefaultDoseSettings)

    if (['loadError', 'updateError'].includes(adminHospitalDetailsStore.settingsLoadStates.defaultDoseSettings)) {
      showErrorToast(adminHospitalDetailsStore.settingsErrors.defaultDoseSettings || 'Failed to update Hospital Default Dose Settings')

      return
    }

    showSuccessToast('Hospital Default Dose Settings updated')
    props.setShow(false)
  }

  const handleClose = () => {
    props.setShow(false)
    form.reset()
  }

  return (
    <Modal
      show={props.show}
      onHide={handleClose}
    >
      <InfoModal
        size='m'
        loading={['loading', 'updating'].includes(adminHospitalDetailsStore.settingsLoadStates.defaultDoseSettings)}
        linkComponent={
          <>
            <Button
              className='mr-3'
              data-testid='cancel-btn'
              disabled={false}
              loading={['loading', 'updating'].includes(adminHospitalDetailsStore.settingsLoadStates.defaultDoseSettings)}
              onClick={handleClose}
              variant='primary-outline'
            >
              Cancel
            </Button>
            <Button
              data-testid='save-btn'
              disabled={!form.valid || ['loading', 'updating'].includes(adminHospitalDetailsStore.settingsLoadStates.defaultDoseSettings)}
              loading={['loading', 'updating'].includes(adminHospitalDetailsStore.settingsLoadStates.defaultDoseSettings)}
              onClick={handleSubmit}
              variant='primary'
            >
              Save
            </Button>
          </>
        }
        customClassName='default-dose-settings-modal'
        limitHeightToWindow
        title='Edit default dosing settings'
        subtitle={props.hospitalName}
        message={formContent()}
      />
    </Modal>
  )
})

export { HospitalDefaultDoseSettingsModal }
