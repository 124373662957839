import { InfoModal, Modal, Button, Dropdown, TextInput } from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'
import classnames from 'classnames'

import { useCourseFeaturesStore, useDosingRecommendationStore } from '../../../../../../../../hooks/useStore'
import { IUpdateCourseFeature } from '../../../../../../../../store/courseFeatures/types'
import { showErrorToast, showSuccessToast } from '../../../../../../../../shared/toast'
import { IModalProps } from '../../../../../../types'
import { IFormField } from '../../../../../../../../types/validation'
import {
  isLessThanFourDecimalPlaces,
  isRequired,
  isStringWithinMaxNumericLimit,
  isStringWithinMinNumericLimit
} from '../../../../../../../../utils/validation/rules'
import { useFormValidation } from '../../../../../../../../hooks/useFormValidation'
import { IPartialLimit } from '../../../../../../../../store/types'

interface IProps extends IModalProps {
  patientId: string
  courseId: string
  drugModelId: string
}

export const UpdateCourseFeaturesModal: React.FC<IProps> = observer((props) => {
  const courseFeaturesStore = useCourseFeaturesStore()
  const dosingRecommendationStore = useDosingRecommendationStore()

  const formFields = useMemo(() => {
    if (courseFeaturesStore.loadState === 'loaded') {
      return [...courseFeaturesStore.courseFeatures].reduce<Record<string, IFormField>>((acc, [key, curr]) => {
        if (curr.type === 'selection') {
          const initialInput = curr.attributes.currentValue || (curr.attributes.isRequired || !curr.attributes.defaultValue
            ? ''
            : curr.attributes.defaultValue)

          return {
            ...acc,
            [key]: {
              // Input - string
              // Value - string
              initialInput: initialInput,
              rules: curr.attributes.isRequired ? [isRequired] : []
            }
          }
        }

        const numberFormat = [isLessThanFourDecimalPlaces]
        const required = curr.attributes.isRequired ? [isRequired] : []
        const withinMaxLimit = curr.attributes.maxValue ? [isStringWithinMaxNumericLimit] : []
        const withinMinLimit = curr.attributes.minValue ? [isStringWithinMinNumericLimit] : []

        const maxLimit: IPartialLimit = {
          max: {
            value: curr.attributes.maxValue,
            unit: { id: '0', name: curr.attributes.units }
          }
        }

        const minLimit: IPartialLimit = {
          min: {
            value: curr.attributes.minValue,
            unit: { id: '0', name: curr.attributes.units }
          }
        }

        return {
          ...acc,
          [key]: {
            // Input - string
            // Value - string
            initialInput: curr.attributes.currentValue,
            rules: numberFormat.concat(required).concat(withinMaxLimit).concat(withinMinLimit),
            initialConstraints: { ...maxLimit, ...minLimit }
          }
        }
      }, {})
    }

    return {}
  }, [courseFeaturesStore.loadState])

  const form = useFormValidation(formFields)

  useEffect(() => {
    form.reset()
  }, [props.show])

  // As this is an edit form, show all validation statuses for fields on initial render
  useEffect(() => {
    form.updateFieldsDisplay(Object.keys(formFields))
  }, [])

  const handleSubmit = async () => {
    const arrayData = Object.keys(form.values).reduce<IUpdateCourseFeature[]>((acc, curr) => {
      return acc.concat({ id: curr, currentValue: form.values[curr] })
    }, [])
    await courseFeaturesStore.updateCourseFeatures(props.patientId, props.courseId, arrayData)

    if (courseFeaturesStore.loadState === 'updateError') {
      showErrorToast(courseFeaturesStore.error || 'Failed to update course variables')

      return
    }

    //leveraging existing useEffects to set the recorded course data panel and
    //simulation panel back to "historical" states
    dosingRecommendationStore.resetStore()

    showSuccessToast('Course Variables updated')
    props.setShow(false)
  }

  const formatDropdownOptions = (allowedValues: string[], defaultValue: string | null) => {
    return allowedValues.map((x) => {
      if (x === defaultValue) {
        return {
          value: x,
          default: true
        }
      }

      return { value: x }
    })
  }

  const formContent = (): JSX.Element[] =>
    [...courseFeaturesStore.courseFeatures].map(([key, curr], idx) => {
      if (curr.type === 'selection') {
        return (
          <div key={key} className={classnames('course-features-dropdown-select', { 'mt-4': idx !== 0 })}>
            <Dropdown
              label={curr.attributes.label}
              fieldState={form.getValidState(key)}
              validationText={form.getValidationMsg(key)}
              data={formatDropdownOptions(curr.attributes.allowedValues, curr.attributes.defaultValue)}
              value={form.inputs[key]}
              onSelect={(item) =>
                form.validateFields(
                  [
                    {
                      field: key,
                      input: item.value
                    }
                  ],
                  'updateFieldsDisplay'
                )
              }
              placeholder='Select an item'
            />
          </div>
        )
      }

      const maxLimit = {
        max: {
          value: curr.attributes.maxValue,
          unit: { id: '0', name: curr.attributes.units }
        }
      }

      const minLimit = {
        min: {
          value: curr.attributes.minValue,
          unit: { id: '0', name: curr.attributes.units }
        }
      }

      return (
        <div key={key}>
          <TextInput
            label={curr.attributes.label}
            fieldState={form.getValidState(key)}
            validationText={form.getValidationMsg(key)}
            required={curr.attributes.isRequired}
            value={form.inputs[key]}
            onChange={(value: string) =>
              form.validateFields([
                {
                  field: key,
                  input: value,
                  constraints: { ...maxLimit, ...minLimit }
                }
              ])
            }
            onBlur={() => form.updateFieldsDisplay([key])}
            units={curr.attributes.units}
            name={`amount-input-edit-${key}`}
          />
        </div>
      )
    }, [])

  return (
    <Modal
      show={props.show}
      onHide={() => {
        props.setShow(false)
      }}
    >
      <InfoModal
        size='s'
        linkComponent={
          <Button
            disabled={!form.valid || courseFeaturesStore.loadState === 'updating'}
            loading={courseFeaturesStore.loadState === 'updating'}
            onClick={handleSubmit}
            variant='primary'
          >
            Update Course
          </Button>
        }
        title='Course Variables'
        message={<div className='position-relative w-100'>{formContent()}</div>}
        onDismiss={() => {
          props.setShow(false)
        }}
      />
    </Modal>
  )
})
