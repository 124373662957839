import { makeAutoObservable } from 'mobx'

import { DosingRecommendationStore } from './DosingRecommendationStore'
import { IDosingRecommendation, IDrugSpecificAttr } from './types'

export class DosingRecommendation implements IDosingRecommendation {
  store: DosingRecommendationStore

  type: string
  drugSpecificAttributes: IDrugSpecificAttr | null | undefined
  attributes: IDosingRecommendation['attributes']

  constructor(store: DosingRecommendationStore, entity: IDosingRecommendation) {
    makeAutoObservable(this, {
      store: false
    })

    this.store = store

    this.type = entity.type
    this.attributes = { ...entity.attributes }
  }
}
