import { ADE, BackArrowButton, Icons } from '@doseme/cohesive-ui'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useBreadcrumbsStore } from '../../../../hooks/useStore'
import { receiveEpicToken, requestEpicHandshake } from '../../../OpenWindowEpic'
import { formatExternalLinkDivs, downloadDocumentURL, getResourcesRoutePrefix } from '../../utils'
import { handleBackButton } from '../../../../utils/navigation'

import '../index.scss'

interface IProps {
  patientId?: string
}

export const ClinicalResources: React.FC<IProps> = (props) => {
  const [vancAdultHover, setVancAdultDivHover] = useState<boolean>(false)
  const [introDivHover, setIntroDivHover] = useState<boolean>(false)
  const [token, setToken] = useState<string>()

  const history = useHistory()
  const breadcrumbsStore = useBreadcrumbsStore()

  useEffect(() => {
    const breadcrumbs = [
      { label: 'Resources', onClick: () => handleBackButton(`/patients/${props.patientId}/resources`, history) },
      { label: 'Clinical resources' }
    ]
    breadcrumbsStore.setBreadcrumbs(breadcrumbs)
  }, [])

  useEffect(() => {
    if (window.env.VENDOR_MODE === 'epic') {
      requestEpicHandshake(receiveEpicToken, setToken)
    }
  }, [])

  return (
    <div data-testid='clinical-resources' className='co-resource-page'>
      <div className='d-flex'>
        <div className='resource-back-btn'>
          <BackArrowButton
            data-testid='back-btn'
            onClick={() => handleBackButton(getResourcesRoutePrefix(props.patientId), history)}
          />
        </div>
        <div className='resource-title clinical-resources'>Clinical Resources</div>
      </div>

      <div className='resource-panel'>
        {formatExternalLinkDivs(
          downloadDocumentURL('/DoseMeRx_Vancomycin_Adult_Model_Selection_Guide.pdf'),
          token,
          <div
            data-testid='clinical-resources-vanc-adult'
            className='download-link-div'
            onMouseLeave={() => setVancAdultDivHover(false)}
            onMouseOver={() => setVancAdultDivHover(true)}
          >
            <div className='link-div-wrapper'>
              <div className='doseme-link-div-title mb-2'>Vancomycin Adult Model Selection Guide</div>
              <div className='doseme-link-div-text'>
                A guide for optimizing the selection of models in adult patients receiving Vancomycin.
              </div>
            </div>
            <div data-testid='clinical-resources-vanc-adult-icon' className='download-link-div-import-icon'>
              <Icons.Import
                data-testid='clinical-resources-vanc-adult-icon'
                width={20}
                height={20}
                background={vancAdultHover ? ADE : undefined}
              />
            </div>
          </div>
        )}

        {formatExternalLinkDivs(
          downloadDocumentURL('/DoseMe%20Introduction%20Bayesian%20Dosing.pdf'),
          token,
          <div
            data-testid='clinical-resources-bayesian-dosing'
            className='download-link-div'
            onMouseLeave={() => setIntroDivHover(false)}
            onMouseOver={() => setIntroDivHover(true)}
          >
            <div className='link-div-wrapper'>
              <div className='doseme-link-div-title mb-2'>Introduction to Bayesian Dosing with DoseMeRx</div>
              <div className='doseme-link-div-text'>
                An overview of Bayesian dosing with DoseMeRx, including the mathematics behind how DoseMeRx works.
              </div>
            </div>
            <div data-testid='clinical-resources-bayesian-dosing-icon' className='download-link-div-import-icon'>
              <Icons.Import width={20} height={20} background={introDivHover ? ADE : undefined} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
