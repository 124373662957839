import { makeAutoObservable } from 'mobx'

import { CourseFeaturesStore } from './CourseFeaturesStore'
import { ICourseFeatureSelection, ICourseFeatureText } from './types'

export class CourseFeatureText implements ICourseFeatureText {
  store: CourseFeaturesStore

  type: 'text'
  id: string
  attributes: ICourseFeatureText['attributes']

  constructor(store: CourseFeaturesStore, entity: ICourseFeatureText) {
    makeAutoObservable(this, {
      store: false
    })

    this.store = store
    this.id = entity.id
    this.type = entity.type
    this.attributes = { ...entity.attributes }
  }
}

export class CourseFeatureSelection implements ICourseFeatureSelection {
  store: CourseFeaturesStore

  type: 'selection'
  id: string
  attributes: ICourseFeatureSelection['attributes']

  constructor(store: CourseFeaturesStore, entity: ICourseFeatureSelection) {
    makeAutoObservable(this, {
      store: false
    })

    this.store = store
    this.id = entity.id
    this.type = entity.type
    this.attributes = { ...entity.attributes }
  }
}
