import { makeAutoObservable } from 'mobx'

import { IAdminClinicianSecurity } from './types'
import { AdminClinicianSecurityStore } from './AdminClinicianSecurityStore'

export class AdminClinicianSecurity implements IAdminClinicianSecurity {
  store: AdminClinicianSecurityStore

  id: string
  type: string
  attributes: IAdminClinicianSecurity['attributes']

  constructor(store: AdminClinicianSecurityStore, entity: IAdminClinicianSecurity) {
    makeAutoObservable(this, {
      store: false
    })

    this.store = store

    this.id = entity.id
    this.type = entity.type
    this.attributes = { ...entity.attributes }
  }
}
