import { makeAutoObservable } from 'mobx'

import { AnalyticsStore } from './AnalyticsStore'
import { IAnalytics } from './types'

export class Analytics implements IAnalytics {
  store: AnalyticsStore

  type: string
  attributes: IAnalytics['attributes']

  constructor(store: AnalyticsStore, entity: IAnalytics) {
    makeAutoObservable(this, {
      store: false
    })

    this.store = store
    this.type = entity.type
    this.attributes = { ...entity.attributes }
  }
}
