import { makeAutoObservable } from 'mobx'

import { IAdminHospitalAddDrugsListItem } from './types'
import { AdminHospitalDrugListStore } from './AdminHospitalDrugListStore'

export class AdminHospitalAddDrugsListItem implements IAdminHospitalAddDrugsListItem {
  store: AdminHospitalDrugListStore

  id: string
  type: 'adminHospitalDrugModelAddable'
  attributes: IAdminHospitalAddDrugsListItem['attributes']

  constructor(store: AdminHospitalDrugListStore, entity: IAdminHospitalAddDrugsListItem) {
    makeAutoObservable(this, {
      store: false
    })

    this.store = store

    this.id = entity.id
    this.type = entity.type
    this.attributes = { ...entity.attributes }
  }
}
