import { useHistory } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import pluralize from 'pluralize'
import classnames from 'classnames'
import { decode } from 'he'
import {
  BackArrowButton,
  Button,
  Dropdown,
  IconButton,
  Icons,
  IDropdownItem,
  LOADING_GREY
} from '@doseme/cohesive-ui'

import {
  useAdminClinicianDetailsStore,
  useBreadcrumbsStore,
  useClinicianStore
} from '../../../../../../hooks/useStore'
import { showErrorToast, showSuccessToast } from '../../../../../../shared/toast'
import {
  buildFormFields,
  buildInputs,
  buildTextInput,
  formatMultipleSelectionOptions,
  formatMultipleSelectionReturnedValues,
  formatMultipleSelectionValues
} from '../../../../../../shared/buildForms'
import { handleBackButton } from '../../../../../../utils/navigation'
import { useFormValidation } from '../../../../../../hooks/useFormValidation'
import { IPutAdminClinicianInvite } from '../../../../../../store/Admin/AdminClinicianDetails/types'
import { getAdminRoutePrefix } from '../../../../utils'

import './index.scss'

interface IProps {
  patientId?: string
}

export const ClinicianInvite: React.FC<IProps> = observer((props) => {
  const [allHospitalAccess, setAllHospitalAccess] = useState<boolean>(false)
  const [trainingHospitalAccess, setTrainingHospitalAccess] = useState<boolean>(false)

  const adminClinicianDetailsStore = useAdminClinicianDetailsStore()
  const clinicianStore = useClinicianStore()
  const breadcrumbsStore = useBreadcrumbsStore()

  const history = useHistory()

  useEffect(() => {
    const breadcrumbs = [
      { label: 'Admin hub', onClick: () => returnToAdminHub() },
      { label: 'Clinicians', onClick: () => returnToCliniciansList() },
      { label: 'Invite new clinician' }
    ]
    breadcrumbsStore.setBreadcrumbs(breadcrumbs)
  }, [])

  useEffect(() => {
    adminClinicianDetailsStore.fetchAdminClinicianInvite()
  }, [])

  const formFields = useMemo(() => {
    if (['loaded', 'updateError'].includes(adminClinicianDetailsStore.loadState) && adminClinicianDetailsStore.adminClinicianInvite) {

      const hospitals = formatMultipleSelectionOptions(adminClinicianDetailsStore.adminClinicianInvite.attributes['hospitalAccess'].attributes.allowedValues)
      const hasTrainingHospitalAccess = hospitals.some((hospital) => hospital.id === '1')
      setTrainingHospitalAccess(hasTrainingHospitalAccess)

      return buildFormFields(adminClinicianDetailsStore.adminClinicianInvite)
    }

    return {}
  }, [adminClinicianDetailsStore.loadState])

  const form = useFormValidation(formFields)

  useEffect(() => {
    if (adminClinicianDetailsStore.loadState === 'loaded') {
      form.reset()
    }
  }, [adminClinicianDetailsStore.loadState, formFields])

  const selectRoles = (items: IDropdownItem[]) => {
    // Set access for all hospitals if superadmin role is selected and user has access to the training hospital
    let newAllHospitalAccess = false
    items.forEach((item: IDropdownItem) => {
      if (item.id === '2' && trainingHospitalAccess) {
        newAllHospitalAccess = true
      }
    })
    setAllHospitalAccess(newAllHospitalAccess)

    const updateFields = [
      {
        field: 'userSpecificRoles',
        input: formatMultipleSelectionReturnedValues(items)
      }
    ]

    // Add a placeholder value for hospitalAccess so form is not blocked by the required validation
    if (newAllHospitalAccess && (!form.inputs['hospitalAccess'] || form.inputs['hospitalAccess']?.length === 0)) {
      updateFields.push(
        {
          field: 'hospitalAccess',
          input: ['all']
        }
      )
    }

    // Remove hospitalAccess placeholder
    if (!newAllHospitalAccess && form.inputs['hospitalAccess']?.includes('all')) {
      updateFields.push(
        {
          field: 'hospitalAccess',
          input: undefined
        }
      )
    }

    form.validateFields(
      updateFields,
      'updateFieldsDisplay'
    )
  }

  const removeHospital = (hospital: IDropdownItem) => {
    const filteredHospitals = form.inputs['hospitalAccess'].filter((curr: IDropdownItem) => curr.id !== hospital.id)

    form.validateFields(
      [
        {
          field: 'hospitalAccess',
          input: formatMultipleSelectionReturnedValues(filteredHospitals)
        }
      ],
      'updateFieldsDisplay'
    )
  }

  const formContent = (): JSX.Element => {
    const displayOrder = ['email', 'title']
    if (
      ['loaded', 'updateError'].includes(adminClinicianDetailsStore.loadState) &&
      adminClinicianDetailsStore.adminClinicianInvite
    ) {
      return (
        <div className='clinician-invite-form'>
          {buildInputs(adminClinicianDetailsStore.adminClinicianInvite, form, displayOrder, formFields)}
          <div className='d-flex'>
            <div className='mr-3'>
              {buildTextInput(
                adminClinicianDetailsStore.adminClinicianInvite.attributes['firstName'],
                form,
                formFields['firstName'],
                'firstName'
              )}
            </div>
            {buildTextInput(
              adminClinicianDetailsStore.adminClinicianInvite.attributes['lastName'],
              form,
              formFields['lastName'],
              'lastName'
            )}
          </div>
          {clinicianStore.clinician?.attributes.isSuperAdmin &&
            adminClinicianDetailsStore.adminClinicianInvite.attributes['userSpecificRoles'] &&
            <div>
              <Dropdown
                multiSelect
                showOptional={!adminClinicianDetailsStore.adminClinicianInvite.attributes['userSpecificRoles'].attributes.isRequired}
                label={adminClinicianDetailsStore.adminClinicianInvite.attributes['userSpecificRoles'].attributes.label}
                fieldState={form.getValidState('userSpecificRoles')}
                validationText={form.getValidationMsg('userSpecificRoles')}
                data={formatMultipleSelectionOptions(
                  adminClinicianDetailsStore.adminClinicianInvite.attributes['userSpecificRoles'].attributes.allowedValues
                )}
                values={formatMultipleSelectionValues(form.inputs['userSpecificRoles'])}
                onSelectMultiple={selectRoles}
                placeholder='Select an item'
              />
            </div>
          }
          <div>
            <Dropdown
              selectedLabel={hospitalSelectLabel()}
              multiSelect
              showSearchThreshold={5}
              showOptional={!adminClinicianDetailsStore.adminClinicianInvite.attributes['hospitalAccess'].attributes.isRequired}
              label={adminClinicianDetailsStore.adminClinicianInvite.attributes['hospitalAccess'].attributes.label}
              fieldState={form.getValidState('hospitalAccess')}
              validationText={form.getValidationMsg('hospitalAccess')}
              data={formatMultipleSelectionOptions(
                adminClinicianDetailsStore.adminClinicianInvite.attributes['hospitalAccess'].attributes.allowedValues
              )}
              values={formatMultipleSelectionValues(form.inputs['hospitalAccess'])}
              onSelectMultiple={(items) =>
                form.validateFields(
                  [
                    {
                      field: 'hospitalAccess',
                      input: formatMultipleSelectionReturnedValues(items)
                    }
                  ],
                  'updateFieldsDisplay'
                )
              }
              placeholder='Select hospitals'
              disabled={allHospitalAccess}
            />
          </div>
          <div className='clinician-invite-hospitals'>
            {buildSelectedHospitals()}
          </div>
        </div>
      )
    }

    return (
      <div className='clinician-invite-spinner'>
        <Icons.ThinSpinner strokeWidth={12} r={32} stroke={LOADING_GREY} width='64px' />
      </div>
    )
  }

  const hospitalSelectLabel = (): string => {
    if (allHospitalAccess) {
      return 'All'
    }

    if (form.inputs['hospitalAccess']?.length > 0) {
      return `${form.inputs['hospitalAccess']?.length} ${pluralize('hospital', form.inputs['hospitalAccess']?.length)} selected`
    }

    return 'Select hospitals'
  }

  const buildSelectedHospitals = () => {
    if (allHospitalAccess) {
      return (
        <div className='clinician-invite-hospitals-none-all-selected'>
          All hospitals.
        </div>
      )
    }

    if (form.inputs['hospitalAccess']?.length) {
      const selectedModels = form.inputs['hospitalAccess'].map((hospital: IDropdownItem) => {
        return (
          <div
            className='clinician-invite-hospitals-selected'
            key={hospital.id}>
            {decode(hospital.label || '')}
            <IconButton
              size='32px'
              onClick={() => removeHospital(hospital)}
              background='none'
              className='clinician-invite-hospitals-selected-icon-button'
            >
              <Icons.CloseActive />
            </IconButton>
          </div>
        )
      }, [])

      return (
        <>
          <div className='clinician-invite-hospitals-selected-title'>Selected hospitals:</div>
          <div
            className={classnames('clinician-invite-hospitals-selected-items',
              { 'clinician-invite-hospitals-selected-items-scroll': form.inputs['hospitalAccess']?.length > 5 }
            )}>
            {selectedModels}
          </div>
        </>
      )
    }

    return (
      <div className='clinician-invite-hospitals-none-all-selected'>
        No hospitals selected.
      </div>
    )
  }

  function doesNewClinicianHaveSuperadminRole(userSpecificRoles: Record<string, any>) {
    return userSpecificRoles?.find((role: { id: string }) => role.id === '2')
  }

  const isSendButtonEnabled = !form.valid || !form.values['hospitalAccess'] || form.values['hospitalAccess']?.length === 0
    || ['updating', 'loading', 'initial'].includes(adminClinicianDetailsStore.loadState)

  const invitePatient = async (createAnother?: boolean) => {
    // Add Training hospital to hospitalAccess if new clinician is a superadmin
    // if current user has access to training hospital as list requires at least 1 hospital
    if (doesNewClinicianHaveSuperadminRole(form.values['userSpecificRoles']) && trainingHospitalAccess) {
      form.values['hospitalAccess'] = [{ id: '1', label: 'Training', value: '1' }]
    }

    const newClinician: IPutAdminClinicianInvite = {
      type: 'adminClinicianInviteWrite',
      attributes: {
        firstName: form.values['firstName'],
        lastName: form.values['lastName'],
        hospitalAccess: form.values['hospitalAccess'],
        userSpecificRoles: form.values['userSpecificRoles'],
        email: form.values['email'].trim(),
        title: form.values['title']
      }
    }

    const result = await adminClinicianDetailsStore.createAdminClinicianInvite(newClinician)

    if (!result || adminClinicianDetailsStore.loadState === 'loadError') {
      showErrorToast(adminClinicianDetailsStore.error || 'Failed invite clinician')

      return
    }

    showSuccessToast('Clinician invited')

    if (createAnother) {
      form.reset()
    } else {
      handleBackButton(getAdminRoutePrefix(props.patientId) + '/clinicians', history)
    }
  }

  const returnToCliniciansList = () => {
    history.push(getAdminRoutePrefix(props.patientId) + '/clinicians')
  }

  const returnToAdminHub = () => {
    history.push(getAdminRoutePrefix(props.patientId))
  }

  return (
    <div className='co-clinician-invite-page'>
      <div className='clinician-invite-heading'>
        <div className='clinician-invite-back-btn'>
          <BackArrowButton
            data-testid='back-btn'
            onClick={() => handleBackButton(getAdminRoutePrefix(props.patientId) + '/clinicians', history)}
          />
        </div>
        <div className='clinician-invite-title'>
          {'Clinicians: '}
          <b>Invite new clinician</b>
        </div>
      </div>
      <div className='clinician-invite-content-panel'>
        <div className='clinician-invite-content-title'>Clinician details & access</div>
        <div className='clinician-invite-form-content'>
          {formContent()}
          <div className='clinician-invite-button-row'>
            <Button
              disabled={isSendButtonEnabled}
              loading={['updating', 'loading'].includes(adminClinicianDetailsStore.loadState)}
              onClick={() => invitePatient()}
              variant='primary'
              className='clinician-invite-button'
            >
              Send invite
            </Button>
            <Button
              disabled={isSendButtonEnabled}
              loading={['updating', 'loading'].includes(adminClinicianDetailsStore.loadState)}
              onClick={() => invitePatient(true)}
              variant='primary-outline'
            >
              {'Send & add another'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
})
