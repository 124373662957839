import { makeAutoObservable } from 'mobx'

import { CourseStore } from './CourseStore'
import { ICourse } from './types'

export class Course implements ICourse {
  store: CourseStore

  id: string
  type: string
  attributes: ICourse['attributes']

  constructor(store: CourseStore, entity: ICourse) {
    makeAutoObservable(this, {
      store: false
    })

    this.store = store

    this.id = entity.id
    this.type = entity.type
    this.attributes = { ...entity.attributes }
  }
}
