import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'
import { BackArrowButton, Button, IBreadcrumb, WHITE } from '@doseme/cohesive-ui'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { IProps } from '../../types'
import {
  useAdminVendorListStore,
  useAdminVendorSiteDetailsStore,
  useAdminVendorSiteListStore,
  useBreadcrumbsStore
} from '../../../../../../../hooks/useStore'
import { showErrorToast, showSuccessToast } from '../../../../../../../shared/toast'
import { useFormValidation } from '../../../../../../../hooks/useFormValidation'
import { buildFormFields, buildInputs } from '../../../../../../../shared/buildForms'
import { getAdminRoutePrefix } from '../../../../../utils'
import { handleBackButton } from '../../../../../../../utils/navigation'

import './index.scss'

export const AddVendorSite: React.FC<IProps> = observer((props) => {
  const [vendorName, setVendorName] = useState<string | undefined>(undefined)

  const adminVendorListStore = useAdminVendorListStore()
  const adminVendorSiteListStore = useAdminVendorSiteListStore()
  const adminVendorSiteDetailsStore = useAdminVendorSiteDetailsStore()
  const breadcrumbsStore = useBreadcrumbsStore()

  const history = useHistory()

  useEffect(() => {
    if (adminVendorListStore.loadState === 'loaded') {
      const breadcrumbs: IBreadcrumb[] = [
        { label: 'Admin hub', onClick: () => returnToAdminHub() },
        { label: 'Vendors', onClick: () => returnToVendors() },
        {
          label: vendorName || '',
          onClick: () => handleBackButton(getAdminRoutePrefix(props.patientId) + `/vendors/${props.vendorId}/sites`, history)
        },
        { label: 'Create vendor site' }
      ]
      breadcrumbsStore.setBreadcrumbs(breadcrumbs)
    }
  }, [vendorName])

  useEffect(() => {
    adminVendorSiteDetailsStore.fetchAdminAddVendorSiteDetails(props.vendorId)

    if (adminVendorListStore.loadState === 'initial') {
      adminVendorListStore.fetchVendors()
    }
  }, [])

  useEffect(() => {
    if (adminVendorListStore.loadState === 'loaded') {
      const vendorName = adminVendorListStore.adminVendors.get(props.vendorId)?.attributes.name
      setVendorName(vendorName)
    }
  }, [adminVendorListStore.loadState])

  const formFields = useMemo(() => {
    if (
      adminVendorSiteDetailsStore.settingsLoadStates.addVendorSiteDetails === 'loaded' &&
      adminVendorSiteDetailsStore.adminAddVendorSiteDetails
    ) {
      return buildFormFields(adminVendorSiteDetailsStore.adminAddVendorSiteDetails)
    }

    return {}
  }, [adminVendorSiteDetailsStore.settingsLoadStates.addVendorSiteDetails])

  const form = useFormValidation(formFields)

  useEffect(() => {
    if (adminVendorSiteDetailsStore.settingsLoadStates.addVendorSiteDetails === 'loaded') {
      form.reset()
    }
  }, [adminVendorSiteDetailsStore.settingsLoadStates.addVendorSiteDetails, formFields])

  const formContent = (): JSX.Element => {
    // FIXME - The hospital "name" requirement in the payload will be removed with IFE-918
    const displayOrder = ['hospital', 'name', 'key']
    if (
      adminVendorSiteDetailsStore.settingsLoadStates.addVendorSiteDetails === 'loaded' &&
      adminVendorSiteDetailsStore.adminAddVendorSiteDetails &&
      formFields
    ) {
      return (
        <div className='add-vendor-inputs-wrapper'>
          {buildInputs(adminVendorSiteDetailsStore.adminAddVendorSiteDetails, form, displayOrder, formFields)}
        </div>
      )
    }

    return <></>
  }

  const returnToVendors = () => {
    history.push(getAdminRoutePrefix(props.patientId) + '/vendors/')
  }

  const returnToAdminHub = () => {
    history.push(getAdminRoutePrefix(props.patientId))
  }

  const submitVendorSite = async () => {
    const vendorSiteId = await adminVendorSiteListStore.createVendorSite(
      props.vendorId,
      form.inputs['key'],
      form.inputs['name'],
      form.inputs['hospital']
    )

    if (!vendorSiteId || adminVendorSiteListStore.loadState === 'updateError') {
      showErrorToast(adminVendorSiteListStore.error || 'Failed to create vendor site')

      return
    }

    if (vendorSiteId) {
      showSuccessToast('Vendor Site created')
      history.push(getAdminRoutePrefix(props.patientId) + `/vendors/${props.vendorId}/sites/${vendorSiteId}`,
        { from: `/admin/vendors/${props.vendorId}/sites` })
    }
  }

  return (
    <div data-testid='add-vendor' className='co-add-vendor-site-page'>
      <div className='add-vendor-site-heading'>
        <div className='add-vendor-site-back-btn'>
          <BackArrowButton
            data-testid='back-btn'
            onClick={() =>
              handleBackButton(getAdminRoutePrefix(props.patientId) + `/vendors/${props.vendorId}/sites`, history)
            }
          />
        </div>
        <div className='add-vendor-site-title'>
          {vendorName}: <b>Add vendor site</b>
        </div>
      </div>
      <div className='add-vendor-site-content-panel'>
        <div className='add-vendor-site-content-title'>Site details</div>
        <div className='add-vendor-site-inputs-wrapper'>
          {formContent()}
          <Button
            disabled={
              ['loading', 'updating'].includes(adminVendorSiteListStore.loadState) ||
              !form.inputs['hospital'] ||
              !form.inputs['name'] ||
              !form.inputs['key']
            }
            loading={
              ['loading', 'updating'].includes(adminVendorSiteDetailsStore.settingsLoadStates.addVendorSiteDetails) ||
              ['loading', 'updating'].includes(adminVendorSiteListStore.loadState)
            }
            className='add-vendor-site-submit-btn'
            onClick={submitVendorSite}
            variant='primary'
          >
            Save & create site&nbsp;&nbsp;
            <FontAwesomeIcon color={WHITE} icon={faArrowRight} />
          </Button>
        </div>
      </div>
    </div>
  )
})
