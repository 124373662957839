import { Modal, InfoModal, InfoBubble } from '@doseme/cohesive-ui'

import { IModalProps } from '../../../../../../../../../../types'
import { ModelFitIndicatorIcons } from '../ModelFitIndicatorIcons'

import './index.scss'

export const ModelFitPopupModal: React.FC<IModalProps> = (props) => {
  const getModalContent = (): JSX.Element => {
    return (
      <div className='model-fit-modal'>
        <div className='model-fit-text-div'>
          <div className='model-fit-subheading'>What is Individualized Model Fit?</div>
          <p className='model-fit-paragraph'>
            Model Fit is a term used to describe how well the selected model fits the observed data.
            In general, a better fit corresponds to a more accurate dosing recommendation.
          </p>
        </div>
        <div className='model-fit-text-div model-fit-text-div-dark'>
          <div className='model-fit-subheading'>
            <div className='model-fit-modal-icons'>
              <ModelFitIndicatorIcons modelFitStatus={{ message: '', hasGoodModelFit: true }} />
            </div>
            Good fit
          </div>
          <p className='model-fit-paragraph'>
            A <i>Good Fit</i> indicates that the measured drug levels from the patient are in agreement with
            the model. This is usually indicated by the blue line being closer or even crossing through
            the recorded drug level(s) on the pharmacokinetic graph. In general, a <i>Good Fit</i> suggests improved
            accuracy of dosing predictions and a higher likelihood of obtaining the therapeutic target (e.g. AUC24).
          </p>
          <hr className='model-fit-horizontal-line' />
          <div className='model-fit-subheading'>
            <div className='model-fit-modal-icons'>
              <ModelFitIndicatorIcons modelFitStatus={{ message: '', hasGoodModelFit: false }} />
            </div>
            Not ideal, needs review
          </div>
          <p className='model-fit-paragraph'>
            This suggests that a closer evaluation of the recommendations may be beneficial.  Consider taking
            additional steps to improve model fit as they may be helpful in increasing the accuracy of future
            predictions (see below).
          </p>
          <hr className='model-fit-horizontal-line' />
          <p className='model-fit-paragraph model-fit-notes'>
            Note: Model fit is intended to be used as a guide and should not supersede clinical judgement.
          </p>
        </div>
        <div className='model-fit-text-div'>
          <div className='model-fit-subheading'>Tips for improving model fit:</div>
          <ol className='model-fit-list'>
            <li className='model-fit-list-item'>Double check that lab results, patient demographics, and dosing information are correct.</li>
            <li className='model-fit-list-item'>Consider obtaining another level at a different time point (relative to dose administration).</li>
            <li className='model-fit-list-item'>
              If there is evidence that the patient's pharmacokinetics have shifted substantially (e.g. significant change in clearance and/or
              volume of distribution), consider deselecting older drug levels that may no longer be representative of the patient's current clinical status.
            </li>
            <li className='model-fit-list-item'>Consider switching to a different model if appropriate.</li>
          </ol>
        </div>
        {infoBubble()}
        <p className='model-fit-bottom-text'>
          For more information, head over to the Resources section or contact the DoseMeRx support team directly - we're avaiable 24/7.
        </p>
      </div>
    )
  }

  const infoBubble = () => {
    const title = <div className='model-fit-subheading'>How it's calculated:</div>

    return (
      <InfoBubble bubbleTitle={title}>
        <p className='model-fit-paragraph-infobubble'>
          When a model is fitted, DoseMeRx works to provide an estimate of the patient's specific pharmacokinetics
          based on the model selected. Model fit distills the difference between the model predicted vs. measured drug
          levels down to a single value. This value is used to inform the model fit status indicator that is displayed.
        </p>
      </InfoBubble>
    )
  }

  return (
    <Modal show={props.show} onHide={() => props.setShow(false)}>
      <InfoModal
        size='m'
        title='Individualized Model Fit Information'
        message={getModalContent()}
        onDismiss={() => props.setShow(false)}
        limitHeightToWindow
      />
    </Modal>
  )
}
