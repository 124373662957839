import { ObservableMap } from 'mobx'

import { IAdminVendorListItem } from '../../../../store/Admin/AdminVendorList/types'

export const searchVendors = (
  searchText: string,
  vendors: ObservableMap<string, IAdminVendorListItem>
): IAdminVendorListItem[] => {
  return [...vendors].reduce((acc: IAdminVendorListItem[], [key, curr]) => {
    if (curr.attributes.name.toLowerCase().includes(searchText.toLowerCase())) {
      return acc.concat(curr)
    }

    return acc
  }, [])
}
