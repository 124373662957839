import moment from 'moment-timezone'

import { rawDateOnly, rawTimeOnly } from '../../../../../../../../../../../constants/timeFormat'
import { IAdministration } from '../../../../../../../../../../../store/administrations/types'
import { IObservation } from '../../../../../../../../../../../store/observations/types'

// These methods all return naive Date objects (which are assumed to be in the hospital timezone).

export const calculateMinNextDoseTime = (
  hospitalTimezone: string,
  lastAdministration?: IAdministration,
  firstSeCrObservation?: IObservation
): Date | null => {
  if (lastAdministration && lastAdministration.attributes.infusionLength) {
    const administeredAt = moment.tz(lastAdministration.attributes.administeredAt.value, hospitalTimezone)

    const outputMoment =
      lastAdministration.attributes.infusionLength.value > 0
        ? administeredAt.add(lastAdministration.attributes.infusionLength.value, 'hours')
        : administeredAt.add(1, 'minute')

    return new Date(outputMoment.format(rawDateOnly) + 'T' + outputMoment.format(rawTimeOnly))
  }

  if (firstSeCrObservation) {
    const observedAt = moment.tz(firstSeCrObservation.attributes.observedAt.value, hospitalTimezone)

    return new Date(observedAt.format(rawDateOnly) + 'T' + observedAt.format(rawTimeOnly))
  }

  return null
}

export const calculateMaxNextDoseTime = (
  hospitalTimezone: string,
  maximumDosingIntervalHours: number,
  lastAdministration?: IAdministration,
  lastSeCrObservation?: IObservation
): Date | null => {
  if (lastAdministration) {
    const administeredAt = moment.tz(lastAdministration.attributes.administeredAt.value, hospitalTimezone)
    const outputMoment = administeredAt.add(maximumDosingIntervalHours, 'hours')

    return new Date(outputMoment.format(rawDateOnly) + 'T' + outputMoment.format(rawTimeOnly))
  }

  if (lastSeCrObservation) {
    const observedAt = moment.tz(lastSeCrObservation.attributes.observedAt.value, hospitalTimezone)
    const outputMoment = observedAt.add(7, 'days')

    return new Date(outputMoment.format(rawDateOnly) + 'T' + outputMoment.format(rawTimeOnly))
  }

  return null
}

export const calculateDefaultNextDoseTime = (
  hospitalTimezone: string,
  defaultIntervalHours: number,
  lastAdministrationDateString?: string,
  lastSeCrObservationDateString?: string
): Date => {
  if (lastAdministrationDateString) {
    const administeredAt = moment.tz(lastAdministrationDateString, hospitalTimezone)
    const outputMoment = administeredAt.add(defaultIntervalHours, 'hours')

    return new Date(outputMoment.format(rawDateOnly) + 'T' + outputMoment.format(rawTimeOnly))
  }

  if (lastSeCrObservationDateString) {
    const observedAt = moment.tz(lastSeCrObservationDateString, hospitalTimezone)

    return new Date(observedAt.format(rawDateOnly) + 'T' + observedAt.format(rawTimeOnly))
  }

  return new Date()
}
