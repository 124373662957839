import { IDateState, ITimeState } from '@doseme/cohesive-ui'
import { cmToInches, inchesToCm, kgToLb, lbToKg } from '@doseme/typescript-utils'
import { format } from 'date-fns'
import moment from 'moment-timezone'

import { dateFnsRawDateOnly } from '../../constants/timeFormat'
import { ILimit, ILimitsWithUnit, IPartialLimit } from '../../store/types'
import { IRuleParams, TValidationRule, ValidationError } from '../../types/validation'
import { isValidDSTDateTime } from '../dates'
import { IBetweenDates, IAfterDate, IBeforeDate } from './types'

// With the exception of isRequired, all rules should pass if given null input.

export const isRequired: TValidationRule = (params: IRuleParams<any>) => {
  const { input } = params

  if (input === undefined || input === null || (typeof input === 'string' && input.trim().length === 0)) {
    throw new ValidationError('Required')
  }
}

export const isRequiredMultipleText: TValidationRule = (params: IRuleParams<any>) => {
  const { input } = params

  for (const item of input) {
    if (item.label === undefined || item.label === null || (typeof item.label === 'string' && item.label.trim().length === 0)) {
      throw new ValidationError('Required')
    }
  }
}

export const noDuplicatesMultipleText: TValidationRule = (params: IRuleParams<any>) => {
  const { input } = params
  const distinct: string[] = []

  for (const item of input) {
    if (!distinct.includes(item.label)) {
      distinct.push(item.label)
    }
  }

  if (distinct?.length !== input?.length) {
    throw new ValidationError('Each item must be unique')
  }
}

export const isStringValidNumber: TValidationRule = (params: IRuleParams<string>) => {
  // only perform check if input exists
  if (!params.input) {
    return
  }

  const { input } = params

  const isValidNumberRegex = /^(-?(\d+|\d+\.\d+))$/

  if (!isValidNumberRegex.test(input.toString())) {
    throw new ValidationError('Must be a valid number')
  }
}

export const isLessThanFourDecimalPlaces: TValidationRule = (params: IRuleParams<string>) => {
  // only perform check if input exists
  if (!params.input) {
    return
  }

  // must first ensure that the input characters are valid
  isStringValidNumber(params)
  const { input } = params

  if (input.toString().includes('.') && input.toString().split('.')[1].length > 3) {
    throw new ValidationError('Max. 3 decimal places')
  }
}

export const isStringValidInteger: TValidationRule = (params: IRuleParams<string>) => {
  // only perform check if input exists
  if (!params.input) {
    return
  }

  const { input } = params

  const isValidNumberRegex = /^(-?(\d+))$/

  if (!isValidNumberRegex.test(input.toString())) {
    throw new ValidationError('Must be a valid integer')
  }
}

export const isStringPositiveNumber: TValidationRule = (params: IRuleParams<string>) => {
  // only perform check if input exists
  if (!params.input) {
    return
  }

  isStringValidNumber(params)
  const { input } = params

  if (Number.parseFloat(input.toString()) <= 0) {
    throw new ValidationError('Must be greater than 0')
  }
}

export const isStringWithinMaxNumericLimit: TValidationRule = (params: IRuleParams<string, IPartialLimit>) => {
  // only perform check if input exists
  if (!params.input || !params.constraints?.max) {
    return
  }

  isStringValidNumber(params)
  const value = parseFloat(params.input)

  if (value > params.constraints.max.value) {
    throw new ValidationError(
      `Must not be greater than ${params.constraints.max.value} ${params.constraints.max.unit?.name || ''}`
    )
  }
}

export const isStringWithinMinNumericLimit: TValidationRule = (params: IRuleParams<string, IPartialLimit>) => {
  // only perform check if input exists
  if (!params.input || !params.constraints?.min) {
    return
  }

  isStringValidNumber(params)
  const value = parseFloat(params.input)

  if (value < params.constraints.min.value) {
    throw new ValidationError(
      `Must not be less than ${params.constraints.min.value} ${params.constraints.min.unit?.name || ''}`
    )
  }
}

export const isStringWithinNumericLimits: TValidationRule = (params: IRuleParams<string, ILimit>) => {
  isStringWithinMinNumericLimit(params)
  isStringWithinMaxNumericLimit(params)
}

export const isStringWithinPatientObsLimits: TValidationRule = (params: IRuleParams<string, ILimitsWithUnit>) => {
  // only perform check if input exists
  if (!params.input) {
    return
  }

  isStringValidNumber(params)
  const { input, constraints } = params
  const value = parseFloat(input)
  let convertedValue = value

  if (constraints.unit?.name === 'kg' && constraints.limits.max.unit?.name === 'lb') {
    convertedValue = kgToLb({ value, unit: 'kg' }).value
  }

  if (constraints.unit?.name === 'lb' && constraints.limits.max.unit?.name === 'kg') {
    convertedValue = lbToKg({ value, unit: 'lb' }).value
  }

  if (constraints.unit?.name === 'cm' && constraints.limits.max.unit?.name === 'in') {
    convertedValue = cmToInches({ value, unit: 'cm' }).value
  }

  if (constraints.unit?.name === 'in' && constraints.limits.max.unit?.name === 'cm') {
    convertedValue = inchesToCm({ value, unit: 'in' }).value
  }

  if (convertedValue > constraints.limits.max.value) {
    throw new ValidationError(
      `Must not be greater than ${constraints.limits.max.value} ${constraints.limits.max.unit?.name || ''}`
    )
  }

  if (convertedValue < constraints.limits.min.value) {
    throw new ValidationError(
      `Must not be less than ${constraints.limits.min.value} ${constraints.limits.min.unit?.name || ''}`
    )
  }
}

export const isValidDateState: TValidationRule = (params: IRuleParams<IDateState>) => {
  // only perform check if input exists
  if (!params.input) {
    return
  }

  const { input } = params
  let yearString = input.yyyy
  while (yearString.length < 4) yearString = '0' + yearString

  let monthString = input.mm
  while (monthString.length < 2) monthString = '0' + monthString

  let dayString = input.dd
  while (dayString.length < 2) dayString = '0' + dayString

  if (!moment(`${yearString}-${monthString}-${dayString}`).isValid()) {
    throw new ValidationError('Invalid date')
  }
}

export const isValidTimeState: TValidationRule = (params: IRuleParams<ITimeState>) => {
  // only perform check if input exists
  if (!params.input) {
    return
  }

  const { input } = params

  if (!input.hh) {
    throw new ValidationError('Invalid time')
  }

  // Valid as a two character string from 00 - 23
  const validHourCharsRegex = /^(2[0-3]|[01][0-9])$/
  const hhString = input.hh.toString().match(validHourCharsRegex)?.[1]

  if (!hhString) {
    throw new ValidationError('Invalid time')
  }

  isStringValidInteger({ input: hhString, constraints: undefined })
  isStringWithinNumericLimits({
    input: hhString,
    constraints: {
      min: { value: 0 },
      max: { value: 23 }
    }
  })

  if (!input.mm) {
    throw new ValidationError('Invalid time')
  }

  // Valid as a two character string from 00 - 59
  const validMinuteCharsRegex = /^([0-5][0-9])$/
  const mmString = input.mm.toString().match(validMinuteCharsRegex)?.[1]

  if (!mmString) {
    throw new ValidationError('Invalid time')
  }

  isStringValidInteger({ input: mmString, constraints: undefined })
  isStringWithinNumericLimits({
    input: mmString,
    constraints: {
      min: { value: 0 },
      max: { value: 59 }
    }
  })
}

export const isHistoricalDateState: TValidationRule = (params: IRuleParams<IDateState>) => {
  // only perform check if input exists
  if (!params.input) {
    return
  }

  isValidDateState(params)
  const { input } = params

  var yearString = input.yyyy
  while (yearString.length < 4) yearString = '0' + yearString

  if (!moment(`${yearString}-${input.mm}-${input.dd}`).isBefore(moment())) {
    throw new ValidationError('Must be in the past')
  }
}

export const isFutureDateState: TValidationRule = (params: IRuleParams<IDateState>) => {
  // only perform check if input exists
  if (!params.input) {
    return
  }

  isValidDateState(params)
  const { input } = params

  if (!moment(`${input.yyyy}-${input.mm}-${input.dd}`).isAfter(moment())) {
    throw new ValidationError('Must be in the future')
  }
}

export const isDateStateAfter1900: TValidationRule = (params: IRuleParams<IDateState>) => {
  // only perform check if input exists
  if (!params.input) {
    return
  }

  isValidDateState(params)
  const { input } = params

  var yearString = input.yyyy
  while (yearString.length < 4) yearString = '0' + yearString

  if (parseInt(moment(`${yearString}-${input.mm}-${input.dd}`).format('YYYY')) < 1900) {
    throw new ValidationError('Must be after 1900')
  }
}

export const isTimeStateDSTValid: TValidationRule = (params: IRuleParams<ITimeState, { date: Date; tz: string }>) => {
  // constraints are unused for isValidTimeState
  isValidTimeState(params)

  const { input, constraints } = params

  // only perform check if inputs exist
  if (!(input && constraints)) {
    return
  }

  const inputDate = format(constraints.date, dateFnsRawDateOnly)

  if (!isValidDSTDateTime(inputDate, input, constraints.tz)) {
    throw new ValidationError('Invalid time')
  }
}

export const isBetweenDateTime: TValidationRule = (params: IRuleParams<ITimeState, IBetweenDates>) => {
  // constraints are unused for isValidTimeState
  isValidTimeState(params)

  const { input, constraints } = params

  // only perform check if inputs exist
  if (!(input && constraints && (constraints.minDate || constraints.maxDate))) {
    return
  }

  // Check min date
  if (constraints.minDate && !moment(constraints.date).isSameOrAfter(moment(constraints.minDate))) {
    throw new ValidationError(`Minimum date is: ${constraints.minDate}`)
  }

  // Check max date
  if (constraints.maxDate && !moment(constraints.date).isSameOrBefore(moment(constraints.maxDate))) {
    throw new ValidationError(`Maximum date is: ${constraints.maxDate}`)
  }
}

export const isBeforeDateTime: TValidationRule = (params: IRuleParams<ITimeState, IBeforeDate>) => {
  // constraints are unused for isValidTimeState
  isValidTimeState(params)

  const { input, constraints } = params

  // only perform check if inputs exist
  if (!(input && constraints)) {
    return
  }

  // Check max date
  if (constraints.maxDate && !moment(constraints.date).isSameOrBefore(moment(constraints.maxDate))) {
    throw new ValidationError(`Maximum date is: ${constraints.maxDate}`)
  }
}

export const isAfterDateTime: TValidationRule = (params: IRuleParams<ITimeState, IAfterDate>) => {
  // constraints are unused for isValidTimeState
  isValidTimeState(params)

  const { input, constraints } = params

  // only perform check if inputs exist
  if (!(input && constraints)) {
    return
  }

  // Check min date
  if (constraints.minDate && !moment(constraints.date).isSameOrAfter(moment(constraints.minDate))) {
    throw new ValidationError(`Minimum date is: ${constraints.minDate}`)
  }
}
