import { ADE, BackArrowButton, Icons } from '@doseme/cohesive-ui'
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useBreadcrumbsStore, useClinicianStore } from '../../../../hooks/useStore'
import { receiveEpicToken, requestEpicHandshake } from '../../../OpenWindowEpic'
import { formatExternalLinkDivs, downloadDocumentURL, getResourcesRoutePrefix } from '../../utils'
import { handleBackButton } from '../../../../utils/navigation'

import '../index.scss'

interface IProps {
  patientId?: string
}

export const UserGuides: React.FC<IProps> = (props) => {
  const [compDivHover, setCompDivHover] = useState<boolean>(false)
  const [adminDivHover, setAdminDivHover] = useState<boolean>(false)
  const [dataDivHover, setDataDivHover] = useState<boolean>(false)
  const [token, setToken] = useState<string>()

  const history = useHistory()
  const breadcrumbsStore = useBreadcrumbsStore()
  const clinicianStore = useClinicianStore()

  useEffect(() => {
    const breadcrumbs = [
      { label: 'Resources', onClick: () => handleBackButton(`/patients/${props.patientId}/resources`, history) },
      { label: 'User guides' }
    ]
    breadcrumbsStore.setBreadcrumbs(breadcrumbs)
  }, [])

  useEffect(() => {
    if (window.env.VENDOR_MODE === 'epic') {
      requestEpicHandshake(receiveEpicToken, setToken)
    }
  }, [])

  return (
    <div data-testid='user-guide' className='co-resource-page'>
      <div className='d-flex'>
        <div className='resource-back-btn'>
          <BackArrowButton
            data-testid='back-btn'
            onClick={() => handleBackButton(getResourcesRoutePrefix(props.patientId), history)}
          />
        </div>
        <div className='resource-title user-guides'>User Guides</div>
      </div>

      <div className='resource-panel'>
        <div className='resource-panel-title'>
          A collection of informative, detailed guides to help you understand, and get the most out of DoseMeRx.
        </div>

        {clinicianStore.canViewAdminHub() &&
          formatExternalLinkDivs(
            downloadDocumentURL('/DoseMeRx_Admin_Guide.pdf'),
            token,
            <div
              data-testid='admin-guide-comp'
              className='download-link-div'
              onMouseLeave={() => setAdminDivHover(false)}
              onMouseOver={() => setAdminDivHover(true)}
            >
              <div className='link-div-wrapper'>
                <div className='doseme-link-div-title'>Admin guide</div>
              </div>
              <div data-testid='admin-guide-comp-icon' className='download-link-div-import-icon'>
                <Icons.Import width={20} height={20} background={adminDivHover ? ADE : undefined} />
              </div>
            </div>
          )}

        {formatExternalLinkDivs(
          downloadDocumentURL(
            window.env.VENDOR_MODE === 'standalone'
              ? '/DoseMeRx_User_Guide.pdf'
              : '/DoseMeRx_Comprehensive_User_Guide.pdf'
          ),
          token,
          <div
            data-testid='user-guide-comp'
            className='download-link-div'
            onMouseLeave={() => setCompDivHover(false)}
            onMouseOver={() => setCompDivHover(true)}
          >
            <div className='link-div-wrapper'>
              <div className='doseme-link-div-title'>User guide</div>
            </div>
            <div data-testid='user-guide-comp-icon' className='download-link-div-import-icon'>
              <Icons.Import width={20} height={20} background={compDivHover ? ADE : undefined} />
            </div>
          </div>
        )}

        {formatExternalLinkDivs(
          downloadDocumentURL(
            window.env.VENDOR_MODE === 'standalone'
              ? '/DoseMeRx_Transition_Guide.pdf'
              : '/DoseMeRx_Data_States_And_Handling_Guide.pdf'
          ),
          token,
          <div
            data-testid={window.env.VENDOR_MODE === 'standalone' ? 'user-guide-transition' : 'user-guide-data'}
            className='download-link-div'
            onMouseLeave={() => setDataDivHover(false)}
            onMouseOver={() => setDataDivHover(true)}
          >
            <div className='link-div-wrapper'>
              <div className='doseme-link-div-title'>
                {window.env.VENDOR_MODE === 'standalone' ? 'Transition guide' : 'Data states'}
              </div>
            </div>
            <div
              data-testid={
                window.env.VENDOR_MODE === 'standalone' ? 'user-guide-transition-icon' : 'user-guide-data-icon'
              }
              className='download-link-div-import-icon'
            >
              <Icons.Import width={20} height={20} background={dataDivHover ? ADE : undefined} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
