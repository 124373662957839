import { makeAutoObservable } from 'mobx'

import { IAdminHospitalDrugGeneralModelSettings } from './types'
import { AdminHospitalDrugDetailsStore } from './AdminHospitalDrugDetailsStore'

export class AdminHospitalDrugGeneralModelSettings implements IAdminHospitalDrugGeneralModelSettings {
  store: AdminHospitalDrugDetailsStore

  id: string
  type: string
  attributes: IAdminHospitalDrugGeneralModelSettings['attributes']

  constructor(store: AdminHospitalDrugDetailsStore, entity: IAdminHospitalDrugGeneralModelSettings) {
    makeAutoObservable(this, {
      store: false
    })

    this.store = store

    this.id = entity.id
    this.type = entity.type
    this.attributes = { ...entity.attributes }
  }
}
