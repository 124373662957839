import { makeAutoObservable } from 'mobx'

import { HospitalStore } from './HospitalStore'
import { IHospital } from './types'

export class Hospital implements IHospital {
  store: HospitalStore

  type: string
  id: string
  attributes: IHospital['attributes']

  constructor(store: HospitalStore, entity: IHospital) {
    makeAutoObservable(this, {
      store: false
    })

    this.store = store
    this.type = entity.type
    this.id = entity.id
    this.attributes = { ...entity.attributes }
  }
}
