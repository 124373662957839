import { ILimit } from '../store/types'

// FIXME: All rounding should be done in the backend
export const roundDecimal = (num: number, places = 1): number => parseFloat(num.toFixed(places))

// for non-validation use cases
// assumes that `value` is automatically in the same units as `limit`
export const isWithinNumericLimits = (value: number, limit: ILimit) => {
  return value <= limit.max.value && value >= limit.min.value
}
