import { CHARCOAL_GREY, GUNMETAL } from '@doseme/cohesive-ui'
import { useEffect, useState } from 'react'

import '../index.scss'

interface IProps {
  percentChange?: number
  amount: string
  viewBox?: {
    x: number
    y: number
    width: number
    height: number
  }
}

///Renders the label on the latest SeCr observation
export const SecrLabel: React.FC<IProps> = (props) => {
  const changeString =
    props.percentChange === undefined
      ? 'Baseline'
      : props.percentChange < 0
        ? `${props.percentChange * -1}%`
        : `${props.percentChange}%`

  const [offsetToLeft, setOffsetToLeft] = useState<number | null>(null)
  const [offsetUpDown, setOffsetUpDown] = useState<number | null>(null)

  useEffect(() => {
    const plotWidth = document.getElementById('secr-x-axis')?.getBoundingClientRect().width
    const plotHeight = document.getElementById('secr-y-axis')?.getBoundingClientRect().height
    const labelWidth = document.getElementById('secr-label')?.getBoundingClientRect().width

    calcOffsets(plotWidth, plotHeight, labelWidth)
  }, [])

  const calcOffsets = (
    plotWidth: number | undefined,
    plotHeight: number | undefined,
    labelWidth: number | undefined
  ) => {
    let newOffSetToLeft = null
    let newOffsetUpDown = null

    if (plotWidth && labelWidth && plotWidth - props.viewBox!.x < labelWidth) {
      newOffSetToLeft = labelWidth + 15
    }

    if (plotHeight && plotHeight - props.viewBox!.y < 15) {
      newOffsetUpDown = -15
    } else if (plotHeight && props.viewBox!.y < 30) {
      newOffsetUpDown = 5
    }

    if (newOffSetToLeft !== offsetToLeft || newOffsetUpDown !== offsetUpDown) {
      setOffsetToLeft(newOffSetToLeft)
      setOffsetUpDown(newOffsetUpDown)
    }
  }

  const plotWidth = document.getElementById('secr-x-axis')?.getBoundingClientRect().width
  const plotHeight = document.getElementById('secr-y-axis')?.getBoundingClientRect().height
  const labelWidth = document.getElementById('secr-label')?.getBoundingClientRect().width

  calcOffsets(plotWidth, plotHeight, labelWidth)

  const xValue = offsetToLeft ? props.viewBox!.x - offsetToLeft : props.viewBox!.x + 10
  const yValue = offsetUpDown ? props.viewBox!.y + offsetUpDown : props.viewBox!.y - 5

  return (
    <svg id='secr-label' xmlns='http://www.w3.org/2000/svg' x={xValue} y={yValue}>
      <text stroke={CHARCOAL_GREY} fill={CHARCOAL_GREY} className='recharts-text' textAnchor={'start'}>
        <tspan className='secr-label-title' x={10} dy='1em'>
          {props.amount}
        </tspan>
        <tspan className='secr-label-sub-title' dy='1.3em' x={props.percentChange === null ? 10 : 23}>
          {changeString}
        </tspan>
      </text>
      <svg y={17} x={10}>
        {props.percentChange && props.percentChange > 0 && (
          <path
            d='M1.30664 5.6543L0.873047 5.2207C0.689453 5.03711 0.689453 4.74023 0.873047 4.55859L4.66797 0.761719C4.85156 0.578125 5.14844 0.578125 5.33008 0.761719L9.125 4.55664C9.30859 4.74023 9.30859 5.03711 9.125 5.21875L8.69141 5.65234C8.50586 5.83789 8.20313 5.83398 8.02148 5.64453L5.78125 3.29297V8.90625C5.78125 9.16602 5.57227 9.375 5.3125 9.375H4.6875C4.42773 9.375 4.21875 9.16602 4.21875 8.90625V3.29297L1.97656 5.64648C1.79492 5.83789 1.49219 5.8418 1.30664 5.6543V5.6543Z'
            fill={GUNMETAL}
          />
        )}
        {props.percentChange && props.percentChange < 0 && (
          <path
            d='M8.69141 4.34473L9.125 4.77832C9.30859 4.96191 9.30859 5.25879 9.125 5.44043L5.33008 9.2373C5.14648 9.4209 4.84961 9.4209 4.66797 9.2373L0.873047 5.44238C0.689453 5.25879 0.689453 4.96191 0.873047 4.78027L1.30664 4.34668C1.49219 4.16113 1.79492 4.16504 1.97656 4.35449L4.2168 6.70605V1.09277C4.2168 0.833008 4.42578 0.624023 4.68555 0.624023H5.31055C5.57031 0.624023 5.7793 0.833008 5.7793 1.09277V6.70605L8.02148 4.35254C8.20313 4.16113 8.50586 4.15723 8.69141 4.34473Z'
            fill={GUNMETAL}
          />
        )}
      </svg>
    </svg>
  )
}
