import { Footer } from '@doseme/cohesive-ui'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

import { useAuthStore, usePatientCoursesStore, useVersionsStore } from '../../hooks/useStore'
import { receiveEpicToken, requestEpicHandshake } from '../OpenWindowEpic'
import { formatExternalLinkDivs } from '../ResourcesRouter/utils'
import { showErrorToast } from '../../shared/toast'

import './index.scss'

interface IProps {
  width: number
}

const IntegratedFooter: React.FC<IProps> = observer((props) => {
  const authStore = useAuthStore()
  const patientCoursesStore = usePatientCoursesStore()
  const versionsStore = useVersionsStore()

  const [token, setToken] = useState<string>()
  const [displayVersions, setDisplayVersions] = useState<boolean>(false)

  useEffect(() => {
    if (window.env.VENDOR_MODE === 'epic') {
      requestEpicHandshake(receiveEpicToken, setToken)
    }
  }, [])

  useEffect(() => {
    if (versionsStore.loadState === 'loadError') {
      showErrorToast(versionsStore.error || 'Failed to retrieve version information')
      setDisplayVersions(false)
    }
  }, [versionsStore.loadState])

  useEffect(() => {
    if (displayVersions && versionsStore.loadState !== 'loaded') {
      versionsStore.fetchVersions()
    }
  }, [displayVersions])

  const drugInformationLink = () => {
    if (authStore.auth?.attributes.patientId
      && ['loaded', 'updating'].includes(patientCoursesStore.loadState)) {
      return (
        <div className='footer-row'>
          <Link to={`/patients/${authStore.auth?.attributes.patientId}/resources/drug-information`}>
            Drug Information
          </Link>
        </div>
      )
    }
  }

  const displayVersionInformation = () => {
    if (displayVersions && versionsStore.loadState === 'loaded') {
      return (
        <div className='display-version-information-footer'>
          <div className='mr-3'>
            IFE: {window.env.VERSION} ({window.env.COMMIT_HASH})
          </div>
          <div className='mr-3'>WWW: {versionsStore.versions?.attributes.backend_version}</div>
          <div className='mr-3'>API: {versionsStore.versions?.attributes.openapi_version}</div>
          <div>Drugs: {versionsStore.versions?.attributes.dpl_version}</div>
        </div>
      )
    }
  }

  return (
    <div className='mt-auto w-100'>
      <Footer
        width={props.width}
        setIconSelected={setDisplayVersions}
        isIconSelected={displayVersions}
        infoBlockOne={
          <div className='co-col-4 d-block'>
            <div className='footer-row'>Important Documents</div>
            <div className='footer-row'>
              {formatExternalLinkDivs(
                'https://doseme-rx.com/tos',
                token,
                <div className='footer-row footer-link'>Terms of Service</div>
              )}
            </div>
            <div className='footer-row'>
              {formatExternalLinkDivs(
                'https://doseme-rx.com/privacy-policy',
                token,
                <div className='footer-row footer-link'>Privacy Policy</div>
              )}
            </div>
          </div>
        }
        infoBlockTwo={
          <div className='co-col-4 d-block'>
            <div className='footer-row font-weight-bold'>Useful Resources</div>
            {drugInformationLink()}
            {formatExternalLinkDivs(
              'https://help.doseme-rx.com/en/',
              token,
              <div className='footer-row footer-link'>Help Center</div>
            )}
          </div>
        }
        infoBlockThree={
          <div className='co-col-4 d-block'>
            <div className='footer-row font-weight-bold'>Compliance</div>
            {formatExternalLinkDivs(
              'https://doseme-rx.com/hipaa',
              token,
              <div className='footer-row footer-link'>HIPAA Privacy Statement</div>
            )}
          </div>
        }
        infoBlockFour={
          <div className='co-col-4 d-block'>
            <div className='footer-row font-weight-bold'>Contact Us</div>
            <div className='footer-row'>US: +1 (832) 358-3308</div>
            <div className='footer-row'>support@dosemehealth.com</div>
          </div>
        }
        versionInformation={displayVersionInformation()}
      />
    </div>
  )
})

export { IntegratedFooter }
