import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'
import { decode } from 'he'
import { InfoModal, Modal, Button } from '@doseme/cohesive-ui'

import { useAdminHospitalDetailsStore, useAdminHospitalDrugDetailsStore } from '../../../../../../../../hooks/useStore'
import { IDrugDetailsModalProps } from '../../types'
import { useFormValidation } from '../../../../../../../../hooks/useFormValidation'
import { buildFormFields, buildInputs } from '../../../../../../../../shared/buildForms'
import { showErrorToast, showSuccessToast } from '../../../../../../../../shared/toast'
import { IPutAdminHospitalDrugSuperAdminInfo } from '../../../../../../../../store/Admin/AdminHospitalDrugDetails/types'
import { findPatientConstraint } from './utils'

import '../../index.scss'

export const SuperAdminSettingsModal: React.FC<IDrugDetailsModalProps> = observer((props) => {
  const adminHospitalDrugDetailsStore = useAdminHospitalDrugDetailsStore()
  const adminHospitalDetailsStore = useAdminHospitalDetailsStore()

  useEffect(() => {
    if (props.show && props.drugId) {
      adminHospitalDrugDetailsStore.fetchAdminHospitalDrugSuperAdminInfo(props.hospitalId, props.drugId)
    }
  }, [props.hospitalId, props.show])

  const formFields = useMemo(() => {
    if (
      adminHospitalDrugDetailsStore.settingsLoadStates.superAdminInfo === 'loaded' &&
      adminHospitalDrugDetailsStore.adminHospitalDrugSuperAdminInfo
    ) {
      const superAdminInfo = adminHospitalDrugDetailsStore.adminHospitalDrugSuperAdminInfo

      return buildFormFields(
        adminHospitalDrugDetailsStore.repackageSuperAdminInfoForBuilding(
          superAdminInfo.attributes.settings.superAdminInfo.patientConstraints,
          superAdminInfo.attributes.name
        )
      )
    }

    return {}
  }, [adminHospitalDrugDetailsStore.settingsLoadStates.superAdminInfo])

  const form = useFormValidation(formFields)

  const formContent = (): JSX.Element => {
    const displayOrder = ['patientConstraints']
    const superAdminInfo = adminHospitalDrugDetailsStore.adminHospitalDrugSuperAdminInfo

    const patientConstraintsList =
      adminHospitalDrugDetailsStore.adminHospitalDrugSuperAdminInfo?.attributes.settings.superAdminInfo.patientConstraints
    const patientConstraints = patientConstraintsList
      ? findPatientConstraint(patientConstraintsList, form.values['patientConstraints'])
      : undefined

    if (superAdminInfo) {
      const returnedSuperAdminInfo = adminHospitalDrugDetailsStore.repackageSuperAdminInfoForBuilding(
        superAdminInfo.attributes.settings.superAdminInfo.patientConstraints,
        superAdminInfo.attributes.name
      )

      return (
        <div className='position-relative w-100'>
          {buildInputs(returnedSuperAdminInfo, form, displayOrder, formFields)}
          <div className='hospital-drug-superadmin-settings-modal-data'>
            <div className='data-row border'>
              <b>Age:</b>
              <div className='data-middle'>
                {patientConstraints?.age.limit.min?.value
                  ? `Min. ${patientConstraints?.age.limit.min?.value} ${patientConstraints?.age.limit.min?.unit?.name}`
                  : '–'
                }
              </div>
              <div className='data-end'>
                {patientConstraints?.age.limit.max?.value
                  ? `Max. ${patientConstraints?.age.limit.max?.value} ${patientConstraints?.age.limit.max?.unit?.name}`
                  : '–'
                }
              </div>
            </div>
            <div className='data-row border'>
              <b>Weight:</b>
              <div className='data-middle'>
                {patientConstraints?.weight.limit.min?.value
                  ? `Min. ${patientConstraints?.weight.limit.min?.value} ${patientConstraints?.weight.limit.min?.unit?.name}`
                  : '–'
                }
              </div>
              <div className='data-end'>
                {patientConstraints?.weight.limit.max?.value
                  ? `Max. ${patientConstraints?.weight.limit.max?.value} ${patientConstraints?.weight.limit.max?.unit?.name}`
                  : '–'
                }
              </div>
            </div>
            <div className='data-row'>
              <b>Height:</b>
              <div className='data-middle'>
                {patientConstraints?.height.limit.min?.value
                  ? `Min. ${patientConstraints?.height.limit.min?.value} ${patientConstraints?.height.limit.min?.unit?.name}`
                  : '–'
                }
              </div>
              <div className='data-end'>
                {patientConstraints?.height.limit.max?.value
                  ? `Max. ${patientConstraints?.height.limit.max?.value} ${patientConstraints?.height.limit.max?.unit?.name}`
                  : '–'
                }
              </div>
            </div>
          </div>
        </div>
      )
    }

    return <></>
  }

  // As this is an edit form, show all validation statuses for fields on initial render
  useEffect(() => {
    if (adminHospitalDrugDetailsStore.settingsLoadStates.superAdminInfo === 'loaded') {
      form.reset()
    }
  }, [adminHospitalDrugDetailsStore.settingsLoadStates.superAdminInfo])

  const handleSubmit = async () => {
    if (props.drugId && adminHospitalDrugDetailsStore.adminHospitalDrugSuperAdminInfo) {
      const responseStructure = {
        name: adminHospitalDrugDetailsStore.adminHospitalDrugSuperAdminInfo.attributes.name,
        settings: {
          superAdminInfo: {
            patientConstraints: form.values.patientConstraints?.toString() || null
          }
        }
      }

      await adminHospitalDrugDetailsStore.putAdminHospitalDrugSuperAdminInfo(
        props.hospitalId,
        props.drugId,
        responseStructure as IPutAdminHospitalDrugSuperAdminInfo
      )
      if (['loadError', 'updateError'].includes(adminHospitalDrugDetailsStore.settingsLoadStates.superAdminInfo)) {
        showErrorToast(
          adminHospitalDrugDetailsStore.settingsErrors.superAdminInfo || 'Failed to update super admin info'
        )

        return
      }
      showSuccessToast('Super admin info updated')
      props.setShow(false)
    }
  }

  const handleClose = () => {
    props.setShow(false)
    form.reset()
  }

  return (
    <Modal show={props.show} onHide={handleClose}>
      <InfoModal
        size='s'
        loading={['loading', 'updating'].includes(adminHospitalDrugDetailsStore.settingsLoadStates.superAdminInfo)}
        linkComponent={
          <>
            <Button
              className='mr-3'
              data-testid='cancel-btn'
              disabled={false}
              loading={['loading', 'updating'].includes(
                adminHospitalDrugDetailsStore.settingsLoadStates.superAdminInfo
              )}
              onClick={handleClose}
              variant='primary-outline'
            >
              Cancel
            </Button>
            <Button
              data-testid='save-btn'
              disabled={!form.valid}
              loading={['loading', 'updating'].includes(
                adminHospitalDrugDetailsStore.settingsLoadStates.superAdminInfo
              )}
              onClick={handleSubmit}
              variant='primary'
            >
              Save
            </Button>
          </>
        }
        title='Superadmin settings'
        subtitle={
          <div className='hospital-drug-model-settings-modal-subtitle'>
            <b>{adminHospitalDrugDetailsStore.adminHospitalDrugDetails?.attributes.name}</b>
            <div className='mt-2'>{decode(adminHospitalDetailsStore.adminHospitalDetails?.attributes.details.name || '')}</div>
          </div>
        }
        message={formContent()}
      />
    </Modal>
  )
})
