import { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Icons, InfoBubble, LOADING_GREY, TextInput } from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'

import { showErrorToast, showSuccessToast } from '../../../../shared/toast'
import { useAuthStore } from '../../../../hooks/useStore'
import { TOSFooter } from '../TOSFooter'
import { IFormState } from '../../../../types/validation'
import { PasswordPanel } from '../PasswordPanel'

import '../../index.scss'

export const SetPasswordPage: React.FC = observer(() => {
  const [isLinkInvalid, setIsLinkInvalid] = useState<boolean>(false)
  const [passwordUpdateError, setPasswordUpdateError] = useState<string | undefined>()

  const authStore = useAuthStore()

  const { search } = useLocation()

  const history = useHistory()

  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  useEffect(() => {
    const token = searchParams.get('token')

    if (!token) {
      setIsLinkInvalid(true)
    } else {
      authStore.authVerifyToken(token)
    }
  }, [])

  const savePassword = async (form: IFormState) => {
    const email = authStore.invitationDetails?.attributes.email
    const token = authStore.invitationDetails?.attributes.resetToken
    const verificationCode = authStore.invitationDetails?.attributes.code

    if (!email || !token || !verificationCode) {
      return
    }

    await authStore.authResetPassword(email, form.values['newPassword'], verificationCode, token)

    if (['loadError', 'updateError'].includes(authStore.loadState)) {
      // Check if the request failed because of a password issue
      if (authStore.error !== 'Invalid password reset request'
        && (authStore.error.includes('password') || authStore.error.includes('Password'))) {
        setPasswordUpdateError(authStore.error)

        return
      }

      showErrorToast(authStore.error || 'Failed to set password')

      return
    }

    showSuccessToast('Saved password')
    history.push('/login')
  }

  const emailField = () => {
    const emailAddress = searchParams.get('email')

    if (!emailAddress) {
      return null
    }

    return (
      <div className='mt-4'>
        <TextInput label={<b>Email:</b>} disabled value={emailAddress} fieldState='valid' />
      </div>
    )
  }

  const getContent = (): JSX.Element | null => {
    if (authStore.loadState === 'initial' || authStore.loadState === 'loading') {
      return (
        <>
          <div className='login-page-panel sso-page-panel'>
            <div className='login-page-title'>Verifying access token...</div>
            <div className='login-page-subtitle'>Please wait a moment</div>
            <div className='sso-loading-spinner'>
              <Icons.ThinSpinner strokeWidth={16} r={30} stroke={LOADING_GREY} width='32px' />
            </div>
          </div>
        </>
      )
    }

    if (authStore.loadState === 'loadError' || isLinkInvalid) {
      return (
        <>
          <div className='login-page-panel invalid-link-page-panel'>
            <div className='invalid-link-title'>Oh no! Invalid link</div>
            <div className='login-page-subtitle'>It looks like this invitation link is no longer valid.</div>
            <div className='invalid-link-body'>
              Invite links can only be used once, and they are only valid for a certain amount of time
            </div>
            <div className='invalid-link-info-bubble'>
              <InfoBubble
                bubbleTitle='Please contact your site manager to resend your invitation link,
                or contact the DoseMe support team if you require further assistance.'
              />
            </div>
          </div>
        </>
      )
    }

    if (authStore.loadState === 'loaded' && authStore.invitationDetails?.attributes) {
      return (
        <div className='login-page-panel larger-width'>
          <div className='login-page-title'>
            Hi there {searchParams.get('firstName')}! <br />
            You've been invited to join DoseMeRx
          </div>

          <div className='login-page-password-subtitle'>
            Create a password to finish setting up your profile.
          </div>
          <div className='login-page-fields-section'>
            {emailField()}
            <PasswordPanel savePassword={savePassword} passwordUpdateError={passwordUpdateError} isOnboardingPage={true}/>
          </div>
        </div>
      )
    }

    return null
  }

  return (
    <div>
      {getContent()}
      <TOSFooter />
    </div>
  )
})
