import { makeAutoObservable } from 'mobx'

import { IAdminVendorSiteListItem } from './types'
import { AdminVendorSiteListStore } from './AdminVendorSiteListStore'

export class AdminVendorSiteListItem implements IAdminVendorSiteListItem {
  store: AdminVendorSiteListStore

  id: string
  type: string
  attributes: IAdminVendorSiteListItem['attributes']

  constructor(store: AdminVendorSiteListStore, entity: IAdminVendorSiteListItem) {
    makeAutoObservable(this, {
      store: false
    })

    this.store = store

    this.id = entity.id
    this.type = entity.type
    this.attributes = { ...entity.attributes }
  }
}
