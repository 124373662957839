import { TFieldState } from '@doseme/cohesive-ui'
import { useState } from 'react'

import {
  IFieldValidationParams,
  IFormField,
  IFormFieldInternalState,
  IFormFieldValidityDisplay,
  IFormState,
  ValidationError
} from '../types/validation'

export const fieldGuard = (fields: Record<string, IFormField | IFormFieldInternalState>, fieldKey: string): void => {
  if (!fields[fieldKey]) {
    throw new Error(`Field ${fieldKey} does not exist`)
  }
}

export const throwNonValidationErrors = (error: Error): string => {
  if (!(error instanceof ValidationError)) {
    throw error
  }

  return error.toString()
}

const validateFieldAgainstRules = (
  fields: Record<string, IFormField>,
  fieldKey: string,
  input: any,
  constraints?: any
): void => {
  for (const validationRule of fields[fieldKey].rules) {
    validationRule({ input, constraints })
  }
}

const getInitialFormFieldState = (fields: Record<string, IFormField>): Record<string, IFormFieldInternalState> => {
  return Object.keys(fields).reduce<Record<string, IFormFieldInternalState>>((acc, curr) => {
    fieldGuard(fields, curr)

    let isValid = false
    let error = ''
    const currentField = fields[curr]

    try {
      validateFieldAgainstRules(fields, curr, currentField.initialInput, currentField.initialConstraints)
      isValid = true
    } catch (e) {
      error = throwNonValidationErrors(e as Error)
    }

    const formattedValue = fields[curr].formatter?.(fields[curr].initialInput, fields[curr].formatterConstraints)

    return {
      ...acc,
      [curr]: {
        ...fields[curr],
        input: fields[curr].initialInput,
        value: formattedValue !== undefined
          ? formattedValue
          : fields[curr].initialInput,
        state: isValid ? 'valid' : 'error',
        statusText: error
      }
    }
  }, {})
}

export const useFormValidation = (fields: Record<string, IFormField>): IFormState => {
  const [formState, setFormState] = useState<Record<string, IFormFieldInternalState>>(getInitialFormFieldState(fields))
  const [displayState, setDisplayState] = useState<Record<string, IFormFieldValidityDisplay>>({})

  const validateFields = (formFields: IFieldValidationParams[], updateFieldsDisplay?: 'updateFieldsDisplay'): void => {
    const formChangeset = formFields.reduce<Record<string, IFormFieldInternalState>>((acc, curr) => {
      fieldGuard(formState, curr.field)

      let isValid = false
      let error = ''

      try {
        validateFieldAgainstRules(fields, curr.field, curr.input, curr.constraints)
        isValid = true
      } catch (e) {
        error = throwNonValidationErrors(e as Error)
      }

      const formattedValue = fields[curr.field].formatter?.(curr.input, fields[curr.field].formatterConstraints)

      return {
        ...acc,
        [curr.field]: {
          ...acc[curr.field],
          input: curr.input,
          value: formattedValue !== null && formattedValue !== undefined ? formattedValue : curr.input,
          state: isValid ? 'valid' : 'error',
          statusText: error
        }
      }
    }, formState)

    setFormState(formChangeset)

    if (updateFieldsDisplay) {
      const displayChangeset = formFields.reduce<Record<string, IFormFieldValidityDisplay>>((acc, curr) => {
        fieldGuard(formChangeset, curr.field)

        return {
          ...acc,
          [curr.field]: {
            state: formChangeset[curr.field].state,
            statusText: formChangeset[curr.field].statusText
          }
        }
      }, displayState)

      setDisplayState(displayChangeset)
    }
  }

  const updateFieldsDisplay = (formFields: string[]): Map<string, string> => {
    const changeset = formFields.reduce<Record<string, IFormFieldValidityDisplay>>((acc, field) => {
      fieldGuard(formState, field)

      return {
        ...acc,
        [field]: {
          state: formState[field].state,
          statusText: formState[field].statusText
        }
      }
    }, displayState)

    setDisplayState(changeset)

    const errorMap = new Map()

    // Array.forEach also always executes in the same order
    Object.keys(formState).forEach((fieldKey) => {
      const status = formState[fieldKey]?.statusText

      if (status) {
        errorMap.set(fieldKey, status)
      }
    })

    return errorMap
  }

  const getValidState = (field: string): TFieldState => {
    return displayState[field]?.state || 'valid'
  }

  const getValidationMsg = (field: string): string => {
    return displayState[field]?.statusText || ''
  }

  const valid: boolean = Object.keys(formState).every((key) => formState[key].state === 'valid')

  const inputs = Object.keys(formState).reduce<Record<string, any>>((acc, curr) => {
    return {
      ...acc,
      [curr]: formState[curr].input
    }
  }, {})

  const values = Object.keys(formState).reduce<Record<string, any>>((acc, curr) => {
    return {
      ...acc,
      [curr]: formState[curr].value
    }
  }, {})

  const reset = (): void => {
    setFormState(getInitialFormFieldState(fields))
    setDisplayState({})
  }

  // Operations on ES6 Map objects are guaranteed to preserve order
  const getErrorMap = (): Map<string, string> => {
    const errorMap = new Map()

    // Array.forEach also always executes in the same order
    Object.keys(formState).forEach((fieldKey) => {
      const status = getValidationMsg(fieldKey)

      if (status) {
        errorMap.set(fieldKey, status)
      }
    })

    return errorMap
  }

  // This is useful for checking whether the form state has been changed.
  // To do so, you can store it in a state variable or use in a dependency array.
  // useEffect deparrays only do a shallow comparison, so serialisation is necessary.
  const jsonState = JSON.stringify(formState)

  return {
    inputs,
    values,
    valid,
    validateFields,
    updateFieldsDisplay,
    getValidState,
    getValidationMsg,
    reset,
    jsonState,
    getErrorMap
  }
}
