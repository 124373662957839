import { makeAutoObservable } from 'mobx'

import { PasswordRulesStore } from './PasswordRulesStore'
import { IPasswordRules } from './types'

export class PasswordRules implements IPasswordRules {
  store: PasswordRulesStore

  type: 'authClinicianPasswordRules'
  attributes: IPasswordRules['attributes']

  constructor(store: PasswordRulesStore, entity: IPasswordRules) {
    makeAutoObservable(this, {
      store: false
    })

    this.store = store

    this.type = entity.type

    this.attributes = { ...entity.attributes }
  }
}
