import { ITab } from '@doseme/cohesive-ui'

import { IChangeLogItem } from '../../../../store/Admin/AdminChangeLog/types'

export const sortChangeLogs = (changeLogs: IChangeLogItem[]) => {
  return changeLogs.sort((a: IChangeLogItem, b: IChangeLogItem) => {
    const one = a.attributes.created
    const two = b.attributes.created

    if (one === two) {
      return 0
    }

    if (!one) {
      return 1
    }

    if (!two) {
      return -1
    }

    return one > two ? 1 : -1
  })
}

export const getChangeLogTabs = (isSuperAdmin?: boolean): ITab[] => {
  const tabs: ITab[] = [
    { id: 'all', displayName: 'All settings' },
    { id: 'hospital', displayName: 'Hospital settings' },
    { id: 'clinician', displayName: 'Clinicians' },
    { id: 'drug', displayName: 'Drug settings' }
  ]

  if (isSuperAdmin) {
    tabs.push(
      { id: 'vendor', displayName: 'Vendors' }
    )
  }

  return tabs
}
