import axios, { AxiosInstance, AxiosRequestConfig, AxiosError, AxiosResponse,  CancelTokenSource, isAxiosError, isCancel } from 'axios'
import axiosRetry from 'axios-retry'

const CancelToken = axios.CancelToken

// Create an Axios instance
const axiosClient: AxiosInstance = axios.create({
  timeout: 0
})

// Configure axios-retry to retry only on network errors
axiosRetry(axiosClient, {
  retries: 3, // Number of retry attempts
  retryDelay: (retryCount: number) => {
    return retryCount * 1000 // Exponential backoff (1 second, 2 seconds, 3 seconds)
  },
  retryCondition: (error: AxiosError) => {
    return error.code === 'ECONNABORTED' || // Request was aborted (timeout)
      error.code === 'ENOTFOUND' || // DNS lookup failed
      error.code === 'ECONNRESET' || // Connection was reset
      error.code === 'ETIMEDOUT' || // Connection timed out
      axiosRetry.isNetworkError(error) // Any other network error
  }
})

export default axiosClient
export { isAxiosError, isCancel, CancelToken }
export type { AxiosRequestConfig, AxiosResponse, AxiosError, CancelTokenSource }
