export const handleSeparatorThreshold = (
  setSeparator: (show: boolean) => void,
  listElementScrollPosition?: number,
  parentElementScrollPosition?: number,
  separatorBufferPx?: number
) => {
  const shouldShowSeparator = !!(listElementScrollPosition && parentElementScrollPosition &&
    (listElementScrollPosition < (parentElementScrollPosition + (separatorBufferPx || 0))))

  setSeparator(shouldShowSeparator)
}
