import { makeAutoObservable } from 'mobx'

import { IAdminClinicianInvite } from './types'
import { AdminClinicianDetailsStore } from './AdminClinicianDetailsStore'

export class AdminClinicianInvite implements IAdminClinicianInvite {
  store: AdminClinicianDetailsStore

  id: string
  type: 'adminClinicianInviteRead'
  attributes: IAdminClinicianInvite['attributes']

  constructor(store: AdminClinicianDetailsStore, entity: IAdminClinicianInvite) {
    makeAutoObservable(this, {
      store: false
    })

    this.store = store

    this.id = entity.id
    this.type = entity.type
    this.attributes = { ...entity.attributes }
  }
}
