import { IPasswordRules } from '../../store/passwordRules/types'
import { IPasswordRulesCheck } from './types'

export const checkPasswordRules = (newPassword: string, confirmPassword: string, rules: IPasswordRules['attributes']): IPasswordRulesCheck => {
  const passwordCheck: IPasswordRulesCheck = {
    upperCaseLetter: false,
    lowerCaseLetter: false,
    number: false,
    characterLength: false,
    passwordsMatch: false
  }

  if (newPassword.length >= rules.minLength && newPassword.length <= rules.maxLength) {
    passwordCheck.characterLength = true
  }

  if (/[0-9\s!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/.test(newPassword)) {
    passwordCheck.number = true
  }

  if (/[a-z]/.test(newPassword)) {
    passwordCheck.lowerCaseLetter = true
  }

  if (/[A-Z]/.test(newPassword)) {
    passwordCheck.upperCaseLetter = true
  }

  if (newPassword === confirmPassword) {
    passwordCheck.passwordsMatch = true
  }

  return passwordCheck
}

export const checkPasswordValid = (checkedRules: IPasswordRulesCheck, rules: IPasswordRules['attributes']): boolean => {
  if (!checkedRules.characterLength) {
    return false
  }

  if (!checkedRules.lowerCaseLetter && rules.requiresLowerCase) {
    return false
  }

  if (!checkedRules.upperCaseLetter && rules.requiresUpperCase) {
    return false
  }

  if (!checkedRules.number && rules.requiresNumOrSymbol) {
    return false
  }

  if (!checkedRules.passwordsMatch) {
    return false
  }

  return true
}
