import { makeAutoObservable } from 'mobx'

import { IAddPatient } from './types'
import { PatientStore } from './PatientStore'

export class AddPatient implements IAddPatient {
  store: PatientStore

  type: 'hospitalPatientNewRead'
  attributes: IAddPatient['attributes']

  constructor(store: PatientStore, entity: IAddPatient) {
    makeAutoObservable(this, {
      store: false
    })

    this.store = store

    this.type = entity.type
    this.attributes = { ...entity.attributes }
  }
}
