import { observer } from 'mobx-react-lite'
import { ActionButton, SidePanel } from '@doseme/cohesive-ui'

import { IDrugDetailsPanelWithModalProps } from '../../types'
import { useAdminHospitalDrugDetailsStore } from '../../../../../../../../hooks/useStore'

import '../../index.scss'

export const SuperadminSettings: React.FC<IDrugDetailsPanelWithModalProps> = observer((props) => {
  const adminHospitalDrugDetailsStore = useAdminHospitalDrugDetailsStore()

  const displayPanel = (data: any): JSX.Element => {
    return (
      <div className='display-formatted-details'>
        <div className='no-modal-panel-data-row'>
          <div className='no-modal-panel-title-row'>
            <b>Patient constraints:</b>
            <div className='no-modal-panel-data-row-column-1'>{`${data.patientConstraints.value} ${
              data.patientConstraints.isDefault ? '(Default)' : ''
            }`}
            </div>
          </div>
        </div>
        <hr></hr>
        <div className='no-modal-panel-data-row'>
          <div className='no-modal-panel-title-row'>
            <b>Age limits:</b>
            <div className='no-modal-panel-data-row-column-1'>
              {data.patientConstraints.age.limit.default?.value
                ? 'Default ' + data.patientConstraints.age.default?.value
                : '–'}
            </div>
            <div className='no-modal-panel-data-row-column-2'>
              {data.patientConstraints.age.limit.min?.value
                ? 'Min. ' + data.patientConstraints.age.limit.min?.value
                : '–'}
            </div>
            <div className='no-modal-panel-data-row-column-3'>
              {data.patientConstraints.age.limit.max?.value
                ? 'Max. ' + data.patientConstraints.age.limit.max?.value
                : '–'}
            </div>
          </div>
        </div>
        <hr></hr>
        <div className='no-modal-panel-data-row'>
          <div className='no-modal-panel-title-row'>
            <b>Weight limits:</b>
            <div className='no-modal-panel-data-row-column-1'>
              {data.patientConstraints.weight.limit.default?.value
                ? 'Default ' + data.patientConstraints.weight.default?.value
                : '–'}
            </div>
            <div className='no-modal-panel-data-row-column-2'>
              {data.patientConstraints.weight.limit.min?.value
                ? 'Min. ' + data.patientConstraints.weight.limit.min?.value
                : '–'}
            </div>
            <div className='no-modal-panel-data-row-column-3'>
              {data.patientConstraints.weight.limit.max?.value
                ? 'Max. ' + data.patientConstraints.weight.limit.max?.value
                : '–'}
            </div>
          </div>
        </div>
        <hr></hr>
        <div className='no-modal-panel-data-row'>
          <div className='no-modal-panel-title-row'>
            <b>Height limits:</b>
            <div className='no-modal-panel-data-row-column-1'>
              {data.patientConstraints.height.limit.default?.value
                ? 'Default ' + data.patientConstraints.height.default?.value
                : '–'}
            </div>
            <div className='no-modal-panel-data-row-column-2'>
              {data.patientConstraints.height.limit.min?.value
                ? 'Min. ' + data.patientConstraints.height.limit.min?.value
                : '–'}
            </div>
            <div className='no-modal-panel-data-row-column-3'>
              {data.patientConstraints.height.limit.max?.value
                ? 'Max. ' + data.patientConstraints.height.limit.max?.value
                : '–'}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='mt-3'>
      <SidePanel
        title='Superadmin settings'
        loading={
          ['loading', 'updating'].includes(adminHospitalDrugDetailsStore.loadState)
          || ['loading', 'updating'].includes(adminHospitalDrugDetailsStore.settingsLoadStates.recordedCourseData)
        }
        actionButton={adminHospitalDrugDetailsStore.loadState === 'loaded' && props.data
          ? (
            <ActionButton
              data-testid='hospital-drug-details-data-btn'
              actionType='edit'
              onClick={() => props.setShow(true)}
              customLabel='Manage'
            />
          )
          : undefined
        }
      >
        {props.data && displayPanel(props.data)}
      </SidePanel>
    </div>
  )
})
