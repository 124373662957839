import { Icons } from '@doseme/cohesive-ui'

import './index.scss'

interface IProps {
  validationErrors: Array<[string, string]>
}

export const ValidationErrorsDisplay: React.FC<IProps> = (props) => {
  if (!props.validationErrors.length) {
    return null
  }

  const formattedValidationErrors: JSX.Element[] = props.validationErrors.map((validationError, idx) => (
    <div
      className='d-flex align-items-center justify-content-start mt-2'
      key={`validation-error-${idx}`}
    >
      <div className='font-bold validation-error-number mr-2'>{idx + 1}</div>
      <div className='validation-error-text'>{validationError[1]}</div>
    </div>
  ))

  return (
    <div className='my-4 pl-1'>
      <div className='d-flex align-items-center validation-error-title'>
        <div className='font-bold mr-1'>Some form fields are invalid</div>
        <Icons.Error/>
      </div>
      <div className='validation-list'>
        {formattedValidationErrors}
      </div>
    </div>
  )
}
