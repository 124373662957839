import { IHeaderItem } from '@doseme/cohesive-ui'

export const itemsPerPage = 5

export const clinicianHospitalColumns = (handleSort: (colIndex: number, ascending: boolean) => void): IHeaderItem[] => [
  {
    name: 'Name',
    handleSort,
    width: 12
  }
]
