import { InfoModal, Modal, Button } from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'

import { useAdminHospitalDetailsStore } from '../../../../../../../hooks/useStore'
import { showErrorToast, showSuccessToast } from '../../../../../../../shared/toast'
import { IPutAdminHospitalCoreDetails } from '../../../../../../../store/Admin/AdminHospitalDetails/types'
import { IModalProps } from '../../../../../../PatientRouter/types'
import { buildFormFields, buildInputs } from '../../../../../../../shared/buildForms'
import { useFormValidation } from '../../../../../../../hooks/useFormValidation'

interface IProps extends IModalProps {
  hospitalId: string
}

const HospitalCoreDetailsModal: React.FC<IProps> = observer((props) => {
  const adminHospitalDetailsStore = useAdminHospitalDetailsStore()

  useEffect(() => {
    if (props.show) {
      adminHospitalDetailsStore.fetchAdminHospitalCoreDetails(props.hospitalId)
    }
  }, [props.hospitalId, props.show])

  const formFields = useMemo(() => {
    if (['loaded', 'updateError'].includes(adminHospitalDetailsStore.settingsLoadStates.coreDetails)) {
      return buildFormFields(adminHospitalDetailsStore.adminHospitalCoreDetails)
    }

    return {}
  }, [adminHospitalDetailsStore.settingsLoadStates.coreDetails])

  const form = useFormValidation(formFields)

  useEffect(() => {
    if (adminHospitalDetailsStore.settingsLoadStates.coreDetails === 'loaded') {
      form.reset()
    }
  }, [adminHospitalDetailsStore.settingsLoadStates.coreDetails])

  const formContent = (): JSX.Element => {
    const displayOrder = ['name', 'timezone', 'loginMethod']

    if (adminHospitalDetailsStore.adminHospitalCoreDetails) {
      return (
        <div className='position-relative w-100'>
          {buildInputs(adminHospitalDetailsStore.adminHospitalCoreDetails, form, displayOrder, formFields)}
        </div>
      )
    }

    return <></>
  }

  const handleSubmit = async () => {
    await adminHospitalDetailsStore.putAdminHospitalCoreDetails(props.hospitalId, form.values as IPutAdminHospitalCoreDetails)

    if (['loadError', 'updateError'].includes(adminHospitalDetailsStore.settingsLoadStates.coreDetails)) {
      showErrorToast(adminHospitalDetailsStore.settingsErrors.coreDetails || 'Failed to update Hospital Details')

      return
    }

    showSuccessToast('Hospital Details updated')
    props.setShow(false)
  }

  const handleClose = () => {
    props.setShow(false)
    form.reset()
  }

  return (
    <Modal
      show={props.show}
      onHide={handleClose}
    >
      <InfoModal
        size='s'
        loading={['loading', 'updating'].includes(adminHospitalDetailsStore.settingsLoadStates.coreDetails)}
        linkComponent={
          <>
            <Button
              className='mr-3'
              data-testid='cancel-btn'
              disabled={false}
              loading={['loading', 'updating'].includes(adminHospitalDetailsStore.settingsLoadStates.coreDetails) }
              onClick={handleClose}
              variant='primary-outline'
            >
              Cancel
            </Button>
            <Button
              data-testid='save-btn'
              disabled={!form.valid || ['loading', 'updating'].includes(adminHospitalDetailsStore.settingsLoadStates.coreDetails)}
              loading={['loading', 'updating'].includes(adminHospitalDetailsStore.settingsLoadStates.coreDetails)}
              onClick={handleSubmit}
              variant='primary'
            >
              Save
            </Button>
          </>
        }
        title='Edit hospital details'
        message={formContent()}
      />
    </Modal>
  )
})

export { HospitalCoreDetailsModal }
