import { observer } from 'mobx-react-lite'
import { DatePickerInput, TimeInput, ITimeState } from '@doseme/cohesive-ui'

interface IProps {
  disabled?: boolean
  hospitalTimezone: string
  nextDoseAtDate: Date
  nextDoseAtTime: ITimeState
  minDate: Date | null
  maxDate: Date | null
  onUpdateDate: (date: Date) => void // naive date object, assumed to be in hospital timezone
  onUpdateTime: (time: ITimeState) => void
}

const NextDoseAtDateTime: React.FC<IProps> = observer((props) => {
  const minAndMaxDateProps = {
    ...(props.minDate && { minDate: props.minDate }),
    ...(props.maxDate && { maxDate: props.maxDate })
  }

  return (
    <>
      <div className='sim-date-picker'>
        <DatePickerInput
          disabled={props.disabled}
          fieldState='valid'
          hasValidation={false}
          value={props.nextDoseAtDate}
          onChange={props.onUpdateDate}
          testId='simulation-panel-date-input'
          {...minAndMaxDateProps}
        />
      </div>

      <div className='ml-2 mr-2 sim-time-picker'>
        <TimeInput
          disabled={props.disabled}
          fieldState='valid'
          hasValidation={false}
          testIdHh='simulation-panel-time-input-hh'
          testIdMm='simulation-panel-time-input-mm'
          value={props.nextDoseAtTime}
          onChange={props.onUpdateTime}
        />
      </div>
    </>
  )
})

export { NextDoseAtDateTime }
